import { UserRoles } from "../Constants/userRoles";
// initial state
const initialState = {
    userRole: []
}

export const UserRoleReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case UserRoles.SET_USERROLES:
            return { ...state, user: payload.data };

    
        default:
            return state;
    }
};