import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { connect } from 'react-redux';
import * as url from "../../api/uri_helper"
import { fetchCompanyLocationData, fetchLocationProducts } from "../../Redux/Api/fetch.api";
import { Modal } from 'antd';
import 'antd/dist/antd.css';
import Loader from "../loader";
import toast, { Toaster } from 'react-hot-toast';
//import Message from 'antd-message';
// Import Layout
import Layout from '../Layout';

// Import static images
import Add from "../../assets/images/Add.svg";
import Edit_light from "../../assets/images/Edit_light.svg";
import Trash_light from "../../assets/images/Trash_light.svg";
import Table, { TableProps } from "antd/lib/table";
import { setCompanyLocationData, deleteCompanyLocationData } from "../../Redux/Actions/RelationActions";
import axios from "axios";

const IndexLocationType = () => {
    const allLocationCompany = useSelector((state) => state.allCompanyLocationData.relation);
    const dispatch = useDispatch();
    const history = useHistory()
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState()
    const [totalIndex, setCurrentIndex] = useState()
    const [sort, setSort] = useState('');
    const [sortloc, setSortLoc] = useState('');
    const [options, setOptions] = useState({
        pagination: { current: 1, pageSizeOptions: [5, 10, 20, 50] },

    });
    const [table, setTable] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)

        getCompanyLocationData(options)
    }, [])

    const getCompanyLocationData = (options) => {
        setLoading(true)
        fetchCompanyLocationData({
            PageIndex: options.pagination.current,
            // PageSize: 5,
        })
            .then(response => {
                dispatch(setCompanyLocationData(response.data))
                setTable(response.data.data)
                setTotal(response.data.count)

                setOptions({
                    ...options,
                    pagination: {
                        current: options.pagination.current,
                        total: response.data.count,
                        pageSizeOptions: ['10', '20', '50'],
                        showSizeChanger: true,
                    }
                })
                // setLoading(false);
            })
            .catch((err) => {
                console.log("Err: ", err);
            })
        setTimeout(() => {
            setLoading(false)
        }, 2000)

    }
    const handleChange = (pagination, sorter, current) => {

        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        getCompanyLocationData({
            ...updatedOptions,

        });

    };
    const deleteLocationCompanyData = async (id) => {
        setLoading(true)
        await axios.delete(url.DELETE_COMPANYLOCATIONDATA + '/' + id, {

        }).then(response => {
            dispatch(deleteCompanyLocationData(response.data))
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            toast.success(response.data.message,{
                className:"toastSuccess",
            }, );
            getCompanyLocationData(options)

        }).catch((err) => {
            console.log("Err: ", err);
        });
        setLoading(true)

    }

    const { confirm } = Modal;
    const handleDelete = (id) => {
        confirm({
            className: "ant-modal",
            title: 'Are you sure you want to delete this record?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteLocationCompanyData(id)
            },
            onCancel() {
            },
        });
    }

   

    const handleEdit = (id) => {
        history.push('/Relation/Edit/' + id)
        
    }

    const uniqueIds = [];
    const filterDataCompany = data => formatter => data.filter(element => {
        const isDublicate = uniqueIds.includes(element.company.companyName);

        if (!isDublicate) {
            uniqueIds.push(element.company.companyName);
            return true;
        }
        return false;
    }).map(item => ({
        text: formatter(item),
        value: formatter(item)

    }));

    const filterDataLocation = data => formatter => data.filter(element => {
        const isDublicate = uniqueIds.includes(element.location.name);

        if (!isDublicate) {
            uniqueIds.push(element.location.name);
            return true;
        }
        return false;
    }).map(item => ({
        text: formatter(item),
        value: formatter(item)

    }));
    

    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "",
            // render: (text, record, index) => `${(options.pagination.current - 1) * 10 + index + 1}`
            //render: (text, record, index) => `${(options.pagination.current === undefined ? 0 :
            //    options.pagination.current - 1) * 10 + index + 1}`
            render: (text, record, index) => { setCurrentIndex(`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}` ); return `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}`},


        },
           
        {
            title: "Company",
            dataIndex: "companyName",
            render: (data, company) => (company.company.companyName),
           
            sorter: (a, b) => a.company.companyName.localeCompare(b.company.companyName),
            filters: filterDataCompany(table)(i => i.company.companyName),
           
            onFilter: (value, record) => record.company.companyName.indexOf(value) === 0,
           
            sortOrder: sort,
            onHeaderCell: () => ({
                onClick: () => setSort(sort === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            })
           
        },
        {
            title: "Location",
            dataIndex: "name",
            render: (data, location) => (location.location.name),
            filters: filterDataLocation(table)((item, i) => item.location.name),
            onFilter: (value, record) => record.location.name === value,
            sorter: (a, b) => a.location.name.localeCompare(b.location.name),
            sortOrder: sortloc,
            onHeaderCell: () => ({
                onClick: () => setSortLoc(sortloc === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            })
            
        },

        {
            title: "Status",
            dataIndex: "active",
            render: (data) => (data ? "Active" : "Inactive"),
            filters: [
                { text: 'Active', value: true },
                { text: 'Inactive', value: false }
            ] ,
            onFilter: (value, record) => {
                return record.active === value
                }
            
        },

        {
            title: "Action ",
            dataIndex: "",
            render: (data, id) => (<><button className="table-btn" >
                <img src={Edit_light} onClick={() => handleEdit(data.id)}></img>
            </button>
                <button className="table-btn" >
                    <img src={Trash_light} onClick={() => handleDelete(data.id)}></img>
           </button></>)
        },
    ]

    return (
        <Layout title="Location Mapping"><Toaster/>
            <div className="position-relative">
                <div className="card-box-header d-inline-flex w-100 align-items-center">
                    <h4 className="m-0">Location Mapping</h4>
                    <div className="ms-auto">
                        <Link className="btn btn-primary d-flex align-items-center" to="/Relation/Add"><img src={Add}></img>New Mapping</Link>
                    </div>
                </div>

            </div>
            <div className="table-responsive table-new position-relative">
            {loading ? <Loader /> : ''}
                <Table

                    columns={columns}
                    dataSource={table}
                    pagination={options.pagination.total > 10 && totalIndex > 9 ? options.pagination : false}
                    onChange={handleChange}
                    paginationTotalRows={total}
                    
                />
            </div>
        </Layout>
    )
};
export default connect()(IndexLocationType);
