import { CompanyLocationData } from "../Constants/Relation-Types";

export const setCompanyLocationData = (relation) => {
    return {
        type: CompanyLocationData.SET_COMPANYLOCATIONDATA,
        payload: relation
    };
};


//Add RelationData
export const addCompanyLocationData = (relation) => {
    return {
        type: CompanyLocationData.ADD_COMPANYLOCATIONDATA,
        payload: relation
    };
};
export const updateCompanyLocationData = (relation) => {
    return {
        type: CompanyLocationData.UPDATE_COMPANYLOCATIONDATA,
        payload: relation
    };
};

export const deleteCompanyLocationData = (relation) => {
    return {
        type: CompanyLocationData.DELETE_COMPANYLOCATIONDATA,
        payload: relation
    };
};