
﻿import React, { useState, useEffect } from "react";
import Cropper from "react-easy-crop";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@material-ui/core";
import { cropImage } from "../QR/CropUtils";
const ImageCropper = ({
    open,
    image,
    onComplete,
    onCancel,
    containerStyle,
    ...props
}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [cropWidth, setCropWidth] = useState(200); // Initial width
    const [cropHeight, setCropHeight] = useState(200);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        // Reset the isDisabled state when a new image is uploaded
        setIsDisabled(false);
    }, [image]);

    const handleWidthChange = (e) => {
        setCropWidth(Number(e.target.value));
    };

    const handleHeightChange = (e) => {
        setCropHeight(Number(e.target.value));
    };

    const handleCropClick = () => {
        setIsDisabled(true); // Disable the button after it's clicked
        onComplete(cropImage(image, croppedAreaPixels, console.log));
    };

    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogTitle>Crop Image</DialogTitle>

            <DialogContent>
                <div style={containerStyle}>
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={cropWidth / cropHeight}
                        onCropChange={setCrop}
                        onCropComplete={(_, croppedAreaPixels) => {
                            setCroppedAreaPixels(croppedAreaPixels);
                        }}

                        onZoomChange={setZoom}
                        {...props}
                    />
                </div>

            </DialogContent>

            <DialogActions>
                <div className="controls" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }} >

                    <div className="col-lg-12 mx-auto">
                        <div className="d-flex gap-3">
                            <label htmlFor="zoomRange" className="m-0">Zoom:</label>
                            <input
                                id="zoomRange"
                                type="range"
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e) => setZoom(Number(e.target.value))}
                                className="zoom-range"
                            />
                        </div>
                        <div className="d-flex gap-3">
                            <label htmlFor="widthRange" className="m-0">Width:</label>
                            <input
                                id="widthRange"
                                type="range"
                                value={cropWidth}
                                min={100}
                                max={1000}
                                step={10}
                                aria-labelledby="Width"
                                onChange={handleWidthChange}
                                className="width-range"
                            />
                        </div>
                        <div className="d-flex gap-3">
                            <label htmlFor="heightRange" className="m-0">Height:</label>
                            <input
                                id="heightRange"
                                type="range"
                                value={cropHeight}
                                min={100}
                                max={1000}
                                step={10}
                                aria-labelledby="Height"
                                onChange={handleHeightChange}
                                className="height-range"
                            />
                        </div>

                        <div className="footer-box d-flex gap-3 text-center justify-content-end">
                            <Button
                                color="primary"
                                onClick={handleCropClick}
                                disabled={isDisabled}
                            >
                                Crop
                            </Button>

                            <Button
                                color="primary"
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </div>

                    </div>

                </div>
            </DialogActions>
        </Dialog>
    );
};

export default ImageCropper;