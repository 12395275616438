import { Logins, Logout } from "../Constants/Login-Types"

// initial state
const initialState = {
    logins: []
}



export const LoginReducers = (state = initialState, { type, payload }) => {
   
    switch (type) {
        case Logins.SET_LOGINS:
            return { ...state, logins: payload.data };

        case Logout.SET_LOGOUT:

           
            return {
                ...state,
                //isLoggedIn: false,
                logins: []
            };

        default:
            return state;
    }
};