import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { connect } from 'react-redux';
import Loader from "../loader";
import Table, { TableProps } from "antd/lib/table";
import { Select } from 'antd';
import '../../assets/css/style.css'
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import Layout from '../Layout';
import '../../../src/custom.css'
import axios from "axios"
import updateIcon from "../../assets/images/updateIcon.svg"
import { Modal } from 'antd';
import { Tooltip } from 'antd';
import { fetchAllQrCodes, updateAllParentQRId, fetchCompanyDetails,fetchUpdatedQRCode, updateInstLocation,fetchLocationProducts } from '../../Redux/Api/fetch.api';
import { getQrCodeData, updateQRCodeData } from '../../Redux/Actions/QR';
import { setLocationType, updateInstLocationData } from '../../Redux/Actions/LocationActions';

import * as url from "../../api/uri_helper"
import { SetCompanyDetails } from "../../Redux/Actions/CompanyActions";

const TOAST_LIMIT = 1;
const LocationUpdate = () => {
    const { toasts } = useToasterStore();
    useEffect(() => {
        toasts
            .filter((t) => t.visible) // Only consider visible toasts
            .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
            .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
    }, [toasts]);
    const [options, setOptions] = useState({ pagination: { current: 1 }, pageSize: pageSize });
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(false);
    const [locname, setLocname] = useState([{}]);
    const [table, setTable] = useState([]);
    const [total, setTotal] = useState()
    const [currentLocationId, setCurrentLocationId] = useState()
    const [updatedLocationId, setUpdatedLocationId] = useState()
    const [currentCompanyId, setCurrentCompanyId] = useState()
    const [bulkLocationId, setBulkLocationId] = useState()  
    const [disabledUpdate, setDisabledUpdate] = useState(true)  
    const [qrCodeId, setQrCodeId] = useState([{}]);
    const [pageData, setPageData] = useState({});
    const [copyModal, setCopyModal] = useState(false);
    const [checkedListOfIds, setCheckedListOfIds] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [getRadioButton, setGetRadioButton] = useState({ show: "show", selected: "show", });
    const [searchOptions, setSearchOptions] = useState({
        pagination: { current: 1 }, pageSize: pageSize
    })
    const dispatch = useDispatch();
    const allLocationTypes = useSelector((state) => state.allLocationType.location)

    useEffect(() => {    
        setTimeout(() => {
            setLoading(false)
        }, 2000)      
        getAllQRCodes(options);     
        getProducts();
        getAllCompanies();
    }, [])

    const handleChange = (pagination, sorter) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        setPageData(updatedOptions)
    };
    const handleCheck = (qRcodeId) => {     
        debugger;
        var updatedList = [...checkedListOfIds];
        if (event.target.checked) {
            updatedList = [...checkedListOfIds, qRcodeId];
        } else {
            updatedList.splice(checkedListOfIds.indexOf(qRcodeId), 1);
        }
        setCheckedListOfIds([...updatedList]);
       
    };

    const radioButtonValueChange = (e) => {
        if (e.target.value === "show") {
            setGetRadioButton({
                show: "show",
                selected: e.target.value,
            });
        } else {
            setGetRadioButton({
                show: "hide",
                selected: e.target.value,
            });
        }
    };

    const getProducts = () => {
        setLoading(true)
        fetchLocationProducts()
            .then(response => {
                dispatch(setLocationType(response.data));

                setLocname(response.data.data.name)

            })
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const getAllCompanies = () => {
        debugger;
        setLoading(true)
        fetchCompanyDetails()
            .then(response => {
                debugger;
                dispatch(SetCompanyDetails(response.data));
                setCompanyData(response.data.data);                                
            })
            .catch((err) => {
                console.log("Err: ", err);
            })

    }

   

    const uncheckcheckbox = () => {
        var items = document.getElementsByName('checkname');
        for (var i = 0; i < items.length; i++) {
            if (items[i].type == 'checkbox')
                items[i].checked = false;
        }
    }

    const handleBulkLocationUpdate = async () => {
        debugger;
        setLoading(true)
        if (checkedListOfIds.length >=1) {
            const data = [];
            checkedListOfIds.map(x => data.push({
                QRcodeId: x,
                LocationId: bulkLocationId
            }))
            const response = await axios.put(url.UPDATE_INSTLOCATION,
                {
                    "InstLocationQrs": data
                }).then(response => {
                    debugger;
                    dispatch(updateInstLocationData(response.data))
                    setBulkLocationId();
                    if (!response.data.success) // False 
                    {
                        debugger;
                        toast.error(response.data.message, {
                            className: "toastSuccess",
                        });
                    }
                    else {
                        toast.success(response.data.message, {
                            className: "toastSuccess",
                        })
                    }
                    setLoading(false)
                })
            getAllQRCodes()
            setCheckedListOfIds('')
            uncheckcheckbox();
            setDisabledUpdate(true)
        }
    };

    const getFullInstalDate = (date) => {
        // setLoading(true)
        if (date) {
            const dateAndTime = date.split('T');
            const time = dateAndTime[1].split(':')
            const newtime = time[0] + ' : ' + time[1]
            const times = time[0] > 12 ? time[0] % 12 : time[0]
            const newtime1 = times + ' : ' + time[1]
            if (time[0] && time[0] >= 12) {
                return dateAndTime[0].split('-').reverse().join('-') + ',' + newtime1 + " PM ";
            }
            else {
                return dateAndTime[0].split('-').reverse().join('-') + ',' + newtime + " AM ";
            }
        }
    };

    const handleSingleLocationUpdate = async (qRcodeId, currentLocationId) => {
         setLoading(true)
        if ( currentLocationId) {
            const response = await axios.put(url.UPDATE_INSTLOCATION, {
                "InstLocationQrs": [{
                    "QRcodeId": qRcodeId,
                    "LocationId": currentLocationId
                }]               
            }).then(response => {
                debugger;
                dispatch(updateInstLocationData(response.data.data))
                if (!response.data.success) // False 
                {
                    toast.error(response.data.message, {
                        className: "toastSuccess",
                    });
                                      
                }
                else {
                    toast.success(response.data.message, {
                        className: "toastSuccess",
                    });
                }
                setLoading(false)
            })
            getAllQRCodes()
        }
       
    };

   

    const getAllQRCodes = (options) => {              
        if (options && options.pagination == undefined) {
            options.pagination = pageData.pagination;
            options.pageSize = pageData.pageSize;
        }
        fetchAllQrCodes()
            .then(response => {
                debugger;
                setTable(response.data.data)
                setQrCodeId(response.data.data.qRcodeId)
                setOptions({
                    ...options,
                    pagination: {
                        pageSize: options.pagination.pageSize,
                        current: options.pagination.current,
                        total: response.data.count,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                    }
                })
            })
            .catch((err) => {
                console.log("Err: ", err);
            })
    }
    const { Option } = Select;
    const columns = [

        ...(getRadioButton.selected === "hide" ?
        [
            {
                title: " ",
                dataIndex: "qRcodeId",
                hidden : false,
                render: (qRcodeId) => (<div className="checkgroup">
                    {
                        <input type="checkbox" className="btn-checkbox" name="checkname"
                          
                            onChange={() => { handleCheck(qRcodeId) }}
                            key={qRcodeId} 
                        ></input>
                    }
                </div>)
            },
        ] : []
        ),
        {
            title: "QR Codes",
            dataIndex: "qRcodeId",
            className: "update_qrcode"
        },

        {
            title: "Product Name",
            className: "update_qrcode",
            dataIndex: "product_Name",
            render: (product_Name) => <Tooltip placement='topLeft' className="media-tooltip prdouct-name" title={product_Name}>{product_Name ? product_Name : "- NA -"}</Tooltip>,
        },

        {
            title: "Inst. Date",
            className: "update_qrcode",
            dataIndex: "installation_Date",
            //key: 'installation_Date',

            render: ((date) => getFullInstalDate(date))

        },
        ...(getRadioButton.selected === "hide" ?
        [
            {
                title: "Location",
                className: "update_qrcode",
                dataIndex: "",
                render: (text, record, index) => (
                    <>
                        {record.location.name}
                    </>
                )

            },
        ] : []
        ),

        ...(getRadioButton.selected === "show" ?
        [
            {
                title: "Inst. Location",
                key: 'i',
                hidden: true,
                render: (record, row, i) => (

                    <div className='d-flex qrTable--select qr-img-select mapping wkmapping' >
                        <Select class="form-select mb-0 flex-grow-1 filter-select " aria-label=".form-select-lg example"
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Update Location"
                            id={i + 1}
                            key={row.qRcodeId}
                            defaultValue={record.location.locationId}
                            onChange={(e) => setCurrentLocationId(e)}
                            
                        >
                            <Option key={0} value={record.location.locationId}>{'Update Location'} </Option>
                            {
                                allLocationTypes && allLocationTypes.map((data, k) => (
                                    <Option key={data.locationId} value={data.locationId}>
                                        {data.name}
                                    </Option>)

                                )}

                        </Select>
                    </div>

                )

            }
        ] : []
        ),

       

       
        ...(getRadioButton.selected === "show" ?
        [
                {
                    title: "Update",
                    key: 'index',
                    hidden: true,
                    //  className: "update_mapping",
                    render: (text, record, index) => (<>

                        <button className="assign-btn btn " id={index + 1}>
                            <img src={updateIcon} onClick={(e) =>  handleUpdate(record.qRcodeId, currentLocationId)
                                } ></img>
                        </button>
                    </>)
                }               
            ] : []
            )
    ]
     
    const { confirm } = Modal;
    const handleUpdate = (qRcodeId, currentLocationId) => {
        debugger;
        confirm({
            title: 'Are you sure you want to update this location?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleSingleLocationUpdate(qRcodeId, currentLocationId)
            },
            onCancel() {
            },
        });
    }


    return (
        <Layout title="Location Update">
            <Toaster />           
            <>
                <div className = "row m-0">
                <div class="col-md-6 d-flex pt-3 pb-3 align-items-center flex-wrap gap-3">
                        <div className="">
                        {" "}
                        <input
                            type="radio"
                            value="show"
                            name="content"
                            id="show"
                            className="mr-2"
                            onClick={radioButtonValueChange}
                            checked={getRadioButton.selected === "show"}
                        />
                            <label className="m-0" for="show">Single Location Update</label>
                    </div>
                    <div className="">
                        {" "}
                        <input
                            className="mr-2"
                            type="radio"
                            value="hide"
                            name="content"
                            id="hide"
                            onClick={radioButtonValueChange}
                            checked={getRadioButton.selected === "hide"}
                            />
                            <label className="m-0" for="show">Bulk Location Update</label>{" "}
                    </div>
                    </div>

                    {getRadioButton.show == "hide" ? (
                        <div className="col-md-6 d-flex pt-3 pb-3 align-items-center flex-wrap gap-3 justify-content-end update_location" > <span className="location-span">Update Location :</span>
                            <Select class="form-select mb-0 flex-grow-1 filter-select " aria-label=".form-select-lg example"
                                showSearch
                                style={{ width: 250 }}
                                placeholder="Update Location"
                                value={bulkLocationId}
                               // disabled={checkedListOfIds.length >=0 ? "" : "disabled"}
                                onChange={(e) => {
                                    debugger;
                                    setBulkLocationId(e)
                                    //if (e ==0) {
                                    //    setDisabledUpdate(true)
                                    //}
                                    //else {
                                    //    setDisabledUpdate(false)
                                    //}
                                    

                                }}                         
                            >
                                <Option key={0} value={0}>{'Update Location'} </Option>
                                {
                                    allLocationTypes && allLocationTypes.map((data, k) => (
                                        <Option key={data.locationId} value={data.locationId}>
                                            {data.name}
                                        </Option>)
                                    )}

                            </Select>

                            {
                                getRadioButton.show == "hide" ?
                                    checkedListOfIds.length >= 1 && bulkLocationId ?
                                        <button className="btn btn-primary location-btn"  onClick={() => handleBulkLocationUpdate()}>Bulk Location Update</button> :
                                    <button className="btn btn-primary location-btn" disabled onClick={() => handleBulkLocationUpdate()}>Bulk Location Update</button>

                                    : ""
                            }
                        </div>
                    ) : ""}

                  

                </div>
                
                
                {
                    getRadioButton.show == "show" ?

                        <div className="Viewer-table position-relative mb-4 pagination-sticky">
                             {loading ? <Loader /> : ''}
                            <Table
                                className='table-responsive antd--detail-table Viewer-table'
                                columns={columns}
                                dataSource={table}
                                pagination={options.pagination.total > 10 ? options.pagination : false}
                                onChange={handleChange}
                                paginationTotalRows={total}
                            />
                        </div> :
                        <div className="Viewer-table position-relative mb-4 pagination-sticky update_loc">
                             {loading ? <Loader /> : ''}
                            <Table
                                className='table-responsive antd--detail-table Viewer-table'
                                columns={columns}
                                dataSource={table}
                                pagination={options.pagination.total > 10 ? options.pagination : false}
                                onChange={handleChange}
                                paginationTotalRows={total}
                            />
                        </div>}


                </>
        </Layout>

    )



}

export default connect()(LocationUpdate);
