import { VirtualQR } from "../Constants/VirtualQR";

// initial state
const initialState = {
    virtualqrData: []
}

export const VirtualQrDataReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case VirtualQR.GET_VIRTUALQRUSERSECTION:
            return { ...state, virtualqrData: payload.data };

        default:
            return state;
    }
}

export const VirtualQrCodesReturned = (state = initialState, { type, payload }) => {
    switch (type) {
        case VirtualQR.GET_ALLVIRTUALQRCODES:
            return { ...state, virtualqrData: payload.data };

        default:
            return state;
    }
}