
import { QRTypes } from "../Constants/QR";

// initial state
const initialState = {
    qrTypes: []
}

export const QrReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case QRTypes.SET_LOCATIONTYPEDATA:
            return { ...state, qrTypes: payload.data };

        case QRTypes.GET_QRCODEDATA:
            return { ...state, qrTypes: payload.data };

    //    case QRTypes.UPDATE_QRCODEDATA:
    //     return {...state,qrTypes:state.qrTypes.map(
    //         (qrType, i) => i === payload.data.index ? {...qrType,favourites:true}
    //                                 : qrType
    //     )};

        case QRTypes.SAVE_MEDIATYPES:
            return { ...state, qrTypes: payload.data };

        case QRTypes.PUT_MEDIATYPES:
            return { ...state, qrTypes: payload.data };

        default:
            return state;
    }
};