import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from "react-redux";
import * as url from "../../api/uri_helper"
import Loader from "../loader";
import Table, { Icon,Button} from "antd/lib/table";
import { useHistory } from "react-router-dom";
import Layout from '../Layout';
import updateIcon from "../../assets/images/updateIcon.svg"
import "antd/dist/antd.css";
import '../../../src/custom.css'
import { Select } from 'antd';
import PDF from "../../UserAssets/images/pdf-icn.svg";
import Add from "../../assets/images/Add.svg";
import Union from "../../assets/images/Union.svg";
import Edit_light from "../../assets/images/Edit_light.svg";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { fetchAllLocationByCompanyId, fetchAllActiveNotMappedQRcodes,fetchAllVirtualQRByLocationId , fetchAllQRByLocationId, fetchAllQrCodes, fetchCompanyDetails, fetchMediaData } from '../../Redux/Api/fetch.api';
import { SetCompanyDetails } from '../../Redux/Actions/CompanyActions';
import { getAllLocationByCompanyId } from '../../Redux/Actions/LocationByCompany';
import { getVirtualQrDataDetails, assignvirtualQrCode } from '../../Redux/Actions/VirtualQRDetails';
import { getQRCodesNotmapped } from '../../Redux/Actions/QRCodesToAssign';
import { getVirtualQRByLocationId } from '../../Redux/Actions/VirtualQrByLocation';
import { setMediaTypes } from '../../Redux/Actions/MediaActions';
import axios from "axios"
import PhotoIcon from '@mui/icons-material/Photo';
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";
import downloadIcon from "../../UserAssets/images/download-icn.svg";
import { Tooltip } from 'antd';

const TOAST_LIMIT = 1;
const VirtualQRReview = () => {
    const { toasts } = useToasterStore();
    useEffect(() => {
        toasts
            .filter((t) => t.visible) // Only consider visible toasts
            .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
            .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
    }, [toasts]);
    const allCompanyDetails = useSelector((state) => state.allCompanyDetails.company);
    const allQRCodesAssigned = useSelector((state) => state.allQRCodesToAssign.qrAssign);
    const allVirtualQRByLocations = useSelector((state) => state.allVirtualQRByLocation.allVirtualQRByLocation);
    const allMediaTypes = useSelector((state) => state.allMediaType.mediaTypes);
    const [currentCompanyId, setCurrentCompanyId] = useState(0);
    const [virtualId, setVirtualId] = useState();
    const [zoomVideo, setZoomVideo] = useState();
    const [currentLocationId, setCurrentLocationId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [assignIndex, setAssignIndex] = useState();  
    const [currentMediaId, setCurrentMediaId] = useState(0);   
    const [currentVirtualQRId, setCurrentVirtualQRId] = useState();  
    const [assignQrCode, setAssignQrCode] = useState();
    const [table, setTable] = useState([]);
    const [tableChild, setTableChild] = useState();
    const [assignModal, setAssignModal] = useState(false)  
    const [imageModal, setImageModal] = useState(false)
    const [videoModal, setVideoModal] = useState(false);
    const [imageData, setImageData] = useState()
    const [imageFilename, setImageFilename] = useState()
    const [total, setTotal] = useState()
    const selectedCompanyLocations = useSelector((state) => state.allLocationByCompany.allLocationByCompanyId);
    const getAllVirtualData = useSelector((state) => state.allvirtualqrcodes.virtualqrTypes)
    const [qRcodeError, setQRCodeError] = useState(false);
    const [count, setCount] = useState(0);
    const [QrCodeID ,setQrCodeId] = useState()
    const [ProductName, setProductName] = useState()
    const [AssignQR, setAssignQr] = useState()

    const [options, setOptions] = useState({
        pagination: { current: 1 }, pageSize: pageSize
    });
    const [pageSize, setPageSize] = useState(10);
    const [pageData, setPageData] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        addVirtualQrDetails(options)
        getCompanyDetails()
        getMediaData()
        allLocationByCompany()
        setPageData(options)
        getQRCodesToAssign()
      // allVirtualQRByLocation()
  
    }, [])

    useEffect(() => {
        allLocationByCompany()
    }, [currentCompanyId])

    useEffect(() => {
        allVirtualQRByLocation()
    }, [currentLocationId])

    const getCompanyDetails = () => {

        fetchCompanyDetails()
            .then(response => dispatch(SetCompanyDetails(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const getQRCodesToAssign = () => {     
        fetchAllActiveNotMappedQRcodes()
            .then(response => dispatch(getQRCodesNotmapped(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const allLocationByCompany = () => {
        fetchAllLocationByCompanyId(currentCompanyId)
            .then(response => dispatch(getAllLocationByCompanyId(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const allVirtualQRByLocation = () => {
        fetchAllVirtualQRByLocationId(currentLocationId)
            .then(response => dispatch(getVirtualQRByLocationId(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const getMediaData = () => {
        fetchMediaData()
            .then(response => dispatch(setMediaTypes(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const handleImageOpen = (fileurl,filename) => {        
        setImageFilename(filename)
        setImageData(fileurl)
        setImageModal(true)
    }

    const handleVideoOpen = (fileurl, filename) => {        
        setImageFilename(filename)
        setVideoModal(true);
        setZoomVideo(fileurl)
        setImageModal(false)
    }

    const handleImageClose = () => {
        setImageModal(false);
    };

    const handleVideoClose = () => {
        setVideoModal(false);        
    };
    
    let qRCodeIdToSend = 0;
    if (virtualId && virtualId != '')
        qRCodeIdToSend = parseInt(virtualId);
    const addVirtualQrDetails = async (options, isReset = false) => {      
        
        setLoading(true)
        if (options && options.pagination == undefined) {
            options.pagination = pageData.pagination;
            options.pageSize = pageData.pageSize;
        }

        const response = await axios.post(url.GET_VIRTUALQRDETAIL, {           
            PageIndex: options.pagination ? options.pagination.current : '1',
            pageSize: options.pagination.pageSize != undefined ? options.pagination.pageSize : options.pageSize,
            companyId: isReset ? 0 : currentCompanyId,
            locationId: isReset ? 0 : currentLocationId,
            mediaTypeId: isReset ? 0 : currentMediaId,
            QRcodeId: isReset ? 0 : currentLocationId ? currentVirtualQRId : qRCodeIdToSend,
        }).then(response => {
           
            dispatch(getVirtualQrDataDetails(response.data)),
                setTable(response.data.data)          
             setTableChild(response.data.data)                  
            setTotal(response.data.count),
            setOptions({
                ...options,
                pagination: {
                    pageSize: options.pagination.pageSize,
                    current: options.pagination.current,
                    total: response.data.count,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    showSizeChanger: true,
                }
            })
            setAssignModal(false);
           // setLoading(false)
        })
            .catch((err) => {
                console.log("Err: ", err);
            })
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }
   
    const handleUpdate = () => {        
        setLoading(true)
        if (AssignQR) {
            const response = axios.put(url.PUT_ASSIGNVIRTUALWITHQR, {
                "VirtualQRid": QrCodeID,
                "Product_Name": ProductName,
                "QRcodeId": AssignQR              
            }).then(response => {               
                dispatch(assignvirtualQrCode(response.data))               
                if (!response.data.success) // False 
                {
                    toast.error(response.data.message, {
                        className: "toastSuccess",
                    });
                }
                else {
                    toast.success(response.data.message, {
                        className: "toastSuccess",
                    });
                }
                setAssignModal(false);
                addVirtualQrDetails(options);
                setAssignQrCode(0);
                setAssignQr()
                setAssignIndex()
               setLoading(false)
            })        
        }       
    }

    const handleChange = (pagination, sorter) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        setPageData(updatedOptions)
        addVirtualQrDetails({
            ...updatedOptions,
        });
    };

    const handleCompanyChange = (e) => {
       // let companyData = allCompanyDetails.filter(x => x.companyId == e.target.value)
        setCurrentCompanyId(e.target.value)
        if (e.target.value == 0) {
            setCurrentLocationId(0);
            setCurrentVirtualQRId(0);
        }
    }

    const handleAssignModal = () => {
        setAssignModal(true)        
    }

    const handleAssignModalClose = () => {
        setAssignModal(false);
    };

    const resetButtonSearch = async () => {
        setCurrentLocationId(0);
        setCurrentMediaId(0);
        setCurrentVirtualQRId(0);
        setCurrentCompanyId(0);
        qRCodeIdToSend = 0;
        addVirtualQrDetails(options, true)
    }

    const { Option } = Select;
    const columns = [
  
        {
            title: "Virtual QR Code",
            dataIndex: "qRcodeId",
            key:"i"
        },

        {
            title: "Product Name",
            dataIndex: "product_Name",
            className: "Products",
            render: (product_Name) => <Tooltip title={product_Name}>{product_Name}</Tooltip>,

        },

        {
            title: "Company",
            dataIndex: "companyName",
            className: "companyName",
            
           
        },

        {
            title: "Location",
            dataIndex: "name",
            className: "Locations",
           
        },
     
        {
            title: "Assign to QR",
            dataIndex: "qRcodeId",
            key: 'i',
            render: (record, row, i) => (

                <div className='d-flex qrTable--select qr-img-select mapping wkmapping' >
                    <Select class="form-select mb-0 flex-grow-1 filter-select " aria-label=".form-select-lg example"
                        showSearch
                        style={{ width: 250 }}
                        placeholder="Assign to QR"
                        id={i + 1}
                        key={row.qRcodeId}
                        defaultValue={row.assignQrCode}
                       //value={assignQrCode}
                        onChange={(e) => {
                            
                            setAssignQrCode(e);
                            setAssignQr(e);
                            setAssignIndex(i);                           
                        }}
                    >
                        <Option key={0} value={0}>{'Assign to QR'} </Option>
                        {
                            allQRCodesAssigned && allQRCodesAssigned.map((data, k) => (
                                <Option key={data} value={data}>
                                    {data}
                                </Option>)
                            )}
                    </Select>
                </div>
            )
        },

        {
            title: "Assign",
            dataIndex: "qRcodeId",
            key: 'index',
            // className: "update_mapping",
            render: (text, record, row, index) => (                
                <>
                    {AssignQR && row == assignIndex ?
                    <button className="assign-btn btn " 
                    >
                        <img src={updateIcon} onClick={(e) => {
                            handleAssignModal()                              
                            setQrCodeId(record.qRcodeId);
                            setProductName(record.product_Name)
                        }

                        } ></img>
                    </button>
                        : <button className="assign-btn btn "
                           disabled 
                    >
                        <img src={updateIcon} onClick={(e) => {
                                handleAssignModal() 
                            
                            //setParameters({ qrCodeID :  record.qRcodeId, product_name : record.product_Name, assign :assignQrCode })
                                setQrCodeId(record.qRcodeId);
                               
                            setProductName(record.product_Name)
                        }
                        } ></img>
                    </button>   }
            </>)
        },
    ]

    const expandedRowRender = (index) => {
        debugger;
        
        const nestedcolumns = [

            {
                title: "Table Id",
                dataIndex: "mediaId",                
                key: "tableid"
            },

            {
                title: "Guide Type",
                dataIndex: "guideType",
                key: "Guidetype"
            },

            {
                title: "Area Type",
                dataIndex: "areaType",
                key: "areatype"
            },

            {
                title: "Media Type",
                dataIndex: "mediaType",
                key: "mediatype"
            },

            {
                title: "Media FileName",
                dataIndex: "filename",
                key: "fileName"
            },

            {
                title: "Preview",
                render: (mediaId, row, i) => <>
                    
                    
                    {row.mediaType == "pdf" ?
                        <Tooltip title="Click to Download">
                            <a
                                className="d-flex align-items-center virtual"
                                target="blank"
                                href={row.fileurl}
                            >
                                <img src={downloadIcon}></img>
                            </a>
                        </Tooltip> :
                        <Tooltip title="Click to preview">
                            {row.mediaType == "Text (HTML)" ? "" :
                                <PhotoIcon style={{ color: "#b97a57", cursor: "pointer" }} onClick={() => {
                                    row.mediaType == "Image" ?
                                        handleImageOpen(row.fileurl, row.filename) :
                                        handleVideoOpen(row.fileurl, row.filename)
                                }
                                } />} </Tooltip> }
                  
                </>
            },
        ]
        return <Table className="nested-table" columns={nestedcolumns} dataSource={
            tableChild[index].mediaQRRelations
        } pagination={false} />;
    };

    return (
        <Layout title="Virtual QR Review">
            <Toaster />           
            <div className="position-relative reviewPage">
                <div className="card-box-header d-inline-flex w-100 align-items-center m-0">
                    <div className="filter-section multi-filter d-flex flex-wrap p-0">
                        <div className="col d-flex align-items-center p-0">
                            <label className="filter-col me-3 mb-0">Search Media:</label>
                            <div className="dropdown">
                                <button className="filter-btn dropdown-toggle d-flex align-items-center" type="button" id="dropdownFilterMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span>Filter</span> <img className='ms-2' src={Union} />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <div className="row g-3">
                                        <div className='col-md-12'>
                                            <div className='row justify-content-center align-items-center'>
                                                <div className='col-md-12 d-flex align-items-center searchbtQR'>
                                                    <label class="form-label mb-0 d-inline-block"><b>Enter Virtual QR Code :</b></label>
                                                    <input className="form-control" placeholder='Enter Virtual QR Code' type="text" maxLength="9"
                                                        value={virtualId}
                                                        // ^(?:[1-9]\d*|0)$
                                                        onKeyPress={(e) => {
                                                            if (new RegExp(/^[0-9\b]+$/).test(e.key)) {
                                                            } else e.preventDefault();
                                                        }}
                                                        disabled={!currentCompanyId && !currentLocationId && !currentMediaId && !currentVirtualQRId ? "" : "disabled"}
                                                        onChange={(e) => {
                                                            setVirtualId(e.target.value);
                                                            setCount(e.target.value.length);
                                                            if (e.target.value.startsWith('0') || count > 6) { setQRCodeError("Enter Valid QR Code") }
                                                            else { setQRCodeError("") }
                                                        }}                                                                                                                                                                >
                                                    </input>
                                                    <strong>OR</strong>
                                                </div>
                                                <small className='text-danger d-block mt-2 ms-5 text-center' >{qRcodeError}</small>                                              
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label class="form-label">Company</label>
                                            <select class="form-select mb-0  filter-select" aria-label=".form-select-lg example"
                                                value={currentCompanyId}
                                                disabled={!virtualId ? "" : "disabled"}
                                                onChange={(e) => handleCompanyChange(e)}
                                            >
                                               <option value="0">Select Company</option>
                                                {allCompanyDetails && allCompanyDetails.map(company =>
                                                    <option key={company.companyId} value={company.companyId}>
                                                        {company.companyName} </option>)}
                                            </select>
                                        </div>
                                        <div className='col-md-6'>
                                            <label class="form-label">Location</label>
                                            <select class="form-select mb-0  filter-select" aria-label=".form-select-lg example"
                                                value={currentLocationId}
                                                disabled={!virtualId && currentCompanyId ? "" : "disabled"}
                                                onChange={(e) => { setCurrentLocationId(e.target.value) }}>
                                                <option value="0">Select Location</option>
                                                {
                                                    selectedCompanyLocations && selectedCompanyLocations.map(data => {
                                                        return <option key={data.location.locationId} value={data.location.locationId}>
                                                            {data.location.name}
                                                        </option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className='col-md-6'>
                                            <label class="form-label">Media</label>
                                            <select class="form-select mb-0  filter-select" aria-label=".form-select-lg example"
                                                value={currentMediaId}
                                                disabled={!virtualId ? "" : "disabled"}
                                                onChange={(e) => {
                                                    setCurrentMediaId(e.target.value)
                                                }}>
                                                <option value="0">Select Media</option>
                                                {
                                                    allMediaTypes && allMediaTypes.map(media =>
                                                        <option key={media.mediaTypeId} value={media.mediaTypeId}>
                                                            {media.name} </option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                        <div className='col-md-6'>
                                            <label class="form-label">Virtual QR Code</label>
                                            <select class="form-select mb-0  filter-select" aria-label=".form-select-lg example"
                                                value={currentVirtualQRId}
                                                disabled={!virtualId && currentLocationId ? "" : "disabled"}
                                                onChange={(e) => { setCurrentVirtualQRId(e.target.value) }}>
                                                <option value="0">Select Code</option>
                                                {
                                                    allVirtualQRByLocations && allVirtualQRByLocations.map((data, k) => (                                                    
                                                        <option key={data} value={data}>
                                                            {data}
                                                        </option> 
                                                    )
                                                    )}                                                                                                    
                                            </select>
                                        </div>
                                        <div className='col-md-12'>
                                            <button className="btn btn-primary pe-3 ps-3" onClick={addVirtualQrDetails} >Search</button>
                                            <button className="btn btn-primary pe-3 ps-3 ms-2"
                                                onClick={
                                                    () => {
                                                        setVirtualId('');
                                                        resetButtonSearch();
                                                        setQRCodeError(false);
                                                    }}
                                            >Reset</button>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="table-responsive Viewer-table position-relative mb-4 device-type pagination-sticky data-table">
                {loading ? <Loader /> : ''}
                <Table
                    className='table-responsive antd--detail-table1 Viewer-table' 
                    columns={columns}
                    dataSource={table}
                    expandedRowRender={(_,index)=>expandedRowRender(index)}
                    pagination={options.pagination.total >10 ? options.pagination : ""}
                 
                   onChange={handleChange}
                    paginationTotalRows={total}      
                    scroll={{ x: "750px" }}
                ></Table>
            </div>

            <Modal
                show={imageModal}
                onHide={handleImageClose}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog-zoom"
            >
                <Modal.Header closeButton>
                    <Modal.Title> <h5 className="imageshow">Media File Name  :</h5>{imageFilename}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        <div className="img-section m-0 flex-wrap d-flex align-items-center">
                            <img className=" w-100" src={imageData}></img>
                        </div>
                    }
                </Modal.Body>
            </Modal>

            <Modal
                show={videoModal}
                onHide={handleVideoClose}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog-video"
            >
                <Modal.Header closeButton>
                    <Modal.Title><h5 className="imageshow">Media File Name  :</h5>{imageFilename}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        <div className="img-section m-0 flex-wrap d-flex align-items-center">
                            <ReactPlayer
                                url={zoomVideo}
                                width="600px"
                                height="600px"
                                controls={true}
                                playing={false}
                            />
                        </div>
                    }
                </Modal.Body>
            </Modal>

            <Modal
                show={assignModal}
                onHide={handleAssignModalClose}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog-copy"
            >
                <Modal.Body>
                    <div class="copy-model">
                        <h5> Are you sure you want to Assign QR code?</h5>
                        <div class="w-100 mt-3 btn-yesno">
                            <button
                                type="button"
                                class="btn btn-primary"
                                onClick={handleUpdate}
                            >                                Yes
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary ml-2"
                                onClick={handleAssignModalClose}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </Layout>
    )
}

export default connect()(VirtualQRReview);
