import { MediaTypes } from "../Constants/Media-Types"

export const setMediaTypes = (mediatypes) => {
    return {
        type: MediaTypes.SET_MEDIATYPES,
        payload: mediatypes
    };
};

//Add CustomerType Data
export const addMediaTypeData = (mediatypes) => {
    return {
        type: MediaTypes.ADD_MEDIATYPES,
        payload: mediatypes
    };
};

//Update CustomerType Data
export const updateMediaTypeData = (mediatypes) => {
    return {
        type:MediaTypes.UPDATE_MEDIATYPES,
        payload: mediatypes
    };
};

//Delete CustomerType Data
export const deleteMediaData = (mediatypes) => {
    return {
        type: MediaTypes.DELETE_SELECTED_MEDIATYPES,
        payload: mediatypes
    };
};
