import React from "react"
import logo from "../../UserAssets/images/authenteklogo.png"

const Footer = () => {
    return (
        <section class="footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6 text-left wow fadeInUp">© AuthenTEK ({new Date().getFullYear()}) | TEKaHelp™</div>
                    <div className="col-sm-6 center-text text-end">
                        <div className="footer--logo">Powered by <a href="https://authentek.io/" target="_blank"><img src={logo}></img> </a></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer