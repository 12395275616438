import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import toast, { Toaster, useToasterStore } from "react-hot-toast";
import Layout from "../Layout";
import Table, { TableProps } from "antd/lib/table";
import Loader from "../loader";
import '../../../src/custom.css'
import { fetchAllThumbnail, fetchSearchData } from "../../Redux/Api/fetch.api";
import { getThumbnail } from "../../Redux/Actions/DashboardCount";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import PhotoIcon from '@mui/icons-material/Photo';
import { Tooltip } from 'antd';
const AllThumbnailPage = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState([]);
    // const allThumbnails = useSelector((state) => state.allDashboardReducer.thumbnail)
    const [imageModal, setImageModal] = useState(false)
    const [imageData, setImageData] = useState()
    const [pageSize, setPageSize] = useState(10);
    const [pageData, setPageData] = useState({});
    const [options, setOptions] = useState({ pagination: { current: 1 }, pageSize: pageSize});
    const [search, setSearch] = useState("")
    const [searchData, setSearchData] = useState()
    const [searchClicked, setSearchClicked] = useState(false)
    const [searchError, setSearchError] = useState()
    const [count, setCount] = useState(0)
    const [total, setTotal] = useState()
    const [totalIndex, setCurrentIndex] = useState()
    const [initialData ,setInitialData]=useState({})

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        getAllThumbnailData(options)
    }, [])

    const handleImageOpen = (row, i) => {
       
        setImageData(row)
        setImageModal(true)
    }

    const handleImageClose = () => {
        setImageModal(false);
    };

    const getAllThumbnailData = (options) => {
        setLoading(true)
        if (options && options.pagination == undefined) {
            options.pagination = pageData.pagination;
            options.pageSize = pageData.pageSize;
        }
       
        
        fetchAllThumbnail(options.pagination.current)
            .then(response => {
                
                dispatch(getThumbnail(response.data.data)),
                    setTable(response.data.data.allThumbnailList)
                // setTotal(response.data.count)
                setTotal(response.data.data.totalCount)
                setOptions({
                    ...options,
                    pagination: {
                        current: options.pagination.current,
                        total: response.data.data.totalCount,
                        pageSizeOptions: ['10', '20', '50'],
                        showSizeChanger: true,
                    }
                })
                setInitialData({table : response.data.data.allThumbnailList , 
                    option :{
                         pagination: {
                    current: options.pagination.current,
                    total: response.data.data.totalCount,
                    pageSizeOptions: ['10', '20', '50'],
                    showSizeChanger: true,
                }}})
              //  setLoading(false)
            }).catch((err) => {
                console.log("Err : ", err);
            })
        setTimeout(() => {
            setLoading(false)
        }, 2000)

    }
    //for Searching
    const handleDataSearch = (e) => {
        setSearchError("")
        setSearch(e.target.value)
        setCount(e.target.value.length)
        if (e.target.value == "") {
            setSearchData(table)
        }
    }

    const handleSearch = () => {
        if (search != "") {
            setLoading(true)
            fetchSearchData(search)
                .then(response => {
                    console.log(response)
                    setSearchClicked(true)
                    setSearchData(response.data.data.allThumbnailList)
                    setLoading(false)
                    setTotal(response.data.data.totalCount)
                    setOptions({
                        ...options,
                        pagination: {
                            current: options.pagination.current,
                            total: response.data.data.totalCount,
                            pageSizeOptions: ['10', '20', '50'],
                            showSizeChanger: true,
                        }
                    })})
        }
        else {
            setSearchError('Please Enter Filename');
        }

    }

    const handleResetButton = () => {
        setSearchClicked(false)
        setSearch("")
        setSearchData("")
        setSearchError("")
        
        setTotal(initialData.table)
        setOptions(initialData.option)
    }

    const handleChange = (pagination, sorter) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        setPageData(updatedOptions)
    };

    const getFullDate = (date) => {
       
        const dateAndTime = date.split('T');
        const time = dateAndTime[1].split(':')       
        const newtime = time[0] + ':' + time[1]
        const times = time[0] > 12 ?  "0" + time[0] % 12 : time[0]
        const newtime1 = times + ':' + time[1]
        if (time[0] && time[0] >= 12) {
            return dateAndTime[0].split('-').reverse().join('-') + ', ' + newtime1 + " PM ";
        }      
        else {
            return dateAndTime[0].split('-').reverse().join('-') + ', ' + newtime + " AM ";

        }     
       
    };

    const columns = [
        {
            title: "Sr No.",
            dataIndex: "Index",
            render: (text, record, index) => { setCurrentIndex(`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}`); return `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}` },
        },
        {
            title: "Media File Name",
            dataIndex: "filename",
            render: (filename) => <Tooltip title={filename}>{filename}</Tooltip>,
        },
        {
            title: "Media File Extension",
            dataIndex: "extension"
        },
        {
            title: "Media File Size",
            dataIndex: "size",
            render: (text, row, i) => {
               
                if (row.size) {
                    var result = row.size;                 
                        if (result >1024)
                        {
                            const mbsize = result/1024
                            return Math.trunc(mbsize) + " mb"
                    }
                    if (result < 1024) {
                        return row.size + " kb"
                    }
                    //if (result > 1000) {
                    //    return result + " gb"
                    //}
                }
                else {
                    return ""
                }
            }
        },

        {
            title: "Upload Date",
            dataIndex: "upload_Date",
            className: "upload_Date",
            key: 'upload_Date',
            
            render: ((date) => getFullDate(date)) 
        },

        {
            title: "Preview",
            className: "preview",
            render: (parent_QRCodeID, row, i) => <>
            <Tooltip title="Click to preview">           
                    <PhotoIcon style={{ color: "#b97a57", cursor: "pointer" }}
                        onClick={() => handleImageOpen(row, i)} />                
            </Tooltip>
            </>

        },
    ]


    return (
        <Layout title="Thumbnail">
            {!searchClicked ?
                <>
                    <div className="position-relative">
                        {/* <div className="card-box-header d-inline-flex w-100 align-items-center"> */}
                            {/* <h4 className="m-0">Thumbnail</h4> */}
                            <div className='ml-4 pt-3 mb-3' >Search Media File : <input type="text" className='form-control searchQR'
                                value={search} placeholder='Enter File Name' onChange={(e) => {
                                    handleDataSearch(e); setSearchError(false)
                            }}></input>
                            {
                                < small className="text-danger d-block mt-2 searchError formob">{searchError}</small>
                            }
                                <button onClick={() => handleSearch()}
                                    className="searchButton ml-4">Search</button>
                                {
                                < small className="text-danger d-block mt-2 searchError forDesk">{searchError}</small>
                                }
                            </div>
                        {/* </div> */}
                    </div>
                    <div className="Viewer-table position-relative ellipsss thumbnail_list">
                        {loading ? <Loader /> : ''}
                        <Table
                            className='table-responsive antd--detail-table Viewer-table'
                            columns={columns}
                            dataSource={table}
                            pagination={options.pagination.total > 10 ? options.pagination : false}
                            //pagination={options.pagination.total > 9 && totalIndex > 9 ? options.pagination : false}
                            onChange={handleChange}
                            paginationTotalRows={total}
                        />
                    </div>
                </> :
                <>
                    <div className="position-relative reviewPage">
                        {/* <div className="card-box-header d-inline-flex w-100 align-items-center"> */}
                            {/* <h4 className="m-0">Thumbnail</h4> */}
                            <div className='ml-4 pt-3 mb-3' >Search Media File : <input type="text" className='form-control searchQR'
                                value={search} placeholder='Enter File Name' onChange={(e) => {
                                    handleDataSearch(e); setSearchError(false)
                            }}></input>
                            {
                                < small className="text-danger d-block mt-2 searchError formob">{searchError}</small>
                            }
                                <button className='searchButton ml-4' onClick={() => handleSearch()} >Search</button>
                                {search == "" ? "" : <button className='searchButton ml-4' onClick={() => handleResetButton()} >Reset</button>}
                                {
                                    < small className="text-danger d-block mt-2 searchError forDesk">{searchError}</small>
                                }
                            {/* </div> */}
                        </div>
                    </div>
                    <div className="Viewer-table position-relative mb-4 pagination-sticky ellipsss thumbnail_list">
                        {loading ? <Loader /> : ''}
                        <Table
                            className='table-responsive antd--detail-table Viewer-table '
                            columns={columns}
                            dataSource={searchData}
                            pagination={options.pagination.total > 9 && totalIndex > 9 ? options.pagination : false}
                            onChange={handleChange}
                            paginationTotalRows={total}
                        />
                    </div>
                </>}

            <Modal
                show={imageModal}
                onHide={handleImageClose}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog-zoom"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{imageData ? 
                  <> <h5 className="mt-2" style={{color:"#b97a57",display:"inline",float:"left"}}>Media File Name  : </h5><h5 className="mt-2 ml-2" style={{display:"inline",float:"right"}}>{imageData.filename}.{imageData.extension}</h5></>
                     : ""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        <div className="align-items-center d-flex flex-wrap img-section justify-content-center m-0">
                            <img className= "thumb_image" style={{ maxWidth: 400 }} src={imageData ? imageData.fileurl : ""}></img>
                        </div>
                    }
                </Modal.Body>
            </Modal>

        </Layout>
    )
}
export default connect()(AllThumbnailPage);