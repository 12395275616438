import React, { useEffect, useCallback, useMemo, useState } from "react";
import axios from "axios"
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
//import Message from 'antd-message';
import toast, { Toaster } from 'react-hot-toast';
// import loading from "../../assets/images/loading.gif"
import Loader from "../loader";
import '../../../src/custom.css'
////#Developer
import Layout from '../Layout';
import * as url from "../../api/uri_helper"
// Import static images
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import Add1 from "../../assets/images/Add.svg";
import { METHODS } from "http";
import { setLocationType, updateLocationTypeData, addLocationTypeData } from "../../Redux/Actions/LocationActions";
import { fetchAllLocationId, fetchLocationProducts, fetchUserData } from "../../Redux/Api/fetch.api";
import { setUserType } from "../../Redux/Actions/UserActions";
import { Formik, Field, useFormik } from 'formik';
import { Input, Form, FormFeedback } from "reactstrap"
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2'
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from "react-places-autocomplete";


const Add = () => {

    const allLocationTypes = useSelector((state) => state.allLocationType.location)
    // const allCustomerTypes = useSelector((state) => state.allCustomerType.customer)

    const [name, setName] = useState("");
    const [address, setAddress] = useState('');
    const [allAddress, getAllAddress] = useState('')
    const [currentCustomerId, setCurrentCustomerId] = useState(0);
    const [id, setId] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        nameErr: '',
        addressErr: ''
    })
    const [isActive, setisActive] = useState(true)
    const [currentId, setCurrentId] = useState(null);
    const [checked, setChecked] = useState(true);
    const [selectedCountryCode, setSelectedCountryCode] = useState();
    const [stateData, setStateData] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [country, setCountry] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [city, setCity] = useState("");
    const [count, setCount] = useState(0);
    const [disable, setDisable] = useState(false);
    const [contactEmail, setContactEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [countText, setCountText] = useState(0);
    const [contactName, setContactName] = useState("");
    const [phoneError, setPhoneError] = useState(false);
    const [fieldValue, setFieldsValue] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    let history = useHistory();
    const handleChange = () => {
        setisActive(!isActive)
    }
    let pageButton = "Update"

    if (!currentId) {
        pageButton = "Save"
    }

    const [state, setState] = useState({ address: '' })
    const [postalCodeDisable, setPostalCodeDisable] = useState(false);
    const [zipCode, setZipCode] = useState(false);
    const [add, setAdd] = useState(false);
    const [addressData, setAddressData] = useState("");
    const [postalCodeData, setPostalCodeData] = useState();

    useEffect(() => {
        let urlData = location.pathname.split('/');
        setCurrentId(urlData[3])
        if (currentId) {
            setLoading(true)
            fetchAllLocationId(urlData[3])
                .then(
                    response => {
                        setLoading(false)
                        setName(response.data.data.name);
                        setAddressData(response.data.data.address);
                        if (response.data.data.address)
                            setCount(response.data.data.address.length)
                        setPhoneNumber(response.data.data.phoneNumber)
                        setId(response.data.data.customerId)
                        setisActive(response.data.data.active)
                        setCity(response.data.data.city)
                        setStateData(response.data.data.state)
                        setPostalCodeData(response.data.data.postalCode)
                        setCountry(response.data.data.country)
                        setContactName(response.data.data.contactName)
                        setContactEmail(response.data.data.contactEmail)
                        setLatitude(response.data.data.latitude)
                        setLongitude(response.data.data.longitude)
                    })

        }
        else {
            // getCustomerData();

        }
    }, [currentId])
    useEffect(() => {

        getLocationData()
    }, [])

    const handleAddressChange = (value) => {
        debugger;
        if(value.length > 0) {
            setAddressData(value)
        }
        else {
            setAddressData("")
        }
    
    
       
    };

    const handleAddressSelect = async (address, placeId) => {
        debugger;
        setAddressData(address);
        getAllAddress(address)

        const results = await geocodeByAddress(address);
        const latlang = await getLatLng(results[0]);
        console.log("data", latlang.lat)
        setLatitude(latlang.lat)
        setLongitude(latlang.lng)
        geocodeByAddress('Montevideo, Uruguay')
            .then(results =>

                getLatLng(results[0]))
            .then(({ lat, lng }) =>
                //setLatitude({lat}),
                console.log('Successfully got latitude and longitude', { lat, lng }),

            );
        const [place] = await geocodeByPlaceId(placeId);

        const { long_name: postalCode = "" } =
            place.address_components.find((c) => c.types.includes("postal_code")) ||
            {};
        let city = "",
            country = "",
            state = ""


        for (const component of results[0].address_components) {
            const componentType = component.types[0];

            switch (componentType) {
                case "locality": {
                    city = component.long_name;
                    break;
                }
                case "administrative_area_level_1": {
                    state = component.long_name;
                    break;
                }
                case "country": {
                    country = component.long_name;
                    break;
                }
            }
        }

        setPostalCodeDisable(!!postalCode);
        setFieldsValue({

            ["postal_code"]: postalCode,
            ["city"]: city,
            ["state"]: state,
            ["country"]: country,
        });
        { addressData != "" ? setCity(city) : '' }
        setPostalCodeData(postalCode)
        setCity(city)
        setStateData(state)
        setCountry(country)
        console.log("postal", postalCode)
        console.log("city", city)
        console.log("state", state)
        console.log("country", country)
    };
    const handleStateChangeForAddress = (e) => {
        debugger;
        if (e == "") {
            setCity("")
            setPostalCodeData("")
            setStateData("")
            setCountry("")
            setLatitude("")
            setLongitude("")         
            getAllAddress("")
        }
    }

    const renderFuncForGooglePlaces = ({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading,
    }) => (
        <div>
            <Input
                id="selectAddress_1"
                //addonAfter={selectAfter("address_1", publicOrPrivate("address_1"))}
                placeholder='Search Places ...'
                size="large"
                {...getInputProps({
                    placeholder: 'Search Places ...',
                })}
                tabIndex={9}
                maxLength={255}
            />
          
            <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, index) => {
                    const style = suggestion.active
                        ? {
                            backgroundColor: "#f8f8f8",
                            cursor: "pointer",
                        }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                        };
                    return (
                        <div
                            {...getSuggestionItemProps(suggestion, {
                                style,
                            })}
                            key={index}
                        >
                            {suggestion.description}
                        </div>
                    );
                })}
            </div>
        </div>
    );

    const getLocationData = () => {
        fetchLocationProducts()
            .then(response => dispatch(setLocationType(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const addLocationType = async (params) => {

        if (params.name) {
            const response = await axios.post(url.POST_LOCATIONTYPE, {
                Name: params.name.trim(),
                city: city.trim(),
                state: stateData.trim(),
                postalCode: postalCodeData,
                country: country,
                // customerId: currentCustomerId,
                address: addressData,
                phoneNumber: phoneNumber,
                contactEmail: params.contactEmail,
                contactName: contactName.trim(),
                Latitude:latitude,
                Longitude:longitude,
                Active: isActive
            }).then(response => {
                dispatch(addLocationTypeData(response.data))
                toast.success(response.data.message, {
                    className: "toastSuccess",
                },);

                history.push('/Location');

            })
                .catch((err) => {
                    setDisable(false)
                });

        }
    };

    const updateLocationType = async (params) => {
        if (params.name) {

            const response = await axios.put(url.UPDATE_LOCATIONTYPE, {
                locationId: currentId,
                Name: params.name.trim(),
                // customerId: currentCustomerId,
                address: addressData,
                city: city,
                state: stateData,
                postalCode: postalCodeData,
                Latitude:latitude,
                Longitude:longitude,
                country: country,
                contactName: contactName,
                phoneNumber: phoneNumber,
                contactEmail: params.contactEmail,
                Active: isActive,
            }).then(response => {
                dispatch(updateLocationTypeData(response.data))
                if (!response.data.success) // False 
                {
                    toast.error(response.data.message, {
                        className: "toastSuccess",
                    });
                    //setLoading(false);
                    //setDisable(false)
                }
                else {
                    toast.success(response.data.message, {
                        className: "toastSuccess",
                    });
                    history.push('/Location');
                   // setLoading(false);
                   // setDisable(true);
                }                 
            })
                .catch((err) => {
                    console.log("Err: ", err);
                    setDisable(false)
                });

        }
    };
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        // initialize of the Values
        initialValues: {
            name: name ? name : '',
            address: address ? address : '',
            contactEmail: contactEmail ? contactEmail : ''


        },
        // Alert messages 
        validationSchema: Yup.object({
            name: Yup.string().required('Please Enter Name ').matches(
                "^([A-Za-z0-9]+ )+[A-Za-z0-9]+$|^[A-Za-z0-9]+$",
                "Please enter valid Name"
            ),
            
            contactEmail: Yup.string().matches(
                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                "Please enter valid email "
            ),
            address: Yup.string().matches(
                "^(.|\s)*[a-zA-Z]+(.|\s)*$",
                "Please enter valid Address"
            )

        }),
        // on form submit / button click
        onSubmit: (values) => {
            if (!phoneError) {
                setDisable(true)
                { currentId == undefined || currentId == null || currentId == isNaN ? addLocationType(values) : updateLocationType(values) };
            }

        }
    });

    // if (loading) return <Loader />;
    return (
        <Layout title="Location"><Toaster />
            <div>
                {loading ? <Loader /> : ''}
                <div className="card-box-header d-inline-flex w-100 align-items-center">
                    <h4 className="m-0">
                        <Link className="backBTN" to="/Location">
                            <img src={Arrow_leftbrown}></img>
                            Back
                        </Link>
                    </h4>
                </div>
                <div className="content-area">
                    <Form className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            //if (phoneNumber.replace(country.dialCode, '').startsWith('0')) {
                            //    setPhoneError(true);
                            //}
                            //if (!(phoneNumber && phoneNumber.length > 10)) {
                            //    setPhoneError(true);
                            //}
                            return false;
                        }}>
                        <form>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Name <span className="required-fields"> * </span> </label>
                                        <div className="input-group">                                           
                                            <Input name="name" lassName="form-control" placeholder="Enter Name"
                                                type="text" maxLength={50} onChange={(e) => { validation.handleChange(e), setName(e.target.value.trim()) }}
                                                onBlur={validation.handleBlur} value={validation.values.name}
                                                invalid={
                                                    validation.touched.name && validation.errors.name ? true : false
                                                }
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                            ) : null}
                                        </div>

                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Contact Name </label>
                                        <div className="input-group">
                                            <input name="companyName" onChange={(e) => { setContactName(e.target.value) }} className="form-control" placeholder="Enter Name" type="text" maxLength={50} value={contactName} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label> Email </label>
                                        <div className="input-group">
                                            {/* <input id="contactEmail" type="text" className="form-control" name="contactEmail" value={contactEmail} onChange={(e) => { setContactEmail(e.target.value) }} placeholder="Enter Your Email"></input> */}

                                            <Input name="contactEmail" lassName="form-control" placeholder="Enter Email"
                                                type="text" maxLength={50} onChange={validation.handleChange}
                                                onBlur={validation.handleBlur} value={validation.values.contactEmail}
                                                invalid={
                                                    validation.touched.contactEmail && validation.errors.contactEmail ? true : false
                                                }
                                            />
                                            {validation.touched.contactEmail && validation.errors.contactEmail ? (
                                                <FormFeedback type="invalid">{validation.errors.contactEmail}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Phone Number </label>
                                        <div className="input-group phnumber">
                                            <PhoneInput
                                               
                                                className="w-5"
                                                country={"us"}
                                                enableSearch={true}
                                                value={phoneNumber}
                                                onChange={(phone, country) => {
                                                    setSelectedCountryCode((prevState) => {
                                                        debugger;
                                                        if (prevState !== country.countryCode) {
                                                            setPhoneNumber(country.dialCode);
                                                        }
                                                        else {
                                                            setPhoneNumber(phone);
                                                        }
                                                        return country.countryCode
                                                    })

                                                    //if (phone.length > 10 )
                                                    //   // && !phone.replace(country.dialCode, '').startsWith('0'))
                                                    //{
                                                    //    setPhoneError(false);
                                                    //}
                                                    //else {
                                                    //    setPhoneError(true);
                                                    //}
                                                   
                                                }}
                                            />
                                        </div>
                                        {
                                            < small className="text-danger d-block mt-2">{!phoneError ? ' ' : 'Please Enter Valid Phone Number'}</small>
                                        }
                                    </div>
                                </div>
                                
                                <div class="col-lg-12">
                                    <div class="form-group autocomplete-places">
                                        <label>Address (Autocomplete)<span className="required-fields"> * </span></label>
                                        <div class="row">
                                            <div className="col-md-12">
                                                <PlacesAutocomplete
                                                
                                                    value={addressData}
                                                    onChange={(e) => {
                                                        handleAddressChange(e), handleStateChangeForAddress(e)
                                                    }}                                                        
                                                    onSelect={handleAddressSelect}                                                   
                                                >
                                                    {renderFuncForGooglePlaces}
                                                </PlacesAutocomplete>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {allAddress != '' || currentId?
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>City </label>
                                                    <div className="input-group">
                                                        <input name="city" className="form-control disabled-input" placeholder="Enter City" type="text" maxLength={50}
                                                         disabled   onChange={(e) => { setCity(e.target.value) }} value={addressData != "" ? city : ''} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>State </label>
                                                    <div className="input-group">
                                                        <input name="stateData" className="form-control disabled-input" placeholder="Enter State" type="text" maxLength={50}
                                                          disabled onChange={(e) => { setStateData(e.target.value) }} value={stateData}  />

                                                    </div>
                                                    {/* <small className="text-danger d-block mt-2">{error.addressErr}</small> */}
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Zip </label>
                                                    <div className="input-group">
                                                        

                                                        <Input name="postalCodeData" className="form-control disabled-input" placeholder="Enter Postal Code"
                                                          disabled  type="text" maxLength={12} value={postalCodeData}
                                                        />
                                                       
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Country </label>
                                                    <div className="input-group">
                                                        <input name="country" disabled className="form-control disabled-input" placeholder="Enter Country" type="text" maxLength={50} onChange={(e) => { setCountry(e.target.value) }} value={country} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Latitude</label>
                                                    <div className="input-group">
                                                        <input name="country" className="form-control disabled-input" placeholder="Enter Latitude"
                                                          disabled  type="text" maxLength={50} onChange={(e) => { setLatitude(e.target.value) }} value={latitude} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Longitude </label>
                                                    <div className="input-group">
                                                        <input name="country" className="form-control disabled-input" placeholder="Enter Longitude" type="text" maxLength={50}
                                                          disabled  onChange={(e) => { setLongitude(e.target.value) }} value={longitude} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    : ""}

                                <div className="col-md-12 mb-4">
                                    <div className="form-checkbox">
                                        {loading && currentId == undefined || currentId == null || currentId == isNaN ?

                                            <label>

                                                <input type="checkbox" defaultChecked={checked} name="isActive" value={isActive} onChange={() => handleChange()} ></input> Active</label> :

                                            <label>
                                                <input type="checkbox" name="isActive" checked={isActive} onChange={() => handleChange()} ></input>
                                                Active</label>}
                                    </div>
                                </div>


                                <div className="col-md-12"><button className="btn btn-primary" >{pageButton}</button>

                                    <Link className="btn btn-bordered ms-2" to="/Location">Cancel</Link>
                                </div>
                            </div>
                        </form>
                    </Form>
                </div>
            </div>
        </Layout>
    );
};
export default connect()(Add);

