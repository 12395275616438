import * as React from 'react';
import { Route } from 'react-router';
import { Switch, BrowserRouter as Router,Navigate } from "react-router-dom";
import { PrivateRoute } from './Routes/PrivateRoute';
// Import components
import Login from './components/Authentication/Login'
import Auth from './components/Authentication/Auth'
import ForgotPassword from './components/Authentication/ForgotPassword';
import EmailVerification from './components/Authentication/EmailVerification';
import Dashboard from './components/Dashboard/Index';
import User from './components/User/Index';
import CompanyIndex from './components/company/Index'
import RelationIndex from './components/Relation/index'
import IndexQrDetails from './components/QrReview/index.js'
import QRScan from './components/UserLogin/QRScan';
import QRThumbnail from './components/UserLogin/QRThumbnail';
import DetailedReview from './components/UserLogin/DetailedReview';
import QRAssign from './components/QRAssign/QRAssign'
import QRParentChild from './components/UserLogin/QRParentChild';
import IndexLocationType from './components/Location/Index';
import MediaIndex from './components/MediaType/Index';
import IndexGuideType from './components/GuideType/Index'
import IndexDevice from './components/DeviceType/Index';
import EmailConfirmation from './components/Authentication/EmailConfirmation';
import AreaType from './components/AreaType/Index.js';
import AreaTypeAdd from './components/AreaType/AddEdit';
import UserTypeAdd from './components/User/Add';
import CompanyAdd from './components/company/AddEdit'
import LocationAdd from './components/Location/Add';
import LocationUpdate from './components/Location/LocationUpdate';
import MediaTypeAdd from './components/MediaType/Add';
import RelationAdd from './components/Relation/add'
import GuideTypeAdd from './components/GuideType/Add';
import DeviceTypeAdd from './components/DeviceType/Add';
// import AssignQR from './components/QR/Index';
import AssignQR from './components/QR/openModal';
import AddQR from './components/QrReview/AddQR'
import VirtualQRReview from './components/QrReview/VirtualQRReview'
import ChangePassword from './components/ChangePassword/ChangePassword';
import ChangeOldPassword from './components/ChangePassword/ChangeOldPassword';
import openQR from './components/QR/openQR';
import ThumbnailList from './components/Dashboard/ThumbnailList';
import AddQREntries from './components/QREntries/QREntry';
import QREntriesIndex from './components/QREntries/Index';
// Import css
import './assets/css/style.css';
import './assets/css/fontawesome.css'

import Index from './components/AreaType/Index.js';

// import UserComponent from './components/User/CustomerComponent';
import MediaComponent from './components/MediaType/MediaComponent';
import QrDetailsComponent from './components/QrReview/QrReviewComponent';

import RelationComponent from './components/Relation/RelationComponent';
import { CompanyDetails } from './Redux/Constants/Company';
import AboutUs from './components/UserLogin/AboutUs';
import contactUs from './components/UserLogin/Contact';
import InvalidQR from './components/UserLogin/InvalidQR';
import ProductPointing from './components/ProductPointing';
import UpdateQR from './components/QrReview/UpdateQR'


 
export default () => (
    <div className="App">
       
        <Router>
            <Switch>  
                <Route exact path='/' component={Login} />
                <Route exact path='/Access/:token/:CompanyId/:SiteId' component={Auth} />
                <Route exact path='/ForgotPassword' component={ForgotPassword} />
                <Route exact path="/EmailVerification" component={EmailVerification}/>
                <Route exact path="/EmailConfirmation" component={EmailConfirmation}/>
                <PrivateRoute exact path="/Dashboard" component={Dashboard} />
                <PrivateRoute exact path="/AreaType" component={Index} />  
                <PrivateRoute exact path="/Area/Add" component={AreaTypeAdd} /> 
                <PrivateRoute exact path="/Area/Edit/:id" component={AreaTypeAdd} /> 
                <PrivateRoute exact path="/DeviceType" component={IndexDevice} />  
                <PrivateRoute exact path="/DeviceType/Add" component={DeviceTypeAdd} />
                <PrivateRoute exact path="/Device/Edit/:id" component={DeviceTypeAdd} />
                <PrivateRoute exact path="/GuideType" component={IndexGuideType} />
                <PrivateRoute exact path="/GuideType/Add" component={GuideTypeAdd} />
                <PrivateRoute exact path="/Guide/Edit/:id" component={GuideTypeAdd} /> 
                <PrivateRoute exact path="/Location" component={IndexLocationType} />
                <PrivateRoute exact path="/Location/Add" component={LocationAdd} />
                <PrivateRoute exact path="/Location/Edit/:id" component={LocationAdd} />
                <PrivateRoute exact path="/LocationUpdate" component={LocationUpdate} />
                <PrivateRoute exact path="/User" component={User} />
                <PrivateRoute exact path="/User/Add" component={UserTypeAdd} /> 
                <PrivateRoute exact path="/User/Edit/:id" component={UserTypeAdd} />
                <PrivateRoute exact path="/Company" component={CompanyIndex} />  
                <PrivateRoute exact path="/Company/Add" component={CompanyAdd} /> 
                <PrivateRoute exact path="/Company/Edit/:id" component={CompanyAdd} />
                <PrivateRoute exact path="/MediaType" component={MediaIndex} /> 
                <PrivateRoute exact path="/MediaType/Add" component={MediaTypeAdd} />
                <PrivateRoute exact path="/MediaType/Edit/:id" component={MediaTypeAdd} />
                <PrivateRoute exact path="/Media/MediaComponent" component={MediaComponent} />
                <PrivateRoute exact path="/QR" component={AssignQR} />
                <PrivateRoute exact path="/Relation" component={RelationIndex} /> 
                <PrivateRoute exact path="/Relation/Add" component={RelationAdd} />
                <PrivateRoute exact path="/Relation/Edit/:id" component={RelationAdd} />
                <PrivateRoute exact path="/Relation/RelationComponent" component={RelationComponent} />
                <PrivateRoute exact path="/QrReview" component={IndexQrDetails} />
                <PrivateRoute exact path="/VirtualQRReview" component={VirtualQRReview} />
                <PrivateRoute exact path="/QrReview/QrReviewComponent" component={QrDetailsComponent} />
                <PrivateRoute exact path="/Add/QR" component={AddQR} />
                <PrivateRoute exact path="/Add/QR/QrCodeId/:qRcodeId/MediaId/:mediaId" component={AddQR} />
                <PrivateRoute exact path="/QRAssign" component={QRAssign} />
                <PrivateRoute exact path="/thumbnailList" component={ThumbnailList} />
                <PrivateRoute exact path="/QREntries" component={QREntriesIndex} />
                <PrivateRoute exact path="/QREntries/Add" component={AddQREntries} />
                <PrivateRoute exact path="/productMarking" component={ProductPointing} />
                <Route exact path="/UpdatePassword/:emailId" component={ChangePassword} />
                <Route exact path="/ChangePassword" component={ChangeOldPassword} />
                <Route exact path="/AboutUs" component={AboutUs} />
                <Route exact path="/Contact" component={contactUs} />           
                <Route exact path="/QRThumbnail/:id" component={QRThumbnail} /> 
                <Route exact path="/QRScan" component={QRScan} />             
                <Route exact path="/:id" component={QRParentChild} />
                <Route exact path='/QRDescription/:mediaId' component={DetailedReview} />
                <Route exact path="/InvalidPage" component={InvalidQR} />
               
                <PrivateRoute exact path="/Update/QR/QrCodeId/:qRcodeId/MediaId/:mediaId" component={UpdateQR} />
            </Switch>
        </Router>
    </div>
);
