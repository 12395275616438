import { allLocationByCompany } from "../Constants/locationBycompany";

export const getAllLocationByCompanyId = (allLocationByCompanyId) => {
    return {
        type: allLocationByCompany.GET_AllLocationByCompanyId,
        payload: allLocationByCompanyId
    };
};

export const updateLocationByCompanyId = (allLocationByCompanyId) => {
    return {
        type: allLocationByCompany.UPDATE_LocationByCompanyId,
        payload: allLocationByCompanyId
    };
};