import React, { useEffect, useState, useCallback, useMemo } from "react";

import { connect } from 'react-redux';
import axios from "axios"
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
// Import Layout
import Layout from '../Layout';
import { Tooltip } from 'antd';

// Import static images
import Add from "../../assets/images/Add.svg";
import Trash_duotone_line from "../../assets/images/Trash_duotone_line.svg";
import Search_light from "../../assets/images/Search_light.svg";
import Union from "../../assets/images/Union.svg";
import double_arrow from "../../assets/images/double-arrow.svg";
import Edit_light from "../../assets/images/Edit_light.svg";
import Trash_light from "../../assets/images/Trash_light.svg";
import { Modal } from 'antd';
import Loader from "../loader";

import Table, { TableProps } from "antd/lib/table";
import { fetchUserData } from "../../Redux/Api/fetch.api";
import { setUserType, deleteUserTypeData } from "../../Redux/Actions/UserActions";
import * as url from "../../api/uri_helper"


const Index = () => {
    const allUserTypes = useSelector((state) => state.allUserType.user)
    const allUserRoleTypes = useSelector((state) => state.allUserType.user.roles)
    const [loading, setLoading] = useState(false);
    const [totalIndex, setCurrentIndex] = useState()
    const [sort, setSort] = useState('');
    const [sortEmail, setSortEmail] = useState('');
    const [sortRoles, setSortRoles] = useState('');
    const [options, setOptions] = useState({
        pagination: { current: 1, pageSizeOptions: [5, 10, 20, 50] },

    });
    const [table, setTable] = useState([]);

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        
        getProducts(options)
    }, [])

    const getProducts = (options) => {
        setLoading(true)
        fetchUserData()
            .then(response => {
                dispatch(setUserType(response.data))
                // setLoading(false);
                setTable(response.data.data)
                
                setOptions({
                    ...options,
                    pagination: {
                        current: options.pagination.current,
                        total: response.data.count,
                        pageSizeOptions: ['10', '20', '50'],
                        showSizeChanger: true,
                    }
                })
                
            })
            .catch((err) => {
                console.log("Err: ", err);
            })
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    const deleteCustomerData = async (userId) => {
        setLoading(true)
        await axios.delete(url.DELETE_USER + '/' + userId, {

        }).then(response => {
           
            dispatch(deleteUserTypeData(response.data))
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            toast.success(response.data.message,{
                className:"toastSuccess",
            }, );

            getProducts(options)

        }).catch((err) => {
            console.log("Err: ", err);
        });
        setTimeout(() => {
            setLoading(false)
        }, 2000)

    }


    const { confirm } = Modal;
    const handleDelete = (userId) => {
        confirm({
            title: 'Are you sure you want to delete this record?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteCustomerData(userId)
            },
            onCancel() {
            },
        });
    }
    const handleEdit = (userId) => {
        history.push('/User/edit/' + userId)
       
    }

    const bindRole = (userRoles) => {
      
        return userRoles.map(x => x.roleName).join(", ");
    }
    const handleChange = (pagination, sorter,current) => {

        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        getProducts({
            ...updatedOptions,

        });    
    };

    const uniqueIds = [];
    const filterDataName = data => formatter => data.filter(element => {
        const isDublicate = uniqueIds.includes(element.firstName);

        if (!isDublicate) {
            uniqueIds.push(element.firstName);
            return true;
        }
        return false;
    }).map(item => ({
        text: formatter(item),
        value: formatter(item)

    }));

    const filterDataRoles = data => formatter => data.filter(element => {
        const isDublicate = uniqueIds.includes(element.roles[0].roleName);

        if (!isDublicate) {
            uniqueIds.push(element.roles[0].roleName);
            return true;
        }
        return false;
    }).map(item => ({
        text: formatter(item),
        value: formatter(item)

    }));

    const filterData = data => formatter => data.map(item => ({

        text: formatter(item),
        value: formatter(item)
    }));
    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "",          
            // render: (text, record, index) => `${(options.pagination.current - 1) * 10 + index + 1}`
            /* render: (text, record,index) =>`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index +1}`*/
            render: (text, record, index) => { setCurrentIndex(`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}`); return `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}` },


            
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (data, user) => (user.firstName + " " + user.lastName),
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
            filters: filterDataName(table)(i => i.firstName),
            onFilter: (value, record) => record.firstName === value,
            sortOrder: sort,
            onHeaderCell: () => ({
                onClick: () => setSort(sort === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            })

        },
        {
            title: "Email",
            dataIndex: "emailId",
            sorter: (a, b) => a.emailId.localeCompare(b.emailId),
             filters: filterData(table)(i => i.emailId),
            onFilter: (value, record) => record.emailId.indexOf(value) === 0,
            sortOrder: sortEmail,
            onHeaderCell: () => ({
                onClick: () => setSortEmail(sortEmail === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            })
        },
        {
            title: "Roles",
            dataIndex: "roles",
            render: (data, user) => (
                user.roles ?
                    <div className="ellips-dec">
                         <Tooltip title={bindRole( user.roles)}>{bindRole( user.roles)}</Tooltip></div>
                    : "NA"
            ),
            sorter: (a, b) => a.roles[0].roleName.localeCompare(b.roles[0].roleName),
            sortOrder: sortRoles,
            onHeaderCell: () => ({
                onClick: () => setSortRoles(sortRoles === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            }),
            //filters: filterDataRoles(table)(i => i.roles[0].roleName),
            //onFilter: (value, record) => record.roles[0].roleName === value,

        },

        {
            title: "Status",
            dataIndex: "active",
            render: (data) => (data ? "Active" : "Inactive"),
            filters: [
                { text: 'Active', value: true },
                { text: 'Inactive', value: false }
            ],
            onFilter: (value, record) => {
                return record.active === value
            }

        },
        {
            title: "Action ",
            dataIndex: "",
            render: (data, userId) => (<><button className="table-btn" >
                <img src={Edit_light} onClick={() => handleEdit(data.userId)}></img>
            </button>
                <button className="table-btn" >
                    <img src={Trash_light} onClick={() => handleDelete(data.userId)}></img>
                </button></>)
        },
    ]
    return (
        <Layout title="User ">
            <Toaster/>
            <div className="position-relative">
            
                <div className="card-box-header d-inline-flex w-100 align-items-center">
                    <h4 className="m-0">User</h4>
                    <div className="ms-auto">
                        <Link className="btn btn-primary d-flex align-items-center" to="/User/Add"><img src={Add}></img>Add User</Link>
                    </div>
                </div>             
            </div>   
            {loading ? <Loader /> : ''}
            <div className="table-responsive table-new  position-relative ">
            
                <Table
                    columns={columns}
                    dataSource={table}
                    pagination={options.pagination.total > 10 && totalIndex > 9 ? options.pagination : false}
                    onChange={handleChange}
                />
            </div>
        </Layout>
    )
};
export default connect()(Index);


