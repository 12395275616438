import React, { useState } from 'react';
import Webcam from "react-webcam";


const WebcamComponent = () => <Webcam />;

const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: "user"
};

// export const WebcamCapture = () => {

//     const [image,setImage]=useState('');
//     const webcamRef = React.useRef(null);

    
//     const capture = React.useCallback(
//         () => {
//         const imageSrc = webcamRef.current.getScreenshot();
//         setImage(imageSrc)
//         });
//     return (
//         <div >
//             <div>
//                 {image == '' ? 
//                 <Webcam
//                     audio={false}
//                     height={200}
//                     ref={webcamRef}
//                     screenshotFormat="image/jpeg"
//                     width={220}
//                     videoConstraints={videoConstraints}
//                 /> : <img src={image} />}
//             </div>
//             <div>
//                 {image != '' ?
//                     <button onClick={(e) => {
//                         e.preventDefault();
//                         setImage('')
//                     }}
//                         className="webcam-btn">
//                         Retake Image</button> :
//                     <button onClick={(e) => {
//                         e.preventDefault();
//                         capture();
//                     }}
//                         className="webcam-btn">Capture</button>
//                 }
//             </div>
//         </div>
//     );
// };


export const WebcamCapture = ({images}) => {
    const webcamRef = React.useRef(null);
    const [imgSrcs, setImgSrcs] = React.useState([]);
    
    const capture = React.useCallback(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrcs(prev => [...prev, imageSrc]);
      images(imageSrc)
    }, [webcamRef, setImgSrcs]);
  
    const handleRemoveImgClick = () => {
      setImgSrcs((prevImgs) => prevImgs.slice(1));
    };
    
    return (
      <>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          className='video-section'
        />
        <div className='w-100 text-center'>
            <button onClick={capture}  className=" btn mt-3 btn-primary">Capture photo</button>
            {/* <button  className=" btn mt-3 ms-2 btn-primary" >Upload photo</button> */}

        </div>
        {/* <div className='img-section flex-wrap d-flex align-items-center'>
            {imgSrcs.map(imgSrc =>
            <div className='img--box position-relative'>
                <span class="position-absolute" onClick={handleRemoveImgClick}>X</span>
                <img
                    src={imgSrc}
                />
                {console.log("image",imgSrcs)}
            </div>
            )}        
        </div> */}
      </>
    );
  };
  