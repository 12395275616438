import React, { useState } from "react"
import Layout from '../Layout';
import Search from "../../UserAssets/images/search1.svg"
import logo from "../../UserAssets/images/authenteklogo.png"
import Menu from "../../UserAssets/images/toggle.svg"
import AboutUs from "../UserLogin/AboutUs";
import { Link, useHistory } from 'react-router-dom';
import BackBtn from "../../UserAssets/images/arrow-left.svg"
import SupportIcon from "../../UserAssets/images/support-open.svg"
import QRCode from "../../UserAssets/images/support.png"
import CloseIcon from "../../UserAssets/images/close.png"
import EditIcon from "../../UserAssets/images/editing.png"

const Header = ({ isParentId, isId, isQRCode, id, parentQRId, parentQrCodeId, parentQrCodeIdnew, 
    parentQrCodeIdlatest, qrGroupingDetails, isBackButton, isCurrentid, setSupportModalDisplay, isSupportIcon }) => {
    
    return (
        <div class="header-section userside-page">
            <div class="container-fluid">
                {
                   
                    //isParentId==0 && parentQRId ? <div class="backBtn"><Link to={`/${id}`}><img src={BackBtn}></img></Link></div>
                    //:
                    parentQrCodeId ? <div class="backBtn"><Link to={`/${parentQrCodeId}`}><img src={BackBtn}></img></Link></div> :
                       
                        // isId && qrGroupingDetails.childThumbnail.qrCode == 0? <div class="backBtn"><Link to={{ pathname: `/QRThumbnail/${isId}`, state: { parentQrCodeIdlatest: parentQrCodeIdnew } }}><img src={BackBtn}></img></Link></div> 
                        isId ? <div class="backBtn"><Link to={{ pathname: `/QRThumbnail/${isId}` }}><img src={BackBtn}></img></Link></div>
                            :
                            parentQrCodeIdnew ? <div class="backBtn"><Link to={`/${parentQrCodeIdnew}`}><img src={BackBtn}></img></Link></div> :
                                parentQrCodeIdlatest  ? <div class="backBtn"><Link to={`/${parentQrCodeIdlatest}`}><img src={BackBtn}></img></Link></div> :
                                    id ? <div class="backBtn"><Link to={`/${id}`}><img src={BackBtn}></img></Link></div> 

                                       
                                        : ''
                }

                <div className="logo m-auto">
                    <div className="header-text">
                        <span className="tekacolor">TEKa</span>
                        <span className="custom-color">Help</span>
                    </div>
                </div>
                <div className="header-nav d-flex gap-4 align-items-center">

                    {!isSupportIcon ? "" :
                        <div className="dropdown">
                            <button className="support-icon" type="button"
                                title="Click to Add Support Request"
                                onClick={() => setSupportModalDisplay(prev => !prev)}>
                                <img src={SupportIcon} className="support-icon-img" alt="Support Icon"></img>
                            </button>

                        </div>
                    }


                    <div className="dropdown">
                        <a className="btn btn-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src={Menu}></img>
                        </a>
                        <ul class="dropdown-menu menu-drop" aria-labelledby="dropdownMenuLink">
                            <li><a class="dropdown-item" href="/QRScan">Home</a></li>
                            <li ><a class="dropdown-item" href="/AboutUs" >About Us</a></li>
                            <li><a class="dropdown-item" href="/Contact">Contact Us</a></li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>)
}
export default Header