import {productTypes} from '../Constants/productMarkingType'

// initial state
const initialState = {
    products: []
}

export const productMarkType = (state = initialState, { type, payload }) => {
    switch (type) {
        case productTypes.GET_ALLPRODUCTS:
            return { ...state, products: payload.data };

        default:
            return state;
    }
};