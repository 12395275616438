
import { QRTypes } from "../Constants/QR";

// initial state
const initialState = {
    qrCodeThumbnailData: []
}

export const QrThumbnailReducers = (state = initialState, { type, payload }) => {
    switch (type) {
       
        case QRTypes.GET_QRCODETHUMBNAIL:

            return { ...state, qrCodeThumbnailData: payload.data };

        default:
            return state;
    }
};
