import { DeviceTypes } from "../Constants/Device-Types"

// initial state
const initialState = {
    deviceTypes: []
}

export const DeviceReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case DeviceTypes.SET_DEVICETYPES:
            return { ...state, deviceTypes: payload.data };

        //Add DeviceType
        case DeviceTypes.ADD_DEVICETYPES:
            return { ...state, deviceTypes: payload };

        //Delete DeviceType
        case DeviceTypes.DELETE_SELECTED_DEVICETYPES:
            return { ...state, deviceTypes: payload };
        
        default:
            return state;
    }
};