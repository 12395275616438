import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import toast, { Toaster, useToasterStore } from "react-hot-toast";
// Import Layout
import Layout from "../Layout";
import Loader from "../loader";
import userIcon from "../../assets/images/active_users_ico.svg"
import qrIcon from "../../assets/images/active_qr_ico.svg"
import productIcon from "../../assets/images/all_product_ico.svg"
import mediaIcon from "../../assets/images/all-medias_ico.svg"
import reviewIcon from "../../assets/images/pending-reviews_ico.svg"
import companyIcon from "../../assets/images/top_companies_ico.svg"
import { Link } from "react-router-dom";
import Chevron from "../../assets/img/Chevron_Right.svg";

import qrcodescan from "../../assets/images/qr_codeindex.svg";
import qrcodereview from "../../assets/images/qr_codereview.svg";
import activeQR from "../../assets/img/active_qr.svg";
import activeUser from "../../assets/img/active-user.svg";
import allMedia from "../../assets/img/all-media.svg";
import CombinedShape from "../../assets/img/Combined Shape.svg";
import Demo from "../../assets/img/demo.jpg";
import Group from "../../assets/img/Group-chat.svg";
import Long_arrow from "../../assets/img/Long_arrow.svg";
import Thumbnail from "../../assets/img/thumbnail-img.svg";
import Total from "../../assets/img/total-company.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardCount, fetchAllThumbnail } from "../../Redux/Api/fetch.api";
import { getDashboardCount, getThumbnail } from "../../Redux/Actions/DashboardCount";
import '../../../src/custom.css'


const TOAST_LIMIT = 1;
const Index = () => {
	const allDashboardCounts = useSelector((state) => state.allDashboardReducer.dashboardData); // data
	console.log("data", allDashboardCounts);
	const allThumbnail = useSelector((state) => state.allDashboardReducer.thumbnail);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const { toasts } = useToasterStore();
	useEffect(() => {
		toasts
			.filter((t) => t.visible) // Only consider visible toasts
			.filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
			.forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
	}, [toasts]);

	useEffect(() => {
		setTimeout(() => {
            setLoading(false)
        }, 2000)
		getDashboardData()
		getAllThumbnail()
	}, [])


	const getDashboardData = () => {
		
		setLoading(true)
		fetchDashboardCount()
			.then(response => {
				dispatch(getDashboardCount(response.data))
				
			})
			.catch((err) => {
				console.log("Err: ", err);
			})
	}

	const getAllThumbnail = () => {
		setLoading(true)
		fetchAllThumbnail(1)
			.then(response => {
			
				dispatch(getThumbnail(response.data.data))
				setLoading(false)
			})
			.catch((err) => {
				console.log("Err: ", err);
			})
	}

	return (
		<Layout title="Dashboard">
			<Toaster />
			{loading ? <Loader /> : ''}
			<div class="new-dashboard">
				<div class="dash-column">
					<div class="row">
						<div class="col-sm-8 column-left">
							<div class="row">
								<div class="col-sm-6 boxes-outers">
									<a href="/ProductMarking" class="box--inner">
										<div class="dash-icon">
											<img src={CombinedShape} alt="" />
										</div>
										<div class="content--box">
											<h4>All Products</h4>
											<div class="counts-text">{allDashboardCounts.all_Product_Count}</div>
											<img class="overlay-arrow" src={Chevron} />
										</div>
									</a>
								</div>

								<div class="col-sm-6 boxes-outers">
									<a href="/QrAssign" class="box--inner">
										<div class="dash-icon">
											<img src={activeQR} alt="" />
										</div>
										<div class="content--box">
											<h4>Active QRs</h4>
											<div class="counts-text">{allDashboardCounts.active_QR_Count}</div>
											<img class="overlay-arrow" src={Chevron} />
										</div>
									</a>
								</div>


								<div class="col-sm-6 boxes-outers">
									<a href="/Company" class="box--inner">
										<div class="dash-icon">
											<img src={Total} alt="" />
										</div>
										<div class="content--box">
											<h4>Total Companies</h4>
											<div class="counts-text">{allDashboardCounts.active_Companies_Count}</div>
											<img class="overlay-arrow" src={Chevron} />
										</div>
									</a>
								</div>

								<div class="col-sm-6 boxes-outers">
									<a href="/User" class="box--inner">
										<div class="dash-icon">
											<img src={activeUser} alt="" />
										</div>
										<div class="content--box">
											<h4>Active Users</h4>
											<div class="counts-text">{allDashboardCounts.active_Users_Count}</div>
											<img class="overlay-arrow" src={Chevron} />
										</div>
									</a>
								</div>

								<div class="col-sm-6 boxes-outers">
									<a href="/QrReview" class="box--inner">
										<div class="dash-icon">
											<img src={Group} alt="" />
										</div>
										<div class="content--box">
											<h4>Pending Review</h4>
											<div class="counts-text">{allDashboardCounts.pending_Review_Count}</div>
											<img class="overlay-arrow" src={Chevron} />
										</div>
									</a>
								</div>

								<div class="col-sm-6 boxes-outers">
									<a href="/QrReview" class="box--inner">
										<div class="dash-icon">
											<img src={allMedia} alt="" />
										</div>
										<div class="content--box">
											<h4>All Media</h4>
											<div class="counts-text">{allDashboardCounts.active_Media_Count}</div>
											<img class="overlay-arrow" src={Chevron} />
										</div>
									</a>
								</div>

								<div class="col-sm-6 boxes-outers">
									<a href="/QR" class="box--inner">
										<div class="dash-icon media_icon">
											<img src={qrcodescan} alt="" />
										</div>
										<div class="content--box">
											<h4>Scan QR and Upload</h4>
											<div class="counts-text"></div>
											<img class="overlay-arrow" src={Chevron} />
										</div>
									</a>
								</div>

								<div class="col-sm-6 boxes-outers">
									<a href="/QRScan" class="box--inner">
										<div class="dash-icon review_icon">
											<img src={qrcodereview} alt="" />
										</div>
										<div class="content--box">
											<h4>Scan QR and Review</h4>
											<div class="counts-text"></div>
											<img class="overlay-arrow" src={Chevron} />
										</div>
									</a>
								</div>


							</div>
						</div>
						<div class="col-sm-4 column-right">
							<div class="thumbnails-section">
								<div class="thumb-heading">
									<div class="thumb-icon"><img src={Thumbnail} alt="" /></div>
									<span>Thumbnails </span>
									
								</div>
								<div class="thumb-box">
									{allThumbnail.length >= 1 ? allThumbnail.map((item, index) => {
										return (<>		
											{ index <14 ?
												<div class="thumb-box-inner">
													<img src={item.fileurl} alt="" />
												</div> : " "
											}
										</>	
									)}) : " "}
									
								</div>
								<div class="w-100 d-flex">
									<Link className="view-BTN ms-auto" to="/thumbnailList" >View More<img src={Long_arrow} alt="" /></Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
				</div>
			</div>
		</Layout>
	);
}
export default connect()(Index);
