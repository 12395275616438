
import { CompanyLocationData } from "../Constants/Relation-Types";

// initial state
const initialState = {
    relation: []
}

export const CompanyLocationReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case CompanyLocationData.SET_COMPANYLOCATIONDATA:
            return { ...state, relation: payload.data };

        //Add DeviceType
        case CompanyLocationData.ADD_COMPANYLOCATIONDATA:
            return { ...state, relation: payload };

        //Delete DeviceType
        case CompanyLocationData.DELETE_COMPANYLOCATIONDATA:
            return { ...state, relation: payload };
        
        default:
            return state;
    }
};