
import { CompanyDetails } from "../Constants/Company";
// initial state
const initialState = {
    company: []
}

export const CompanyReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case CompanyDetails.SET_COMPANYDETAILS:
            return { ...state, company: payload.data };
        //Developer
        case CompanyDetails.ADD_COMPANYDATA:
            return { ...state, company: payload };


     //Delete CompanyData
        case CompanyDetails.DELETE_COMPANYDATA:
            return { ...state, company: payload };
        default:
            return state;
    }
};