// Server details
//export const BASE = 'https://localhost:44359' // LocalHost
//export const BASE = 'https://tekahelpbeta.azurewebsites.net' //Dev
 export const BASE = 'https://tekahelp.azurewebsites.net' //Production


export const BASE_URI = `${BASE}/api`
export const SIGNALR_ENDPOINT = 'https://localhost:7044'

export const PARENT = 'https://tekaverse.com'
export const Parent_URI = `${PARENT}`

// Authentication 
export const POST_LOGIN = `${BASE_URI}/User/SignIn`

//Email Verification
export const GET_EMAILVERIFICATION=  `${BASE_URI}/User/VerifyEmail`


//Forgot Password
export const ADD_UPDATEPASSWORD = `${BASE_URI}/User/UpdatePassword` 

//Change Password
export const POST_CHANGEPASSWORD = `${BASE_URI}/User/ChangePassword` 

// Area Type 
export const GET_AREATYPES = `${BASE_URI}/AreaType`
export const GET_AREATYPEBYID = `${BASE_URI}/AreaType/GetAreaDetailById`
export const POST_AREATYPE = `${BASE_URI}/AreaType`
export const DELETE_AREATYPE = `${BASE_URI}/AreaType`
export const UPDATE_AREATYPE = `${BASE_URI}/AreaType`

// Device Type 
export const GET_DEVICETYPES = `${BASE_URI}/DeviceType`
export const POST_DEVICETYPE = `${BASE_URI}/DeviceType`
export const GET_DEVICEBYID = `${BASE_URI}/DeviceType/GetDeviceDetailById`
export const DELETE_DEVICETYPE = `${BASE_URI}/DeviceType`
export const UPDATE_DEVICETYPE = `${BASE_URI}/DeviceType`


// Guide Type 
export const GET_GUIDETYPES = `${BASE_URI}/GuideType`
export const POST_GUIDETYPE = `${BASE_URI}/GuideType`
export const GET_GUIDEBYID = `${BASE_URI}/GuideType/GetGuideDetailById`
export const DELETE_GUIDETYPE = `${BASE_URI}/GuideType`
export const UPDATE_GUIDETYPE = `${BASE_URI}/GuideType`


// Location Type 
export const GET_LOCATIONTYPES = `${BASE_URI}/Location`
export const POST_LOCATIONTYPE = `${BASE_URI}/Location`
export const GET_LOCATIONBYID = `${BASE_URI}/LOCATION/GetLocationById`
export const DELETE_LOCATIONTYPE = `${BASE_URI}/Location`
export const UPDATE_LOCATIONTYPE = `${BASE_URI}/Location`
//export const UPDATE_LOCATIONFORQRCODEID = `${BASE_URI}/UpdateLocationForQRCodeId/qrCodeId/{qrCodeId}/locationId/{locationId}`
export const UPDATE_LOCATIONFORQRCODEID = (id, locationId) => { return `${BASE_URI}/QR/UpdateLocationForQRCodeId/qrCodeId/${id}/locationId/${locationId}` };
export const UPDATE_INSTLOCATION = `${BASE_URI}/QR/UpdateInstallationLocation`;


//User Type
export const GET_USER = `${BASE_URI}/user`
export const POST_USER = `${BASE_URI}/user`
export const GET_USERBYID = `${BASE_URI}/user/GetUserById`
export const GET_DECRYPTEDTOKEN = `${BASE_URI}/user/DecryptToken`
export const AUTHENTICATE_USER = `${BASE_URI}/user/AuthenticateUser`
export const GET_TOKENAUTHENTICATION = `${BASE_URI}/user/TokenAuthentication`




export const DELETE_USER = `${BASE_URI}/user`
export const UPDATE_USER = `${BASE_URI}/user`

//User Role
export const GET_USERROLES = `${BASE_URI}/User/GetRoles`

//DashBoard
export const GET_DASHBOARDCOUNT = `${BASE_URI}/DashBoard/DashBoardCount`


//Company
export const GET_COMPANYDETAILS= `${BASE_URI}/Company`
export const GET_COMPANYDETAILSID= `${BASE_URI}/Company/GetCompanyById`
export const UPDATE_COMPANYDETAILS= `${BASE_URI}/Company`
export const POST_COMPANYDETAILS= `${BASE_URI}/Company`
export const DELETE_COMPANYDETAILS= `${BASE_URI}/Company`


//Media Type
export const GET_MEDIATYPES = `${BASE_URI}/MediaType`
export const POST_MEDIATYPES = `${BASE_URI}/MediaType`
export const GET_MEDIATYPESBYID = `${BASE_URI}/MediaType/GetMediaDetailById`
export const DELETE_MEDIATYPES = `${BASE_URI}/MediaType`
export const UPDATE_MEDIATYPES = `${BASE_URI}/MediaType`


//QR 
// export const GET_LOCATIONTYPEDATA = `${BASE_URI}/Location/AllLocationWithCustomer`
// export const CHECK_QRVERIFYSTATUS= `${BASE_URI}/QR/VerifyStatus`
export const GET_LocationByQRCodeId =  `${BASE_URI}/QR/GetLocationByQRCodeId`
export const CREATE_VIRTUALQRCODE = `${BASE_URI}/QR/CreateVirtualQrCode`
export const SAVEMEDIA_QRDATA = `${BASE_URI}/QR/SaveMedia`
export const DELETEMEDIA_QRDATA = (mediaId) => { return `${BASE_URI}/QR/Deletemedia/${mediaId}` };
export const DELETEMULTIPLEMEDIA_QRDATA = `${BASE_URI}/QR/DeleteMultipleMedia`;
export const SAVELISTOFMEDIA_QRDATA = `${BASE_URI}/QR/SaveListOfMedia`;
export const SAVEQRDETAILS_QRDATA = `${BASE_URI}/QR/SaveQrDetails`;
export const ADDMEDIADETAILS_QRDATA = `${BASE_URI}/QR/AddmediafromQRcode`;
export const GET_ALLACTIVENOTMAPPEDQRCODES = `${BASE_URI}/QR/GetAllActiveNotMappedQrCodes`;
export const SAVE_QRCODES = `${BASE_URI}/QR/AddQRCodes`;


//Virtual QR
export const GET_VIRTUALQRDETAIL = `${BASE_URI}/QR/GetVirtualQRDetail`
export const PUT_ASSIGNVIRTUALWITHQR = `${BASE_URI}/QR/AssignVirtualWithQR`
export const GET_VIRTUALQRUSERSECTION = `${BASE_URI}/QR/GetVirtualQRData`
export const GET_ALLVIRTUALQRCODES = `${BASE_URI}/QR/GetAllVirtualQRCodes`


//Relation

export const GET_COMPANYLOCATIONDATA = `${BASE_URI}/CompanyLocationRelation`
export const POST_COMPANYLOCATIONDATA = `${BASE_URI}/CompanyLocationRelation`
export const GET_COMPANYLOCATIONBYID= `${BASE_URI}/CompanyLocationRelation/GetCompanyLocationById`
export const UPDATE_COMPANYLOCATIONDATA = `${BASE_URI}/CompanyLocationRelation`
export const DELETE_COMPANYLOCATIONDATA = `${BASE_URI}/CompanyLocationRelation`
export const GET_VERIFYLOCATIONID = (locationid) => { return `${BASE_URI}/CompanyLocationRelation/VerifyLocationId/${locationid}` };

//CompanyLocationMapping
export const GET_CompanyLocationMapping = `${BASE_URI}/CompanyLocationRelation/CompanyLocationMapping`


//Qr Details
export const GET_QRDETAILS = `${BASE_URI}/QR`

// Media
export const GET_ALLMEDIA = `${BASE_URI}/Media/GetAllMedia`

// Link
export const LINK_QRDETAILS = `${BASE_URI}/Link/LinkQRCode`


//QR/GetAllLocationByCompanyId
export const GET_AllLocationByCompanyId = (id) => { return `${BASE_URI}/QR/GetAllLocationByCompanyId/${id}`};

//QR/GetAllQRByLocationId
export const GET_AllQRByLocationId = (id) => { return `${BASE_URI}/QR/GetAllQRByLocationId/${id}` };

//QR/GetAllVirtualQRByLocationId
export const GET_AllVirtualQRByLocationId = (id) => { return `${BASE_URI}/QR/GetVirtualQRByLocation/${id}` };



//QR/GetAllQRCodes
export const GET_GetAllQRCodes = () => { return `${BASE_URI}/QR/GetAllQRCodes` };

export const GET_GetAllProductLocation = (pageIndex) => { return `${BASE_URI}/QR/GetProductLocationDetails/${pageIndex}` };
export const GET_GetAllQRCodesWithThumbnail = () => { return `${BASE_URI}/QR/GetAllQRCodesWithThumbnail` };
//export const GET_GetAllThumbnail = `${BASE_URI}/QR/GetAllThumbnails`;

export const GET_GetAllThumbnail = (pageIndex) => { return `${BASE_URI}/QR/GetAllThumbnails/${pageIndex}` };
export const GET_SearchByThumbnail = (filename) => { return `${BASE_URI}/QR/SearchThumbnailByFilename/${filename}` };
export const GET_SearchByProduct = (productname) => { return `${BASE_URI}/QR/SearchByProductname/?Productname=${productname}` };




//QR/UpdateQrReviewDetails
export const POST_UpdateQrReviewDetails = `${BASE_URI}/QR/UpdateQrReviewDetails`;
export const PUT_UPDATEQREDITDETAILS = `${BASE_URI}/QR/UpdateQrEditDetails`;


//QR/UpdateThumbnail
export const POST_UpdateThumbnail = `${BASE_URI}/QR/UpdateThumbnail`;

///QR/ParentChildData
export const GET_ParentChildData = `${BASE_URI}/QR/GetQRRelationship`;


//QRCodeAPIUser 
// export const GET_AllQRCODEID= (id) => {`${BASE_URI}/QR/GetQRDetailsById/${id}`}; 
export const GET_AllQRCODEID= `${BASE_URI}/QR/GetQRDetailsById`

export const GET_AllQRCODEID_ByGuideType= (id) => { return `${BASE_URI}/QR/GetQRDetailsById/${id}/ByGuideType`};

export const UPDATE_ParentQRCodeId = (id, parentId) => { return `${BASE_URI}/QR/UpdateParentForQRCodeId/qrCodeId/${id}/parentId/${parentId}` };

export const UPDATE_FavoritesQRCode = (id) => { return `${BASE_URI}/QR/UpdateFavoritesForQRCodeId/${id}` };

export const GET_QRDETAILS_BY_ID = `${BASE_URI}/QR/GetQRDetailsById`

export const GET_WORK_CATEGORY = `${Parent_URI}/User/GetAllWorkCategory`

export const POST_SUPPORT_REQUEST = `${Parent_URI}/Support/AddSupportData`
