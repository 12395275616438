import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loader from '../loader';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Added api url
import * as url from "../../api/uri_helper"
import axios from 'axios';

// added Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Input, Form, FormFeedback } from "reactstrap"
import { useHistory, useParams } from 'react-router';
//import Message from 'antd-message';
import toast, { Toaster } from 'react-hot-toast';
import logo from "../../UserAssets/images/authenteklogo.png"
import EyeClose from "../../assets/images/eyeClose.svg";
import PassLight from "../../assets/images/fi-rr-lock1.svg";

const changePassword = (data) => {
    const [loading, setLoading] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [passwordTypeCon, setPasswordTypeCon] = useState("password");
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const togglePasswordCon = () => {
        if (passwordTypeCon === "password") {
            setPasswordTypeCon("text")
            return;
        }
        setPasswordTypeCon("password")
    }
    let history = useHistory();
 
    let { emailId } = useParams();


    // On Validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        // initialize of the Values
        initialValues: {
            newPassword: '',
            conPassword: ''
        },
        // Alert messages 
        validationSchema: Yup.object({
            newPassword: Yup.string().required("Please Enter New password").matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),

            conPassword: Yup.string().required("Please Enter Confirm password").oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        }),

        // on form submit / button click
        onSubmit: (values) => {
            // call the api here
            debugger;

            onUpdatePassword(values, emailId);
        }
    });

    //Call update password api
    const onUpdatePassword = async (params,emailId) => {
        // call the api here
        debugger;
          //  setLoading(true);
        const response = await axios.post(url.ADD_UPDATEPASSWORD, {
            // emailId: "saunak@gmail.com",
            emailId: emailId,
            newPassword: params.newPassword,

        }).then(response => {
            debugger;
            if (response.data.success) {                            
                toast.success(response.data.message, {
                    className: "toastSuccess",
                });
                setTimeout(() => {
                    history.push('/');
                }, 2000)
               // setLoading(false);               
            }          
        })
            .catch((err) => {
                console.log("Err: ", err);
            });
          
    }

    if (loading) return <Loader />;

    return (

        <div class="auth-section d-flex flex-wrap login-page min-vh-100 "><Toaster/>
            <div class="d-flex align-items-center flex-wrap flex w-100">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-6 left-section d-flex justify-content-center flex-column">
                            <a href="#"  className="logo mb-4 text-center"><img src={logo} /></a>
                            <p class="m-0 text-center">Welcome to TEkaHelp</p>
                        </div>
                        <div class="col-sm-6 right-section">
                            <div class="card auth-card">
                                <h4>Reset Password</h4>
                               {/* <h6 class="m-0">Enter your new password below.</h6>*/}
                                {/* <form class="form-signin"> */}
                                <Form className="form-horizontal"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}>
                                    <div class="mt-4"></div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <label>New Password</label>
                                                <div class="input-group">
                                                   {/* <span className="input-group-addon"><img src={PassLight}></img></span>*/}
                                                    <Input name="newPassword" lassName="form-control" placeholder="Enter New Password"
                                                        type={passwordType} maxLength={50} onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur} value={validation.values.newPassword}
                                                        invalid={
                                                            validation.touched.newPassword && validation.errors.newPassword ? true : false
                                                        }
                                                    />
                                                    <div class="input-group-append">
                                                        <span class="input-group-text user_icon" id="basic-addon2">
                                                            <div onClick={togglePassword}>
                                                                {passwordType === "password" ? <VisibilityOffIcon style={{ color: 'gray' }} /> : <VisibilityIcon style={{ color: 'gray' }} />}
                                                            </div>
                                                        </span>
                                                    </div>
                                                    {validation.touched.newPassword && validation.errors.newPassword ? (
                                                        <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                                                    ) : null}
                                                    
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label>Confirm Password</label>
                                                <div class="input-group">
                                                   {/* <span className="input-group-addon"><img src={PassLight}></img></span> */}                                                 
                                                    <Input name="conPassword" lassName="form-control" placeholder="Enter Confirm Password"
                                                        type={passwordTypeCon} maxLength={50} onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur} value={validation.values.conPassword}
                                                        invalid={
                                                            validation.touched.conPassword && validation.errors.conPassword ? true : false
                                                        }
                                                    />
                                                    <div class="input-group-append">
                                                        <span class="input-group-text user_icon" id="basic-addon2">
                                                            <div onClick={togglePasswordCon}>
                                                                {passwordTypeCon === "password" ? <VisibilityOffIcon style={{ color: 'gray' }} /> : <VisibilityIcon style={{ color: 'gray' }} />}
                                                            </div>
                                                        </span>
                                                    </div>

                                                    {validation.touched.conPassword && validation.errors.conPassword ? (
                                                        <FormFeedback type="invalid">{validation.errors.conPassword}</FormFeedback>
                                                    ) : null}
                                                </div>

                                            </div>

                                            <div class="d-flex w-100 align-items-center justify-content-between">
                                                <button class="btn btn-primary">Reset Password</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="auth-footer w-100 position-fixed">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6">© AuthenTEK (2023) | TEKaHelp™</div>
                        <div class="col-sm-6 text-end">
                            <a href="#">Terms & Conditions | </a>
                            <a href="#">Privacy Policy</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(changePassword);



