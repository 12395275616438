//import * as React from 'react';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';
import Footer from '../VerticalLayout/Footer';
import axios from "axios"
import React, { useEffect, useCallback, useMemo, useState } from "react";
import * as url from "../../api/uri_helper";
import { Link, useHistory, useLocation } from 'react-router-dom';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import '../../../src/custom.css'
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
// Import static images 
import logo from "../../UserAssets/images/authenteklogo.png"
import MessageLight from "../../assets/images/Message_light.svg";
//import EyeClose from "../../assets/images/eyeClose.svg";
import PassLight from "../../assets/images/fi-rr-lock1.svg";
import { Formik, Field, useFormik } from 'formik';
import { Input, Form, FormFeedback } from "reactstrap"
import * as Yup from 'yup';
import CryptoJS from "crypto-js";
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import { fetchdecrypttoken } from "../../Redux/Api/fetch.api";


const TOAST_LIMIT = 1;
const Log = () => {
    //to make only 1 toast inn total 
    const { toasts } = useToasterStore();
    useEffect(() => {
        toasts
            .filter((t) => t.visible) // Only consider visible toasts
            .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
            .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
    }, [toasts]);
    const [emailid, setEmailId] = useState('');
    const [password, setPassword] = useState('');
    const [encryptedToken, setEncryptedToken] = useState('');
    const [decrypttoken, setDecryptToken] = useState('');
    const [isChecked, setisActive] = useState(false)
    const [success, setSuccess] = useState(false)
    const [disable, setDisable] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const location = useLocation();
    const [text, setText] = useState("");
   
   
   
    const togglePassword =()=>{
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
      }


    const history = useHistory();
    

    // On Validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        // initialize of the Values
        initialValues: {
            emailid: localStorage.getItem('emailid') ? localStorage.getItem('emailid') : '',
            password: localStorage.getItem('password') ? localStorage.getItem('password') : ''
        },

        // Alert messages 
        validationSchema: Yup.object({
            emailid: Yup.string().required('Please Enter Email').matches(
                /^\w+([\.-]?\w+)*@\w+([\.web]?\w+)*(\.\w{2,3})+$/,
                "Please enter valid email "
            ),
            password: Yup.string().required("Please Enter Password").matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
        }),

        // on form submit / button click
        onSubmit: (values) => {
            // call the api here

            setDisable(true);
            Authenticate(values);
        }
    });

    //const dispatch = useDispatch();
    const handleChange = () => {
        setisActive(!isChecked)

    }
    const Authenticate = async (params) => {

        const response = await axios
            .post(url.POST_LOGIN,
                {
                    emailid: params.emailid,
                    password: params.password,
                    isChecked: isChecked
                }).then(response => {
                    console.log(response.data);

                    if (response.data.success) {
                        localStorage.removeItem('userName')
                        localStorage.removeItem('emailid')
                        localStorage.removeItem('password')
                        localStorage.removeItem('isChecked')
                        localStorage.removeItem('tekaHelpToken');
                        localStorage.setItem('UserId', response.data.data.userId); // New changes
                        if (isChecked) {
                            localStorage.setItem('UserId', response.data.data.userId); // New changes
                            localStorage.setItem('isChecked', isChecked ? isChecked : '');
                            localStorage.setItem('emailid', isChecked ? params.emailid : '');
                            localStorage.setItem('tekaHelpToken', response.data.data.token);
                            localStorage.setItem('password', isChecked ? params.password : '');
                            localStorage.setItem('userName', response.data.data.userName);
                            toast.success(response.data.message, {
                                className: "toastSuccess",
                            });
                            history.push({
                                pathname: "/Dashboard",
                                state: { from: location.pathname },
                                data: response.data.message
                            });


                        }
                        else {
                            localStorage.setItem('tekaHelpToken', response.data.data.token);
                            localStorage.setItem('userName', response.data.data.userName);
                            localStorage.setItem('emailid', params.emailid);
                            toast.success(response.data.message, {
                                className: "toastSuccess",
                            });
                            history.push({
                                pathname: "/Dashboard",
                                state: { from: location.pathname },
                                data: response.data.message
                            });
                        }
                    }
                    else {
                        toast.error(response.data.message, {
                            className: "toastSuccess",
                        },);
                    }

                })

            .catch((err) => {
                setDisable(false);
            });
    }

    useEffect(() => {
        const rememberMe = localStorage.getItem('isChecked');
        const alreadyLoggedIn = localStorage.getItem('tekaHelpToken')

        if (alreadyLoggedIn) {
            history.push("/Dashboard");
        }

        if (rememberMe) {
            const userId = rememberMe ? localStorage.getItem('emailid') : '';
            const password = rememberMe ? localStorage.getItem('password') : '';

            setisActive(rememberMe);
            setEmailId(userId);
            setPassword(password);
        }
    }, [])

    return (
        <section className="auth-section d-flex flex-wrap login-page min-vh-100 "><Toaster />
            <div className="d-flex align-items-center flex-wrap flex w-100">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 left-section d-flex justify-content-center flex-column">
                            <a className="logo mb-4 text-center"><img src={logo}></img></a>
                            <p className="m-0 text-center">Welcome to TEKaHelp</p>
                        </div>
                        <div className="col-sm-6 right-section">
                            <div className="card auth-card">
                                <h4>Admin Login</h4>
                                <Form className="form-horizontal"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}>
                                    <div className="mt-5 social-login d-flex flex-wrap"></div>

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label>Email <span className="required-fields"> * </span></label>
                                                <div className="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                        <EmailIcon style={{color:"gray"}}/>
                                                        </span>
                                                    </div>
                                                    <Input name="emailid" className="form-control" placeholder="Enter Email"
                                                        type="text" maxLength={50} onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur} value={validation.values.emailid}
                                                        invalid={
                                                            validation.touched.emailid && validation.errors.emailid ? true : false }
                                                    />
                                                    {validation.touched.emailid && validation.errors.emailid ? (
                                                        <FormFeedback type="invalid">{validation.errors.emailid}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Password <span className="required-fields"> * </span> </label>
                                                <div className="input-group">
                                                        <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon3">
                                                        <LockIcon style={{color:"gray"}}/>
                                                        </span>
                                                    </div>
                                                    <Input name="password" className="form-control" placeholder="Enter Password"
                                                         type={passwordType} maxLength={50} onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur} value={validation.values.password}
                                                        invalid={
                                                            validation.touched.password && validation.errors.password ? true : false}
                                                    />
                                                    <div class="input-group-append">
                                                        <span class="input-group-text" id="basic-addon2">
                                                            <div onClick={togglePassword}>
                                                            { passwordType==="password"? <VisibilityOffIcon style={{ color: 'gray' }} />:<VisibilityIcon style={{ color: 'gray' }} />}
                                                            </div>
                                                        </span>
                                                    </div>
                                                    {validation.touched.password && validation.errors.password ? (
                                                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="agree w-100">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="d-flex align-items-center">
                                                            <input type="checkbox" className="me-2"
                                                                name="isChecked"
                                                                checked={isChecked}
                                                                onChange={handleChange} /> Remember me</div>
                                                    </div>
                                                    <div className="col text-end">

                                                        <Link to={'/EmailVerification'} title="Click to reset password" className="link"> Forgot Password ?</Link>

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="d-flex w-100 align-items-center justify-content-between">
                                                <button className="btn btn-primary" >Sign In</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Footer />
        </section>

    );

}
export default connect()(Log);
