import { allUserGroupingData } from "../Constants/QRCodeIdByGuideType";
// initial state
const initialState = {
    allUserData: []
}

export const allUserGroupingReducer = (state = initialState, { type, payload }) => {
    switch (type) {
           
            case allUserGroupingData.GET_AllUserGroupingData:
                return { ...state, allUserData: payload };

        default:
            return state;
    }
};