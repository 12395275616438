import { allQRCodeIdUser } from "../Constants/QRCodeId";

// initial state
const initialState = {
    allQRCodeId: []
}

export const allQRCodeIdUserData = (state = initialState, { type, payload }) => {

    switch (type) {
       
            case allQRCodeIdUser.GET_AllQRCodeIdUser:
                return { ...state, allQRCodeId: payload.data };

        default:
            return state;
    }
};

