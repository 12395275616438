import React, { useEffect, useCallback, useMemo, useState } from "react";
import axios from "axios"
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
//import Message from 'antd-message';
import { Spinner } from "reactstrap";
// import loading from "../../assets/images/loading.gif"
import Loader from "../loader";


////#Developer
import Layout from '../Layout';
import * as url from "../../api/uri_helper"
// Import static images
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import Add1 from "../../assets/images/Add.svg";
import { METHODS } from "http";
import { addDeviceTypeData, updateDeviceTypeData } from "../../Redux/Actions/DeviceActions";
import { Formik, Field, useFormik } from 'formik';
import { Input, Form, FormFeedback } from "reactstrap"
import * as Yup from 'yup';
import '../../../src/custom.css'
import { fetchAllDeviceId } from "../../Redux/Api/fetch.api";
import toast, { Toaster } from 'react-hot-toast';

const Add = () => {
    const allDeviceTypes = useSelector((state) => state.allDeviceType.deviceTypes)

    const [name, setName] = useState("");
    const [desc, setDesc] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        nameErr: ''
    })
    const [isActive, setisActive] = useState(true)
    const [currentId, setCurrentId] = useState(null);
    const [checked, setChecked] = useState(true);
    const [count, setCount] = useState(0);
    const [disable, setDisable] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    let history = useHistory();
    const handleChange = () => {
        setisActive(!isActive)
    }
    let pageButton = "Update"

    if (!currentId) {
        pageButton = "Save"
    }
 
    useEffect(() => {
        let urlData = location.pathname.split('/');
        setCurrentId(urlData[3])
        if (currentId) {
            setLoading(true)
            fetchAllDeviceId(urlData[3]).then(
                response => {
                    setName(response.data.data.name);
                    setDesc(response.data.data.description);
                    setCount(response.data.data.description.length)
                    setisActive(response.data.data.active)
                    setLoading(false)
                })
        }
    }, [currentId])

    const addDeviceType = async (params) => {
        if (params.name) {
            const response = await axios.post(url.POST_DEVICETYPE, {
                Name: params.name.trim(),
                Description: params.desc.trim(),
                Active: isActive
            }).then(response => {
                dispatch(addDeviceTypeData(response.data))
                if(!response.data.success) // False 
                {
                    toast.error(response.data.message,{
                        className:"toastSuccess",
                    }, );
                    setLoading(false);
                    setDisable(false)
                }
                else {
                    toast.success(response.data.message,{
                        className:"toastSuccess",
                    }, );
                    history.push('/DeviceType');
                    setLoading(false);
                    setDisable(true)
                }
            })
                .catch((err) => {
                    console.log("Err: ", err);
                    setDisable(false)
                });
        }

    };
    const updateDeviceType = async (params) => {
        if (params.name) {
            const response = await axios.put(url.UPDATE_DEVICETYPE, {
                deviceTypeId: currentId,
                Name: params.name.trim(),
                Description: params.desc.trim(),
                Active: isActive,
            }).then(response => {
                dispatch(updateDeviceTypeData(response.data))
                if(!response.data.success) // False 
                {
                    toast.error(response.data.message,{
                        className:"toastSuccess",
                    }, );
                    setLoading(false);
                    setDisable(false)
                }
                else {
                    toast.success(response.data.message,{
                        className:"toastSuccess",
                    }, );
                    history.push('/DeviceType');
                    setLoading(false);
                    setDisable(true);
                }  
            })
                .catch((err) => {
                    console.log("Err: ", err);
                    setDisable(false)
                });
        }
    };
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        // initialize of the Values
        initialValues: {
            name: name ? name : '',
            desc: desc ? desc : ''
        },
        // Alert messages 
        validationSchema: Yup.object({
            name: Yup.string().required('Please Enter Name ').matches(
                "^([A-Za-z0-9]+ )+[A-Za-z0-9]+$|^[A-Za-z0-9]+$",
                "Please enter valid Name"
            ),
            desc: Yup.string().matches(
                "^(.|\s)*[a-zA-Z]+(.|\s)*$",
                "Please enter valid Description"
            )
        }),
        // on form submit / button click
        onSubmit: (values) => {
            setDisable(true)
             { currentId == undefined || currentId == null || currentId == isNaN ? addDeviceType(values) : updateDeviceType(values) };
           
        }
    });
  
    // if (loading) return <Loader />;
    return (
        <Layout title="Device Type">
            <Toaster/>
            <div>
            {loading ? <Loader /> : ''}
                <div className="card-box-header d-inline-flex w-100 align-items-center">
                    <h4 className="m-0">
                        <Link className="backBTN" to="/DeviceType">
                            <img src={Arrow_leftbrown}></img>
                            Back
                        </Link>
                    </h4>
                </div>
                <div className="content-area">
                    <Form className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            
                            validation.handleSubmit();
                            return false;
                        }}>
                        <div className="row">

                            <div class="col-lg-6 col-md-12">

                                <div className="form-group">
                                    <label>Name <span className="required-fields"> * </span> </label>
                                    <div className="input-group">
                                        {/* <input id="text" type="text" value={name} onKeyUp={handleErrors} onChange={(e) => { setName(e.target.value) }} className="form-control" name="Name" placeholder="Enter Your Area"></input> */}
                                        <Input name="name" lassName="form-control" placeholder="Enter Name"
                                            type="text" maxLength={50} onChange={(e) => { validation.handleChange(e), setName(e.target.value.trim()) }}
                                            onBlur={validation.handleBlur} value={validation.values.name}
                                            invalid={
                                                validation.touched.name && validation.errors.name ? true : false
                                            }
                                        />
                                        {validation.touched.name && validation.errors.name ? (
                                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label>Description</label>
                                    <div className="input-group">
                                        <Input name="desc" className="form-control " placeholder="Enter Description"
                                            type="textarea" maxLength={1000} onChange={(e) => { validation.handleChange(e), setCount(e.target.value.length), setDesc(e.target.value.trim()) }}
                                            onBlur={validation.handleBlur} value={validation.values.desc}
                                            invalid={
                                                validation.touched.desc && validation.errors.desc ? true : false
                                            }
                                        />
                                        <span className="w-100 text-right fs12">{count}/1000</span>
                                        {validation.touched.desc && validation.errors.desc ? (
                                            <FormFeedback type="invalid">{validation.errors.desc}</FormFeedback>
                                        ) : null}

                                    </div>
                                </div>

                            </div>

                            <div className="col-md-12 mb-4">
                                <div className="form-checkbox ">
                                    {loading && currentId == undefined || currentId == null || currentId == isNaN ?

                                        <label>

                                            <input type="checkbox" defaultChecked={checked} name="isActive" value={isActive} onChange={() => handleChange()} ></input> Active</label> :

                                        <label>  <input type="checkbox" name="isActive" checked={isActive} onChange={() => handleChange()} ></input>
                                            Active</label>}
                                </div>
                            </div>
                           
                            <div className="col-md-12">
                                <button className="btn btn-primary" disabled={disable}>{pageButton}</button>
                                <Link className="btn btn-bordered ms-2" to="/DeviceType">Cancel</Link>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Layout>
    );
};
export default connect()(Add);

