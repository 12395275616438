import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import Message from 'antd-message';
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios"
// Import Layout

import Layout from '../Layout';
// Import static images
import * as url from "../../api/uri_helper"
import Add from "../../assets/images/Add.svg";
import Trash_duotone_line from "../../assets/images/Trash_duotone_line.svg";
import Search_light from "../../assets/images/Search_light.svg";
import Union from "../../assets/images/Union.svg";
import double_arrow from "../../assets/images/double-arrow.svg";
import Edit_light from "../../assets/images/Edit_light.svg";
import Trash_light from "../../assets/images/Trash_light.svg";
import { Modal } from 'antd';
import { fetchCompanyDetails } from "../../Redux/Api/fetch.api";
import { SetCompanyDetails, deleteCompanyTypeData } from "../../Redux/Actions/CompanyActions";
import Table, { TableProps } from "antd/lib/table";
import Loader from "../loader";
import { Tooltip } from 'antd';

const CompanyIndex = () => {
    const [table, setTable] = useState([]);
    const [loading, setLoading] = useState(false);
    const allCompanyDetails = useSelector((state) => state.allCompanyDetails.company)
    const dispatch = useDispatch();
    const history = useHistory()
    const [totalIndex, setCurrentIndex] = useState()
    const [sort, setSort] = useState('');
    const [sortAdd, setSortAdd] = useState('');
    const location = useLocation();
    const [options, setOptions] = useState({
        pagination: { current: 1, pageSizeOptions: [5, 10, 20, 50] },
    });

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        getCompanyDetails(options)
    }, [])

    const getCompanyDetails = (options) => {
        setLoading(true)
        fetchCompanyDetails({
            PageIndex: options.pagination.current,
            PazeSize: 5,
        })
            .then(response => 
                {dispatch(SetCompanyDetails(response.data))
                    setTable(response.data.data)
                    setOptions({
                        ...options,
                        pagination: {
                           current: options.pagination.current,
                            total: response.data.count,
                            pageSizeOptions: ['10', '20', '50'],
                            showSizeChanger: true,
                        }
                    })                   
                })
            .catch((err) => {
                console.log("Err: ", err);
            })
        setTimeout(() => {
            setLoading(false)
        }, 2000)

    }
    const handleChange = (pagination, sorter, current) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        getCompanyDetails({
            ...updatedOptions,
        });
    };

    const deleteCompanyData = async (companyId,) => {
        setLoading(true)
        await axios.delete(url.DELETE_COMPANYDETAILS + '/' + companyId, {
        }).then(response => {
            dispatch(deleteCompanyTypeData(response.data))
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            toast.success(response.data.message,{
                className:"toastSuccess",
            }, );
            getCompanyDetails(options)

        }).catch((err) => {
            console.log("Err: ", err);
        });
        setLoading(true)
    }

    const { confirm } = Modal;
    const handleDelete = (companyId) => {
        confirm({
            title: 'Are you sure you want to delete this record?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteCompanyData(companyId)
            },
            onCancel() {
            },
        });
    }

    const handleEdit = (companyId) => {
        history.push('/Company/edit/' + companyId)
    }
    const uniqueIds = [];
    const filterDataAddress = data => formatter => data.filter(element => {
        if (element.address1) {
            const isDublicate = uniqueIds.includes(element.address1);
            if (!isDublicate) {
                uniqueIds.push(element.address1);
                return true;
            }
            return false;
        }
    }).map(item => ({
        text: formatter(item),
        value: formatter(item)
    }));
    const filterData = data => formatter => data.map(item => ({
        text: formatter(item),
        value: formatter(item)
    }));
    //const filterData = data => formatter => data.map(item => ({
    //    text: formatter(item),
    //    value: formatter(item)
    //}));

    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "",
            render: (text, record, index) => { setCurrentIndex(`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}`); return `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}` },
        },
        {
            title: "Company Name",
            dataIndex: "companyName",
            sorter: (a, b) => a.companyName.localeCompare(b.companyName),
            filters: filterData(table)(i => i.companyName),
            onFilter: (value, record) => record.companyName.indexOf(value) === 0,
            sortOrder: sort,
            onHeaderCell: () => ({
                onClick: () => setSort(sort === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            })
        },
        {
            title: "Address",
            dataIndex: "address1",
            render: (address1) => <Tooltip title={address1}>{address1}</Tooltip>,
            sorter: (a, b) => a.address1.localeCompare(b.address1),
            filters: filterDataAddress(table)(i => i.address1),
            onFilter: (value, record) => record.address1.indexOf(value) === 0,
            sortOrder: sortAdd,
            onHeaderCell: () => ({
                onClick: () => setSortAdd(sortAdd === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            })
        },

        {
            title: "Status",
            dataIndex: "active",
            render: (data) => (data ? "Active" : "Inactive"),
            filters: [
                { text: 'Active', value: true },
                { text: 'Inactive', value: false }
            ],
            onFilter: (value, record) => {
                return record.active === value
            }
        },
        {
            title: "Action ",
            dataIndex: "",
            render: (data, companyId) => (<><button className="table-btn" >
                <img src={Edit_light} onClick={() => handleEdit(data.companyId)}></img>
            </button>
                <button className="table-btn" >
                    <img src={Trash_light} onClick={() => handleDelete(data.companyId)}></img>
                </button></>)
        },
    ]

    return (
        <Layout title="Company"  >
            <Toaster/>
            <div className="position-relative">
                <div className="card-box-header d-inline-flex w-100 align-items-center">
                    <h4 className="m-0">Company</h4>
                    <div className="ms-auto">
                        <Link className="btn btn-primary d-flex align-items-center" to="/Company/Add"><img src={Add}></img>Add Comapny</Link>
                    </div>
                </div>           
            </div>
            <div className=" Viewer-table table-new pagination-sticky position-relative device-type">
            {loading ? <Loader /> : ''}
                <Table
                     className='table-responsive antd--detail-table1 Viewer-table'
                    columns={columns}
                    dataSource={table}
                    pagination={options.pagination.total > 10 && totalIndex > 9 ? options.pagination : false}
                    onChange={handleChange}
                    scroll={{ x: "450px" }}
                />
            </div>          
        </Layout>
    )
};
export default connect()(CompanyIndex);


