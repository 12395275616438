import { LocationTypes } from "../Constants/Location-Types";

export const setLocationType = (location) => {
    return {
        type: LocationTypes.SET_LOCATIONTYPES,
        payload: location
    };
};


//Add LocationType Data
export const addLocationTypeData = (location) => {
    return {
        type: LocationTypes.ADD_LOCATIONTYPES,
        payload: location
    };
};

//Update LocationType Data
export const updateLocationTypeData = (location) => {
    return {
        type:LocationTypes.UPDATE_LOCATIONTYPES,
        payload: location
    };
};

// Update Installation location
export const updateInstLocationData = (location) => {
    
    return {
        type: LocationTypes.UPDATE_INSTLOCATION,
        payload: location
    };
};

//Delete LocationType Data
export const deleteLocationData = (location) => {
    return {
        type:LocationTypes.DELETE_SELECTED_LOCATIONTYPES,
        payload: location
    };
};

