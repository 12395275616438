import { AreaTypes } from "../Constants/Area-Types"

export const setAreaTypes = (aretypes) => {
    return {
        type: AreaTypes.SET_AREATYPES,
        payload: aretypes
    };
};

export const setSelectedAreaType = (aretype) => {
    return {
        type: AreaTypes.SELECTED_AREATYPE,
        payload: aretype
    };
};
//#Developer
export const addAreaType = (aretype) => {
    return {
        type: AreaTypes.ADD_AREATYPE,
        payload: aretype
    };
};

//Update AreaType Data
export const updateAreaTypeData = (aretype) => {
    return {
        type:AreaTypes.UPDATE_AREATYPE,
        payload: aretype
    };
};

//Delete AreaTypeData
export const deleteAreaTypeData = (aretype) => {
    return {
        type:AreaTypes.DELETE_SELECTED_AREATYPE,
        payload: aretype
    };
};

