import { VirtualQRDetails } from "../Constants/VirtualQRDetails";

export const getVirtualQrDataDetails = (virtualqrDetails) => {
    return {
        type: VirtualQRDetails.GET_VIRTUALQRDATADETAILS,
        payload: virtualqrDetails
    };
};

export const assignvirtualQrCode = (virtualqrDetails) => {
    return {
        type: VirtualQRDetails.PUT_ASSIGNVIRTUALWITHQR,
        payload: virtualqrDetails
    };
};
