
import { allLocationByCompany } from "../Constants/locationBycompany";
// initial state
const initialState = {
    allLocationByCompanyId: []
}

export const AllLocationByCompanyReducers = (state = initialState, { type, payload }) => {
    switch (type) {
       
            case allLocationByCompany.GET_AllLocationByCompanyId:
                return { ...state, allLocationByCompanyId: payload.data };

        default:
            return state;
    }
};