import { QRDetails } from "../Constants/QrDetails";

export const getQrDataDetails = (qrDetails) => {
    return {
        type: QRDetails.GET_QRDATADETAILS,
        payload: qrDetails
    };
};

export const deleteMediaData = (qrDetails) => {
    return {
        type: QRDetails.DELETE_SELECTED_QRTYPE,
        payload: qrDetails
    };
};

export const deleteMultipleMediaData = (qrDetails) => {
    return {
        type: QRDetails.DELETE_MULTIPLE_QRTYPE,
        payload: qrDetails
    };
};

export const updateQrData = (qrDetails) => {
    return {
        type: QRDetails.PUT_UPDATEQREDIT_DETAILS,
        payload: qrDetails
    };
};

