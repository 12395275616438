import React from "react";
import ImageUploader from "react-images-upload";

const UploadImage = (props) => {
    const onDrop = (pictureFiles, pictureDataURLs) => {
        const newImagesUploaded = pictureDataURLs.slice(
            props.defaultImages.length
        );
        props.handleChange(newImagesUploaded);
        debugger;
              
    };

    return (
        <ImageUploader
            withIcon={false}
            withLabel={false}
          //  withPreview={true}
            buttonText={"Upload Media"}
           // fileSizeError={"File size is too big!"}
            fileTypeError={"This extension is not supported!"}
            onChange={onDrop}
            imgExtension={props.imgExtension}
            maxFileSize={props.maxFileSize}
          //  defaultImages={props.defaultImages}
        />
    );

}

export default UploadImage;