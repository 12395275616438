import { VirtualQRDetails } from "../Constants/VirtualQRDetails";

// initial state
const initialState = {
    virtualqrTypes: []
}

export const VirtualQrReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case VirtualQRDetails.GET_VIRTUALQRDATADETAILS:
            return { ...state, virtualqrTypes: payload.data };

        default:
            return state;
    }
}
