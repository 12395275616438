import React, { useEffect, useCallback, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
// Import Layout
import Layout from '../Layout';
//import Message from 'antd-message';
import toast, { Toaster } from 'react-hot-toast';
// Import static images
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import Add1 from "../../assets/images/Add.svg";
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as url from "../../api/uri_helper"
import { setMediaTypes, updateMediaTypeData, addMediaTypeData } from '../../Redux/Actions/MediaActions';
import { fetchAllMediaId, fetchMediaData } from "../../Redux/Api/fetch.api";
import Loader from "../loader";
import axios from "axios";
import '../../../src/custom.css'
import { Formik, Field, useFormik } from 'formik';
import { Input, Form, FormFeedback } from "reactstrap"
import * as Yup from 'yup';


const Add = () => {
    const allMediaTypes = useSelector((state) => state.allMediaType.mediaTypes)
    const [name, setName] = useState("");
    const [key, setKey] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        nameErr: '',
        addressErr: ''
    })
    const [isActive, setisActive] = useState(true)
    const [currentId, setCurrentId] = useState(null);
    const [checked, setChecked] = useState(true);
    const [count, setCount] = useState(0);
    const [disable, setDisable] = useState(false);

    const location = useLocation();
    const dispatch = useDispatch();
    let history = useHistory();
    const handleChange = () => {
        setisActive(!isActive)
    }
    let pageButton = "Update"

    if (!currentId) {
        pageButton = "Save"
    }
    useEffect(() => {
        let urlData = location.pathname.split('/');
        setCurrentId(urlData[3])
        if (currentId) {
            setLoading(true)
            fetchAllMediaId(urlData[3]).then(
                response => {     
                    setName(response.data.data.name);
                    setKey(response.data.data.key);
                    setisActive(response.data.data.active)
                    setLoading(false)
                })
        }
    }, [currentId])

    useEffect(() => {

        getMediaData()
    }, [])

    const getMediaData = () => {
        fetchMediaData()
            .then(response => dispatch(setMediaTypes(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const addMediaType = async (params) => {
        if (params.name) {        
            const response = await axios.post(url.POST_MEDIATYPES, {
                Name: params.name,
                Key: params.key,
                Active: isActive
            }).then(response => {
                dispatch(addMediaTypeData(response.data))               
                toast.success(response.data.message,{
                    className:"toastSuccess",
                }, );            
                    history.push('/MediaType');              
            })
                .catch((err) => {
                    console.log("Err: ", err);
                    setDisable(false)
                });
        }
    };

    const updateMediaType = async (params) => {      
        if (params.name) {
            const response = await axios.put(url.UPDATE_MEDIATYPES, {
                mediaTypeId: currentId,
                Name: params.name,
                Key: params.key,
                Active: isActive,
            }).then(response => {
                dispatch(updateMediaTypeData(response.data))
                toast.success(response.data.message,{
                    className:"toastSuccess",
                }, );
                    history.push('/MediaType');
            })
                .catch((err) => {
                    console.log("Err: ", err);
                    setDisable(false)
                });
        }
    };
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        // initialize of the Values
        initialValues: {
            name: name ? name : '',
            key: key ? key : ''
        },
        // Alert messages 
        validationSchema: Yup.object({
            name: Yup.string().required('Please Enter Name ').matches(
                "^(.|\s)*[a-zA-Z]+(.|\s)*$",
                "Please enter valid Name"
            ),
        }),
        // on form submit / button click
        onSubmit: (values) => {
            setDisable(true)
            { currentId == undefined || currentId == null || currentId == isNaN ? addMediaType(values) : updateMediaType(values) };

        }
    });
    // if (loading) return <Loader />;
    return (
        <Layout><Toaster/>
            <div className="position-relative">
            {loading ? <Loader /> : ''}
                <div className="card-box-header d-inline-flex w-100 align-items-center">
                    <h4 className="m-0">
                        <Link className="backBTN" to="/MediaType">
                            <img src={Arrow_leftbrown}></img>
                            Back
                        </Link>
                    </h4>

                </div>
                <div className="content-area">
                    <Form className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="form-group mb-3">
                                    <label>Name <span className="required-fields"> * </span></label>
                                    <div className="input-group">                                        
                                        <Input name="name" lassName="form-control" placeholder="Enter Name"
                                            type="text"  onChange={validation.handleChange} 
                                            onBlur={validation.handleBlur} value={validation.values.name}
                                            invalid={
                                                validation.touched.name && validation.errors.name ? true : false
                                            }
                                        />
                                        {validation.touched.name && validation.errors.name ? (
                                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="form-group mb-3">
                                    <label>Master Key (Non Editable)</label>
                                    <div className="input-group">
                                        <Input name="key" lassName="form-control" placeholder="Enter Key"
                                            disabled={currentId == undefined || currentId == null || currentId == isNaN ? false : true}
                                            type="text"  onChange={validation.handleChange}
                                            onBlur={validation.handleBlur} value={validation.values.key}
                                            invalid={
                                                validation.touched.key && validation.errors.key ? true : false
                                            }
                                        />
                                        {validation.touched.key && validation.errors.key ? (
                                            <FormFeedback type="invalid">{validation.errors.key}</FormFeedback>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="form-checkbox mb-4">
                                    {loading && currentId == undefined || currentId == null || currentId == isNaN ?
                                        <label>
                                            <input type="checkbox" defaultChecked={checked} name="isActive" value={isActive} onChange={() => handleChange()} ></input> Active</label> :

                                        <label>  <input type="checkbox" name="isActive" checked={isActive} onChange={() => handleChange()} ></input>
                                            Active</label>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button className="btn btn-primary" >{pageButton}</button>
                                <Link className="btn btn-bordered ms-2" to="/MediaType">Cancel</Link>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Layout>
    );
};
export default connect()(Add);

