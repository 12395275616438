
import { allQRCodeIdUser } from "../Constants/QRCodeId";

export const setQrCodeIdData = (qrId) => {
    
    return {
        type: allQRCodeIdUser.GET_AllQRCodeIdUser,
        payload: qrId
    };
};

export const addQrCodes = (qrId) => {

    return {
        type: allQRCodeIdUser.ADD_AllQRCodes,
        payload: qrId
    };
};

