import { LocationTypes } from "../Constants/Location-Types"

// initial state
const initialState = {
    location: []
}

export const LocationReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case LocationTypes.SET_LOCATIONTYPES:
            return { ...state, location: payload.data };

        //Add DeviceType
        case LocationTypes.ADD_LOCATIONTYPES:
            return { ...state, location: payload };

        //Delete DeviceType
        case LocationTypes.DELETE_SELECTED_LOCATIONTYPES:
            return { ...state, location: payload };
        
        default:
            return state;
    }
};