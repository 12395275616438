import React, { useEffect, useCallback, useMemo, useState } from "react";
import axios from "axios"
import { useDispatch, useSelector } from "react-redux";
import { addAreaType, setAreaTypes, updateAreaTypeData } from "../../Redux/Actions/AreaActions";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import Message from 'antd-message';
import toast, { Toaster } from 'react-hot-toast';
import { Spinner } from "reactstrap";
// import loading from "../../assets/images/loading.gif"
import Loader from "../loader";
import { Formik, Field, useFormik } from 'formik';
import { Input, Form, FormFeedback } from "reactstrap"
import * as Yup from 'yup';
import '../../../src/custom.css'
import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/bootstrap.css";
import { useRef } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from "react-places-autocomplete";

////#Developer
import Layout from '../Layout';
import * as url from "../../api/uri_helper"

// Import static images

import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import Add1 from "../../assets/images/Add.svg";
import { METHODS } from "http";
import { updateCompanyData, addCompanyData } from "../../Redux/Actions/CompanyActions";
import { fetchAllCompanyId } from "../../Redux/Api/fetch.api";
// import AreaType from './components/AreaType/Index.js';

const Add = () => {
    const allCompanyDetails = useSelector((state) => state.allCompanyDetails.company)
    let history = useHistory();
    const dispatch = useDispatch();
    const [companyName, setCompanyName] = useState("");
    const [selectedCountryCode, setSlectedCountryCode] = useState();
    const [email, setEmail] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [address, setAddress] = useState("");
    const [allAddress, getAllAddress] = useState('')
    const [addressData, setAddressData] = useState("");
    const [stateData, setStateData] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [country, setCountry] = useState("");
    const [postalCodeDisable, setPostalCodeDisable] = useState(false);
    const [city, setCity] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [loading, setLoading] = useState(false);
    const [isActive, setisActive] = useState(true)
    const [currentId, setCurrentId] = useState(null);
    const [checked, setChecked] = useState(true);
    const [count, setCount] = useState(0);
    const [countText, setCountText] = useState(0);
    const [disable, setDisable] = useState(false);
    const [contactName, setContactName]= useState("");
    const [success, setSuccess] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [postalCodeData, setPostalCodeData] = useState();
    const [fieldValue, setFieldsValue] = useState(false);
    let btnRef = useRef();

    const handleChange = () => {
        setisActive(!isActive)
    }

    let pageButton = "Update"
    if (!currentId) {
        pageButton = "Save"
    }
    useEffect(() => {
        let urlData = location.pathname.split('/');   
        setCurrentId(urlData[3])
        if (currentId) {
            setLoading(true)
            fetchAllCompanyId(urlData[3]).then(
                response => {
                    setLoading(false)
                    setCompanyName(response.data.data.companyName);
                    setAddressData(response.data.data.address1);
                    setEmail(response.data.data.emailId)
                    setPhoneNumber(response.data.data.phoneNumber)
                  
                   
                    if (response.data.data.address1)
                        setCity(response.data.data.city)
                    setStateData(response.data.data.state)
                    setPostalCodeData(response.data.data.postalCode)

                    setCountry(response.data.data.country)

                    setCount(response.data.data.address1.length)
                   
                    setisActive(response.data.data.active)
                    
                })

        }
    }, [currentId])

    const handleAddressSelect = async (address, placeId) => {
        debugger;
        setAddressData(address);
        getAllAddress(address)

        const results = await geocodeByAddress(address);
        const latlang = await getLatLng(results[0]);
        console.log("data", latlang.lat)
      
        geocodeByAddress('Montevideo, Uruguay')
            .then(results =>

                getLatLng(results[0]))
            .then(({ lat, lng }) =>
                //setLatitude({lat}),
                console.log('Successfully got latitude and longitude', { lat, lng }),

            );
        const [place] = await geocodeByPlaceId(placeId);

        const { long_name: postalCode = "" } =
            place.address_components.find((c) => c.types.includes("postal_code")) ||
            {};
        let city = "",
            country = "",
            state = ""


        for (const component of results[0].address_components) {
            const componentType = component.types[0];

            switch (componentType) {
                case "locality": {
                    city = component.long_name;
                    break;
                }
                case "administrative_area_level_1": {
                    state = component.long_name;
                    break;
                }
                case "country": {
                    country = component.long_name;
                    break;
                }
            }
        }

        setPostalCodeDisable(!!postalCode);
        setFieldsValue({

            ["postal_code"]: postalCode,
            ["city"]: city,
            ["state"]: state,
            ["country"]: country,
        });
        { addressData != "" ? setCity(city) : '' }
        setPostalCodeData(postalCode)
        setCity(city)
        setStateData(state)
        setCountry(country)
        console.log("postal", postalCode)
        console.log("city", city)
        console.log("state", state)
        console.log("country", country)
    }

    const handleStateChangeForAddress = (e) => {
        debugger;
        if (e == "") {
            setCity("")
            setPostalCodeData("")
            setStateData("")
            setCountry("")
           
            getAllAddress("")
        }
    }

    const renderFuncForGooglePlaces = ({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading,
    }) => (
        <div>
            <Input
                id="selectAddress_1"
                //addonAfter={selectAfter("address_1", publicOrPrivate("address_1"))}
                placeholder='Search Places ...'
                size="large"
                {...getInputProps({
                    placeholder: 'Search Places ...',
                })}
                tabIndex={9}
                maxLength={255}
            />

            <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, index) => {
                    const style = suggestion.active
                        ? {
                            backgroundColor: "#f8f8f8",
                            cursor: "pointer",
                        }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                        };
                    return (
                        <div
                            {...getSuggestionItemProps(suggestion, {
                                style,
                            })}
                            key={index}
                        >
                            {suggestion.description}
                        </div>
                    );
                })}
            </div>
        </div>
    );

    const handleAddressChange = (value) => {
        debugger;
        if (value.length > 0) {
            setAddressData(value)
        }
        else {
            setAddressData("")
        }

    };

   

    const postCompanyData = async (params) => {
        if (params.companyName ) {
            const response = await axios.post(url.POST_COMPANYDETAILS, {
                companyId: currentId,
                companyName: params.companyName.trim(),
                emailId: params.email,
                address1: addressData,
                phoneNumber: phoneNumber,
                city: city,
                state: stateData,
                postalCode: postalCodeData,
                country: country,
                contactName: contactName.trim(),
                Active: isActive
            }).then(response => {
                dispatch(addCompanyData(response.data))
                if(!response.data.success) // False 
                {
                    toast.error(response.data.message,{
                        className:"toastSuccess",
                    }, );
                    //setLoading(false);
                    //setDisable(false)
                }
                else {
                    toast.success(response.data.message,{
                        className:"toastSuccess",
                    }, );
                    history.push('/Company');
                    //setLoading(false);
                    //setSuccess(true)
                }
            })
                .catch((err) => {
                    console.log("Err: ", err);
                    setDisable(false)
                });
        }
    };

    const updateCompanyDetails = async (params) => {
        if (params.companyName) {
            // setLoading(true);
            const response = await axios.put(url.UPDATE_COMPANYDETAILS, {
                companyId: currentId,
                companyName: params.companyName.trim(),
                emailId: params.email,
                address1: addressData,
                phoneNumber: phoneNumber,
                city: city,
                state: stateData,
                postalCode: postalCodeData,
                country: country,
                contactName: contactName.trim(),
                Active: isActive
            }).then((response) => {
                dispatch(updateCompanyData(response.data))
                if(!response.data.success) // False 
                {
                    toast.error(response.data.message,{
                        className:"toastSuccess",
                    }, );
                    //setLoading(false);
                    //setDisable(false)
                }
                else {
                    toast.success(response.data.message,{
                        className:"toastSuccess",
                    }, );
                    history.push('/Company');
                   /* setLoading(false);*/
                   // setDisable(true)
                }
            })
                .catch((err) => {
                    console.log("Err: ", err);
                    setDisable(false)
                });
        }
    };
    const validation = useFormik({
        enableReinitialize: true,
        // initialize of the Values
        initialValues: {
            companyName: companyName ? companyName : '',
            email: email ? email : '',
            address: address ? address : '',
            addressData: addressData ? addressData : '',
            contactEmail:contactEmail?contactEmail:'',
            postalCode: postalCode?postalCode:''
        },
        // Alert messages 
        validationSchema: Yup.object({
            companyName: Yup.string().required('Please Enter Name').matches(
             ///^[a-zA-Z0-9\s
                "^([A-Za-z0-9]+ )+[A-Za-z0-9]+$|^[A-Za-z0-9]+$",
                "Please enter valid Name"
            ),
            
            postalCode: Yup.string().matches(
                /(^\d{7}$)|(^\d{7}-\d{4}$)/,
                "Please enter valid code "
            ),
           
            email: Yup.string().matches(
                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                "Please enter valid email "
            ),
            contactEmail:Yup.string().matches(
                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                "Please enter valid email "
            ),
            address: Yup.string().matches(
                "^(.|\s)*[a-zA-Z]+(.|\s)*$",
                "Please enter valid Address"
            ),
             addressData: Yup.string().matches(
                 "^(.|\s)*[a-zA-Z]+(.|\s)*$",
                 "Please enter valid Address"
             )
        }),
        // on form submit / button click
        onSubmit: (values) => {
            if (!phoneError) {
                // call the api here
                setDisable(true)
                { currentId == undefined || currentId == null || currentId == isNaN ? postCompanyData(values) : updateCompanyDetails(values) };
            }
        }
    });
    return (
        <Layout title="Company">
            <Toaster/>
            <div className="position-relative">
                {loading ? <Loader /> : ''}
                <div className="card-box-header d-inline-flex w-100 align-items-center">
                    <h4 className="m-0">
                        <Link className="backBTN" to="/Company">
                            <img src={Arrow_leftbrown}></img>
                            Back
                        </Link>
                    </h4>
                </div>
                <div className="content-area">
                    {/* <form> */}
                    <Form className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            //if (phoneNumber.replace(country.dialCode, '').startsWith('0')) {
                            //    setPhoneError(true);
                            //}    
                            //if (!(phoneNumber && phoneNumber.length >10)) {
                            //    setPhoneError(true);
                            //}
                            return false;
                        }}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Company Name <span className="required-fields"> * </span> </label>
                                    <div className="input-group">
                                        {/* <input id="text" type="text" className="form-control" name="text" value={name} onChange={(e) => { setName(e.target.value) }} placeholder="Enter First Name"></input> */}
                                        <Input name="companyName" lassName="form-control" placeholder="Enter Company Name"
                                            type="text" maxLength={50} //onChange={validation.handleChange}
                                            onChange={(e) => { validation.handleChange(e), setCompanyName(e.target.value.replace(/\s{2}/g, '')) }}
                                            onBlur={validation.handleBlur} value={validation.values.companyName}                                           
                                            invalid={
                                                validation.touched.companyName && validation.errors.companyName ? true : false
                                            }
                                        />
                                        {validation.touched.companyName && validation.errors.companyName ? (
                                            <FormFeedback type="invalid">{validation.errors.companyName}</FormFeedback>
                                        ) : null}
                                    </div>
                                    {/* <small className="text-danger d-block mt-2">{error.nameErr}</small> */}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Contact Name </label>
                                    <div className="input-group">
                                        <input name="companyName" className="form-control" placeholder="Enter Contact Name" type="text" maxLength={50} onChange={(e) => { setContactName(e.target.value) }} value={contactName} />   
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email </label>
                                    <div className="input-group">
                                        {/* <input id="email" type="text" className="form-control" name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder="Enter Your Email"></input> */}
                                        <Input name="email" lassName="form-control" placeholder="Enter Email"
                                            type="text" maxLength={50} onChange={validation.handleChange}
                                            onBlur={validation.handleBlur} value={validation.values.email}
                                            invalid={
                                                validation.touched.email && validation.errors.email ? true : false
                                            }
                                        />
                                        {validation.touched.email && validation.errors.email ? (
                                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                        ) : null}
                                    </div>
                                    {/* <small className="text-danger d-block mt-2">{error.emailErr}</small> */}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Phone Number </label>
                                    <div className="input-group phnumber">
                                        <PhoneInput
                                            country={"us"}
                                            enableSearch={true}
                                            value={phoneNumber}
                                            //onChange={(phone) => setPhoneNumber(phone)}
                                            onChange={(phone, country) => {
                                                debugger;
                                                setSlectedCountryCode((prevState) => {
                                                    if (prevState !== country.countryCode) {
                                                        setPhoneNumber(country.dialCode);
                                                    }
                                                    else {
                                                        setPhoneNumber(phone);
                                                    }
                                                    return country.countryCode; 
                                                })

                                                //if (phone && phone.length > 10)
                                                //   // && !phone.replace(country.dialCode, '').startsWith('0')) 
                                                //{
                                                //    setPhoneError(false);
                                                //}
                                              
                                                //else {
                                                //    setPhoneError(true);
                                                //}

                                               
                                            }}
                                        />
                                    </div>
                                    {
                                        < small className="text-danger d-block mt-2">{!phoneError ? ' ' : 'Please Enter Valid Phone Number'}</small>
                                    }
                                </div>
                            </div>
                            
                            <div class="col-lg-12">
                                <div class="form-group autocomplete-places">
                                    <label>Address (Autocomplete)</label>
                                    <div class="row">
                                        <div className="col-md-12">
                                            <PlacesAutocomplete

                                                value={addressData}
                                                onChange={(e) => {
                                                    handleAddressChange(e), handleStateChangeForAddress(e)
                                                }}
                                                onSelect={handleAddressSelect}
                                            >
                                                {renderFuncForGooglePlaces}
                                            </PlacesAutocomplete>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {allAddress != '' || currentId ?
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>City </label>
                                                <div className="input-group">
                                                    <input name="city" className="form-control disabled-input" placeholder="Enter City" type="text" maxLength={50}
                                                        disabled onChange={(e) => { setCity(e.target.value) }} value={addressData != "" ? city : ''} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>State </label>
                                                <div className="input-group">
                                                    <input name="stateData" className="form-control disabled-input" placeholder="Enter State" type="text" maxLength={50}
                                                        disabled onChange={(e) => { setStateData(e.target.value) }} value={stateData} />

                                                </div>
                                               
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Zip </label>
                                                <div className="input-group">
                                                   

                                                    <Input name="postalCodeData" className="form-control disabled-input" placeholder="Enter Postal Code"
                                                        disabled type="text" maxLength={12} value={postalCodeData}
                                                    />

                                                </div>
                                               
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Country </label>
                                                <div className="input-group">
                                                    <input name="country" disabled className="form-control disabled-input" placeholder="Enter Country" type="text" maxLength={50} onChange={(e) => { setCountry(e.target.value) }} value={country} />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                </div>

                                : ""}

                            
                           
                            <div className="col-md-12 mb-4">
                                <div className="form-checkbox ">
                                    {loading && currentId == undefined || currentId == null || currentId == isNaN ?
                                        <label>
                                            <input type="checkbox" defaultChecked={checked} name="isActive" value={isActive} onChange={() => handleChange()} ></input> Active</label> :
                                        <label>  <input type="checkbox" name="isActive" checked={isActive} onChange={() => handleChange()} ></input>
                                            Active</label>}
                                </div>
                            </div>

                            <div className="col-md-12">
                                <button className="btn btn-primary" >{pageButton}</button>
                                <Link className="btn btn-bordered ms-2" to="/Company">Cancel</Link>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Layout>
    );
}
export default connect()(Add);

