import { GuideTypes } from "../Constants/Guide-Types"

export const setGuideTypes = (guidetypes) => {
    return {
        type: GuideTypes.SET_GUIDETYPES,
        payload: guidetypes
    };
};


//Add GuideType Data
export const addGuideTypeData = (guidetypes) => {
    return {
        type: GuideTypes.ADD_GUIDETYPES,
        payload: guidetypes
    };
};

//Update GuideType Data
export const updateGuideTypeData = (guidetypes) => {
    return {
        type:GuideTypes.UPDATE_GUIDETYPES,
        payload: guidetypes
    };
};

//Delete GuideType Data
export const deleteGuideData = (guidetypes) => {
    return {
        type:GuideTypes.DELETE_SELECTED_GUIDETYPES,
        payload: guidetypes
    };
};