import { DashboardCount,GetAllThumbnail } from "../Constants/DashboardCount"

// initial state
const initialState = {
    dashboardData: [],
    thumbnail :[]
}


export const DashboardReducers = (state = initialState, { type, payload }) => {
    switch (type) {
    
            case DashboardCount.GET_DASHBOARDCOUNT:
                return { ...state, dashboardData: payload.data };
            case GetAllThumbnail.GET_ALLTHUMBNAIL:
                return {...state,thumbnail :payload.allThumbnailList}
        default:
            return state;
    }
};