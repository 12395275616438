import { AreaTypesIdData } from "../Constants/AreaId"
// initial state
const initialState = {
    areaTypesId: []
}


export const AreaIdReducers = (state = initialState, { type, payload }) => {
    switch (type) {
    
            case AreaTypesIdData.GET_AllAreaIdUser:
                return { ...state, areaTypesId: payload.data };
        //
        default:
            return state;
    }
};