import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios"
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import Message from 'antd-message';
import toast, { Toaster } from 'react-hot-toast';
import { connect } from 'react-redux';

// Import Layout
import Layout from '../Layout';
import * as url from "../../api/uri_helper"

// Import static images
import Add from "../../assets/images/Add.svg";
import Search_light from "../../assets/images/Search_light.svg";
import Union from "../../assets/images/Union.svg";
import double_arrow from "../../assets/images/double-arrow.svg";
import Edit_light from "../../assets/images/Edit_light.svg";
import Trash_light from "../../assets/images/Trash_light.svg";
import { UPDATE_AREATYPE } from "../../api/uri_helper";
import { deleteAreaTypeData, setAreaTypes, updateAreaTypeData } from "../../Redux/Actions/AreaActions";
import { fetchProducts } from "../../Redux/Api/fetch.api";
import { Modal } from 'antd';
import 'antd/dist/antd.css';
import Loader from "../loader";
import Table, { TableProps } from "antd/lib/table";
import { Tooltip } from 'antd';
import '../../custom.css';

const Index = () => {
    const allAreaTypes = useSelector((state) => state.allAreaType.areaTypes); // data
    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory()
    const location = useLocation();
    const [totalIndex, setCurrentIndex] = useState()
    const [sort, setSort] = useState('');
    const [sortloc, setSortLoc] = useState('');
    const [options, setOptions] = useState({
        pagination: { current: 1, pageSizeOptions: [5, 10, 20, 50] },

    });
   
    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        
        getProducts(options)
    }, [])

    const handleEdit = (areaTypeId) => {
        history.push('/Area/edit/' + areaTypeId)
    }
    //Get all the areaTypes
    const getProducts = (options) => {       
            setLoading(true)              
        fetchProducts()           
            .then(response => {
                dispatch(setAreaTypes(response.data))
                setTable(response.data.data)
                setOptions({
                    ...options,
                    pagination: {
                        current: options.pagination.current, 
                        total: response.data.count,
                        pageSizeOptions: ['10', '20', '50'],
                        showSizeChanger: true,
                    }
                })

               // setLoading(false)
            })
            .catch((err) => {
                console.log("Err: ", err);
            })
        setTimeout(() => {
            setLoading(false)
        }, 2000)
           
    }
    //change the page size
    const handleChange = (pagination, sorter, current) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        getProducts({
            ...updatedOptions,

        });
    };

    const deleteAreaData = async (areaTypeId) => {
        setLoading(true)
        await axios.delete(url.DELETE_AREATYPE + '/' + areaTypeId, {
        }).then(response => {
            dispatch(deleteAreaTypeData(response.data))
            toast.success(response.data.message,{
                className:"toastSuccess",
            }, );
            getProducts(options);
            setLoading(false)
        }).catch((err) => {
            console.log("Err: ", err);
        });
       

    }

    const filterData = data => formatter => data.map(item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "",
            render: (text, record, index) => { setCurrentIndex(`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}`); return `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}` },
        },
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            filters: filterData(table)(i => i.name),
            onFilter: (value, record) => record.name.indexOf(value) === 0,
            sortOrder: sort,
            onHeaderCell: () => ({
                onClick: () => setSort(sort === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            })
        },
        {
            title: "Description",
            dataIndex: "description",
            render: (description) => <Tooltip title={description}>{description}</Tooltip>,
            sorter: (a, b) => a.description.localeCompare(b.description),
            filters: filterData(table)(i => i.description),
            onFilter: (value, record) => record.description.indexOf(value) === 0,
            sortOrder: sortloc,
            onHeaderCell: () => ({
                onClick: () => setSortLoc(sortloc === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            })
        },
        {
            title: "Status",
            dataIndex: "active",
            render: (data) => (data ? "Active" : "Inactive"),
            filters: [
                { text: 'Active', value: true },
                { text: 'Inactive', value: false }
            ],
            onFilter: (value, record) => {
                return record.active === value
            }
        },
        {
            title: "Action ",
            dataIndex: "",
            render: (data, areaTypeId) => (<><button className="table-btn" >
                <img src={Edit_light} onClick={() => handleEdit(data.areaTypeId)}></img>
            </button>
                <button className="table-btn" >
                    <img src={Trash_light} onClick={() => handleDelete(data.areaTypeId)}></img>
                </button></>)
        },
    ]
    const { confirm } = Modal;
    const handleDelete = (areaTypeId) => {
        confirm({
            title: 'Are you sure you want to delete this record?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteAreaData(areaTypeId)
            },
            onCancel() {
            },
        });
    }
    return (
        <Layout title="Area Type">
            <Toaster/>
            <div className="position-relative card--heights ">
                {/* {loading ? <Loader /> : ''} */}
                <div className="card-box-header d-inline-flex w-100 align-items-center">
                    <h4 className="m-0">Area Type</h4>
                    <div className="ms-auto">
                        <Link className="btn btn-primary d-flex align-items-center" to="/Area/Add"><img src={Add}></img>Add Area Type</Link>
                    </div>
                </div>
            </div>
            <div className="Viewer-table table-new pagination-sticky position-relative device-type ">
            {loading ? <Loader /> : ''}
           
                <Table
                    className='table-responsive  antd--detail-table1 Viewer-table'
                    columns={columns}
                    dataSource={table}
                    pagination={options.pagination.total > 10 && totalIndex > 9 ? options.pagination : false}
                    onChange={handleChange}
                    scroll={{ x: "450px" }}
                />
            </div>
        </Layout>
    );

};
export default connect()(Index);