
import { ParentQRId } from "../Constants/assignQR";
// initial state
const initialState = {
    qrTypes: []
}

export const parentQrReducers = (state = initialState, { type, payload }) => {
    switch (type) {
       
        case ParentQRId.PUT_PARENTQRID:
            return { ...state, qrTypes: payload.data };

        default:
            return state;
    }
};