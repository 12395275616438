import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector  } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as url from "../../api/uri_helper";
import PDF from "../../UserAssets/images/pdf-icn.svg";
import downloadIcon from "../../UserAssets/images/download-icn.svg";
import favoriteIcon from "../../assets/images/favorite_star.png";
import Loader from "../loader";
import Layout from "../Layout";
import AddVirtual from "../../assets/images/Add.svg";
import Modal from "react-bootstrap/Modal";
import "../../../src/custom.css";
import { Tooltip } from "antd";
import { Checkbox, Select } from 'antd';
import { getQrCodeThumbnail, getVirtualQRCode } from '../../Redux/Actions/QR';
import LoginImage from "../../assets/images/login-image.png";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { Link } from "react-router-dom";
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import {
  fetchAllQRByLocationId, fetchDeviceProducts,  fetchAllLocationByCompanyId, fetchGuideProducts,
    fetchCompanyDetails, fetchMediaData, fetchProducts, fetchVirtualQRCode,copyAllQRDataById,fetchAllQrCodesWithThumbnail} from "../../Redux/Api/fetch.api";
import {
  fetchAllQrCodes, fetchAllQrId} from "../../Redux/Api/fetch.api";
import { setDeviceTypes } from "../../Redux/Actions/DeviceActions";
import { setAreaTypes } from "../../Redux/Actions/AreaActions";
import { setGuideTypes } from "../../Redux/Actions/GuideActions";
import { getQrCodeData } from "../../Redux/Actions/QR";
import { setMediaTypes } from "../../Redux/Actions/MediaActions";
import { SetCompanyDetails } from "../../Redux/Actions/CompanyActions";
import { getAllLocationByCompanyId } from "../../Redux/Actions/LocationByCompany";
import { getAllQRByLocationId } from "../../Redux/Actions/qrByLocationId";
import UploadComponent from "../QR/upload";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactPlayer from "react-player";
import UrlImage from "../../UserAssets/images/attach.png";
import { copyQRCodeIdData } from "../../Redux/Actions/CopyQRCode";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import '../QrReview/QRReview.css'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import IndexQrDetails from "../QrReview/index.js";

let PAGE_SIZE = 1;
const { Option } = Select;
const TOAST_LIMIT = 1;

const Add = () => {
    let locationObject = useLocation();  
    const iswithmasterqr = locationObject.state
        ? locationObject.state.iswithmasterqr 
        : "";  
    const { toasts } = useToasterStore();
    useEffect(() => {
        toasts
            .filter((t) => t.visible) // Only consider visible toasts
            .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
            .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
    }, [toasts]);
  const allQrDataWithThumbnail = useSelector((state) => state.allQrDataWithThumbnail.qrCodeThumbnailData);
  const allDeviceTypes = useSelector((state) => state.allDeviceType.deviceTypes );
  const allAreaTypes = useSelector((state) => state.allAreaType.areaTypes);
  const allGuideTypes = useSelector((state) => state.allGuideType.guideTypes); 
  const allCompanyDataDetails = useSelector((state) => state.allCompanyDetails.company);
  const allMediaTypes = useSelector((state) => state.allMediaType.mediaTypes);
  const selectedCompanyLocations = useSelector((state) => state.allLocationByCompany.allLocationByCompanyId);
  const selectedQrByLocation = useSelector((state) => state.allQRByLocationId.allQRByLocation);   
  const [countQr, setCountQr] = useState(0);
  const [countProduct, setCountProduct] = useState(0);
  const [countDevice, setCountDevice] = useState(0);
  const [played, setPlayed] = useState(0);
  const [getRadioButton, setGetRadioButton] = useState({show: "show",selected: "show",});
  const [productData, setProductData] = useState()
  const [countArea, setCountArea] = useState(0);
  const [countGuide, setCountGuide] = useState(0);
  const [countMedia, setCountMedia] = useState(0);
  const [countText, setCountText] = useState(0);
  const [videoSizeError, setVideoSizeError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [zoomFilename, setZoomFilename] = useState();
  const [zoomVideo, setZoomVideo] = useState();
  const [showQrDropdown, setShowQrDropdown] = useState();
  const [qrInfoSearch, setQrInfoSearch] = useState()
  const [assignBtnDisable, setAssignBtnDisable] = useState(true);
  const [mediaTypeIdToFilter, setMediaTypeIdToFilter] = useState();
  let qrCodeToUpdate = {};
    
    const priorityList = [{ name: "0", value: 0 }, { name: "1", value: 1 }, { name: "2", value: 2 }, { name: "3", value: 3 }, { name: "4", value: 4 }, { name: "5", value: 5 }, { name: "6", value: 6 },];
  const [loading, setLoading] = useState(false);
  const [currentDeviceTypeId, setCurrentDeviceTypeId] = useState();
  const [currentAreaTypeId, setCurrentAreaTypeId] = useState();
  const [currentVirtualqrcode, setCurrentVirtualqrcode] = useState();
  const [showVirtualqrcode, setShowVirtualqrcode] = useState();
  const [currentGuideTypeId, setCurrentGuideTypeId] = useState();
  const [currentCompanyId, setCurrentCompanyId] = useState(0);
  const [currentLocationId, setCurrentLocationId] = useState(0);
  const [currentMediaTypeId, setCurrentMediaTypeId] = useState();
  const [currentMediaTypeKey, setCurrentMediaTypeKey] = useState();
  const [deviceName, setDeviceName] = useState([]);
  const [areaName, setAreaName] = useState([]);
  const [guideName, setGuideName] = useState([]);
  const [deviceDescription, setDeviceDescription] = useState();
  const [currentQRCodeId, setCurrentQRCodeId] = useState("Select QR");
  const [copyQRCodeId, setCopyQRCodeId] = useState("Select QR");
  const [qrCodeDescription, setQrCodeDescription] = useState();
  const [areaDescription, setAreaDescription] = useState();
  const [mediaName, setMediaName] = useState([]);
  const [guideDescription, setGuideDescription] = useState();
  const [ifAddingQrCode, setifAddingQrCode] = useState(true);
  const [pdfLink, setpdfLink] = useState();
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [postalCode, setPostalCode] = useState();
  const [address, setAddress] = useState();
  const [priority, setPriority] = useState();
  const [header, setHeader] = useState();
  const [changeCount, setChangeCount] = useState(0);
 const [mediaIdToUpdate, setMediaIdToUpdate] = useState();
 const [qrcodeToAdd, setqrcodeToAdd] = useState();
  const [modal, setModal] = useState(false);
  const [showmodal, setShowModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
    const [copyModal, setCopyModal] = useState(false);
    const [virtualModal, setVirtualModal] = useState(false);
  const [filteredMediaData, setFilteredMediaData] = useState([]);
  const [videoFormatError, setVideoFormatError] = useState(false);
  const [hasMoreThumbnail, setHasMoreThumbnail] = useState(true)
  const [imgSrcs, setImgSrcs] = useState([]);
  const dispatch = useDispatch();
  const [filteredResults, setFilteredResults] = useState();
  const [images, setImages] = useState({
    upload: {
      pictures: [],
      maxFileSize: 5242880,
      imgExtension: [".jpg", ".png", ".jpeg"],
      defaultImages: [
      ],
    },
  });
const [AssignError,setAssignError] = useState(false)
 const [editorState, setEditorState] = useState(EditorState.createEmpty());
 const [textMedia, setTextMedia] = useState("");
 let mediaKey = '';
 const [urlMedia, setUrlMedia] = useState(""); 
 const [mediaDesc, setMediaDesc] = useState("");
  const [mediaFilename, setMediaFilename] = useState("");
  const InputRef = useRef();
  const [source, setSource] = useState();
  const [showVideo, setShowVideo] = useState();
  const [urlFormatError, setUrlFormatError] = useState(false);
  const [videoMedia, setVideoMedia] = useState("");
  const [videoFileName, setVideoFileName] = useState("");
  const [pdfFileName, setPDFFileName] = useState("");
  const [pdfMedia, setPdfMedia] = useState("");
  let history = useHistory();
  const [checkedListOfMedia, setCheckedListOfMedia] = useState([]);
  const [selectedMediaList, setSelectedMediaList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  //States for Conditional Styling in case of error
  const [conditionalStyleQR, setConditionalStyleQR] = useState(false)
  const [conditionalStyleDevice, setConditionalStyleDevice] = useState(false)
  const [conditionalStyleCompany, setConditionalStyleCompany] = useState(false)
  const [conditionalStyleLocation, setConditionalStyleLocation] = useState(false)
  const [conditionalStyleArea, setConditionalStyleArea] = useState(false)
  const [conditionalStyleGuide, setConditionalStyleGuide] = useState(false)
  const [conditionalStyleUpload, setConditionalStyleUpload] = useState(false)
  const [conditionalStyleHeader, setConditionalStyleHeader] = useState(false)
  const [conditionalStylePriority, setConditionalStylePriority] = useState(false)
  const [conditionalStyleImage, setConditionalStyleImage] = useState(false)
  const [conditionalStyleVideo, setConditionalStyleVideo] = useState(false)
  const [conditionalStylePdf, setConditionalStylePdf] = useState(false)
  const [conditionalStyleUrl, setConditionalStyleUrl] = useState(false)
  //state to automatically close the Link QR section 
  const [closeLinkSection, setCloseLinkSection] = useState(0)
  const [error, setError] = useState({
    video: "",
    imagesErr: "",
    urlMediaErr: "",
    pdfMediaErr: "",
    //durationError: "",
    textMediaError:""
  });

  const [dataErrorShow, setDataErrorShow] = useState({
    companyErr: "",
    locationErr: "",
    qrcodeErr: "",
    deviceErr: "",
    areaErr: "",
    guideErr: "",
    mediaErr: "",
    priorityErr: "",
    subPriorityErr: "",
    headerErr: "",
  });
  const [mediaFiles, setMediaFiles] = useState([]);
  const getAllQRWithThumbnail = () => {
    fetchAllQrCodesWithThumbnail()
      .then(response => {
        dispatch(getQrCodeThumbnail(response.data));
      })
      .catch((err) => {
        console.log("Err: ", err);
      })
  }
  const getAllQRCodes = () => {
    fetchAllQrCodes({
    })
      .then((response) => {
        dispatch(getQrCodeData(response.data));
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  };

  //for video
  const handleFileChange = (event) => {
   
    setPdfMedia()
    setUrlMedia()
    setImages()
    setImgSrcs([])
    if(event.target.files.length>0){      
      const file = event.target.files[0];
        const url = URL.createObjectURL(file);
        const size = file.size / 1024 / 1024;
      setSource(file.name);
      setShowVideo(url);
      setVideoMedia(file);
      setVideoFileName(file.name);
        if (file.type == "audio/mpeg") {
            setVideoFormatError(true);
        setConditionalStyleVideo(true)
        setUploadMedia((prev) => ({ ...prev, video: true }))
        }       
        else {
            setError("");
            setVideoFormatError(false);
        setConditionalStylePdf(false)
        setConditionalStyleImage(false)
        setConditionalStyleUrl(false)
        setConditionalStyleUpload(false)
        setConditionalStyleVideo(false)       
        
        setUploadMedia((prev) => ({ ...prev, video: false, pdf: false, image: false, url: false }))
        setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false }))
        //return;
        }

        if (size > 250) {
            setVideoSizeError(true);
            setConditionalStyleVideo(true)
            setUploadMedia((prev) => ({ ...prev, video: true }))
            setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: true }))
        }
        else {
            setError("");
            setVideoSizeError(false)
            setConditionalStylePdf(false)
            setConditionalStyleImage(false)
            setConditionalStyleUrl(false)
            setConditionalStyleUpload(false)
            setConditionalStyleVideo(false)

            setUploadMedia((prev) => ({ ...prev, video: false, pdf: false, image: false, url: false }))
            setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false }))
            return;
        }
      setChangeCount(changeCount + 1);
    }
    else{      
      setSource();
      setShowVideo();
      setVideoMedia();
      setVideoFileName();
      setConditionalStyleVideo(true)
    }
  };

    const handleErrors = (e) => {
        
    e.preventDefault();
    let err = { ...error };
   
    setError(err);
    if (err.imagesErr != "" && err.imagesErr != undefined && ifAddingQrCode) {
      setConditionalStyleImage(true)
      return false;
    }
    if (!ifAddingQrCode && err.imagesErr != "" && err.imagesErr != undefined) {
      setUploadMedia((prev) => ({ image: true }))
    }
        if (header == null && priority == null) {
          setUpdateSection((prev) => ({ ...prev, activeAccordianHeader: true }))
        }

        if (videoSizeError == true) {
            setUpdateSection((prev) => ({ ...prev, activeAccordianupload: true }))
        }
   
    else {
      return true;
    }
    };

    useEffect(() => {
       
        //setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        getDeviceTypeData();
        getCompanyDetails();
        allQRByLocation();
        getAllQRCodes();
        getAllQRWithThumbnail();
        // getAllLocationByCompany();
        getAreaTypeDetails();
        getMediaTypeDetails();
        getGuideTypeDetails()
        let urlData = location.pathname.split("/");

        if (urlData[4] && urlData[6] && urlData[4] !== 0 && urlData[6] !== 0) {
            setCurrentQRCodeId(urlData[4]);
            setMediaIdToUpdate(urlData[6]);
            setqrcodeToAdd(urlData[4]);

            setifAddingQrCode(false);
           // setLoading(false);
        }

    }, []);
  useEffect(() => {
    setUploadMedia((prev) => ({ ...prev, image: false, video: false, pdf: false, url: false }))
    setUpdateSection((prev) => ({ ...prev, activeAccordianArea: false, activeAccordianDevice: false, activeAccordianGuides: false, activeAccordianHeader: false, activeAccordianQR: false, activeAccordianUpload: false }))
  }, [])

    useEffect(() => {      
        setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: true }))
    }, [iswithmasterqr])

  //Searching operation on dropdown
  const [searchState, setSearchState] = useState()
  const handleInputForDropdown = (e) => {
      const QRProductPresent = allQrDataWithThumbnail.filter((item) => item.qRcodeId.toString().startsWith(e) || item.qRcodeId.toString().includes(e))
    QRProductPresent.length > 0 ? setSearchState("Number") : setSearchState("String")
  }
  const radioButtonValueChange = (e) => {
    if (e.target.value === "show") {
      setGetRadioButton({
        show: "show",
        selected: e.target.value,
      });
    } else {
      setGetRadioButton({
        show: "hide",
        selected: e.target.value,
      });
    }
  };

    const handleInputForQRInfo = (e) => {
       
        const QRInfoProduct = selectedQrByLocation.filter((item) => item.qRcodeId.toString().startsWith(e) || item.qRcodeId.toString().includes(e) )
    QRInfoProduct.length > 0 ? setQrInfoSearch("Number") : setQrInfoSearch("String")
  }

  const showDataError = (e) => {
      e.preventDefault();

     
      let errors = { ...dataErrorShow };
      if (videoSizeError && mediaIdToUpdate) {
          setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: true }))
      }
      if (ifAddingQrCode) {
      if (!currentCompanyId && currentCompanyId == 0) {
        setConditionalStyleCompany(true)
      }
      if (!currentCompanyId && currentCompanyId == 0 || closeLinkSection == 0 || currentQRCodeId == "Select QR" || currentDeviceTypeId == null || currentAreaTypeId == null || currentGuideTypeId == null || currentMediaTypeId == null || checkedListOfMedia < 1 || priority == null || header == null) {
        setCloseLinkSection(1)
      }
      if (currentLocationId == 0) {
        setConditionalStyleLocation(true)
      }
     
      if (currentDeviceTypeId == null) {
        setConditionalStyleDevice(true)
        setUpdateSection((prev) => ({ ...prev, activeAccordianDevice: true }))
      }
      if (currentAreaTypeId == null) {
        setConditionalStyleArea(true)
        setUpdateSection((prev) => ({ ...prev, activeAccordianArea: true }))
      }
      if (currentGuideTypeId == null) {
        setConditionalStyleGuide(true)
        setUpdateSection((prev) => ({ ...prev, activeAccordianGuides: true }))
      }
      if (currentMediaTypeId == null && checkedListOfMedia < 1) {
        setConditionalStyleUpload(true)
        setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: true }))
      }
      if (priority == null || priority == "Select Priority") {
        setConditionalStylePriority(true)
        setUpdateSection((prev) => ({ ...prev, activeAccordianHeader: true }))
          }
          if (header == null) {
              setConditionalStyleHeader(true)
              setUpdateSection((prev) => ({ ...prev, activeAccordianHeader: true }))
          }
    }
   
    setDataErrorShow(errors);
    //if (ifAddingQrCode) {
    //  if (errors.companyErr) {
    //    errors.companyErr = "Please select Company & Location";
    //    return false;
    //  }
    //  if (errors.locationErr) {
    //    errors.locationErr = "Please select Location";
    //    return false;
    //  }
    //  if (errors.qrcodeErr) {
    //    errors.qrcodeErr = "Please select QR Code";
    //    return false;
    //  }
    //  if (errors.areaErr) {
    //    errors.areaErr = "Please select Area";
    //    return false;
    //  }
    //  if (errors.guideErr) {
    //    errors.guideErr = "Please select Guide";
    //    return false;
    //  }
    //  if (errors.mediaErr) {
    //    errors.mediaErr = "Please Add Media";
    //    return false;
    //  }
    //  if (errors.priorityErr) {
    //    errors.priorityErr = "Please select Priority & Header";
    //    return false;
    //  }
    //}
    if (errors.headerErr) {
      errors.headerErr = "Please Enter Header";
      return false;
    }
    if (errors.priorityErr) {
      errors.priorityErr = "Please select Priority & Header";
      return false;
    } else {
      return true;
    }
  };
  const mediaFilterChangeHndlr = (mediaTypeId) => {
    PAGE_SIZE = 1;
    setMediaTypeIdToFilter(mediaTypeId);
    const emptyArray = [];
    getMediaDataForModal(mediaTypeId, true);
  };

  //for image
    const handleImageUploadChange = (files) => {
        
    setUrlMedia()
    setPdfMedia()
    setVideoMedia()
    const { pictures } = images.upload;
    if (!ifAddingQrCode) {
      setCurrentMediaTypeId(1); // for image type as this method is an en event handler for image upload
    }
    setImages(
      {
        ...images,
        upload: {
          ...images.upload,
          pictures: [...pictures, ...files],
        },
      },
    );
    setMediaFiles([...files]);
    setChangeCount(changeCount + 1);
    setError("");
    setConditionalStylePdf(false)
    setConditionalStyleImage(false)
    setConditionalStyleVideo(false)
    setConditionalStyleUrl(false)
    setConditionalStyleUpload(false)
    setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false }))
    setUploadMedia((prev) => ({ ...prev, image: false, video: false, url: false, pdf: false }))
    setImgSrcs([]);
  };
  const confirmUpload = (e) => {
    let result = handleErrors(e);
    return result;
    };

    useEffect(() => {
       
        setLoading(true)

        let urlData = location.pathname.split("/");
        fetchAllQrId(urlData[4]).then(response => {
            qrCodeToUpdate = response.data.data.find((q) => q.mediaId == urlData[6]);
            setCurrentQRCodeId(urlData[4]);
            if (qrCodeToUpdate.guideTypeId)
            setCurrentGuideTypeId(qrCodeToUpdate.guideTypeId);
            setCurrentAreaTypeId(qrCodeToUpdate.areaTypeId);
            setCurrentDeviceTypeId(qrCodeToUpdate.deviceTypeId);
           // setUpdateGuideid(qrCodeToUpdate.guideTypeId);
            // Company & Location
            if (qrCodeToUpdate && qrCodeToUpdate.companyId)
                setCurrentCompanyId(qrCodeToUpdate.companyId);
            if (qrCodeToUpdate && qrCodeToUpdate.locationId)
                setCurrentLocationId(qrCodeToUpdate.locationId);
            if (qrCodeToUpdate && qrCodeToUpdate.address)
                setAddress(qrCodeToUpdate.address);
            if (qrCodeToUpdate && qrCodeToUpdate.state)
                setState(qrCodeToUpdate.state);
            if (qrCodeToUpdate && qrCodeToUpdate.city)
                setCity(qrCodeToUpdate.city);
            if (selectedCompanyLocations && selectedCompanyLocations.length > 0) {
                setCurrentLocationId(qrCodeToUpdate.locationId);
                let countryObj = selectedCompanyLocations.find(
                    (c) => c.locationId == qrCodeToUpdate.locationId
                );
                if (countryObj) {
                    setCountry(countryObj.location.country);
                    setPostalCode(countryObj.location.postalCode);
                }
            }
            // media
            if (allMediaTypes && allMediaTypes.length > 0) {
               
                if (qrCodeToUpdate.mediaKey == "pdf" && !iswithmasterqr) {
                    setPdfPresent(qrCodeToUpdate.mediaTypeId)
                }
                if (qrCodeToUpdate.mediaKey == "img" && !iswithmasterqr) {
                    setImgPresent(qrCodeToUpdate.mediaTypeId)
                }
                if (!iswithmasterqr) {
                    setCurrentMediaTypeId(qrCodeToUpdate.mediaTypeId);
                    setMediaDesc(qrCodeToUpdate.mediaDescription);
                }
                if (qrCodeToUpdate.mediaDescription != null && !iswithmasterqr)
                    setCountMedia(qrCodeToUpdate.mediaDescription.length);
                setMediaFilename(qrCodeToUpdate.filename);
                let mediaDetails = allMediaTypes.find(
                    (x) => x.mediaTypeId == qrCodeToUpdate.mediaTypeId
                );

                if (mediaDetails && mediaDetails.name && !iswithmasterqr)
                    setMediaName(mediaDetails.name);
                if (mediaDetails && mediaDetails.key && !iswithmasterqr)
                    setCurrentMediaTypeKey(mediaDetails.key);
                if (qrCodeToUpdate.mediaKey === "img" && !iswithmasterqr)
                    setImgSrcs([qrCodeToUpdate.filename]);
                if (qrCodeToUpdate.mediaKey === "vdo" && !iswithmasterqr)
                    setShowVideo([qrCodeToUpdate.filename]);
                if (qrCodeToUpdate.mediaKey === "pdf" && !iswithmasterqr)
                    setpdfLink(qrCodeToUpdate.filename);
                if (qrCodeToUpdate.mediaKey === "url" && !iswithmasterqr) {
                    setUrlMedia(qrCodeToUpdate.filename);
                }
                if (qrCodeToUpdate.mediaKey === "Text" && !iswithmasterqr) {
                    setTextMedia(qrCodeToUpdate.mediaDescription);
                }
            }

            if (priorityList && qrCodeToUpdate.priority && priorityList.length > 0 || qrCodeToUpdate.priority == 0) {
                setPriority(qrCodeToUpdate.priority);
            }
          
            if (qrCodeToUpdate.header) {
                setHeader(qrCodeToUpdate.header);

            }
            if (qrCodeToUpdate.header == null || qrCodeToUpdate.priority == "Select Priority") {
                setUpdateSection((prev) => ({ ...prev, activeAccordianHeader: true }))
            }

            setLoading(false)
        });
    }, [mediaIdToUpdate]);

   
  const [imgPresent, setImgPresent] = useState()
    const [pdfpresent, setPdfPresent] = useState()

    useEffect(() => {
        getDeviceTypeData();
    }, [currentQRCodeId]);
   
    useEffect(() => {       
        getAreaTypeDetails();
    }, [currentQRCodeId]);

    useEffect(() => {
        getGuideTypeDetails()
    }, [currentQRCodeId]);

    useEffect(() => {
        if (currentCompanyId > 0)
    getAllLocationByCompany();
  }, [currentCompanyId]);

    useEffect(() => {
      
        if (currentCompanyId > 0)
    allQRByLocation();
    }, [currentLocationId]);

    useEffect(() => {
        if (allGuideTypes && allGuideTypes.length > 0) {
           // setCurrentGuideTypeId(qrCodeToUpdate.guideTypeId);
            let guideType = allGuideTypes.find(
                (g) => g.guideTypeId == currentGuideTypeId
            );
            if (guideType && guideType.name)
                setGuideName(guideType.name);
            if (guideType && guideType.description)
                setGuideDescription(guideType.description);
            if (guideType && guideType.description)
                setCountGuide(guideType.description.length);
        }
    })

    useEffect(() => {
        if (allAreaTypes && allAreaTypes.length > 0)       
        {
            //setCurrentAreaTypeId(qrCodeToUpdate.areaTypeId);
            let areaType = allAreaTypes.find(
                (a) => a.areaTypeId == currentAreaTypeId
            );
            if (areaType && areaType.name)
                setAreaName(areaType.name);
            if (areaType && areaType.description)
                setAreaDescription(areaType.description);
            if (areaType && areaType.description)
                setCountArea(areaType.description.length);
        }

    })

    useEffect(() => {
        if (allDeviceTypes && allDeviceTypes.length > 0) {
           // setCurrentDeviceTypeId(qrCodeToUpdate.deviceTypeId);
            let deviceType = allDeviceTypes.find(
                (a) => a.deviceTypeId == currentDeviceTypeId
            );
            if (deviceType && deviceType.name) setDeviceName(deviceType.name);
            if (deviceType && deviceType.description)
                setDeviceDescription(deviceType.description);
            if (deviceType && deviceType.description)
                setCountDevice(deviceType.description.length);
        }

    })

    useEffect(() => {
        if (selectedQrByLocation && selectedQrByLocation.length > 0) {                     
            let qrcodedeDesc = selectedQrByLocation.find(
                (q) => q.qRcodeId == currentQRCodeId
            );         
            if (qrcodedeDesc && qrcodedeDesc.description) {
                setQrCodeDescription(qrcodedeDesc.description);
                setCountQr(qrcodedeDesc.description.length);
            }
            if (qrcodedeDesc && qrcodedeDesc.product_Name) {
                setProductData(qrcodedeDesc.product_Name);
                setCountProduct(qrcodedeDesc.product_Name.length);
            }
        }

    }, [selectedQrByLocation]);

    const createVirtualQRCode = () => {       
        fetchVirtualQRCode()
            .then(response => {                
                dispatch(getVirtualQRCode(response.data.data));
                setCurrentVirtualqrcode(response.data.data);
                if (!response.data.success) // False 
                {
                    toast.error(response.data.message, {
                        className: "toastSuccess",
                    });
                }
                else {
                    toast.success(response.data.message, {
                        className: "toastSuccess",
                    });
                }
               setCurrentQRCodeId(0)
                setProductData("");
                setQrCodeDescription("");
                setVirtualModal(false);
                //setConditionalStylePriority(false)
                
            })
    }

  const getDeviceTypeData = () => {
    fetchDeviceProducts()
      .then((response) => {
        dispatch(setDeviceTypes(response.data));
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  };
  const getAreaTypeDetails = () => {
    fetchProducts()
      .then((response) => {
        dispatch(setAreaTypes(response.data));
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  };
    const getGuideTypeDetails = () => {     
    fetchGuideProducts()
        .then(response => {
           
            dispatch(setGuideTypes(response.data))           
        })
      .catch((err) => {
        console.log("Err: ", err);
      });
  };
  const getCompanyDetails = () => {
    fetchCompanyDetails()
      .then((response) => {
        dispatch(SetCompanyDetails(response.data));
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  };
  const allQRByLocation = () => {
    fetchAllQRByLocationId(currentLocationId)
      .then((response) => dispatch(getAllQRByLocationId(response.data)))
      .catch((err) => {
        console.log("Err: ", err);
      });
  };

  const getAllLocationByCompany = () => {
    fetchAllLocationByCompanyId(currentCompanyId)
      .then((response) => {
        dispatch(getAllLocationByCompanyId(response.data));
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  };

  const getMediaTypeDetails = () => {
    fetchMediaData()
      .then((response) => dispatch(setMediaTypes(response.data)))
      .catch((err) => {
        console.log("Err: ", err);
      });
  };
  const getDeviceDetails = (e) => {
      setCurrentDeviceTypeId(e.target.value);
      setCopyQRCodeId("");
    let deviceDetails = allDeviceTypes.find(
      (x) => x.deviceTypeId == e.target.value
    );
    if (deviceDetails && deviceDetails.name) {
      setDeviceName(deviceDetails.name)
        setDeviceDescription(deviceDetails.description)
        setCountDevice(deviceDetails.description.length)
    }
    if (e.target.value == "Select Device" || e.target.value == 0) {
      setDeviceName("Select Device")
      setDeviceDescription("")
    }
    setDataErrorShow("");
  };
    const getQRCodeDetails = (e) => {
        
    setCurrentQRCodeId(e);
    let qrCode = selectedQrByLocation.find((q) => q.qRcodeId == e);
    if (qrCode && qrCode.description) {
      setQrCodeDescription(qrCode.description);
     // setProductData(qrCode.product_Name);
        }
    else {
        setQrCodeDescription("");

    }
        if (qrCode && qrCode.product_Name) {
            setProductData(qrCode.product_Name);
        }
       
    if (qrCode && qrCode.product_Name) {
      setCountProduct(qrCode.product_Name.length);
    }
    if (e == "Select QR" || e == "0") {
      setCurrentQRCodeId("Select QR")
    }
    setDataErrorShow("");
  };
  //For Area Details
  const getAreaDetails = (e) => {
    setCurrentAreaTypeId(e.target.value);
    let areaDetails = allAreaTypes.find((x) => x.areaTypeId == e.target.value);
    if (areaDetails && areaDetails.name) {
      setAreaName(areaDetails.name);
        setAreaDescription(areaDetails.description)
        setCountArea(areaDetails.description.length)
    }
    if (e.target.value == "Select Area" || e.target.value == 0) {
      setAreaName("Select Area")
      setAreaDescription("")
      setUpdateSection((prev) => ({ ...prev, activeAccordianArea: true }))
    }
    setDataErrorShow("");
  };
  //For Guide Details
  const getGuideDetails = (e) => {
    setCurrentGuideTypeId(e.target.value);
    let guideDetails = allGuideTypes.find(
      (x) => x.guideTypeId == e.target.value
    );
    guideDetails ? setGuideName(guideDetails.name) : setGuideName("");
      guideDetails ? setGuideDescription(guideDetails.description) : setGuideDescription("");
      if (guideDetails && guideDetails.description) {
          setCountGuide(guideDetails.description.length)
      }
    setDataErrorShow("");
  };
  const getMediaDetailsUpload = (e) => {
    const MediaChanges = e.target.value
    setCurrentMediaTypeId(e.target.value)
    let mediaDetails = allMediaTypes.find(
      (x) => x.mediaTypeId == MediaChanges
    );
    if (mediaDetails && mediaDetails.name) {
      setMediaName(mediaDetails.name);
      setCurrentMediaTypeKey(mediaDetails.key);
      mediaDetails.mediaDescription ? setMediaDesc(mediaDetails.mediaDescription) : "";
    }
    if (e.target.value == "Select Media") {
      setMediaName("Select Media")
      selectMedia("Select Media")
      //setMediaDesc("")
    }
    setDataErrorShow("");
  }
  const getMediaDetails = (e) => {
    let mediaDetails = allMediaTypes.find(
      (x) => x.mediaTypeId == e.target.value
    );
    if (mediaDetails && mediaDetails.name) {
      setMediaName(mediaDetails.name);
      setCurrentMediaTypeKey(mediaDetails.key);
      mediaDetails.mediaDescription ? setMediaDesc(mediaDetails.mediaDescription) : "";
    }
    if (e.target.value == "Select Media") {
      setMediaName("Select Media")
      selectMedia("Select Media")
    }
    setDataErrorShow("");
  };

  //For location in Company Section
    const getLocationDetails = (e) => {
       
    setCurrentLocationId(e.target.value);
    let locationDetails = selectedCompanyLocations.find(
      (loc) => loc.locationId == e.target.value
    );
      if (locationDetails && locationDetails.location.address) 
          setAddress(locationDetails.location.address);
      if (locationDetails && locationDetails.location.city) {
          setCity(locationDetails.location.city);
      }
      else {
          setCity("")
      }
      if (locationDetails && locationDetails.location.postalCode) {
          setPostalCode(locationDetails.location.postalCode);
      }
      else {
          setPostalCode("");
      }
      if (locationDetails && locationDetails.location.state) {
          setState(locationDetails.location.state);
      }
      else {
          setState("");
      }
          
      if (locationDetails && locationDetails.location.country) {
          setCountry(locationDetails.location.country);
      }
      else {
          setCountry("");
      }
    
    if (e.target.value == "Select Location") {
      setCurrentLocationId("Select Location")
      setAddress("");
      setCity("");
      setPostalCode("");
      setState("");
      setCountry("");
    }
      setDataErrorShow("");    
  };
    const handleCheckMedia = (mediaId, filename, mediaTypeName) => {
       
    var updatedList = [...checkedListOfMedia];

    if (event.target.checked) {
      updatedList = [
        ...checkedListOfMedia,
        { mediaId: mediaId, fileName: filename, mediaTypeName: mediaTypeName },
      ];
    } else {
      updatedList.splice(
        checkedListOfMedia.findIndex((x) => x.mediaId == mediaId),
        1
      );
      setAssignBtnDisable(true);
    }
    if (updatedList.length > 0) setAssignBtnDisable(false);
    else {
      setAssignBtnDisable(true);
    }
    setCheckedListOfMedia([...updatedList]);
  };

    const CompanyDetails = (e) => {
      
      
        setCurrentCompanyId(e.target.value);
        setCurrentLocationId("");
        setShowQrDropdown(true);
        setCurrentQRCodeId("");
        setProductData("");
        setQrCodeDescription("");
        setCopyQRCodeId("");
        
       
        let locationDetails = selectedCompanyLocations.find(
            (loc) => loc.locationId == e.target.value
        );
        if (!locationDetails)
            setCurrentLocationId("")
        if (locationDetails && locationDetails.location.address) {
            setAddress(locationDetails.location.address);
        }
        else {
            setAddress("")
        }
        if (locationDetails && locationDetails.location.city) {
            setCity(locationDetails.location.city);
        }
        else {
            setCity("")
        }
        if (locationDetails && locationDetails.location.postalCode) {
            setPostalCode(locationDetails.location.postalCode);
        }
        else {
            setPostalCode("");
        }
        if (locationDetails && locationDetails.location.state) {
            setState(locationDetails.location.state);
        }
        else {
            setState("");
        }
        if (locationDetails && locationDetails.location.country) {
            setCountry(locationDetails.location.country);
        }
        else {
            setCountry("");
        }
      
      if (e.target.value == "Select Company") {
          setCurrentCompanyId("Select Company")
          setCurrentLocationId("")
          setAddress("");
          setCity("");
          setPostalCode("");
          setState("");
          setCountry("");
      }

    setDataErrorShow("");
  };

  const handleRemoveImgClick = () => {
    setImgSrcs((prevImgs) => prevImgs.slice(1));
  };

  const getMediaDataForModal = (mediaTypeIdToFilter, resetTable = false) => {
    const API_URL = url.GET_ALLMEDIA + `/${PAGE_SIZE}/${mediaTypeIdToFilter}`;
    axios
      .get(API_URL)
      .then((response) => {
        const mediaDataFromDB = response.data.data.mediaList;
        const totalCount = response.data.data.totalCount;
        const currentDataLength = [...filteredMediaData, ...mediaDataFromDB]
          .length;
        if (mediaDataFromDB.length > 0 && currentDataLength < totalCount)
          setHasMore(true);
        else setHasMore(false);
        if (!resetTable) {
          setFilteredMediaData([...filteredMediaData, ...mediaDataFromDB]);
        } else {
          setFilteredMediaData([...mediaDataFromDB]);
        }
        PAGE_SIZE = PAGE_SIZE + 1;
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  };

    const handleRemoveMediaClick = (mediaId) => {  
    let selectedMediaArray = [...selectedMediaList];
    selectedMediaArray.splice(
      selectedMediaArray.findIndex((x) => x.mediaId == mediaId),
      1
    );
    setSelectedMediaList([...selectedMediaArray]);
    setCheckedListOfMedia([...selectedMediaArray]);
  };
    const urlDataMedia = (e) => {
        
    setPdfMedia()
    setVideoMedia()
    setImages()
    setImgSrcs([])
    setUrlMedia(e.target.value);
    var res = e.target.value.match(
      "https?: //(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,}"
    );
    if (res == null) {
      setConditionalStyleUrl(true)
      setUploadMedia((prev) => ({ ...prev, url: true }))
    } else {
      setConditionalStyleImage(false)
      setConditionalStyleVideo(false)
      setConditionalStyleUrl(false)
      setConditionalStyleUpload(false)
      setConditionalStylePdf(false)
      setUploadMedia((prev) => ({ ...prev, video: false, pdf: false, image: false, url: false }))
      setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false }))
    }
    setError("");
  };
  const [updateSection, setUpdateSection] = useState({ activeAccordianHeader: false, activeAccordianDevice: false, activeAccordianArea: false, activeAccordianGuides: false, activeAccordianUpload: false, activeAccordianQR: false })
    const [uploadMedia, setUploadMedia] = useState({ image: false, pdf: false, video: false, url: false, text: false })

    const onPdfSaveData = (e) => {
       
    setUrlMedia()
    setVideoMedia()
    setImages()
      setImgSrcs([])
      setTextMedia()
    const files = e.target.files[0];
      setPDFFileName(files.name.replace(/ /g, "_"));
    setPdfMedia(files);
    setError("");
    setConditionalStylePdf(false)
    setConditionalStyleImage(false)
    setConditionalStyleVideo(false)
    setConditionalStyleUrl(false)
    setConditionalStyleUpload(false)
    setUploadMedia((prev) => ({ ...prev, pdf: false, image: false, video: false, url: false }))
    setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false }))
    setChangeCount(changeCount + 1);
  };
  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
    }

    
    const onEditorStateChange = (editorState) => {
       
        
        setEditorState(editorState);
        setUrlMedia()
        setVideoMedia()
        setImages()
        setImgSrcs([])
        
        setConditionalStylePdf(false)
        setConditionalStyleImage(false)
        setConditionalStyleVideo(false)
        setConditionalStyleUrl(false)
        setConditionalStyleUpload(false)
        setUploadMedia((prev) => ({ ...prev, pdf: false, image: false, video: false, url: false, text: false }))
        setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false }))
        setChangeCount(changeCount + 1);
    }

    const setEditorReference = (ref) => {
        
        if (ref != null) {
            if (ref.innerText != "") {
                setTextMedia(ref.innerHTML);
            }
            else {
                setError("Media text should not be empty.");
            }
        }
        else {
            setError("Media text should not be empty.");
        }
    }
    

    const getMediaData = () => {

        
    const formData = new FormData();
    let isMedia = false;
    // convert base64 to fileData // this is for images
    //image
    mediaFiles.forEach((mFile) => {
      var imageUrl = mFile.split(";");

      var contentType = imageUrl[0].split(":")[1];
      var realData = imageUrl[2].split(",")[1];
      // Convert to blob
      var blob = b64toBlob(realData, contentType);
      const _fileData = new File([blob], imageUrl[1].substring(5)); // passing name
      formData.append("MediaFiles", _fileData);
      isMedia = true;
    });

    // for source / vedios
    if (source) {
      const vedioData = new File([new Blob([videoMedia])], videoFileName);
      formData.append("MediaFiles", vedioData);
      isMedia = true;
    }

    // for PDF
    if (pdfMedia) {
      const pdfData = new File([new Blob([pdfMedia])], pdfFileName);
      formData.append("MediaFiles", pdfData);
      isMedia = true;
      }

    // For TEXT

      if (textMedia) {
          isMedia = false;
      }

        if (ifAddingQrCode || iswithmasterqr) {
      // Add    
           if (currentVirtualqrcode)
            formData.append("VirtualQRid", currentVirtualqrcode)
      if (checkedListOfMedia.length > 0)
        formData.append("QRCode", currentQRCodeId);
      else if (currentQRCodeId)
            formData.append("QRCodeID", currentQRCodeId);       
      formData.append("QRDescription", qrCodeDescription);
      formData.append("Product_Name", productData);
      formData.append("MediaData", urlMedia);
     /* if (subPriority) formData.append("SubPriority", subPriority);*/
      formData.append("LocationID", currentLocationId);
      formData.append("CompanyId", currentCompanyId);
      formData.append("DeviceDescription", deviceDescription);
      formData.append("GuideDescription", guideDescription);
      formData.append("AreaDescription", areaDescription);
      formData.append("DeviceTypeId", currentDeviceTypeId);
      formData.append("AreaTypeId", currentAreaTypeId);
        formData.append("GuideTypeId", currentGuideTypeId);
        formData.append("MediaTypeKey", currentMediaTypeKey);
      if (currentMediaTypeId)
        formData.append("MediaTypeId", currentMediaTypeId);     
      if (checkedListOfMedia.length > 0)
        formData.append("Description", mediaDesc);
      formData.append("name", "abc");
      formData.append("deviceName", deviceName);
      formData.append("areaName", areaName);
      formData.append("guideName", guideName);
      formData.append("IsMedia", isMedia);
      if (checkedListOfMedia.length > 0)
        formData.append("PriorityQRSupport", priority);
      else formData.append("PriorityQr", priority);
      formData.append("Header", header);
      if (mediaIdToUpdate) formData.append("MediaId", mediaIdToUpdate);
      if (checkedListOfMedia.length > 0)
        formData.append(
          "mediaIdsStr",
          checkedListOfMedia.map((x) => x.mediaId).toString()
        );
        // New Changes
        if (mediaKey != "Text"){
          formData.append("MediaDescription", mediaDesc);
        }
        else{
          formData.append("MediaDescription", textMedia);
        }
        //End
    }
    return formData;
  };

  const handleBtnClick = () => {
    setModal(true);
  };

  const handleImageShow = (filename) => {
    setShowModal(true);
    setZoomFilename(filename);
    setModal(false);
  };
  const handleVideoShow = (filename) => {
    setVideoModal(true);
    setZoomVideo(filename);
    setModal(false);
  };
  const handleImageClose = () => {
    setShowModal(false);
    setModal(true);
  };

  const handleVideoClose = () => {
    setVideoModal(false);
    setModal(true);
  };

  const handleCopyModalClose = () => {
    setCopyModal(false);
    };

    const handleVirtualModalClose = () => {
        setVirtualModal(false);
    };
  const handleClose = () => {
    setModal(false);
  };
    const handleProductName = (e) => {
        
    setProductData(e.target.value)
    setCountProduct(e.target.value.length)
    let error = { ...dataErrorShow };
    if (e.target.value.trim() != "") {
      setUpdateSection((prev) => ({ ...prev, activeAccordianQR: false }))
    } else {
      setUpdateSection((prev) => ({ ...prev, activeAccordianQR: true }))
    }
    setDataErrorShow(error);
  }

  const handleDescription = (e) => {
    setQrCodeDescription(e.target.value),
      setCountQr(e.target.value.length),
      setChangeCount(changeCount + 1);
    if (e.target.value.trim() != "") {
      setUpdateSection((prev) => ({ ...prev, activeAccordianQR: false }))
    }
    else {
      setUpdateSection((prev) => ({ ...prev, activeAccordianQR: true }))
    }
  }
    const handleHeaderOnChange = (e) => {
      
    setChangeCount(changeCount + 1);
    setHeader(e.target.value);
    setCountText(e.target.value.length);
    let error = { ...dataErrorShow };
        if (priority != undefined && priority != "Select priority" && priority != null) {
      setConditionalStyleHeader(false)
      setUpdateSection((prev) => ({ ...prev, activeAccordianHeader: false }))
    } else {
      setConditionalStyleHeader(true)
      setUpdateSection((prev) => ({ ...prev, activeAccordianHeader: true }))
        }       
    setDataErrorShow(error);
    };

   
    const assignBtnHndlr = () => {
    setModal(false);
    toast.success("All the medias are successfully Assigned to QRCode, To Save Please Click on Save button", {
      className: "toastSuccess",
    });      
        setSelectedMediaList([...checkedListOfMedia]);               
        if (checkedListOfMedia.length < 1) {            
            setAssignError(true)
        }
        else {           
            setAssignError(false)
            setConditionalStyleUpload(false)
            setConditionalStyleImage(false)
            setConditionalStylePdf(false)
            setConditionalStyleUrl(false)
            setConditionalStyleVideo(false)
        }
       
    setDataErrorShow("");
  };
    const uncheckcheckbox = () => {
        
    var items = document.getElementsByName("acs");
    for (var i = 0; i < items.length; i++) {
        if (items[i].type == "checkbox")
            items[i].checked = false;
        }
        setCheckedListOfMedia([]);
    setSelectedMediaList([]);
    setAssignBtnDisable(true);
  };

    const saveMediaType = async (e) => {
        debugger;
        let errResult = confirmUpload(e);
        let iffieldserror = showDataError(e);      
        setLoading(true);      
        const formData = getMediaData();
        if (iffieldserror === true && checkedListOfMedia.length > 0)
        {
            setLoading(false);
            // const formData = getMediaData();
            setLoading(true);
            if (ifAddingQrCode === true && checkedListOfMedia.length > 0) {
                const response_ = await axios({
                    method: "post",
                    url: url.SAVELISTOFMEDIA_QRDATA,
                    data: formData,
                }).then((response) => {
                    setSource();
                    setLoading(false);
                    toast.success(response.data.message, {
                        className: "toastSuccess",
                    });
                    setLoading(false);
                    history.push("/QrReview");
                });
                setLoading(false);
                setImages({
                    upload: {
                        pictures: [],
                        maxFileSize: 5242880,
                        imgExtension: [".jpg", ".png", ".jpeg"],
                        defaultImages: [],
                    },
                });
                setSource();
                setUrlMedia();
                setPdfMedia();
                setDataErrorShow("");
            }
        }
        else if (iffieldserror === true  && errResult === true && checkedListOfMedia.length <= 0          
            && !conditionalStyleQR
            && !conditionalStyleDevice
            && !conditionalStyleCompany
            && !conditionalStyleLocation
            && !conditionalStyleArea
            && !conditionalStyleGuide
            && !conditionalStyleUpload
          
            && !conditionalStylePriority
            && !conditionalStyleImage
            && !conditionalStyleVideo
            && !conditionalStylePdf           
            && !conditionalStyleUrl
            && !updateSection.activeAccordianUpload 
            && !videoSizeError
            && priority != null
           // && !updateSection.activeAccordianHeader
             /* || iswithmasterqr*/
           //              
        ) {          
            setLoading(true);
            const response_ = await axios({
                method: "post",
                url: url.SAVEQRDETAILS_QRDATA,
                data: formData,
            })
                .then((response) => {
                   
                    setSource();
                    setLoading(false);
                    if (currentVirtualqrcode && !showQrDropdown) {
                        toast.success(response.data.message, {
                            className: "toastSuccess",
                        });
                        history.push("/VirtualQRReview");
                    }
                    else {
                        toast.success(response.data.message, {
                            className: "toastSuccess",
                        });
                        history.push("/QrReview");
                    }
                    setLoading(false);
                })

                .catch((err) => {
                    console.log("Err: ", err);
                });

           // setLoading(false);
            setImages({
                upload: {
                    pictures: [],
                    maxFileSize: 5242880,
                    imgExtension: [".jpg", ".png", ".jpeg"],
                    defaultImages: [],
                },
            });
            setSource();
            setUrlMedia();
            setPdfMedia();
            setDataErrorShow("");
        }
        setLoading(false);
    };
  
  const handleCopyQRdata = () => {
    setCopyModal(true);
    };

    const handleVirtualQRdata = () => {
        setVirtualModal(true);
        setShowQrDropdown(false);
    };

    const showActualDropdown = () => {
       
        setShowQrDropdown(true);
        setCurrentVirtualqrcode("");
        setProductData("");
        setCountProduct("");
        setQrCodeDescription("");
        setCountQr("");
    };
  const copyMediaData = () => {
    const formData = new FormData();
    formData.append("secondaryQR", currentQRCodeId);
    formData.append("primaryQR", copyQRCodeId);
    formData.append("QRCodeID", currentQRCodeId);
    formData.append("QRDescription", qrCodeDescription);
    formData.append("Product_Name", productData);
    copyAllQRDataById(formData).then(response => {
      dispatch(copyQRCodeIdData(formData))
      if (response.data.success) {
        toast.success(response.data.message, {
          className: "toastSuccess",
        });
        setProductData(" ")
        history.push("/QrReview");
        setLoading(false);
      }
      else {
        setCopyModal(false);
        toast.error(response.data.message, {
          className: "toastSuccess",
        },);
        setLoading(false);
      }
    })
      .catch((err) => {
        console.log("Err: ", err);
      });
  };

    const selectMedia = (params) => { 
     
      mediaKey = params
      //setMediaKey(params); 
    switch (params) {
      case "img":
        return (
          <div className=" mt-3 qrBtn row">
            <div className="col-sm-12 text-center">
              <UploadComponent
                {...images.upload ? images.upload : ""}
                handleChange={handleImageUploadChange}
                setConditionalStyleImage={setConditionalStyleImage}
                setUploadMedia={setUploadMedia}
              />
            </div>
            <div className="img-section m-0 flex-wrap d-flex align-items-center">
              {ifAddingQrCode ? imgSrcs.map((imgSrc) => (
                <div className="img--box position-relative">
                  <span
                    className="position-absolute"
                    onClick={handleRemoveImgClick}
                  >
                    X
                  </span>
                  <img src={imgSrc} />

                </div>
              )) :
                imgSrcs.map((imgSrc) => (
                  <div className="img--box position-relative">
                    <img src={imgSrc} />

                  </div>))
              }
            </div>
            {updateSection.activeAccordianUpload && imgPresent ? setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false }))
              : (
                ""
              )}
          </div>
        );

      case "vdo":       
        return (
          <div className="VideoInput ">
            <input
              ref={InputRef}
              className="VideoInput_input"
              type="file"
              ReactPlayer
              onChange={(e) => handleFileChange(e)}
                    accept=".mov,.mp4,.wmv,.avi,.MOV"
            />

            {showVideo ?
             <ReactPlayer
              url={showVideo}
              width="500px"
              height="300px"
              controls={true}
              playing={false}
              //onDuration={(duration) => {
              //  setPlayed(duration);
              //}}
            /> 
              : ""}

            {updateSection.activeAccordianUpload && showVideo ? setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false }))
              : ( "")}
            
            {
              <small className="text-danger d-inline-block mt-3">
                {!videoFormatError ? " " : "Invalid Format"}
              </small>
                }
                {
                    < small className="text-danger d-inline-block mt-3">{!videoSizeError ? ' ' : 'Size Cannot Exceed 250 MB'}</small>

                }
          </div>
        );

      case "url":
        return (
          <div>
            <input
              name="urlMedia"
              className="form-control"
              placeholder=" https://example.com"
              type="text"
              maxLength={50}
              onChange={urlDataMedia}
              value={urlMedia}
            />
            {updateSection.activeAccordianUpload && urlMedia ? setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false })) : (
              ""
            )}
            {
              <small className="text-danger d-block mt-2">
                {!urlFormatError ? " " : "Invalid URL"}
              </small>
            }
          </div>
        );

      case "pdf":
        return (
          <div>
            <div style={{ marginBottom: "5px" }}>
              {" "}
              {!ifAddingQrCode && pdfpresent ? (
                <a className="pdf-download-btn" target="blank" href={pdfLink}>
                  <div><img className="thumb--inner" src={PDF}></img>{" "}
                    <div>DOWNLOAD FILE</div>
                  </div>
                  <span>
                    <img className="thumb--inner" src={downloadIcon}></img>
                  </span>
                </a>
              ) : (
                ""
              )}
            </div>
            <input type="file" name="pdfMedia" className="form-control col-sm-6" accept=".pdf" onChange={(e) => {onPdfSaveData(e) }}  />

            {updateSection.activeAccordianUpload && pdfpresent ? setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: false })) : (
              ""
            )}
          </div>
            );
        case "Text":
            return (
                <div>
                    <Editor
                        //toolbarFocus
                        className="form-control"
                        editorState={editorState}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        onEditorStateChange={onEditorStateChange}
                        editorRef={setEditorReference}   
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history', 'link', 'emoji'],                          
                        }}
                    />                   
                </div>
            );
      default:
        return <div></div>;
    }
  };
// functions to be called in the main component
  return (
    <div>
      <Toaster />
      <Layout title="QR Review">
        <div className="position-relative">
          {loading ? <Loader /> : ""}
          <div className="card-box-header d-inline-flex w-100 align-items-center">
            <h4 className="m-0">
              <Link className="backBTN" to="/QrReview">
                <img src={Arrow_leftbrown}></img>
                Back
              </Link>
            </h4>
          </div>

          <div class="content-area new-page">
            <div class="accordion" id="accordionExample">
              {ifAddingQrCode ? (
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class={conditionalStyleCompany || conditionalStyleLocation ? "accordion-button errorStyle collapsed" : "accordion-button collapsed "}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                      style={{ fontSize: 14.5 }}
                    >
                      Company and Location
                      {conditionalStyleCompany || conditionalStyleLocation ? <ErrorOutlineOutlinedIcon className="submitError" /> : ""}
                    </button>
                  </h2>

                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label>
                              Select Company{" "}
                              <span className="required-fields"> * </span>{" "}
                            </label>
                            <div class="input-group">
                              <select
                                class="form-select mb-0  filter-select"
                                aria-label=".form-select-lg example"
                                value={currentCompanyId}
                                disabled={ifAddingQrCode ? "" : "disabled"}
                                onChange={(e) => {
                                  CompanyDetails(e);
                                  if (e.target.value != "Select Company") {
                                    setConditionalStyleCompany(false)
                                  } else {
                                    setConditionalStyleCompany(true)
                                  }
                                }
                                }
                              >
                                <option selected="">Select Company</option>
                                {allCompanyDataDetails &&
                                  allCompanyDataDetails.map((company) => company.active && (
                                    <option
                                      key={company.companyId}
                                      value={company.companyId}
                                    >
                                      {" "}
                                      {company.companyName}{" "}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                                              </div>
                                              {ifAddingQrCode && currentCompanyId && currentCompanyId != "Select Company"?
                                                  <div class="col-md-4">
                                                      <div class="form-group">
                                                          <label>
                                                              Select Location{" "}
                                                              <span className="required-fields"> * </span>{" "}
                                                          </label>
                                                          <div class="input-group">
                                                              <select
                                                                  class="form-select mb-0  filter-select"
                                                                  aria-label=".form-select-lg example"
                                                                  value={currentLocationId}
                                                                 // disabled={ifAddingQrCode && currentCompanyId && currentCompanyId != "Select Company" ? "" : "disabled"}
                                                                  onChange={(e) => {
                                                                      getLocationDetails(e);
                                                                      setCurrentLocationId(e.target.value);
                                                                      let error = { ...dataErrorShow };
                                                                      if (e.target.value != "Select Location" || e.target.value == 0 || e.target.value == null) {
                                                                          setConditionalStyleLocation(false)
                                                                      } else {
                                                                          setConditionalStyleLocation(true)
                                                                      }
                                                                      setDataErrorShow(error);
                                                                  }}
                                                              >
                                                                  <option selected="">Select Location</option>
                                                                  {selectedCompanyLocations &&
                                                                      selectedCompanyLocations.map((data) => (
                                                                          <option
                                                                              key={data.locationId}
                                                                              value={data.locationId}
                                                                          >
                                                                              {data.location.name}
                                                                          </option>
                                                                      ))}
                                                              </select>
                                                          </div>
                                                          <div>
                                                              {dataErrorShow ? (
                                                                  <small className="text-danger d-block mt-2">
                                                                      {dataErrorShow.locationErr}
                                                                  </small>
                                                              ) : (
                                                                  ""
                                                              )}
                                                          </div>
                                                      </div>
                                                  </div> : 

                                                  <div class="col-md-4">
                                                      <div class="form-group">
                                                          <label>
                                                              Select Location{" "}
                                                              <span className="required-fields"> * </span>{" "}
                                                          </label>
                                                          <div class="input-group">
                                                              <select
                                                                  class="form-select mb-0  filter-select"
                                                                  aria-label=".form-select-lg example"
                                                                  value={currentLocationId}
                                                                  disabled
                                                                  onChange={(e) => {
                                                                      getLocationDetails(e);
                                                                      setCurrentLocationId(e.target.value);
                                                                      let error = { ...dataErrorShow };
                                                                      if (e.target.value != "Select Location" || e.target.value == 0 || e.target.value == null) {
                                                                          setConditionalStyleLocation(false)
                                                                      } else {
                                                                          setConditionalStyleLocation(true)
                                                                      }
                                                                      setDataErrorShow(error);
                                                                  }}
                                                              >
                                                                  <option selected="">Select Location</option>
                                                                  {selectedCompanyLocations &&
                                                                      selectedCompanyLocations.map((data) => (
                                                                          <option
                                                                              key={data.locationId}
                                                                              value={data.locationId}
                                                                          >
                                                                              {data.location.name}
                                                                          </option>
                                                                      ))}
                                                              </select>
                                                          </div>
                                                          <div>
                                                              {dataErrorShow ? (
                                                                  <small className="text-danger d-block mt-2">
                                                                      {dataErrorShow.locationErr}
                                                                  </small>
                                                              ) : (
                                                                  ""
                                                              )}
                                                          </div>
                                                      </div>
                                                  </div>

                                              }                                                      
                        <div class="col-md-4">
                          <div class="form-group">
                            <label>Country</label>
                            <div class="input-group">
                              <input
                                id="text"
                                type="text"
                                                              class="form-control disabled-input"
                                name="text"
                                placeholder="Enter Country Name"
                                                              value={country}
                                                              disabled
                                                            //  disabled={ifAddingQrCode && currentLocationId ? "" : "disabled"}
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label>State</label>
                            <div class="input-group">
                              <input
                                id="text"
                                type="text"
                                                              class="form-control disabled-input"
                                name="text"
                                placeholder="Enter State Name"
                                                              value={state}
                                                              disabled
                                                             // disabled={ifAddingQrCode && currentLocationId ? "" : "disabled"}
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label>City</label>
                            <div class="input-group">
                              <input
                                id="text"
                                type="text"
                                                              class="form-control disabled-input"
                                name="text"
                                placeholder="Enter City Name"
                                                              value={city}
                                                              disabled
                                                            
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label>Postal Code</label>
                            <div class="input-group">
                              <input
                                id="text"
                                type="text"
                                                              class="form-control disabled-input"
                               name="text"
                                                              placeholder="Enter Postal Code"
                                                              disabled                                                             
                                                              value={postalCode}
                                                                                                                       
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Address</label>
                            <div class="input-group">
                              <textarea
                                id="text"
                                type="text"
                                                              class="form-control disabled-input"
                                name="text"
                                placeholder="Enter Address"
                                                              value={address}
                                                              disabled                                                            
                              />
                            </div>
                          </div>
                        </div>
                                              
                      </div>
                    </div>
                  </div>
                  {dataErrorShow ? (
                    <small className="text-danger d-block mt-2">
                      {dataErrorShow.companyErr}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                      style={{ fontSize: 14.5 }}
                    >
                      Company and Location
                    </button>
                  </h2>

                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label>
                              Select Company{" "}
                              <span className="required-fields"> * </span>{" "}
                            </label>
                            <div class="input-group">
                              <select
                                class="form-select mb-0  filter-select"
                                aria-label=".form-select-lg example"
                                value={currentCompanyId}
                                disabled={ifAddingQrCode ? "" : "disabled"}
                                onChange={(e) => {
                                  CompanyDetails(e)
                                }}
                              >
                                <option selected="">Select Company</option>
                                {allCompanyDataDetails &&
                                  allCompanyDataDetails.map((company) =>  (
                                    <option
                                      key={company.companyId}
                                      value={company.companyId}
                                    >
                                      {company.companyName}{" "}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div>
                              {dataErrorShow ? (
                                <small className="text-danger d-block mt-2">
                                  {dataErrorShow.companyErr}
                                </small>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                                                  </div>

                                                  {ifAddingQrCode && currentCompanyId && currentCompanyId != "Select Company" ?
                                                      <div class="col-md-4">
                                                          <div class="form-group">
                                                              <label>
                                                                  Select Location{" "}
                                                                  <span className="required-fields"> * </span>{" "}
                                                              </label>
                                                              <div class="input-group">
                                                                  <select
                                                                      class="form-select mb-0  filter-select"
                                                                      aria-label=".form-select-lg example"
                                                                      value={currentLocationId}
                                                                     // disabled={ifAddingQrCode ? "" : "disabled"}
                                                                      onChange={(e) => {
                                                                          getLocationDetails(e);
                                                                      }}
                                                                  >
                                                                      <option selected="">Select Location</option>
                                                                      {selectedCompanyLocations &&
                                                                          selectedCompanyLocations.map((data) => (
                                                                              <option
                                                                                  key={data.locationId}
                                                                                  value={data.locationId}
                                                                              >
                                                                                  {data.location.name}
                                                                              </option>
                                                                          ))}
                                                                  </select>
                                                              </div>
                                                              {dataErrorShow ? (
                                                                  <small className="text-danger d-block mt-2">
                                                                      {dataErrorShow.locationErr}
                                                                  </small>
                                                              ) : (
                                                                  ""
                                                              )}
                                                          </div>
                                                      </div> :

                                                      <div class="col-md-4">
                                                          <div class="form-group">
                                                              <label>
                                                                  Select Location{" "}
                                                                  <span className="required-fields"> * </span>{" "}
                                                              </label>
                                                              <div class="input-group">
                                                                  <select
                                                                      class="form-select mb-0  filter-select"
                                                                      aria-label=".form-select-lg example"
                                                                      value={currentLocationId}
                                                                      disabled
                                                                      onChange={(e) => {
                                                                          getLocationDetails(e);
                                                                      }}
                                                                  >
                                                                      <option selected="">Select Location</option>
                                                                      {selectedCompanyLocations &&
                                                                          selectedCompanyLocations.map((data) => (
                                                                              <option
                                                                                  key={data.locationId}
                                                                                  value={data.locationId}
                                                                              >
                                                                                  {data.location.name}
                                                                              </option>
                                                                          ))}
                                                                  </select>
                                                              </div>
                                                              {dataErrorShow ? (
                                                                  <small className="text-danger d-block mt-2">
                                                                      {dataErrorShow.locationErr}
                                                                  </small>
                                                              ) : (
                                                                  ""
                                                              )}
                                                          </div>
                                                      </div>
                                                      }

                        <div class="col-md-4">
                          <div class="form-group">
                            <label>Country</label>
                            <div class="input-group">
                              <input
                                id="text"
                                type="text"
                                class="form-control"
                                name="text"
                                placeholder="Enter Country Name"
                                value={country}
                                disabled={ifAddingQrCode ? "" : "disabled"}
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label>State</label>
                            <div class="input-group">
                              <input
                                id="text"
                                type="text"
                                class="form-control"
                                name="text"
                                placeholder="Enter State Name"
                                value={state}
                                disabled={ifAddingQrCode ? "" : "disabled"}
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label>City</label>
                            <div class="input-group">
                              <input
                                id="text"
                                type="text"
                                class="form-control"
                                name="text"
                                placeholder="Enter City Name"
                                value={city}
                                disabled={ifAddingQrCode ? "" : "disabled"}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label>Postal Code</label>
                            <div class="input-group">
                              <input
                                id="text"
                                type="text"
                                class="form-control"
                                name="text"
                                placeholder="Enter City Name"
                                value={postalCode}
                                disabled={ifAddingQrCode ? "" : "disabled"}
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Address</label>
                            <div class="input-group">
                              <textarea
                                id="text"
                                type="text"
                                class="form-control"
                                name="text"
                                placeholder="Enter Address"
                                value={address}
                                disabled={ifAddingQrCode ? "" : "disabled"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                                      class={
                                         /* conditionalStyleQR || updateSection.activeAccordianQR ? "accordion-button collapsed errorStyle" :*/
                                              "accordion-button collapsed "}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                    style={{ fontSize: 14.5 }}
                  >
                    QR Information
                    {conditionalStyleQR ? <ErrorOutlineOutlinedIcon className="submitError" /> : ""}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse "
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <div class="row ">
                                          <div class="col-md-5">
                                              {!currentVirtualqrcode || showQrDropdown  ?
                                                  <div class="form-group">
                                                      <label >
                                                          Select QR{" "}
                                                          <span className="required-fields"> * </span>{" "}

                                                      </label>
                                                      <div class="input-group QR_select">
                                                          <Select
                                                              class="form-select mb-0 flex-grow-1 filter-select"
                                                              aria-label=".form-select-lg example"
                                                              showSearch
                                                              style={{ width: 550 }}
                                                              value={currentQRCodeId ? currentQRCodeId : "Select QR"}
                                                              // value={currentVirtualqrcode ? currentVirtualqrcode : currentQRCodeId}
                                                              disabled={ifAddingQrCode || iswithmasterqr ? "" : "disabled"}
                                                              defaultValue="Select QR"
                                                              onChange={(e) => {

                                                                  let error = { ...dataErrorShow };
                                                                  setDataErrorShow(error);

                                                                  var productFound = selectedQrByLocation.filter((item) => item.product_Name == e)
                                                                  if (productFound.length > 0) {
                                                                      setCurrentQRCodeId(productFound[0].qRcodeId);
                                                                      getQRCodeDetails(productFound[0].qRcodeId);
                                                                  }
                                                                  else {
                                                                      setCurrentQRCodeId(e);
                                                                      getQRCodeDetails(e);

                                                                  }
                                                              }}
                                                              onSearch={(e) => {
                                                                  handleInputForQRInfo(e)
                                                              }}

                                                          >
                                                              <Option value="0"><div className="w-100 align-items-center d-flex justify-content-between">Select QR
                                                                  {currentLocationId && currentLocationId != "Select Location"  ? < button className="btn btn-primary virtual-btn"
                                                                      onClick={() => handleVirtualQRdata()}

                                                                  ><img src={AddVirtual}></img>

                                                                      Virtual QR Code</button>
                                                                      :
                                                                      < button className="btn btn-primary virtual-btn"
                                                                          onClick={() => handleVirtualQRdata()}
                                                                          disabled
                                                                      ><img src={AddVirtual}></img>

                                                                      Virtual QR Code</button>}
                                                              </div></Option>
                                                              
                                                              {currentLocationId && currentLocationId != "Select Location" ? selectedQrByLocation &&
                                                                  selectedQrByLocation.map((QRCode) => (
                                                                      <Option
                                                                          key={QRCode.qRcodeId}
                                                                          // value={QRCode.qRcodeId}
                                                                          value={qrInfoSearch === "Number" || QRCode.product_Name == null ? QRCode.qRcodeId : QRCode.product_Name}
                                                                      >
                                                                          <div className="qrDesc content d-flex">
                                                                              {QRCode.product_Name ? <Tooltip title={QRCode.product_Name}>
                                                                                  <h6>{QRCode.product_Name}</h6>
                                                                              </Tooltip>
                                                                                  : <h6>- NA - </h6>}
                                                                              <p>{QRCode.qRcodeId}</p>
                                                                               <Tooltip title={QRCode.description}>
                                                                                  <p>{QRCode.description}</p>
                                                                              </Tooltip> 
                                                                          </div>
                                                                      </Option>
                                                                  ))
                                                                  : ""
                                                              }
                                                          </Select>
                                                      </div>
                                                  </div> :

                                                  <div class="form-group">
                                                      <label>Virtual QR Code</label>
                                                      <input type='text' className="form-control disabled-input" disabled value={currentVirtualqrcode}></input>

                                                      < a className="btn btn-primary mt-3"
                                                          onClick={() => showActualDropdown()}

                                                      >Back To QR</a>

                                                  </div>}
 
                                          </div>


                                          {currentQRCodeId != "Select QR" || currentVirtualqrcode ? <div class="col-md-6">
                        <div class="form-group">
                          <label>Product Name</label>
                          <input type='text' placeholder="Enter product name" maxLength={100} className="form-control" value={productData} onChange={(e) => handleProductName(e)}></input>
                          <span className="d-inline-block w-100 text-right fs12">
                            {countProduct}/100
                          </span>
                        </div>
                                          </div> : ""}
                                         
                      {currentQRCodeId != "Select QR" ? <div >
                        <div class="form-group">
                          <label>QR Description</label>
                          <div class="input-group">
                            <textarea
                              placeholder="Description"
                              value={qrCodeDescription}
                              maxLength={1000}
                              onChange={(e) => {
                                handleDescription(e)
                              }}
                            >
                              {" "}
                            </textarea>
                            <span className="w-100 text-right fs12">
                              {countQr}/1000
                            </span>
                          </div>
                        </div>
                      </div> : ""}
                    </div>
                  </div>
                </div>
                {dataErrorShow ? (
                  <small className="text-danger d-block mt-2">
                    {dataErrorShow.qrcodeErr}
                  </small>
                ) : (
                  ""
                )}
              </div>

              <Modal
                show={modal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog-media"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Add Media</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Select Media Type </label>
                      <select
                        class="form-select mb-0"
                        value={mediaTypeIdToFilter}
                        onChange={(e) => {
                          mediaFilterChangeHndlr(e.target.value);
                        }}
                      >
                        <option selected="" value={-1}>
                          Select Media Type
                        </option>
                        {allMediaTypes &&
                          allMediaTypes.map((m) => (
                            <option key={m.mediaType} value={m.mediaTypeId}>
                              {m.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <div className="col-md-12 ">
                      <div class="table-responsive tableclass ">
                        <InfiniteScroll
                          dataLength={filteredMediaData.length}
                          next={() => {
                            getMediaDataForModal(mediaTypeIdToFilter);
                          }}
                          hasMore={hasMore}
                          loader={<h5>Loading...</h5>}
                          endMessage={
                            <p style={{ textAlign: "center" }}>
                              <b>No More Records</b>
                            </p>
                          }
                          height={400}
                        >
                          <table className="table table-sm table-light table-striped table-hover">
                            <thead className="">
                              <tr>
                                <th className="checkbox-th"></th>
                                <th scope="col">Media</th>
                                <th scope="col" className="text-center">
                                  Action
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {filteredMediaData &&
                                filteredMediaData.length > 0 &&
                                filteredMediaData.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>
                                        <input
                                          type="checkbox"
                                          className="btn btn-checkbox"
                                          name="acs"
                                          key={item.mediaId}
                                          Checked={isChecked}
                                          onChange={() => {
                                            handleCheckMedia(
                                              item.mediaId,
                                              item.filename,
                                              item.mediaType.name
                                            );
                                          }}
                                        ></input>
                                      </td>

                                      <td key={index}>
                                        {item.mediaType.name == "Image" ? (
                                          <div className="imgbox">
                                            <img
                                              className="thumb img"
                                              src={item.filename}
                                            ></img>
                                          </div>
                                        ) : item.mediaType.name == "video" ? (
                                          <ReactPlayer
                                            // url="https://vimeo.com/291715535"
                                            url={item.filename}
                                            width="100px"
                                            height="100px"
                                            controls={true}
                                            playing={false}
                                          />
                                        ) : item.mediaType.name == "pdf" ? (
                                          <Tooltip
                                            title={item.filename.substr(64, 40)}
                                          >
                                            <a
                                              className="pdf-download-btn1 d-flex align-items-center"
                                              target="blank"
                                              href={item.filename}
                                            >
                                              <img src={PDF}></img>
                                              <span className="filename">
                                                {" "}
                                                {item.filename.substr(
                                                  64,
                                                  40
                                                )}{" "}
                                              </span>
                                            </a>
                                          </Tooltip>
                                        ) : item.mediaType.name == "url" ? (
                                          <img src={UrlImage}></img>
                                        ) : (
                                          ""
                                        )}
                                      </td>

                                      <td className="text-center" key={index}>
                                        {item.mediaType.name == "Image" ? (
                                          <div>
                                            <button
                                              className="btn btn-secondary"
                                              onClick={() =>
                                                handleImageShow(item.filename)
                                              }
                                            >
                                              Click to Zoom Image
                                            </button>
                                          </div>
                                        ) : 
                                          item.mediaType.name == "video" ? (
                                            <div>
                                              <button
                                                className="btn btn-secondary"
                                                onClick={() =>
                                                  handleVideoShow(item.filename)
                                                }
                                              >
                                                Preview
                                              </button>
                                            </div>
                                          ) : item.mediaType.name == "pdf" ? (
                                            <a
                                              target="blank"
                                              href={item.filename}
                                            >
                                              <span>
                                                <img
                                                  className="thumb--inner-new"
                                                  src={downloadIcon}
                                                ></img>
                                              </span>
                                            </a>
                                          ) : item.mediaType.name == "url" ? (
                                            <img src={UrlImage}></img>
                                          ) : (
                                            ""
                                          )}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </InfiniteScroll>
                      </div>
                      <div class="row">
                        <div class="d-flex mt-3">
                          <button
                            type="button"
                            class="btn btn-primary"
                            onClick={assignBtnHndlr}
                            disabled={assignBtnDisable}
                          >
                            Assign
                          </button>
                          <button
                            type="button"
                            class="btn btn-bordered ml-3 "
                            onClick={uncheckcheckbox}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

                          {ifAddingQrCode && !currentVirtualqrcode || showQrDropdown
                             ? (
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingEight">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                      style={{ fontSize: 14.5 }}
                    >
                      Link QR Data to Selected QR / Upload
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingEight"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="row mb-2">
                                                  <div class="col-md-6 d-flex">
                                                      {!currentVirtualqrcode || showQrDropdown ?
                                                      <div>
                                                          {" "}
                                                          <input
                                                              type="radio"
                                                              value="show"
                                                              name="content"
                                                              id="show"
                                                              className="mr-2"
                                                              onClick={radioButtonValueChange}
                                                              checked={getRadioButton.selected === "show"}
                                                          />
                                                          <label for="show">Link QR Data</label>
                                                      </div> : 
                                                     ""
                                                  }
                          <div className="ml-4">
                            {" "}
                            <input
                              className="mr-2"
                              type="radio"
                              value="hide"
                              name="content"
                              id="hide"
                              onClick={radioButtonValueChange}
                              checked={getRadioButton.selected === "hide"}
                            />
                            <label for="show">Upload Media</label>{" "}
                          </div>
                                                  </div>
                                                  {getRadioButton.show == "show"   ? (
                          <div className="assign-media col-sm-12 mt-4 row">
                            <div className="col-sm-5">
                              <div class="form-group m-0">
                                <label>Select QR {filteredResults && filteredResults.length}</label>
                                <div class=" d-flex qr-img-select qrTable--select mapping wkmapping ">
                                  <Select
                                    class="form-select mb-0 flex-grow-1 filter-select"
                                    aria-label=".form-select-lg example"
                                    showSearch
                                    value={copyQRCodeId}
                                    style={{ width: 650 }}
                                    onChange={(e) => {
                                      var productFound = allQrDataWithThumbnail.filter((item) => item.product_Name == e)
                                      if (productFound.length > 0) {
                                        setCopyQRCodeId(productFound[0].qRcodeId);
                                      }
                                      else {
                                        setCopyQRCodeId(e);
                                      }
                                    }}
                                    onSearch={(e) => {
                                      handleInputForDropdown(e)
                                    }}
                                  >
                                    <Option value="0">Select QR </Option>
                                    {allQrDataWithThumbnail &&
                                      allQrDataWithThumbnail.map((QRCode, k) =>
                                        QRCode.qRcodeId != currentQRCodeId ? (
                                          <Option
                                            key={QRCode.qRcodeId}
                                            value={searchState === "Number" || QRCode.product_Name == null ? QRCode.qRcodeId : QRCode.product_Name}
                                          >
                                            <div className="d-flex gap20 align-items-center">
                                              <div className="avatar-img">
                                                {QRCode.filename ?
                                                  <>
                                                    < img className="img-mapping" src={QRCode.filename}></img>{QRCode.favorites == true ? <img className="favorite_star" src={favoriteIcon}></img> : ""}
                                                  </>
                                                  :
                                                  <>
                                                    < img className="img-mapping" src={LoginImage}></img>{QRCode.favorites == true ? <img className="favorite_star" src={favoriteIcon}></img> : ""}
                                                  </>}
                                              </div>
                                              <div className="qrDesc content d-flex">
                                                {QRCode.product_Name ? <Tooltip title={QRCode.product_Name}>
                                                  <h6>{QRCode.product_Name}</h6>
                                                </Tooltip>
                                                  : <h6>- NA - </h6>}
                                                <p>{QRCode.qRcodeId}</p>
                                                <Tooltip title={QRCode.qR_Description}>
                                                  <p>{QRCode.qR_Description}</p>
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </Option>
                                        ) : null
                                      )}
                                  </Select>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 d-flex align-items-end mt-sm-0 mt-2">
                                                              {currentQRCodeId && currentQRCodeId != "Select QR" && copyQRCodeId && copyQRCodeId != "Select QR" ? <button

                                className="btn btn-primary d-flex align-items-center"
                                onClick={() => handleCopyQRdata(copyQRCodeId)}
                              >
                                Link & Save
                              </button> : <button

                                className="btn btn-primary"
                                onClick={() => handleCopyQRdata(copyQRCodeId)} disabled
                              >
                                Link & Save
                              </button>}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="UploadMedia">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        //class="accordion-button collapsed"
                        class={updateSection.activeAccordianDevice ? "accordion-button collapsed errorStyle" : "accordion-button collapsed"}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        style={{ fontSize: 14.5 }}
                      >
                        Device Detail
                        {updateSection.activeAccordianDevice ? <ErrorOutlineOutlinedIcon className="submitError" /> : ""}
                      </button>
                    </h2>
                    <div
                      id="collapseThree"                     
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row mb-2">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>
                                Select Device Type{" "}
                                <span className="required-fields"> * </span>{" "}
                              </label>
                              <div class="input-group">
                                <select
                                  class="form-select mb-0  filter-select"
                                  aria-label=".form-select-lg example"
                                  value={currentDeviceTypeId}
                                  onChange={(e) => {
                                    setChangeCount(changeCount + 1);
                                    getDeviceDetails(e);
                                    setCurrentDeviceTypeId(e.target.value);
                                    let error = { ...dataErrorShow };
                                    if (e.target.value != "Select Device") {                                      
                                      setUpdateSection((prev) => ({ ...prev, activeAccordianDevice: false }))
                                    } else {                                     
                                      setUpdateSection((prev) => ({ ...prev, activeAccordianDevice: true }))
                                    }
                                    setDataErrorShow(error);
                                  }}
                                >
                                  <option selected="">Select Device</option>
                                  {allDeviceTypes &&
                                    allDeviceTypes.map(
                                      (d) =>
                                            !d.isDeleted && d.active && (
                                          <option
                                            key={d.deviceTypeId}
                                            value={d.deviceTypeId}
                                          >
                                            {" "}
                                            {d.name}{" "}
                                          </option>
                                        )
                                    )}
                                </select>
                              </div>                             
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Device Description</label>
                              <div class="input-group">
                                <textarea
                                  placeholder="Description"
                                  value={deviceDescription}
                                                                      onChange={(e) => {
                                                                          
                                    setDeviceDescription(e.target.value),
                                      setCountDevice(e.target.value.length),
                                      setChangeCount(changeCount + 1);
                                  }}
                                ></textarea>
                                <span className="w-100 text-right fs12">
                                  {countDevice}/1000
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {dataErrorShow ? (
                      <small className="text-danger d-block mt-2">
                        {dataErrorShow.deviceErr}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button
                        //class="accordion-button collapsed"
                        class={updateSection.activeAccordianArea ? "accordion-button collapsed errorStyle" : "accordion-button collapsed"}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                        style={{ fontSize: 14.5 }}
                      >
                        Area Details
                        {updateSection.activeAccordianArea ? <ErrorOutlineOutlinedIcon className="submitError" /> : ""}
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse "
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row mb-2">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>
                                Select Area Type{" "}
                                <span className="required-fields"> * </span>{" "}
                              </label>
                              <div class="input-group">
                                <select
                                  class="form-select mb-0  filter-select"
                                  aria-label=".form-select-lg example"
                                  value={currentAreaTypeId}
                                  onChange={(e) => {
                                    setChangeCount(changeCount + 1);
                                    getAreaDetails(e);
                                    setCurrentAreaTypeId(e.target.value);
                                    if (e.target.value != "Select Area") {
                                      setUpdateSection((prev) => ({ ...prev, activeAccordianArea: false }))
                                    } else {
                                      setUpdateSection((prev) => ({ ...prev, activeAccordianArea: true }))
                                    }
                                    setDataErrorShow(error);
                                  }}
                                >
                                  <option selected="">Select Area</option>
                                  {allAreaTypes &&
                                    allAreaTypes.map(
                                      (a) =>
                                        !a.isDeleted && a.active && (
                                          <option
                                            key={a.areaTypeId}
                                            value={a.areaTypeId}
                                          >
                                            {" "}
                                            {a.name}{" "}
                                          </option>
                                        )
                                    )}
                                </select>
                              </div>                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Area Description</label>
                              <div class="input-group">
                                <textarea
                                  placeholder="Description"
                                  value={areaDescription}
                                  onChange={(e) => {
                                    setAreaDescription(e.target.value),
                                      setCountArea(e.target.value.length),
                                      setChangeCount(changeCount + 1);
                                  }}
                                ></textarea>
                                <span className="w-100 text-right fs12">
                                  {countArea}/1000
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {dataErrorShow ? (
                      <small className="text-danger d-block mt-2">
                        {dataErrorShow.areaErr}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        //class="accordion-button collapsed"
                        class={updateSection.activeAccordianGuides ? "accordion-button collapsed errorStyle" : "accordion-button collapsed"}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                        style={{ fontSize: 14.5 }}
                      >
                        Guide Details
                        {updateSection.activeAccordianGuides ? <ErrorOutlineOutlinedIcon className="submitError" /> : ""}
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row mb-2">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>
                                Select Guide Type{" "}
                                <span className="required-fields"> * </span>{" "}
                              </label>
                              <div class="input-group">
                                <select
                                  class="form-select mb-0  filter-select"
                                  aria-label=".form-select-lg example"
                                  value={currentGuideTypeId}
                                  onChange={(e) => {
                                    setChangeCount(changeCount + 1);
                                    getGuideDetails(e);
                                    setCurrentGuideTypeId(e.target.value);
                                    let error = { ...dataErrorShow };
                                    if (e.target.value != "Select Guide") {
                                      setUpdateSection((prev) => ({ ...prev, activeAccordianGuides: false }))
                                    } else {
                                      setUpdateSection((prev) => ({ ...prev, activeAccordianGuides: true }))
                                    }
                                    setDataErrorShow(error);
                                  }}
                                >
                                  <option selected="">Select Guide</option>
                                  {allGuideTypes &&
                                    allGuideTypes.map(
                                      (g) =>
                                            !g.isDeleted && g.active && (
                                          <option
                                            key={g.guideTypeId}
                                            value={g.guideTypeId}
                                          >
                                            {" "}
                                            {g.name}{" "}
                                          </option>
                                        )
                                    )}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Guide Description</label>
                              <div class="input-group">
                                <textarea
                                  placeholder="Description"
                                  value={guideDescription}
                                  onChange={(e) => {
                                    setGuideDescription(e.target.value),
                                      setCountGuide(e.target.value.length),
                                      setChangeCount(changeCount + 1);
                                  }}
                                ></textarea>
                                <span className="w-100 text-right fs12">
                                  {countGuide}/1000
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {dataErrorShow ? (
                      <small className="text-danger d-block mt-2">
                        {dataErrorShow.guideErr}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>

                                      {ifAddingQrCode || checkedListOfMedia.length > 0 || currentVirtualqrcode ? (
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingSix">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                          style={{ fontSize: 14.5 }}
                        >
                          Upload Media
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                                                  <label>Select Media
                                                                      <span className="required-fields"> * </span>{" "}
                                                                  </label>
                                <div class="input-group">
                                  <select
                                    class="form-select mb-0  filter-select"
                                    aria-label=".form-select-lg example"
                                    value={currentMediaTypeId}
                                    onChange={(e) => {
                                      getMediaDetails(e);
                                      setCurrentMediaTypeId(e.target.value);
                                      let error = { ...dataErrorShow };                                     
                                        if (
                                            e.target.value != "Select Media"  &&
                                        checkedListOfMedia.length < 1
                                      ) {
                                        error.mediaErr = "";
                                      } else if (
                                        e.target.value == "Select Media" &&
                                        checkedListOfMedia.length >= 1
                                      ) {
                                        setConditionalStyleUpload(false)
                                      } else {
                                        setConditionalStyleUpload(true)
                                      }
                                      setDataErrorShow(error);
                                    }}
                                  >
                                    <option selected="">Select Media</option>
                                    {allMediaTypes &&
                                      allMediaTypes.map((mediaTypes) => (
                                        <option
                                          key={mediaTypes.mediaTypeId}
                                          value={mediaTypes.mediaTypeId}
                                        >
                                          {mediaTypes.name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                              <div class="addMedia">
                                {currentMediaTypeKey != null ? (
                                  <div>{selectMedia(currentMediaTypeKey)}</div>
                                ) : (
                                  ""
                                )}
                                                              </div>
                                                              {
                                                                  <div className="img-section m-0 flex-wrap d-flex align-items-center">
                                                                      {selectedMediaList &&
                                                                          selectedMediaList.map((item) => {
                                                                              return item.mediaTypeName == "Image" ? (
                                                                                  <div className="imgbox">
                                                                                      <span
                                                                                          className="crossBtn"
                                                                                          onClick={() => {
                                                                                              handleRemoveMediaClick(item.mediaId);
                                                                                          }}
                                                                                      >
                                                                                          X
                                                                                      </span>
                                                                                      <img
                                                                                          className="thumb img"
                                                                                          src={item.fileName}
                                                                                      ></img>
                                                                                  </div>
                                                                              ) : item.mediaTypeName == "video" ? (
                                                                                  <div className="imgbox">
                                                                                      <span
                                                                                          className="crossBtn"
                                                                                          onClick={() => {
                                                                                              handleRemoveMediaClick(item.mediaId);
                                                                                          }}
                                                                                      >
                                                                                          X
                                                                                      </span>
                                                                                      <ReactPlayer
                                                                                          // url="https://vimeo.com/291715535"
                                                                                          url={item.fileName}
                                                                                          width="100px"
                                                                                          height="100px"
                                                                                          controls={true}
                                                                                          playing={false}
                                                                                      />
                                                                                  </div>
                                                                              ) : item.mediaTypeName == "pdf" ? (
                                                                                  <div className="imgbox">
                                                                                      <span
                                                                                          className="crossBtn"
                                                                                          onClick={() => {
                                                                                              handleRemoveMediaClick(item.mediaId);
                                                                                          }}
                                                                                      >
                                                                                          X
                                                                                      </span>
                                                                                      <img src={PDF}></img>
                                                                                  </div>
                                                                              ) : item.mediaTypeName == "url" ? (
                                                                                  <img src={UrlImage}></img>
                                                                              ) : (
                                                                                  ""
                                                                              );
                                                                          })}
                                                                  </div>
                                                              }

                                                          </div>
                                                          {mediaKey != "Text" ?

                                                              <div class="col-md-6">
                                                                  <div class="form-group">
                                                                      <label>Media Description</label>
                                                                      <div class="input-group">
                                                                          <textarea
                                                                              placeholder="Add Description"
                                                                              value={mediaDesc}
                                                                              onChange={(e) => {
                                                                                  /*  getMediaDetails(e)*/
                                                                                  setMediaDesc(e.target.value);
                                                                                  setCountMedia(e.target.value.length);
                                                                                  setChangeCount(changeCount + 1);
                                                                              }}
                                                                          ></textarea>
                                                                          <span className="w-100 text-right fs12">
                                                                              {countMedia}/1000
                                                                          </span>
                                                                      </div>
                                                                      {!currentVirtualqrcode ? 
                                                                          <div class="addMedia1 text-right">
                                                                              <a
                                                                                  className="preview_Link"
                                                                                  onClick={(e) => {
                                                                                      handleBtnClick();
                                                                                      //  getMediaDataForModal(e)
                                                                                      setMediaTypeIdToFilter(1);
                                                                                      getMediaDataForModal(1, true);
                                                                                  }}
                                                                              >
                                                                                  Choose Existing Media
                                                                              </a>
                                                                          </div> :

                                                                          ""

                                                                      }
                                                                  </div>
                                                              </div> : ""
                                                          }
                          </div>
                         
                        </div>
                      </div>
                      {dataErrorShow ? (
                        <small className="text-danger d-block mt-2">
                          {dataErrorShow.mediaErr}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingSix">
                        <button
                          //class="accordion-button collapsed"
                          class={updateSection.activeAccordianUpload || uploadMedia.image || uploadMedia.video || uploadMedia.pdf || uploadMedia.url ? "accordion-button collapsed errorStyle" : "accordion-button collapsed"}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="true"
                          aria-controls="collapseSix"
                          style={{ fontSize: 14.5 }}
                        >
                          Upload Media
                          {updateSection.activeAccordianUpload ? <ErrorOutlineOutlinedIcon className="submitError" /> : ""}
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>
                                  Select Media{" "}
                                  <span className="required-fields"> * </span>{" "}
                                </label>
                                <div class="input-group">
                                  <select
                                    class="form-select mb-0  filter-select"
                                    aria-label=".form-select-lg example"
                                    value={currentMediaTypeId}
                                    onChange={(e) => {
                                      setChangeCount(changeCount + 1);
                                      setCurrentMediaTypeId(e.target.value)
                                      // getMediaDetails(e);
                                      getMediaDetailsUpload(e)
                                      if (e.target.value != "Select Media") {
                                          setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: true }))
                                         // setConditionalStyleUpload(false)
                                      }
                                      else {
                                          setUpdateSection((prev) => ({ ...prev, activeAccordianUpload: true }))
                                          //setConditionalStyleUpload(true)
                                      }
                                    }}
                                  >
                                    <option selected="">Select Media</option>
                                    {allMediaTypes &&
                                      allMediaTypes.map((mediaTypes) => (
                                        <option
                                          key={mediaTypes.mediaTypeId}
                                          value={mediaTypes.mediaTypeId}
                                        >
                                          {mediaTypes.name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                              <div class="addMedia">
                                {currentMediaTypeKey != null && currentMediaTypeKey != undefined && currentMediaTypeId != "Select Media" ? (
                                  <div>{selectMedia(currentMediaTypeKey)}</div>
                                ) : (" ")}
                              </div>
                            </div>

                            {
                              mediaKey != "Text" ?

                              <div class="col-md-6">
                              <div class="form-group">
                                <label>Media Description</label>
                                <div class="input-group">
                                  <textarea
                                    placeholder="Add Description"
                                    value={mediaDesc}
                                    onChange={(e) => {
                                      setMediaDesc(e.target.value);
                                      setCountMedia(e.target.value.length);
                                      setChangeCount(changeCount + 1);
                                    }}
                                  ></textarea>
                                  <span className="w-100 text-right fs12">
                                    {countMedia}/1000
                                  </span>
                                </div>
                              </div>
                            </div>:
                            ""
                            }
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSeven">
                      <button
                        class={updateSection.activeAccordianHeader ? "accordion-button collapsed errorStyle" : "accordion-button collapsed"}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                        style={{ fontSize: 14.5 }}
                      >
                        Header & Priority
                        {updateSection.activeAccordianHeader ? <ErrorOutlineOutlinedIcon className="submitError" /> : ""}
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>
                                Select Priorty{" "}
                                <span className="required-fields"> * </span>
                              </label>
                              <div class="input-group">
                                <select
                                  class="form-select mb-0  filter-select"
                                  aria-label=".form-select-lg example"
                                  value={priority}
                                                                      onChange={(e) => {
                                                                          
                                                                          
                                    setChangeCount(changeCount + 1);
                                    setPriority(e.target.value);
                                    let error = { ...dataErrorShow };

                                      if (e.target.value != "Select Priorty") {
                                          
                                        setUpdateSection((prev) => ({ ...prev, activeAccordianHeader: false }))
                                        setConditionalStylePriority(false)
                                       
                                    } else {
                                       
                                          setConditionalStylePriority(false)
                                         // setUpdateSection((prev) => ({ ...prev, activeAccordianHeader: true }))
                                    }
                                    setDataErrorShow(error);
                                  }}
                                >
                                  <option selected="">Select Priorty</option>
                                  {priorityList &&
                                    priorityList.map((p, index) => (
                                      <option key={index} value={p.value}>
                                        {p.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                                                      </div>

                                                      <div class="col-md-6">
                                                          <div class="form-group">
                                                              <label>
                                                                  Add Header{" "}
                                                                
                                                              </label>
                                                              <div class="input-group">
                                                                  <textarea
                                                                      placeholder="Add Header"
                                                                      value={header}
                                                                      maxLength="200"
                                                                      onChange={(e) => {
                                                                          handleHeaderOnChange(e);
                                                                      }}
                                                                  ></textarea>{" "}
                                                                  <span className="w-100 text-right">
                                                                      {countText}/200
                                                                  </span>
                                                              </div>
                                                          </div>
                                                          {dataErrorShow ? (
                                                              <small className="text-danger d-block mt-2">
                                                                  {dataErrorShow.headerErr}
                                                              </small>
                                                          ) : (
                                                              ""
                                                          )}
                                                      </div>                                                 
                        </div>
                        <div class="row">
                          
                        </div>
                      </div>
                    </div>
                    {dataErrorShow ? (
                      <small className="text-danger d-block mt-2">
                        {dataErrorShow.priorityErr}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="row">
                                          <div class="col-sm-12 mt-2">                                             
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={(e) => saveMediaType(e)}
                        >
                         Save
                        </button>                      
                      <Link className="btn btn-bordered ms-2" to="/QrReview">
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              )}

              {getRadioButton.show == "hide" ? (
                <div className="UploadMedia">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class={conditionalStyleDevice ? "accordion-button collapsed errorStyle" : "accordion-button collapsed"}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        style={{ fontSize: 14.5 }}
                      >
                        Device Detail
                        {conditionalStyleDevice ? <ErrorOutlineOutlinedIcon className="submitError" /> : ""}
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row mb-2">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>
                                Select Device Type{" "}
                                <span className="required-fields"> * </span>{" "}
                              </label>
                              <div class="input-group">
                                <select
                                  class="form-select mb-0  filter-select"
                                  aria-label=".form-select-lg example"
                                  value={currentDeviceTypeId}
                                  onChange={(e) => {
                                    setChangeCount(changeCount + 1);
                                    getDeviceDetails(e);
                                    setCurrentDeviceTypeId(e.target.value);
                                    let error = { ...dataErrorShow };
                                    if (e.target.value != "Select Device") {
                                      error.deviceErr = "";
                                      setConditionalStyleDevice(false)
                                    } else {
                                      setConditionalStyleDevice(true)
                                    }
                                    setDataErrorShow(error);
                                  }}
                                >
                                  <option selected="">Select Device</option>
                                  {allDeviceTypes &&
                                    allDeviceTypes.map(
                                      (d) =>
                                        d.active && (
                                          <option
                                            key={d.deviceTypeId}
                                            value={d.deviceTypeId}
                                          >
                                            {" "}
                                            {d.name}{" "}
                                          </option>
                                        )
                                    )}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Device Description</label>
                              <div class="input-group">
                                <textarea
                                  placeholder="Description"
                                  value={deviceDescription}
                                                                  onChange={(e) => {
                                                                      
                                    setDeviceDescription(e.target.value),
                                      setCountDevice(e.target.value.length),
                                      setChangeCount(changeCount + 1);
                                  }}
                                ></textarea>
                                <span className="w-100 text-right fs12">
                                  {countDevice}/1000
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {dataErrorShow ? (
                      <small className="text-danger d-block mt-2">
                        {dataErrorShow.deviceErr}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button
                        class={conditionalStyleArea ? "accordion-button collapsed errorStyle" : "accordion-button collapsed"}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                        style={{ fontSize: 14.5 }}
                      >
                        Area Detail
                        {conditionalStyleArea ? <ErrorOutlineOutlinedIcon className="submitError" /> : ""}
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row mb-2">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>
                                Select Area Type{" "}
                                <span className="required-fields"> * </span>{" "}
                              </label>
                              <div class="input-group">
                                <select
                                  class="form-select mb-0  filter-select"
                                  aria-label=".form-select-lg example"
                                  value={currentAreaTypeId}
                                  onChange={(e) => {
                                    setChangeCount(changeCount + 1);
                                    getAreaDetails(e);
                                    setCurrentAreaTypeId(e.target.value);
                                    let error = { ...dataErrorShow };
                                    if (e.target.value != "Select Area") {
                                      setConditionalStyleArea(false)
                                    } else {
                                      setConditionalStyleArea(true)
                                    }
                                    setDataErrorShow(error);
                                  }}
                                >
                                  <option selected="">Select Area</option>
                                  {allAreaTypes &&
                                    allAreaTypes.map(
                                      (a) =>
                                        a.active && (
                                          <option
                                            key={a.areaTypeId}
                                            value={a.areaTypeId}
                                          >
                                            {" "}
                                            {a.name}{" "}
                                          </option>
                                        )
                                    )}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Area Description</label>
                              <div class="input-group">
                                <textarea
                                  placeholder="Description"
                                  value={areaDescription}
                                  onChange={(e) => {
                                    setAreaDescription(e.target.value),
                                      setCountArea(e.target.value.length),
                                      setChangeCount(changeCount + 1);
                                  }}
                                ></textarea>
                                <span className="w-100 text-right fs12">
                                  {countArea}/1000
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {dataErrorShow ? (
                      <small className="text-danger d-block mt-2">
                        {dataErrorShow.areaErr}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        class={conditionalStyleGuide ? "accordion-button collapsed errorStyle" : "accordion-button collapsed"}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                        style={{ fontSize: 14.5 }}>
                        Guide Detail
                        {conditionalStyleGuide ? <ErrorOutlineOutlinedIcon className="submitError" /> : ""}
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row mb-2">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>
                                Select Guide Type{" "}
                                <span className="required-fields"> * </span>{" "}
                              </label>
                              <div class="input-group">
                                <select
                                  class="form-select mb-0  filter-select"
                                  aria-label=".form-select-lg example"
                                  value={currentGuideTypeId}
                                  onChange={(e) => {
                                    setChangeCount(changeCount + 1);
                                    getGuideDetails(e);
                                    setCurrentGuideTypeId(e.target.value);
                                    let error = { ...dataErrorShow };
                                    if (e.target.value != "Select Guide") {
                                      setConditionalStyleGuide(false)
                                    } else {
                                      setConditionalStyleGuide(true)
                                    }
                                    setDataErrorShow(error);
                                  }}
                                >
                                  <option selected="">Select Guide</option>
                                  {allGuideTypes &&
                                    allGuideTypes.map(
                                      (g) =>
                                        g.active && (
                                          <option
                                            key={g.guideTypeId}
                                            value={g.guideTypeId}
                                          >
                                            {" "}
                                            {g.name}{" "}
                                          </option>)
                                    )}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Guide Description</label>
                              <div class="input-group">
                                <textarea
                                  placeholder="Description"
                                  value={guideDescription}
                                  onChange={(e) => {
                                    setGuideDescription(e.target.value),
                                      setCountGuide(e.target.value.length),
                                      setChangeCount(changeCount + 1);
                                  }}
                                ></textarea>
                                <span className="w-100 text-right fs12">
                                  {countGuide}/1000
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {dataErrorShow ? (
                      <small className="text-danger d-block mt-2">
                        {dataErrorShow.guideErr}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>

                  {ifAddingQrCode ? (
                    <div class="accordion-item" >
                      <h2 class="accordion-header" id="headingSix">
                        <button
                                                  class={ AssignError||conditionalStyleUpload || conditionalStyleImage || conditionalStylePdf || conditionalStyleUrl || conditionalStyleVideo ? "accordion-button collapsed errorStyle" : "accordion-button collapsed"}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                          style={{ fontSize: 14.5 }}
                        >
                          Upload Medias
                          {conditionalStyleUpload || conditionalStyleImage || conditionalStylePdf || conditionalStyleUrl || conditionalStyleVideo || AssignError? <ErrorOutlineOutlinedIcon className="submitError" /> : ""}
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                                              <label>Select Media
                                                                  <span className="required-fields"> * </span>{" "}
                                                              </label>
                                <div class="input-group">
                                  <select
                                    class="form-select mb-0  filter-select"
                                    aria-label=".form-select-lg example"
                                    value={currentMediaTypeId}
                                    onChange={(e) => {
                                      setCurrentMediaTypeId(e.target.value === "Select Media" ? 0 : e.target.value);
                                      getMediaDetails(e);
                                      let error = { ...dataErrorShow };
                                      if (e.target.value != "Select Media" && checkedListOfMedia.length < 1) {
                                        setConditionalStyleUpload(true)
                                      }
                                      else {
                                        setCurrentMediaTypeId("Select Media")
                                          setConditionalStyleUpload(true)
                                          setConditionalStylePriority(true)
                                          setConditionalStyleHeader(true)
                                      }
                                      setDataErrorShow(error);
                                    }}
                                  >
                                    <option selected="">Select Media</option>
                                    {allMediaTypes &&
                                      allMediaTypes.map((mediaTypes) => (
                                        <option
                                          key={mediaTypes.mediaTypeId}
                                          value={mediaTypes.mediaTypeId}
                                        >
                                          {mediaTypes.name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                              <div class="addMedia">
                                {currentMediaTypeKey != null && currentMediaTypeId != "Select Media" ? (
                                  <div>{selectMedia(currentMediaTypeKey)}</div>
                                ) : ("")}
                                                          </div>
                                                          {<div className="img-section mb-3 ml-0 m-md-0 mt-0 mr-0 flex-wrap d-flex align-items-center">
                                                              {selectedMediaList &&
                                                                  selectedMediaList.map((item) => {
                                                                      

                                                                      return item.mediaTypeName == "Image" ? (
                                                                          <div className="imgbox">
                                                                              <span
                                                                                  className="crossBtn"
                                                                                  onClick={() => {
                                                                                      handleRemoveMediaClick(item.mediaId);
                                                                                  }}
                                                                              >
                                                                                  X
                                                                              </span>
                                                                              <img
                                                                                  className="thumb img"
                                                                                  src={item.fileName}
                                                                              ></img>
                                                                          </div>
                                                                      ) : item.mediaTypeName.includes('Text') ? (
                                                                          <div>
                                                                              <input type="text"></input>
                                                                          </div>
                                                                      )
                                                                          : item.mediaTypeName == "video" ? (
                                                                              <div className="imgbox">
                                                                                  <span
                                                                                      className="crossBtn"
                                                                                      onClick={() => {
                                                                                          handleRemoveMediaClick(item.mediaId);
                                                                                      }}
                                                                                  >
                                                                                      X
                                                                                  </span>
                                                                                  <ReactPlayer
                                                                                      url={item.fileName}
                                                                                      width="100px"
                                                                                      height="100px"
                                                                                      controls={true}
                                                                                      playing={false}
                                                                                  />
                                                                              </div>
                                                                          ) : item.mediaTypeName == "pdf" ? (
                                                                              <div className="imgbox">
                                                                                  <span
                                                                                      className="crossBtn"
                                                                                      onClick={() => {
                                                                                          handleRemoveMediaClick(item.mediaId);
                                                                                      }}
                                                                                  >
                                                                                      X
                                                                                  </span>
                                                                                  <img src={PDF}></img>
                                                                              </div>
                                                                          ) : item.mediaTypeName == "url" ? (
                                                                              <img src={UrlImage}></img>
                                                                          ) : (
                                                                              ""
                                                                          );
                                                                  })}
                                                          </div>
                                                          }

                                                      </div>
                                                    
                                                      {mediaKey != "Text" ?
                                                          <div class="col-md-6">
                                                              <div class="form-group">
                                                                  <label>Media Description</label>
                                                                  <div class="input-group">
                                                                      <textarea
                                                                          placeholder="Add Description"
                                                                          value={mediaDesc}
                                                                          onChange={(e) => {
                                                                              getMediaDetails(e)
                                                                              setMediaDesc(e.target.value);
                                                                              setCountMedia(e.target.value.length);
                                                                              setChangeCount(changeCount + 1);
                                                                          }}
                                                                      ></textarea>
                                                                      <span className="w-100 text-right fs12">
                                                                          {countMedia}/1000
                                                                      </span>
                                                                  </div>
                                                                  <div class="addMedia1 text-right">
                                                                      <a
                                                                          className="preview_Link"
                                                                          onClick={(e) => {
                                                                              handleBtnClick();
                                                                              setMediaTypeIdToFilter(1);
                                                                              getMediaDataForModal(1, true);
                                                                          }}
                                                                      >
                                                                          Choose Existing Media
                                                                      </a>
                                                                  </div>
                                                              </div>
                                                          </div> : ""
                                                      }
                          </div>
                        
                        </div>
                      </div>
                      {dataErrorShow ? (
                        <small className="text-danger d-block mt-2">
                          {dataErrorShow.mediaErr}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingSix">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="true"
                          aria-controls="collapseSix"
                          style={{ fontSize: 14.5 }}
                        >
                          Upload Media
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>
                                  Select Media{" "}
                                  <span className="required-fields"> * </span>{" "}
                                </label>
                                <div class="input-group">
                                  <select
                                    class="form-select mb-0  filter-select"
                                    aria-label=".form-select-lg example"
                                    value={currentMediaTypeId}
                                    onChange={(e) => {
                                      setChangeCount(changeCount + 1);
                                      getMediaDetails(e);
                                    }}
                                  >
                                    <option selected="">Select Media</option>
                                    {allMediaTypes &&
                                      allMediaTypes.map((mediaTypes) => (
                                        <option
                                          key={mediaTypes.mediaTypeId}
                                          value={mediaTypes.mediaTypeId}
                                        >
                                          {mediaTypes.name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                              <div class="addMedia">
                                {currentMediaTypeKey != null ? (
                                  <div>{selectMedia(currentMediaTypeKey)}</div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                                                          {mediaKey != "Text" ?
                                                              <div class="col-md-6">
                                                                  <div class="form-group">
                                                                      <label>Media Description</label>
                                                                      <div class="input-group">
                                                                          <textarea
                                                                              placeholder="Add Description"
                                                                              value={mediaDesc}
                                                                              onChange={(e) => {
                                                                                  setMediaDesc(e.target.value);
                                                                                  setCountMedia(e.target.value.length);
                                                                                  setChangeCount(changeCount + 1);
                                                                              }}
                                                                          ></textarea>
                                                                          <span className="w-100 text-right fs12">
                                                                              {countMedia}/1000
                                                                          </span>
                                                                      </div>
                                                                  </div>
                                                              </div> : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSeven">
                      <button
                        class={conditionalStylePriority ? "accordion-button collapsed errorStyle" : "accordion-button collapsed"}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                        style={{ fontSize: 14.5 }}
                      >
                        Header & Priorty
                                              {conditionalStylePriority  ? <ErrorOutlineOutlinedIcon className="submitError" /> : ""}
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>
                                Select Priorty{" "}
                                <span className="required-fields"> * </span>
                              </label>
                              <div class="input-group">
                                <select
                                  class="form-select mb-0  filter-select"
                                  aria-label=".form-select-lg example"
                                  value={priority}
                                  onChange={(e) => { 
                                    setChangeCount(changeCount + 1);
                                      setPriority(e.target.value);
                                     
                                    let error = { ...dataErrorShow };

                                      if (e.target.value != "Select Priorty" ) {
                                          
                                          setUpdateSection((prev) => ({ ...prev, activeAccordianHeader: false }))
                                        setConditionalStylePriority(false)
                                        
                                      } else {
                                         
                                          setConditionalStylePriority(true)
                                         // setUpdateSection((prev) => ({ ...prev, activeAccordianHeader: true }))
                                    }
                                    setDataErrorShow(error);
                                  }}
                                >
                                  <option selected="">Select Priorty</option>
                                  {priorityList &&
                                    priorityList.map((p, index) => (
                                      <option key={index} value={p.value}>
                                        {p.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                                                  </div>

                                                  <div class="col-md-6">
                                                      <div class="form-group">
                                                          <label>
                                                              Add Header{" "}
                                                             
                                                          </label>
                                                          <div class="input-group">
                                                              <textarea
                                                                  placeholder="Add Header"
                                                                  value={header}
                                                                  maxLength="200"
                                                                  onChange={(e) => {
                                                                      handleHeaderOnChange(e);
                                                                  }}
                                                              ></textarea>{" "}
                                                              <span className="w-100 text-right">
                                                                  {countText}/200
                                                              </span>
                                                          </div>
                                                      </div>
                                                      {dataErrorShow ? (
                                                          <small className="text-danger d-block mt-2">
                                                              {dataErrorShow.headerErr}
                                                          </small>
                                                      ) : (
                                                          ""
                                                      )}
                                                  </div>
                          
                        </div>
                       
                      </div>
                    </div>
                    {dataErrorShow ? (
                      <small className="text-danger d-block mt-2">
                        {dataErrorShow.priorityErr}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>

                  <div class="row">
                                      <div class="col-sm-12 mt-2">
                                        
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={(e) => saveMediaType(e)}
                        >
                         Save
                        </button>
                      
                      
                      <Link className="btn btn-bordered ms-2" to="/QrReview">
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Layout>

      <Modal
        show={showmodal}
        onHide={handleImageClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-dialog-zoom"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <div className="img-section m-0 flex-wrap d-flex align-items-center">
              <img className=" w-100" src={zoomFilename}></img>
            </div>
          }
        </Modal.Body>
      </Modal>

      <Modal
        show={videoModal}
        onHide={handleVideoClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-dialog-video"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <div className="img-section m-0 flex-wrap d-flex align-items-center">
              <ReactPlayer
                url={zoomVideo}
                width="600px"
                height="600px"
                controls={true}
                playing={false}
              />
            </div>
          }
        </Modal.Body>
      </Modal>

      <Modal
        show={copyModal}
        onHide={handleCopyModalClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-dialog-copy"
      >
        <Modal.Body>
          <div class="copy-model">
            <h5> Are you sure you want to link QR code?</h5>
            <div class="w-100 mt-3 btn-yesno">
              <button
                type="button"
                class="btn btn-primary"
                              onClick={copyMediaData}
              >
                Yes
              </button>
              <button
                type="button"
                class="btn btn-primary ml-2"
                onClick={handleCopyModalClose}
              >
                No
              </button>
            </div>
          </div>
        </Modal.Body>
          </Modal>

          <Modal
              show={virtualModal}
              onHide={handleVirtualModalClose}
              backdrop="static"
              keyboard={false}
              dialogClassName="modal-dialog-copy"
          >
              <Modal.Body>
                  <div class="copy-model">
                      <h5> Are you sure you want to Create Virtual QR code?</h5>
                      <div class="w-100 mt-3 btn-yesno">
                          <button
                              type="button"
                              class="btn btn-primary"
                              onClick={createVirtualQRCode}
                          >
                              Yes
                          </button>
                          <button
                              type="button"
                              class="btn btn-primary ml-2"
                              onClick={handleVirtualModalClose}
                          >
                              No
                          </button>
                      </div>
                  </div>
              </Modal.Body>
          </Modal>
    </div>
  );
};
export default connect()(Add);
