
import { UserRoles } from "../Constants/userRoles";

//get UserTypeData
export const setUserRoles = (userRole) => {
    return {
        type:UserRoles.SET_USERROLES,
        payload: userRole
    };
};
