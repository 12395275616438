
import { QRDetails } from "../Constants/QrDetails";
// initial state
const initialState = {
    qrDetails: []
}

export const QrDetailReducers = (state = initialState, { type, payload }) => {
    switch (type) {
       
        case QRDetails.GET_QRDATADETAILS:
            return { ...state, qrDetails: payload.data };

        //Delete QrType
        case QRDetails.DELETE_SELECTED_QRTYPE:
            return { ...state, qrDetails: payload.data };

        // Delete Multiple QrType
        case QRDetails.DELETE_MULTIPLE_QRTYPE:
            return { ...state, qrDetails: payload.data };

            
        default:
            return state;
          
    }
};