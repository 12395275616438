import { CompanyLocationMapping } from "../Constants/CompanyLocationMapping";
export const setCompanyLocationMapping = (CompanyLocationMappingData) => {
    return {
        type: CompanyLocationMapping.SET_COMPANYLOCATIONMAPPING,
        payload: CompanyLocationMappingData
    };
};

export const verifyNotMappedLocation = (CompanyLocationMappingData) => {
    return {
        type: CompanyLocationMapping.GET_NOTMAPPEDLOCATION,
        payload: CompanyLocationMappingData
    };
};
