import { CompanyDetails } from "../Constants/Company";


//get CompanyData
export const SetCompanyDetails = (company) => {
    return {
        type:CompanyDetails.SET_COMPANYDETAILS,
        payload: company
    };
};
//Edit
export const setSelectedCompanydDetails = (company) => {
    return {
        type: CompanyDetails.SELECTED_COMPANYDETAILS,
        payload: company
    };
};

//Update Company Data
export const updateCompanyData = (company) => {
    return {
        type:CompanyDetails.UPDATE_COMPANYDETAILS,
        payload: company
    };
};

//#Developer
export const addCompanyData = (company) => {
    return {
        type: CompanyDetails.ADD_COMPANYDATA,
        payload: company
    };
};

//Delete Company Data
export const deleteCompanyTypeData = (company) => {
    return {
        type: CompanyDetails.DELETE_COMPANYDATA,
        payload: company
    };
};
