import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { connect } from 'react-redux';
import Loader from "../loader";
import Table, { TableProps } from "antd/lib/table";
import { Select } from 'antd';
import '../../assets/css/style.css'
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import Layout from '../Layout';
import '../../../src/custom.css'
import axios from "axios"
import { QrReader } from "react-qr-reader";
import { fetchAllQrId, AddQRCodes, VerifyQrStatus } from '../../Redux/Api/fetch.api';
import { addQrCodes } from "../../Redux/Actions/QrCodeId";

const AddQREntries = () => {
    
    const [qrError, setQRError] = useState('');
    const [qrCodeError, setQRCodeError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [data, setData] = useState('No result');
    const [id, setId] = useState();
    const [loading, setLoading] = useState(false);
    const history = useHistory()
    const dispatch = useDispatch();
    const handleError = (err) => {
        setData({ result: "Device camera not found", qrError: err.toString() })
    }
    const onResult = (result, error) => {

        if (result) {

            setData(result.text);
            var uri = result.text.toString();
            if (uri.indexOf("/") > 0) { // Example : https://www.tekahelp.com/10000004
                var qrCode = uri.substring(uri.lastIndexOf('/') + 1);
                console.log("uri", qrCode);
                if (qrCode) {

                    fetchAllQrId(qrCode)
                        .then(response => {

                            if (response.data.success) {
                                setId(qrCode);
                               // history.push("/" + qrCode);
                            }
                            else {
                                setErrorMessage("QR Code is not valid, please try again.");
                            }
                        })
                        .catch((err) => {
                            console.log("Err: ", err);
                            setErrorMessage("QR Code is not valid, please try again.");
                        });
                }
                else {

                    setErrorMessage("QR Code is not valid, please try again.");
                }
            }
            else {
                setErrorMessage("QR Code is not valid, please try again.");

            }
        }

    }

    const addQRCode = (id) => {
        debugger;
        if (id) {
            setLoading(true)
            AddQRCodes(id)
                .then(response => {
                    debugger;
                    dispatch(addQrCodes(response.data))
                    if (!response.data.success) // False 
                    {
                        toast.error(response.data.message, {
                            className: "toastSuccess",
                        });

                    }
                    else {
                        toast.success(response.data.message, {
                            className: "toastSuccess",
                        })
                        history.push('/QREntries');
                    }
                    setLoading(false)
                })
                .catch((err) => {
                    console.log("Err: ", err);
                });
            setLoading(false)
        }

        setQRCodeError("Please Scan QR Code")
    }
    return (
        <Layout title="QR Entries">
            <Toaster />
            <div id="qr-id">
                {loading ? <Loader /> : ''}
                <div className="card-box-header d-inline-flex w-100 align-items-center justify-content-center">
                    <h4 className="m-0">Scan your QR code</h4>
                </div>
                <div className='QrReader'>
                    <QrReader
                        className="qr--scanner mt-0"
                        delay={300}
                        onError={handleError}
                        constraints={{ facingMode: 'environment' }}
                        style={{ width: '100%' }}
                        onResult={onResult}
                    />

                </div>

                <small className="qR-error-show text-danger d-block mt-2">{qrError}</small>
                {id ? "" : <small className="qR-error-show text-danger text-center d-block mt-2">{qrCodeError}</small>}
                <div className="scanqrcode">
                    {id?<label class="form-label mb-0 d-inline-block"><b>QR Code :</b>{id}</label> : ""}<br/>

                    <button type="button" className="btn btn-outline-primary mt-3" data-bs-dismiss="modal" onClick={(e) => addQRCode(id)}>Add QRCode</button>
            </div>
            </div>

        </Layout>
    )

}
export default AddQREntries;
