import React, { useEffect, useState } from "react"
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';

// Import static images 
import Pipeduotoneline from "../../assets/images/Pipe_duotone_line.svg";
import Bookduotone from "../../assets/images/Book_duotone.svg";
import Databaseduotone from "../../assets/images/Database_duotone.svg";
import Mapping from "../../assets/images/mapping-image.svg";
import Micaltduotone from "../../assets/images/Mic_alt_duotone.svg";

import Folderaltduotonefill from "../../assets/images/Folder_alt_duotone_fill.svg";
import Targetduotoneline from "../../assets/images/Target_duotone_line.svg";
import Arhiveloadduotone from "../../assets/images/Arhive_load_duotone.svg";
import Userscanduotone from "../../assets/images/User_scan_duotone.svg";
import Signinsqurelight from "../../assets/images/Sign_in_squre_light.svg";
import '../HorizontalLayout/Navbar.css'
import Companyduotone from "../../assets/images/company_duotone.svg"
import Userduotone from "../../assets/images/User_duotone.svg"
import qrcodeduotone from "../../assets/images/qrcode_duotone.svg"
import settingduotone from "../../assets/images/settings_duotone.svg"
import locationduotone from "../../assets/images/location_duotone.svg"
import QRupload from "../../assets/images/QRupload_duotone.svg"
import QrReview from "../../assets/images/review_duotone.svg"
import ImageIcon from '@mui/icons-material/Image';
import PersonPinIcon from '@mui/icons-material/PersonPin';
const Navbar = () => {
    
    const history = useHistory();
    const [alwaysShow, toggleAlwaysShow] = useState(0);
    const [alwaysShowLocation, setAlwaysShowLocation] = useState(0)
    const [alwaysShowVirtual, setAlwaysShowVirtual] = useState(0)
    const [alwaysShowSettings, setAlwaysShowsettings] = useState(0)
    const [active, setActive] = useState(true);
    const location = useLocation();
    const qrRelatedLocations = ["/QR", "/QrReview", "/Add/QR", "/QRAssign", "/QREntries"];
    const locationRelated = ["/Location", "/Relation", "/LocationUpdate"];
    const SettingsRelated = ["/AreaType", "/GuideType", "/DeviceType", "/MediaType"];
    const virtualQRRelated = ["/VirtualQRReview"];
    useEffect(() => {
        if (qrRelatedLocations.includes(location.pathname)) {
            toggleAlwaysShow(1)
        } else {
            toggleAlwaysShow(0)
        }
        if (locationRelated.includes(location.pathname)) {
            setAlwaysShowLocation(1)
        }
        else {
            setAlwaysShowLocation(0)
        }
        if (virtualQRRelated.includes(location.pathname)) {
            setAlwaysShowVirtual(1)
        }
        else {
            setAlwaysShowVirtual(0)
        }
        if (SettingsRelated.includes(location.pathname)) {
            setAlwaysShowsettings(1)
        }
        else {
            setAlwaysShowsettings(0)
        }
    }, [location])

    const handleKey = (event) => {
        if (event.keyCode === 13) {
            debugger;
            switch (event.target.innerText) {
                case 'Area Type':
                    return history.push("/AreaType");
                case 'Dashboard':
                    return history.push("/Dashboard")
                case 'User':
                    return history.push("/User")
                case 'Company':
                    return history.push("Company")
                case 'Location':
                    return history.push("Location")
                case 'Device Type':
                    return history.push("DeviceType")
                case 'Location Mapping':
                    return history.push("/Relation")
                case 'Location Update':
                    return history.push("/LocationUpdate")
                case 'Guide Type':
                    return history.push("/GuideType")
                case 'Media Type':
                    return history.push("/MediaType")
                case 'QR':
                    return toggleAlwaysShow(1)

                case 'Settings':
                    return history.push("Settings")
                case 'Upload':
                    return history.push("/QR")
                case 'Review':
                    return history.push("/QrReview")
                case 'Mapping':
                    return history.push("/QRAssign")

                case 'Entries':
                    return history.push("/QREntries")

                //case 'Virtual QR':
                  //  return toggleAlwaysShow(2)
                case 'Review & Assign':
                    return history.push("/VirtualQRReview")
            }
        }
    }

    return (
        <div className="header-menu-container">
            <ul className="header-menu nav">

                <li className="nav-item" tabIndex={1} onKeyDown={(event) => handleKey(event)}>
                    
                    <NavLink to="/Dashboard" exact activeClassName="active" className="nav-link" >
                        <div className="nav-icon" >
                            <img className="nav-icon1" src={Pipeduotoneline}></img>
                        </div>
                        <span>Dashboard</span>
                    </NavLink>
                </li>

                <li className="nav-item" tabIndex={4} onKeyDown={(event) => handleKey(event)}>
                    <NavLink to="/Company" activeClassName="active" className="nav-link">
                        <div className="nav-icon">
                            <img className="nav-icon1" src={Companyduotone}></img>
                        </div>
                        <span>Company</span>
                    </NavLink>
                </li>

                <li className="nav-item" tabIndex={13} onKeyDown={(event) => handleKey(event)}>
                    <div className="accordion accordion-flush wkAccordian" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">

                                    <div className="nav-icon">
                                        <img className="nav-icon1" src={qrcodeduotone}></img>
                                    </div>
                                    <span>QR</span>
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className={["accordion-collapse collapse", alwaysShow ? "show" : ""].join(" ")} aria-labelledby="flush-headingOne" >
                                <div className="accordion-body">
                                    <ul>
                                        <li tabIndex={14}>
                                            <NavLink to="/QR" activeClassName="active" className="nav-link sub-menu ms-4">
                                                <div className="nav-icon">
                                                    <img className="nav-icon1" src={QRupload}></img>
                                                </div>
                                                <span>Upload</span>
                                            </NavLink>
                                        </li>
                                        <li tabIndex={15}>
                                            <NavLink to="/QrReview" activeClassName="active" className="nav-link sub-menu ms-4">
                                                <div className="nav-icon">
                                                    <img className="nav-icon1" src={QrReview}></img>
                                                </div>
                                                <span>Review</span>
                                            </NavLink></li>
                                        <li tabIndex={16}>
                                            <NavLink to="/QRAssign" activeClassName="active" className="nav-link sub-menu ms-4">
                                                <div className="nav-icon text-center">
                                                    <img className="nav-icon1" src={Mapping}></img>
                                                </div>
                                                <span>Mapping</span>
                                            </NavLink>
                                        </li>
                                        <li tabIndex={20}>
                                            <NavLink to="/QREntries" activeClassName="active" className="nav-link sub-menu ms-4">
                                                <div className="nav-icon text-center">
                                                    <img className="nav-icon1" src={qrcodeduotone}></img>
                                                </div>
                                                <span>QR Entries</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

                <li className="nav-item" tabIndex={19} onKeyDown={(event) => handleKey(event)}>
                    <div className="accordion accordion-flush wkAccordian" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingFour">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">

                                    <div className="nav-icon">
                                        <img className="nav-icon1 setting_navbar" src={settingduotone}></img>
                                    </div>
                                    <span>Settings</span>
                                </button>
                            </h2>
                            <div id="flush-collapseFour" className={["accordion-collapse collapse", alwaysShowSettings ? "show" : ""].join(" ")} aria-labelledby="flush-headingFour" >
                                <div className="accordion-body">
                                    <ul>
                                        <li className="nav-item" tabIndex={2} onKeyDown={(event) => handleKey(event)}>
                                            <NavLink to="/AreaType" activeClassName="active" className="nav-link">
                                                <div className="nav-icon">
                                                    <img className="nav-icon1" src={Targetduotoneline}></img>
                                                </div>
                                                <span>Area Type</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item" tabIndex={8} onKeyDown={(event) => handleKey(event)}>
                                            <NavLink to="/DeviceType" activeClassName="active" className="nav-link">
                                                <div className="nav-icon">
                                                    <img className="nav-icon1" src={Databaseduotone}></img>
                                                </div>
                                                <span>Device Type</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item" tabIndex={9} onKeyDown={(event) => handleKey(event)}>
                                            <NavLink to="/GuideType" activeClassName="active" className="nav-link">
                                                <div className="nav-icon">
                                                    <img className="nav-icon1" src={Folderaltduotonefill}></img>
                                                </div>
                                                <span>Guide Type</span>
                                            </NavLink>
                                        </li>

                                        <li className="nav-item" tabIndex={11} onKeyDown={(event) => handleKey(event)}>
                                            <NavLink to="/MediaType" activeClassName="active" className="nav-link">
                                                <div className="nav-icon">
                                                    <img className="nav-icon1" src={Micaltduotone}></img>
                                                </div>
                                                <span>Media Type</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                
                
               
                <li className="nav-item" tabIndex={3} onKeyDown={(event) => handleKey(event)}>
                    <NavLink to="/User" activeClassName="active" className="nav-link">
                        <div className="nav-icon"    >
                            <img className="nav-icon1" src={Userduotone}></img>
                        </div>
                        <span>User</span>
                    </NavLink>
                </li>
              
                <li className="nav-item" tabIndex={10} onKeyDown={(event) => handleKey(event)}>
                    <div className="accordion accordion-flush wkAccordian" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingtwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">

                                    <div className="nav-icon">
                                        <img className="nav-icon1" src={locationduotone}></img>
                                    </div>
                                    <span>Location & Map</span>
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" className={["accordion-collapse collapse", alwaysShowLocation ? "show" : ""].join(" ")} aria-labelledby="flush-headingtwo" >
                                <div className="accordion-body">
                                    <ul>
                                        <li className="nav-item" tabIndex={5} onKeyDown={(event) => handleKey(event)}>
                                            <NavLink to="/Location" activeClassName="active" className="nav-link">
                                                <div className="nav-icon">
                                                    {/* <img className="nav-icon1" src={locationduotone}></img> */}
                                                <PersonPinIcon className="nav-icon1" />
                                                </div>
                                                <span>Location</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item" tabIndex={6} onKeyDown={(event) => handleKey(event)}>
                                            <NavLink to="/Relation" activeClassName="active" className="nav-link">
                                                <div className="nav-icon">
                                                    <img className="nav-icon1" src={qrcodeduotone} ></img>
                                                </div>
                                                <span>Mapping</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item" tabIndex={7} onKeyDown={(event) => handleKey(event)}>
                                            <NavLink to="/LocationUpdate" activeClassName="active" className="nav-link">
                                                <div className="nav-icon">
                                                    <img className="nav-icon1" src={Mapping} ></img>
                                                </div>
                                                <span>Location Update</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

               
               
                

                 <li className="nav-item" tabIndex={12} onKeyDown={(event) => handleKey(event)}>
                    <NavLink to="/thumbnailList" activeClassName="active" className="nav-link">
                        <div className="nav-icon">
                            {/* <img className="nav-icon1" src={locationduotone}></img> */}
                       <ImageIcon className="nav-icon1"/>
                        </div>
                        <span>Thumbnails</span>

                    </NavLink>
                </li>

                

                <li className="nav-item" tabIndex={17} onKeyDown={(event) => handleKey(event)}>
                    <div className="accordion accordion-flush wkAccordian" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingthree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseOne">

                                    <div className="nav-icon">
                                        <img className="nav-icon1" src={Targetduotoneline}></img>
                                    </div>
                                    <span>Virtual QR</span>
                                </button>
                            </h2>
                            <div id="flush-collapseThree" className={["accordion-collapse collapse", alwaysShowVirtual ? "show" : ""].join(" ")} aria-labelledby="flush-headingThree" >
                                <div className="accordion-body">
                                    <ul>
                                        <li tabIndex={18}>
                                            <NavLink to="/VirtualQRReview" activeClassName="active" className="nav-link sub-menu ms-4">
                                                <div className="nav-icon">
                                                    <img className="nav-icon1" src={QrReview}></img>
                                                </div>
                                                <span>Review & Assign</span>
                                            </NavLink>
                                        </li>                                                                              
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default Navbar
