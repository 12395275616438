import { DeviceTypes } from "../Constants/Device-Types"

export const setDeviceTypes = (devictypes) => {
    return {
        type: DeviceTypes.SET_DEVICETYPES,
        payload: devictypes
    };
};

//Add DeviceType Data
export const addDeviceTypeData = (devictypes) => {
    return {
        type: DeviceTypes.ADD_DEVICETYPES,
        payload: devictypes
    };
};

//Update DeviceType Data
export const updateDeviceTypeData = (devictypes) => {
    return {
        type:DeviceTypes.UPDATE_DEVICETYPES,
        payload: devictypes
    };
};

//Delete DeviceType Data
export const deleteDeviceData = (devictypes) => {
    return {
        type:DeviceTypes.DELETE_SELECTED_DEVICETYPES,
        payload: devictypes
    };
};
