import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import Add from "../../assets/images/Add.svg";
import { Select } from 'antd';

import Loader from "../loader";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import Layout from '../Layout';
import * as url from "../../api/uri_helper"
import Table, { TableProps } from "antd/lib/table";
import { fetchAllQrCodes } from '../../Redux/Api/fetch.api';
import { getQrCodeData } from '../../Redux/Actions/QR';
import { Tooltip } from 'antd';


const QREntriesIndex = () => {
    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState([]);
    const dispatch = useDispatch(); 
    const [pageSize, setPageSize] = useState(10);
    const [options, setOptions] = useState({ pagination: { current: 1 }, pageSize: pageSize });
    const [totalIndex, setCurrentIndex] = useState()
    const [pageData, setPageData] = useState({});
    const [total, setTotal] = useState()
    let history = useHistory();


    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        getallQRCodes(options);

    }, [])

    const getallQRCodes = (options) => {
        
        setLoading(false)
        if (options && options.pagination == undefined) {
            options.pagination = pageData.pagination;
            options.pageSize = pageData.pageSize;
        }
        fetchAllQrCodes({})                   
            .then(response => {
               
                dispatch(getQrCodeData(response.data))
                setTable(response.data.data)
                setOptions({
                    ...options,
                    pagination: {
                        pageSize: options.pagination.pageSize,
                        current: options.pagination.current,
                        total: response.data.count,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                    }
                })
            })

            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)

    }

    const handleChange = (pagination, sorter, current) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        setPageData(updatedOptions)
        getallQRCodes({
            ...updatedOptions,

        });
    };

   
    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "",
            // render:(text, record, index) => `${(options.pagination.current - 1) * 10 + index +1}`
            /* render: (text, record,index) =>`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index +1}`*/
            render: (text, record, index) => { setCurrentIndex(`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}`); return `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}` },

        },

        {
            title: "QR Code",
            dataIndex: "qRcodeId",
            className: "qrcode_mapping",
        },
        {
            title: "Location",
            dataIndex: "qRcodeId",
            className: "Locations",
            key: 'index',
            render: (text, record, index) => (
                <>
                    {record.location.name}
                </>
            )
        },

        {
            title: "Product Name",
            dataIndex: "product_Name",
            render: (product_Name) => <Tooltip placement='topLeft' className="media-tooltip prdouct-name" title={product_Name}>{product_Name ? product_Name : "- NA -"}</Tooltip>,
        },

       
    ]


    return (
        <Layout title="Device Type"><Toaster />
            <div className="position-relative ">
                <div className="card-box-header d-inline-flex w-100 align-items-center">
                    <h4 className="m-0">QR Entries</h4>
                    <div className="ms-auto">
                        <Link className="btn btn-primary d-flex align-items-center" to="/QREntries/Add"><img src={Add}></img>Add QRCode</Link>
                    </div>
                </div>
            </div>
            <div className="Viewer-table table-new pagination-sticky device-type status position-relative">
                {loading ? <Loader /> : ''}
                <Table
                    //className='table-responsive Viewer-table mb-4'
                    className='table-responsive antd--detail-table1 Viewer-table'
                    columns={columns}
                    dataSource={table}
                    pagination={options.pagination.total > 10 && totalIndex > 9 ? options.pagination : false}
                    onChange={handleChange}
                    paginationTotalRows={total}
                    scroll={{ x: "750px" }}
                    
                />
            </div>
        </Layout>

    )



}
export default connect()(QREntriesIndex);
