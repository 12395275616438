import React, { useState } from "react"
import Header from "../UserLayout/Header";
import Footer from "../UserLayout/Footer";
import comingSoon from "../../UserAssets/images/comingSoon.png"

const contactUs = () => {
    return (
        <div>
            <Header
                isSupportIcon={false}
            ></Header>
            <div className="contact-us inner-section ">
                <div className="container">
                    <div className="card-box">
                        <h3>CONTACT US</h3>

                        

                        <div className="map row">
                            <div className=" col-sm-4">
                                <div className=" overlay-section">
                                <h5>You can find us at:</h5>

                                <span>EMAIL</span>
                                    <h6>tekahelp@authentek.io</h6>

                                <span>PHONE NUMBER</span>
                                <h6>  (833) 288-4835</h6>

                                <span>LOCATION</span>
                                <h6> 2155 Chenault Dr. Suite 510 Carrollton, TX 75006</h6>
                                </div>
                            </div>
                            <div className=" col-sm-8">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3347.4523822517185!2d-96.85015442444399!3d32.96546467358548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c26ba3bc6d23f%3A0xf3805a948e721b60!2s2155%20Chenault%20Dr%2C%20Carrollton%2C%20TX%2075006%2C%20USA!5e0!3m2!1sen!2sin!4v1682667317243!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <Footer></Footer>
     </div>
    )}
export default contactUs