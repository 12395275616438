import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

// Import Layout
import Layout from '../Layout';
import background from "../../UserAssets/images/bg.svg"
import logo from "../../UserAssets/images/logo.svg"
import phone from "../../UserAssets/images/phone.svg"
import QR from "../../UserAssets/images/qr.svg"
import ScanQR from "../../UserAssets/images/scan-me.svg"
import Menu from "../../UserAssets/images/toggle.svg"

//import css
import "../../UserAssets/css/animate.css"
import "../../UserAssets/css/bootstrap.min.css"
import "../../UserAssets/css/style.css"
import Modal from 'react-bootstrap/Modal';
import { QrReader } from "react-qr-reader";
import { Link, useHistory } from 'react-router-dom';
import { fetchAllQrId, VerifyQrStatus } from '../../Redux/Api/fetch.api';
import QRThumbnail from './QRThumbnail';
import { getQrCodeIdData, setQrCodeIdData } from '../../Redux/Actions/QrCodeId';
import Header from '../UserLayout/Header';
import Footer from '../UserLayout/Footer';




const QRScan = () => {
    const allQRIdBasedData = useSelector((state) => state.allQrCodeIdReducers.allQRCodeId)
    console.log("data",allQRIdBasedData);
    const [qrModal, setQrModal] = useState(false);
    const [data, setData] = useState('No result');
    const [errorMessage, setErrorMessage] = useState("");
    const [id, setId] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [codeId, setCodeId] = useState(0);
    const history=useHistory();
   
    const qrCameraShow = () => {

        setQrModal(true);
    }

    const handleError = (err) => {
        setData({ result: "Device camera not found", qrError: err.toString() })
    }


   
    //upload the QR 
    const onResult = (result, error) => {
    
        if (result) {
            
            setData(result.text);
            var uri = result.text.toString();
            if (uri.indexOf("/") > 0) { // Example : https://www.tekahelp.com/10000004
                var qrCode = uri.substring(uri.lastIndexOf('/') + 1);
                console.log("uri", qrCode);
                if (qrCode) {
                 
                     fetchAllQrId(qrCode)
                        .then(response => { 
                          
                            if (response.data.success) {
                                setId(qrCode);
                                history.push("/" + qrCode);
                            }
                            else {
                                setErrorMessage("QR Code is not valid, please try again.");
                            }
                        })
                        .catch((err) => {
                            console.log("Err: ", err);
                            setErrorMessage("QR Code is not valid, please try again.");
                        });
                 }
                else {
                    
                    setErrorMessage("QR Code is not valid, please try again.");
                }
            }
            else {
                setErrorMessage("QR Code is not valid, please try again.");
           
            }
        }
       
    }

    const handleClose = () => {
        setQrModal(false)
    }

    return (
        <div>
            <Header></Header>
            <section class="center-section">
                <div class="container">
                    <h3 class="small-heading wow fadeInUp">Welcome to TEKaHelp</h3>
                    <h1 class="main-heading wow fadeInUp" data-wow-delay="0.3s">TEKaHelp, It gives Information through QR  </h1>
                    <div class="Qr-section">
                        <div class="QRLeft">
                            {qrModal == true ? <div class="QRInner wow fadeInUp" data-wow-delay="0.3s" >
                                <QrReader
                                    className="qr--scanner mt-0"
                                    delay={300}
                                    onError={handleError}
                                    constraints={{ facingMode: 'environment' }}
                                    style={{ width: '100%', height: '50%' }}
                                    onResult={onResult}
                                />
                                <small className="text-danger d-block mt-2 ErrorMsg">{errorMessage}</small>
                            </div> : ''}
                            {qrModal == true ?
                                <div class="scan-btn closebtnn"><button class="wow fadeInUp" data-wow-delay="0.5s" onClick={handleClose}>Close</button></div>
                                : <div class="scan-btn"><button class="wow fadeInUp" data-wow-delay="0.5s" onClick={qrCameraShow}><span><img src={ScanQR}></img></span>Click to Read QR Code</button></div>}

                        </div>
                        <div class="QrRight">

                            <img class="wow fadeInUp" data-wow-delay="0.5s" src={phone}></img>
                            <div>
                                <h4 class="wow fadeInUp" data-wow-delay="0.5s">Get to Know us!</h4>
                                <p class="wow fadeInUp" data-wow-delay="0.7s"> Scan the QR code for more information!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </div>
    )
}
export default connect()(QRScan);