import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios"
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import Message from 'antd-message';
import toast, { Toaster } from 'react-hot-toast';
import { updateGuideTypeData, setGuideTypes, deleteGuideData } from "../../Redux/Actions/GuideActions";


import { connect } from 'react-redux';
// Import Layout
import Layout from '../Layout';
import * as url from "../../api/uri_helper"
// Import static images
import Add from "../../assets/images/Add.svg";
import Search_light from "../../assets/images/Search_light.svg";
import Union from "../../assets/images/Union.svg";
import double_arrow from "../../assets/images/double-arrow.svg";
import Edit_light from "../../assets/images/Edit_light.svg";
import Trash_light from "../../assets/images/Trash_light.svg";
import { fetchGuideProducts } from "../../Redux/Api/fetch.api";
import { Modal } from 'antd';
import 'antd/dist/antd.css';
import Loader from "../loader";
import Table, { TableProps } from "antd/lib/table";
import { Tooltip } from 'antd';


//Gudelisting

const Index = () => {
    const allGuideTypes = useSelector((state) => state.allGuideType.guideTypes);
    const dispatch = useDispatch();
    const history = useHistory()
    const [table, setTable] = useState([]);
    const [totalIndex, setCurrentIndex] = useState()
    const [sort, setSort] = useState('');
    const [sortDesc, setSortDesc] = useState('');
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    /*const [page, setPage] = useState(1);*/
    const [options, setOptions] = useState({
        pagination: { current: 1, pageSizeOptions: [5, 10, 20, 50] },

    });

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)

        getProducts(options)
    }, [])

    const handleUpdate = (guideTypeId) => {
        history.push('/Guide/edit/' + guideTypeId)
    }

    const getProducts = (options) => {
        setLoading(true)
        fetchGuideProducts({
            PageIndex: options.pagination.current,
            pageSize: 5,

        })
            .then(response => {
                dispatch(setGuideTypes(response.data))
                setTable(response.data.data)
                setOptions({
                    ...options,
                    pagination: {
                        current: options.pagination.current,
                        total: response.data.count,
                        pageSizeOptions: ['10', '20', '50'],
                        showSizeChanger: true,
                    }
                })
                // setLoading(false);
            })
            .catch((err) => {
                console.log("Err: ", err);
            })
        setTimeout(() => {
            setLoading(false)
        }, 2000)

    }

    const handleChange = (pagination, sorter, current) => {

        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        getProducts({
            ...updatedOptions,

        });

    };

    const deleteGuideTypeData = async (guideTypeId) => {
        setLoading(true)
        await axios.delete(url.DELETE_GUIDETYPE + '/' + guideTypeId, {

        }).then(response => {
            dispatch(deleteGuideData(response.data))
            
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            toast.success(response.data.message,{
                className:"toastSuccess",
            }, );
            getProducts(options)

            /*setLoading(false)*/
        }).catch((err) => {
            console.log("Err: ", err);
        });


    }
    const filterData = data => formatter => data.map(item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "",
            // render:(text, record, index) => `${(options.pagination.current - 1) * 10 + index +1}`
            /* render: (text, record,index) =>`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index +1}`*/
            render: (text, record, index) => { setCurrentIndex(`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}`); return `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}` },

        },
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            filters: filterData(table)(i => i.name),
            onFilter: (value, record) => record.name.indexOf(value) === 0,
            sortOrder: sort,
            onHeaderCell: () => ({
                onClick: () => setSort(sort === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            })

        },
        {
            title: "Description",
            dataIndex: "description",
            render: (description) => <Tooltip title={description}>{description}</Tooltip>,
            sorter: (a, b) => a.description.localeCompare(b.description),
            filters: filterData(table)(i => i.description),
            onFilter: (value, record) => record.description.indexOf(value) === 0,
            sortOrder: sortDesc,
            onHeaderCell: () => ({
                onClick: () => setSortDesc(sortDesc === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            })

        },

        {
            title: "View Order",
            dataIndex: "viewOrder",
            sorter: (a, b) => a.viewOrder - b.viewOrder,
           
        },

        {
            title: "Status",
            dataIndex: "active",
            render: (data) => (data ? "Active" : "Inactive"),
            filters: [
                { text: 'Active', value: true },
                { text: 'Inactive', value: false }
            ],
            onFilter: (value, record) => {
                return record.active === value
            }

        },
        {
            title: "Action ",
            dataIndex: "",
            render: (data, guideTypeId) => (<><button className="table-btn" >
                <img src={Edit_light} onClick={() => handleUpdate(data.guideTypeId)}></img>
            </button>
                <button className="table-btn" >
                    <img src={Trash_light} onClick={() => handleDelete(data.guideTypeId)}></img>
                </button></>)
        },
    ]

    const { confirm } = Modal;
    const handleDelete = (guideTypeId) => {
        confirm({
            title: 'Are you sure you want to delete this record?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteGuideTypeData(guideTypeId)
            },
            onCancel() {
            },
        });
    }
    return (
        <Layout  title ="Guide Type"><Toaster/>
            <div className="position-relative">
                <div className="card-box-header d-inline-flex w-100 align-items-center">
                    <h4 className="m-0">Guide Type</h4>
                    <div className="ms-auto">
                        <Link className="btn btn-primary d-flex align-items-center" to="/GuideType/Add"><img src={Add}></img>Add Guide Type</Link>
                    </div>
                </div>
                
            </div>
            <div className="Viewer-table table-new pagination-sticky device-type position-relative">
            {loading ? <Loader /> : ''}
                <Table
                    //className='table-responsive Viewer-table mb-4'
                    className='table-responsive antd--detail-table1 Viewer-table'
                    columns={columns}
                    dataSource={table}
                    pagination={options.pagination.total > 10 && totalIndex > 9 ? options.pagination : false}
                    onChange={handleChange}
                    scroll={{ x: "450px" }}
                />

            </div>

        </Layout>
    );
    
};

export default connect()(Index);
