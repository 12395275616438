import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios"
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setMediaTypes, deleteMediaData } from "../../Redux/Actions/MediaActions";
import Message from 'antd-message';
import toast, { Toaster } from 'react-hot-toast';
import MediaComponent from './MediaComponent.js';
import { connect } from 'react-redux';

// Import Layout
import Layout from '../Layout';
import * as url from "../../api/uri_helper"

// Import static images
import Add from "../../assets/images/Add.svg";
import Search_light from "../../assets/images/Search_light.svg";
import Edit_light from "../../assets/images/Edit_light.svg";
import Trash_light from "../../assets/images/Trash_light.svg";
import Union from "../../assets/images/Union.svg";
import double_arrow from "../../assets/images/double-arrow.svg";
import { fetchMediaData } from "../../Redux/Api/fetch.api";
import Loader from "../loader";
import { Modal } from 'antd';
import 'antd/dist/antd.css';
import Table, { TableProps } from "antd/lib/table";
//Media
const Index = () => {

    const allMediaTypes = useSelector((state) => state.allMediaType.mediaTypes);
    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState([]);
    const history = useHistory()
    const [sort, setSort] = useState('');
    const dispatch = useDispatch();
    const [options, setOptions] = useState({
        pagination: { current: 1, pageSizeOptions: [5, 10, 20, 50] },

    });

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)

        getProducts(options)
    }, [])

    const handleEdit = (mediaTypeId) => {
        history.push('/MediaType/edit/' + mediaTypeId)
    }

    const getProducts = () => {
        setLoading(true)
        fetchMediaData()
            .then(response => {
                dispatch(setMediaTypes(response.data))
                setTable(response.data.data)
                setOptions({
                    ...options,
                    pagination: {
                        current: options.pagination.current,
                        total: response.data.count,
                        pageSizeOptions: ['10', '20', '50'],
                        showSizeChanger: true,
                    }
                })
                // setLoading(false);
            })
    
            .catch((err) => {
                console.log("Err: ", err);
            })
        setTimeout(() => {
            setLoading(false)
        }, 2000)

    }

    const handleChange = (pagination, sorter, current) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        getProducts({
            ...updatedOptions,
        });

    };

    const deleteMediaTypeData = async (mediaTypeId) => {
        setLoading(true)
        await axios.delete(url.DELETE_MEDIATYPES + '/' + mediaTypeId, {

        }).then(response => {
            dispatch(deleteMediaData(response.data))
            
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            toast.success(response.data.message,{
                className:"toastSuccess",
            }, ); 
            getProducts()

        }).catch((err) => {
            console.log("Err: ", err);
        });
        setLoading(true)

    }
    const filterData = data => formatter => data.map(item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "",
            /* render: (text, record) => `${table.indexOf(record) + 1}`*/
          /*  render: (text, record, index) => `${(options.pagination.current - 1) * 10 + index + 1}`*/
           render: (text, record, index) => `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}`
        },
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            filters: filterData(table)(i => i.name),
            onFilter: (value, record) => record.name.indexOf(value) === 0,
            sortOrder: sort,
            onHeaderCell: () => ({
                onClick: () => setSort(sort === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            })
           
        },
        {
            title: "Master Key",
            dataIndex: "key",
           
            //sorter: (a, b) => a.key.localeCompare(b.key),
            //filters: filterData(table)(i => i.key),
            //onFilter: (value, record) => record.key.indexOf(value) === 0,

        },

        {
            title: "Status",
            dataIndex: "active",
            render: (data) => (data ? "Active" : "Inactive"),
            //filters: [
            //    { text: 'Active', value: true },
            //    { text: 'Inactive', value: false }
            //],
            //onFilter: (value, record) => {
            //    return record.active === value
            //}

        },
        //{
        //    title: "Action ",
        //    dataIndex: "",
        //    render: (data, areaTypeId) => (<><button className="table-btn" >
        //        <img src={Edit_light} onClick={() => handleEdit(data.mediaTypeId)}></img>
        //    </button>
        //        <button className="table-btn" >
        //            <img src={Trash_light} onClick={() => handleDelete(data.mediaTypeId)}></img>
        //        </button></>)
        //},
    ]

    const { confirm } = Modal;
    const handleDelete = (mediaTypeId) => {
        confirm({
            title: 'Are you sure you want to delete this record?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteMediaTypeData(mediaTypeId)
            },
            onCancel() {
            },
        });
    }

    return (

        <Layout title="Media Type"><Toaster/>
            <div className="position-relative card--heights">
                <div className="card-box-header d-inline-flex w-100 align-items-center">
                    <h4 className="m-0">Media Type</h4>
                    {/*<div className="ms-auto">*/}
                    {/*    <Link className="btn btn-primary d-flex align-items-center" to="/MediaType/Add"><img src={Add}></img>Add Media Type</Link>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className="table-responsive table-new position-relative">
            {loading ? <Loader /> : ''}
                <Table
                    className='table-responsive antd--detail-table1 Viewer-table mediatype'
                    columns={columns}
                    dataSource={table}
                    pagination={options.pagination.total > 10 ? options.pagination : false}
                    onChange={handleChange}
                />
            </div>
        </Layout>
    );

};
export default connect()(Index);