import React from "react";
import ImageUploader from "react-images-upload";

const UploadComponent = (props) => {
  const onDrop = (pictureFiles, pictureDataURLs) => {
    const newImagesUploaded = pictureDataURLs.slice( props.defaultImages.length);
    props.handleChange(newImagesUploaded);
    //for save section conditions
    if (newImagesUploaded.length > 0) {
      props.setConditionalStyleImage(false)
      props.setUploadMedia((prev) => ({ ...prev, image: false }))
    } else {
      props.setConditionalStyleImage(true)
      props.setUploadMedia((prev) => ({ ...prev, image: true }))
    }
  };  

  return (
    <ImageUploader
      withIcon={false}
      withLabel={false}
      withPreview={true}
      buttonText={"Upload Media"}
     // fileSizeError={"File size is too big!"}
      fileTypeError={"This extension is not supported!"}
      onChange={onDrop}
      imgExtension={props.imgExtension}
      maxFileSize={props.maxFileSize}
      defaultImages={props.defaultImages}
    />
  );
}

export default UploadComponent;
