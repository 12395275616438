import { UserTypes } from "../Constants/User";


//get UserTypeData
export const setUserType = (user) => {
    return {
        type:UserTypes.SET_USERTYPES,
        payload: user
    };
};

//Add UserType Data
export const addUserType = (user) => {
    return {
        type: UserTypes.ADD_USERTYPES,
        payload: user
    };
};

//Update UserType Data
export const updateUserTypeData = (user) => {
    return {
        type: UserTypes.UPDATE_USERTYPES,
        payload: user
    };
};

//Delete UserType Data
export const deleteUserTypeData = (user) => {
    return {
        type: UserTypes.DELETE_USERTYPES,
        payload: user
    };
};
