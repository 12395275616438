import Layout from "../Layout";
import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
} from "react";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import "../../../src/custom.css";
import Loader from "../loader";

import { red } from "@mui/material/colors";
import DummyGoogle from "../../assets/images/dummyGoogle.jpg";
import SearchIcon from "@mui/icons-material/Search";
import GoogleLogo from "../../assets/images/googleLogo.png";
import DirectionsIcon from "@mui/icons-material/Directions";
import {
  fetchProductLocation,
  fetchSearchProductName,
} from "../../Redux/Api/fetch.api";
import { getProductType } from "../../Redux/Actions/productMarking";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  MarkerF,
  InfoWindow,
} from "@react-google-maps/api";
import InfiniteScroll from "react-infinite-scroll-component";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import connectIcon from "../../assets/images/connect.svg";

let PAGE_INDEX = 1;
const ProductPointing = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCEG3K6fRhMb6ikoQ1205Lx4IiykvXYGSY",
  });
  const center = useMemo(() => ({ lat: 32.9656398, lng: -96.846783699999 }), []);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [searchData, setSearchData] = useState();
  const [searchClicked, setSearchClicked] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [filteredProductData, setFilteredProductData] = useState([]);
  const [total, setTotal] = useState();
  const products = useSelector((state) => state.allProductMarkings.products);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [activeMarker, setActiveMarker] = useState(null);
  const [selected, setSelected] = useState("off");
  const [buyerSelected, setBuyerSelected] = useState("on");
  const [id, setId] = useState();
  const [index, setIndex] = useState();
  const [allData, setAllData] = useState();
    const [searchClickData, setSearchClickData] = useState(false)
    const [directionClick, setDirectionClick] = useState(false)
  

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
  }, 2000)
    getProductLocation();
  }, []);

  const handleDataSearch = (e) => {
    
    setSearch(e.target.value);
      setCount(e.target.value.length);
        
  };
 

  const handleActiveMarker = (marker) => {
  
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
  const handleSearch = () => {
   
    setSearchClickData(true)
    // if (search != "") {
      
      setLoading(true);
      fetchSearchProductName(search).then((response) => {
        setSearchClicked(true);
        setSearchData(response.data.data.allProductList);

        setTotal(response.data.data.totalCount);
      });
      if (search == "") {
          setDirectionClick(false)         
      }  
  };

  const getProductLocation = (resetTable = false) => {
   
    setLoading(true);
    fetchProductLocation(PAGE_INDEX)
      .then((response) => {
        dispatch(getProductType(response.data));
        const productDataFromDB = response.data.data.allProductList;
        const totalCount = response.data.data.totalCount;
        const currentDataLength = [...filteredProductData, ...productDataFromDB]
          .length;
        if (productDataFromDB.length > 0 && currentDataLength < totalCount)
          setHasMore(true);
        else setHasMore(false);
        if (!resetTable) {
          setFilteredProductData([
            ...filteredProductData,
            ...productDataFromDB,
          ]);
        } else {
          setFilteredProductData([...productDataFromDB]);
        }
        PAGE_INDEX = PAGE_INDEX + 1;
        setLoading(false);
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  };

    const handleDirectionButton = (qRcodeId, locationId, index) => {
        setDirectionClick(true);
    const data = filteredProductData.filter(
      (x) => x.qRcodeId == qRcodeId && x.locationId == locationId
    );

    setLatitude(data[0].latitude);
    setLongitude(data[0].longitude);
    setIndex(index);
    setAllData(data);
  };

    const handleDirectionButtonOnSearch = (qRcodeId, locationId, index) => {
        setDirectionClick(true);
    const data = searchData.filter(
      (x) => x.qRcodeId == qRcodeId && x.locationId == locationId
    );

    setLatitude(data[0].latitude);
    setLongitude(data[0].longitude);
    setIndex(index);
    setAllData(data);
  };

  let lat = latitude;
  let lng = longitude;
  return (
    <Layout title="Products located on Google Maps">
      <div className="map-product_container">
      
        <div className="map-product_wrap row flex-md-row-reverse">
          {searchClicked ? (
            <div className="col-xl-3 col-lg-4 col-md-5 pl-0">
              <div class="table-responsive tableclass ">
                <InfiniteScroll
                  dataLength={filteredProductData.length}
                  next={() => {
                    getProductLocation();
                  }}
                  hasMore={hasMore}
                  endMessage={
                    <div className="no-data">
                      No More Records
                    </div>
                  }
                  height={400}
                >
                  <table className="table table-sm table-light table-striped table-hover">
                    <thead className="">
                      <tr>
                        <th className="checkbox-th sticky-top">
                          <div className="map-items_wrapper">
                            <div className="d-flex map-items_search mb-1 me-3">
                              <input
                                className="form-control"
                                value={search}
                                placeholder="Search Product..."
                                onChange={(e) => {
                                  handleDataSearch(e);
                                }}
                              ></input>
                              <SearchIcon
                                className="map-button_search"
                                onClick={handleSearch}
                              />
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    {searchData != "" ? (
                      <tbody>
                        {searchData &&
                          searchData.map((item, index) => {
                            return (
                              <tr>
                                <td key={index}>
                                  <div className="map-items_card d-flex align-items-center py-3">
                                    <div className="map-items_detail">
                                      <h5 className="mt-0">
                                        <b>{item.product_Name}</b>
                                      </h5>
                                      <span className="map-items_qrcode d-block">
                                        {" "}
                                        <small>{item.qRcodeId}</small>
                                      </span>
                                      <span className="map-items_name d-block">
                                        {" "}
                                        <small>{item.name}</small>
                                      </span>
                                      <span className="map-items_address d-block">
                                        <small>{item.address}</small>
                                      </span>
                                    </div>
                                    <div className="map-items_direction">
                                      <DirectionsIcon
                                        onClick={(e) =>
                                          handleDirectionButtonOnSearch(
                                            item.qRcodeId,
                                            item.locationId,
                                            index
                                          )
                                        }
                                      />
                                      <span>Directions</span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    ) : (
                      <tfoot className="no-border">
                        <tr>
                          <td>                            
                            <div className="no-data">
                              No Data Available
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                      
                    )}
                  </table>
                </InfiniteScroll>
              </div>
            </div>
          ) : (
            <div className="col-xl-3 col-lg-4 col-md-5 pl-0">
              <div class="table-responsive tableclass ">
                <InfiniteScroll
                  dataLength={filteredProductData.length}
                  next={() => {
                    getProductLocation();
                  }}
                  hasMore={hasMore}
                  loader={
                    <div className="loading-data">
                      <span>Loading...</span>
                    </div>
                    
                  }
                  endMessage={
                    <div className="no-data">
                      No More Records
                    </div>
                  }
                  height={400}
                >
                  <table className="table map-items_table table-sm">
                    <thead className="">
                      <tr>
                        <th className="checkbox-th sticky-top">
                          <div className="map-items_wrapper">
                            <div className="d-flex map-items_search me-3">
                              <input
                                className="form-control"
                                value={search}
                                placeholder="Search Product..."
                                onChange={(e) => {
                                  handleDataSearch(e);
                                }}
                              ></input>
                              <SearchIcon
                                className="map-button_search"
                                onClick={handleSearch}
                              />
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {loading ? <Loader /> : ""}
                      {filteredProductData &&
                        filteredProductData.length > 0 &&
                        filteredProductData.map((item, index) => {
                          return (
                            <tr>
                              <td key={index}>
                                <div className="map-items_card d-flex align-items-center py-3">
                                  <div className="map-items_detail">
                                    <h5 className="mt-0">
                                      <b>{item.product_Name}</b>
                                    </h5>
                                    <span className="map-items_qrcode d-block">
                                      {" "}
                                      <small>{item.qRcodeId}</small>
                                    </span>
                                    <span className="map-items_name d-block">
                                      {" "}
                                      <small>{item.name}</small>
                                    </span>
                                    <span className="map-items_address d-block">
                                      <small>{item.address}</small>
                                    </span>
                                  </div>

                                  <div className="map-items_direction">
                                    <DirectionsIcon
                                      onClick={(e) =>
                                        handleDirectionButton(
                                          item.qRcodeId,
                                          item.locationId,
                                          index
                                        )
                                      }
                                    />
                                    <span>Directions</span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </div>
          )}

          <div className="col-xl-9 col-lg-8 col-md-7 pe-md-0">
           
            {!isLoaded ? (
              <div className="h-100 d-flex align-items-center justify-content-center p-5">
                <b>
                  <h6>Loading...</h6>
                </b>
              </div>
            ) :  (
              <GoogleMap
                mapContainerClassName="map-container"
                center={center}
                zoom={5}
                              >
                                  {latitude && longitude && directionClick ? (
                                      <GoogleMap
                                          mapContainerClassName="map-container"
                                          center={{ lat, lng }}
                                          zoom={13}
                                      >
                                          <MarkerF
                                              position={{ lat, lng }}
                                              key={index}
                                              onClick={() => {
                                                  setSelected("on");
                                                  setId(index);
                                              }}
                                              onMouseOver={() => {
                                                  setSelected("on");
                                                  setId(index);
                                              }}
                                              onMouseOut={() => {
                                                  setSelected("off");
                                              }}
                                          >
                                              {selected == "on" &&
                                                  id == index &&
                                                  allData &&
                                                  allData.map((item, index) => (
                                                      <InfoWindow
                                                      // onCloseClick={() => setActiveMarker(null)}
                                                      >
                                                          <div className="map-detail-hove_card">
                                                              <div className="map-detail-hove_card-img">
                                                                  <img src={item.filename}></img>
                                                              </div>
                                                              <div className="map-detail-hove_card-content">
                                                                  <div className="map-detail_inner">
                                                                      <h5 className="mt-0">
                                                                          <b>{item.product_Name}</b>
                                                                      </h5>
                                                                      <span className="map-items_qrcode d-block">
                                                                          {" "}
                                                                          <small>{item.qRcodeId}</small>
                                                                      </span>
                                                                      <span className="map-items_name d-block">
                                                                          {" "}
                                                                          <small>{item.name}</small>
                                                                      </span>
                                                                      <span className="map-items_address d-block">
                                                                          <small>{item.address}</small>
                                                                      </span>
                                                                  </div>
                                                                  <div className="map-items_direction">
                                                                      <DirectionsIcon />
                                                                      <span>Connect</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </InfoWindow>
                                                  ))}
                                          </MarkerF>
                                      </GoogleMap>
                                  ) : (


                                       filteredProductData &&
                                      filteredProductData.map((item, index) => {
                                          let lat = item.latitude;
                                          let lng = item.longitude;
                                          return (
                                              <MarkerF
                                                  key={index}
                                                  //onClick={() => {handleActiveMarker(index), setSelected("on"),setId(index)}}
                                                  position={{ lat, lng }}
                                                  onClick={() => {
                                                      setSelected("on");

                                                      setId(index);
                                                  }}
                                                  onMouseOver={() => {
                                                      setSelected("on");
                                                      setId(index);
                                                  }}
                                                  onMouseOut={() => {
                                                      setSelected("off");
                                                  }}
                                              >
                                                  {selected == "on" && id == index ? (

                                                  // {selected == "on" && activeMarker == index ? (
                                                      <InfoWindow
                                                      //onCloseClick={() => setActiveMarker(null)}
                                                      >
                                                          <div className="map-detail-hove_card">
                                                              <div className="map-detail-hove_card-img">
                                                                  <img src={item.filename}></img>
                                                              </div>
                                                              <div className="map-detail-hove_card-content">
                                                                  <div className="map-detail_inner">
                                                                      <h5 className="mt-0">
                                                                          <b>{item.product_Name}</b>
                                                                      </h5>
                                                                      <span className="map-items_qrcode d-block">
                                                                          {" "}
                                                                          <small>{item.qRcodeId}</small>
                                                                      </span>
                                                                      <span className="map-items_name d-block">
                                                                          {" "}
                                                                          <small>{item.name}</small>
                                                                      </span>
                                                                      <span className="map-items_address d-block">
                                                                          <small>{item.address}</small>
                                                                      </span>
                                                                  </div>
                                                                  <div className="map-items_direction">
                                                                      <img
                                                                          className="connect_ico"
                                                                          src={connectIcon}
                                                                      />
                                                                      <span>Connect</span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </InfoWindow>
                                                  ) : null}
                                              </MarkerF>
                                          );
                                      })
                                  )}
              </GoogleMap>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default connect()(ProductPointing);
