import { UserTypes } from "../Constants/User";
// initial state
const initialState = {
    user: []
}

export const UserReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case UserTypes.SET_USERTYPES:
            return { ...state, user: payload.data };

       //Add CustomerType
        case UserTypes.ADD_USERTYPES:
            return { ...state, user: payload };

        //Delete CustomerType
        case UserTypes.DELETE_USERTYPES:
            return { ...state, user: payload };

        default:
            return state;
    }
};