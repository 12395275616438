import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Footer from '../VerticalLayout/Footer';
import * as url from "../../api/uri_helper"
//import Message from 'antd-message';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { Formik, Field, useFormik } from 'formik';
import { Input, Form, FormFeedback } from "reactstrap"
import * as Yup from 'yup';
import EmailIcon from '@mui/icons-material/Email';
import logo from "../../UserAssets/images/authenteklogo.png"
import MessageLight from "../../assets/images/Message_light.svg";
import axios from 'axios';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
const TOAST_LIMIT = 1;
const emailRegex = /^\s*(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
const EmailVerfication = () => {
    const { toasts } = useToasterStore();
    useEffect(() => {
        toasts
            .filter((t) => t.visible) // Only consider visible toasts
            .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
            .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
    }, [toasts]);
    const history=useHistory();
    const [email, setEmail] = useState("");
    const [error, setError] = useState({
        emailErr: '',
    })
    const [showMessage, setShowMessage] = useState(false);

   
    const [isChecked, setisActive] = useState(false)

    const sendEmail = async (email) => {
        debugger;
        if (email) {
            const response = await axios.get(url.GET_EMAILVERIFICATION + '\\' + email.email, ).then(response => { 
                 
                if (response.data.success) {
                    toast.success(response.data.message,{
                        className:"toastSuccess",
                    }, );
                    history.push({
                        pathname: '/EmailConfirmation',
                        state: { data: response.data.data }
                    });
                }
                else {
                    toast.error(response.data.message,{
                        className:"toastSuccess",
                    }, );
                }
               
            })
                .catch((err) => {
                    console.log("Err: ", err);
                });
        };
       
    }
  


const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
        email: localStorage.getItem('email') ? localStorage.getItem('email') : '',
        
    },

    // Alert messages 
    validationSchema: Yup.object({
        email: Yup.string().required('Please Enter Email').matches(
            /^([a-zA-Z0-9+_.-]+)(|([+])([0-9])+)@([a-zA-Z0-9.-]+)\.([a-zA_Z]{2,3}$)/,
            "Please enter valid email "
        )
    }),

    // on form submit / button click
    onSubmit: (values) => {
        // call the api here
        debugger;
        // setDisable(true);
        sendEmail(values);
    }
});
return (
    <section className="auth-section d-flex flex-wrap login-page min-vh-100 "><Toaster/>
        <div className="d-flex align-items-center flex-wrap flex w-100">
            <div className="container">
                <div className="row">
                  <div className="col-sm-6 left-section d-flex justify-content-center flex-column">
                        <a href="#"  className="logo mb-4 text-center"><img src={logo}></img></a>
                        <p className="m-0 text-center">Welcome to TEKaHelp</p>
                    </div>
                    <div className="col-sm-6 right-section">
                        <div className="card auth-card">
                            <h4>Email Verification</h4>
                            <Form className="form-horizontal"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}>
                                <div className="mt-4"></div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <div className="input-group">
                                                {/* <span className="input-group-addon"><img src={MessageLight}></img></span> */}
                                                <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                        <EmailIcon style={{color:"gray"}}/>
                                                        </span>
                                                    </div>
                                                    <Input name="email" lassName="form-control" placeholder="Enter Email"  
                                                    type="text" maxLength={50} onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur} value={validation.values.email}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null}
                                            </div>
                                        </div>
                                        <div className="d-flex w-100 align-items-center justify-content-between">
                                            <button className="btn btn-primary">Send</button>
                                            <Link className='font-12' to="/" title="Go to Login Page"> Back to Login </Link>
                                        </div>
                                    </div>
                                </div>
                                </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </section>
);
};

export default connect()(EmailVerfication);
