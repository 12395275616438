import { combineReducers } from "redux";
import { AreaReducers } from './AreaReducer';
import { DeviceReducers } from './DeviceReducer';
import { GuideReducers } from './GuideReducer';
import { MediaReducers } from './MediaReducer';
import { LoginReducers } from './LoginReducer';
import { LocationReducers } from "./LocationReducer";
import { CustomerReducers, UserReducers } from "./UserReducer";
import { QrReducers } from "./QR";
import { VirtualQrReducers } from "./VirtualQRDetails";
import { VirtualQrDataReducers } from "./VirtualQR";
import { VirtualQrCodesReturned } from "./VirtualQR";
import { CompanyReducers } from "./CompanyReducer";
import { QRAssignReducers } from "./QRCodesToAssign";
import { CompanyLocationReducers } from "./RelationReducer";
import { CompanyLocationMappingReducers } from "./CompanyLocationMapping";
import { QrDetailReducers } from "./QrDetails";
import { AllLocationByCompanyReducers } from "./locationByCompany";
import { allQRByLocation } from "./qrByLocationId";
import { UserRoleReducers } from "./userRolesReducer";
import { allQRCodeIdUserData } from "./qrCodeId";
import { ThumbnailReducers } from "./ThumbnailReducer";
import { AreaIdReducers } from "./AreaId";
import { allQRCodeIdDataGuideReducer, allUserGroupingReducer } from "./QRCodeIdByGuideType";
import { parentQrReducers } from "./assignQR";
import { QrParentChildReducers } from "./QRParentChild";
import { QrThumbnailReducers } from "./QrCodeThumbnailReducer"
import { CopyQRDataId } from "./CopyQRId";
import { DashboardReducers } from "./DashboardCount";
import { productMarkType } from "./productMarking";
import { allVirtualQRByLocation } from "./VirtualQrByLocation";

// combine all reducers here // mapping
const reducers = combineReducers({
    allAreaType: AreaReducers,
    allDeviceType: DeviceReducers,
    allUserType: UserReducers,
    allGuideType: GuideReducers,
    allLocationType:LocationReducers,
    allMediaType: MediaReducers,
    allLoginType: LoginReducers,
    allQrType: QrReducers,
    allQRCodesToAssign: QRAssignReducers,
    allCompanyDetails: CompanyReducers,
    allCompanyLocationData: CompanyLocationReducers,
    allCompanyLocationMapping: CompanyLocationMappingReducers,
    allQrDetailData: QrDetailReducers,
    allLocationByCompany: AllLocationByCompanyReducers,
    allQRByLocationId: allQRByLocation,
    allUserRoleReducers: UserRoleReducers,
    allQrCodeIdReducers: allQRCodeIdUserData,
    allThumbnailReducers: ThumbnailReducers,
    allAreaIdReducers: AreaIdReducers,
    allUserGroupingReducer: allUserGroupingReducer,
    allQrCodeData: QrReducers,
    allParentQRId:parentQrReducers,
    allQRParentChildData: QrParentChildReducers,
    allQrDataWithThumbnail: QrThumbnailReducers,
    allQRCopyData : CopyQRDataId,
    allDashboardReducer: DashboardReducers,
    allProductMarkings: productMarkType,
    allvirtualqrcodes: VirtualQrReducers,
    allVirtualQRByLocation: allVirtualQRByLocation,
    allVirtualQRData: VirtualQrDataReducers,
   allVirtualQRCode :VirtualQrCodesReturned
});

export default reducers;