import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";

// Import static images 
import Dummy from "../../assets/images/dummy.png";
import Useraltlight from "../../assets/images/User_alt_light.svg";
import Settinglinelight from "../../assets/images/Setting_line_light.svg";
import Signinsqurelight from "../../assets/images/logout.png";
import Changepassword from "../../assets/images/change-password.png";
import LoginImage from "../../assets/images/login-image.png";
import Takeatour from "../../assets/images/TakeATour.svg";
import { useHistory } from "react-router-dom";
import { setLogout } from "../../Redux/Actions/LoginActions";
import { message } from "antd";
import { setLogins } from "../../Redux/Actions/LoginActions";
import { colors } from "@mui/material";
import '../HorizontalLayout/MenuBarTour.css'


const Menubar = () => {
    const loggedInUserName = localStorage.getItem('userName');
    const loggedInEmailId = localStorage.getItem('emailid');
    const remmeberMe = localStorage.getItem('isChecked');

    require('dotenv').config();
    const { REACT_APP_TOUR_VIDEO } = process.env;

    //const dispatch = useDispatch();
    const history = useHistory();

    const Logout = (e) => {
        //debugger;

        //dispatch(setLogout());
        if (!remmeberMe) {
            localStorage.removeItem('userName')
            localStorage.removeItem('emailid')
            localStorage.removeItem('password')
            localStorage.removeItem('isChecked')
            localStorage.removeItem('tekaHelpToken');
        }
        localStorage.removeItem('tekaHelpToken');
        history.push("/");
    };

    const ChangePassword = (e) => {
        //debugger;

        //dispatch(setLogout());
        /* localStorage.removeItem('tekaHelpToken');*/
        history.push('/ChangePassword');
    };

    const GoToProfile = (e) => {  // New changes
        debugger;

        //dispatch(setLogout());
        /* localStorage.removeItem('tekaHelpToken');*/
        var UserID = localStorage.getItem('UserId');
        history.push('/User/edit/' + UserID);
    };

    useEffect(() => {
        // debugger;

        if (localStorage.getItem('tekaHelpToken') == null) {
            history.push("/");
        }
    }, [])

    // New Changes
    const [showModal, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const TourVideoUrl = process.env.REACT_TOUR_VIDEO;
    //End

    return (
        <div className="tourPara">
            <button className="btnTour d-flex align-items-center mr-5" >
                <img src={Takeatour} onClick={handleShow}></img>
               
            </button>
            <div className="dropdown header-dropdown">
                <button className="d-flex dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    {/*<div className="avatar-img">
                  <img src={Dummy}></img>
              </div> */}

                    <div className="card d-flex align-items-center whflex">
                        <span> <img src={LoginImage}></img></span>
                        <div className="content">
                            <h6 className="dashboard_user">{loggedInUserName}</h6>
                        </div>

                    </div>

                </button>

                <ul className="dropdown-menu side_profile" aria-labelledby="dropdownMenuButton1">
                    <div className="card d-flex align-items-center">
                        <span> <img src={LoginImage}></img></span>
                        <div className="content">
                            <h5>{loggedInUserName}</h5>
                            <p className="text-muted">{loggedInEmailId}</p>
                        </div>
                    </div>
                    <ul>
                        <li>  <a className="dropdown-item" onClick={(e) => GoToProfile(e)}>
                            <img src={LoginImage}></img>
                            Profile</a>
                        </li>

                        <li>  <a className="dropdown-item" onClick={(e) => ChangePassword(e)}>
                            <img src={Changepassword}></img>
                            Change Password</a>
                        </li>

                        <li>    <a className="dropdown-item" onClick={(e) => Logout(e)}>
                            <img src={Signinsqurelight}></img>
                            Sign Out</a>
                        </li>

                    </ul>
                </ul>
            </div>
            <Modal show={showModal} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Have a look on the Tour!!!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ textAlign: "center" }}>
                        <video
                            controls
                            src={REACT_APP_TOUR_VIDEO}
                            type="video/mp4"
                            width="100%"
                            height="100%"
                            autoPlay>
                        </video>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary btnCloseTour" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Menubar
