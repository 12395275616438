import React from "react"
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation,Navigate } from "react-router-dom";
import Header from "../UserLayout/Header";
import Footer from "../UserLayout/Footer";
import comingSoon from "../../UserAssets/images/comingSoon.png"
import { Breadcrumb } from "antd";


const InvalidQR = () => {
    return (
        <div>
            <Header></Header>
            <div className="invalid-QR">

                    <div class="container">
                        <a href="/QrScan" className="home-icon">
                            <i class="fa-solid fa-house"></i>
                        </a>

                        <div className="invalid">
                            <h4> Invalid Page. Please try Again.Thanks </h4>
                        </div>
                    </div>
            </div>
               
               
            <Footer></Footer>
        </div>
    )
}
export default connect()(InvalidQR);
