import * as React from 'react';
import { connect } from 'react-redux';
import Footer from '../VerticalLayout/Footer';
import LockIcon from '@mui/icons-material/Lock';
// Import static images 
import logo from "../../UserAssets/images/authenteklogo.png"
import EyeClose from "../../assets/images/eyeClose.svg";
import PassLight from "../../assets/images/fi-rr-lock1.svg";

const ForgotPassword = () => (
    <section className="auth-section d-flex flex-wrap login-page min-vh-100 ">
        <div className="d-flex align-items-center flex-wrap flex w-100">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 left-section d-flex justify-content-center flex-column">
                        <a href="#"  className="logo mb-4 text-center"><img src={logo}></img></a>
                        <p className="m-0 text-center">Welcome to TEkaHelp</p>
                    </div>
                    <div className="col-sm-6 right-section">
                        <div className="card auth-card">
                            <h4>Reset Passwords</h4>
                           {/* <h6 className="m-0">Enter your new password below.</h6>*/}
                            <form className="form-signin">
                                <div className="mt-4"></div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>New Password</label>
                                            <div className="input-group">
                                               
                                                <input id="email" type="text" className="form-control" name="email" placeholder="***********"></input>
                                            
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>Confirm Password</label>
                                            <div className="input-group">
                                               
                                                <input id="email" type="text" className="form-control" name="email" placeholder="***********"></input>
                                              
                                            </div>
                                        </div>

                                        <div className="d-flex w-100 align-items-center justify-content-between">
                                            <button className="btn btn-primary">Reset Password</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </section>
);

export default connect()(ForgotPassword);
