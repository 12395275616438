import React, { useEffect, useState,useRef } from 'react';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from "react-redux";
import Loader from "../loader";
import favoriteIcon from "../../assets/images/favorite_star.png";
import Table, { TableProps } from "antd/lib/table";
import {  Select } from 'antd';
import '../../assets/css/style.css'
import toast, { Toaster,useToasterStore } from 'react-hot-toast';
import Layout from '../Layout';
import '../../../src/custom.css'
import updateIcon from "../../assets/images/updateIcon.svg"
import { Modal } from 'antd';
import { Tooltip } from 'antd';
import { fetchAllParentQRId, fetchAllQrCodesWithThumbnail, fetchAllParentChildQRId, fetchAllQrCodes, updateAllParentQRId, fetchUpdatedQRCode } from '../../Redux/Api/fetch.api';
import { getQrCodeData, updateQRCodeData } from '../../Redux/Actions/QR';
import { getQrCodeThumbnail } from '../../Redux/Actions/QR';
import { setQrCodeIdData } from '../../Redux/Actions/QrCodeId';
import { updateParentByQRId } from '../../Redux/Actions/assignQR';
import LoginImage from "../../assets/images/login-image.png";
import { getQrParentChildData } from "../../Redux/Actions/QRParentChild";
import Switch from "react-switch";
import InvalidQR from '../UserLogin/InvalidQR';

const TOAST_LIMIT = 1;
const IndexQrAssigning = () => {
    const { toasts } = useToasterStore();
    useEffect(() => {
      toasts
        .filter((t) => t.visible) // Only consider visible toasts
        .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
        .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
    }, [toasts]);
    const allQrDataWithThumbnail = useSelector((state) => state.allQrDataWithThumbnail.qrCodeThumbnailData);
    const [qrId, setQRId] = useState()
    const [parentId, setParentId] = useState()
    const [qrCodeId, setQrCodeId] = useState([{}]);
    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [options, setOptions] = useState({
        pagination: { current: 1 }, pageSize: pageSize
    });
    const [search, setSearch] = useState("")
    const [searchData, setSearchData] = useState()
    const [checkScannedData, setCheckScannedData] = useState()
    const [searchClicked, setSearchClicked] = useState(false)
    const [searchError, setSearchError] = useState()
    const [count,setCount] = useState(0)
    const [searchOptions, setSearchOptions] = useState({
        pagination: { current: 1 }, pageSize: pageSize
    })
    const [total, setTotal] = useState()
    const [pageData, setPageData] = useState({});
    const [state, setState] = useState({ message: "", items: [] })
    const [pageURL, getPageURL] = useState("");
    const dispatch = useDispatch();
    //To immediatel fetch favourite changes
    const [changeOnFav,setChangeOnFav] = useState(false)
    const  [selectedItem,setSelectedItem] = useState("")

    const prevCountRef =  useRef(selectedItem);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        const nameUrl = window.location.host
        getPageURL(nameUrl)
        setLoading(false)
        getAllQRCodes(options);
        setPageData(options)
        getAllQRWithThumbnail();
    }, [changeOnFav,selectedItem])

    useEffect(() => {
        if (search !== "") {
            handleSearch()}
    }, [table])
const handleDataSearch =(e)=>{
    setSearchError("")
    setSearch(e.target.value)
    setCount(e.target.value.length)
    if(e.target.value==""){
        setSearchData(table)
    }
}
    const handleSearch = () => {
        if (search=="") {
            setSearchError('Please Enter QR/Product');
        }
        else if(search !="") {
            var searchProductName =table.length>0? table.filter(item=> item.product_Name!=null && item.product_Name.toLowerCase().startsWith(search.toLowerCase().trim()) ||  item.product_Name!=null && item.product_Name.toLowerCase().includes(search.toLowerCase().trim())) :""
            var searchDataFinal = table.length>0? table.filter(item => item.qRcodeId.toString().startsWith(search)) :""
            if(searchDataFinal.length<1){
                setSearchClicked(true)
                setSearchData("")
            }
            if(searchDataFinal.length>0){
                setSearchClicked(true)
                setSearchData(searchDataFinal)
                setSearchOptions({
                    ...searchOptions,
                    pagination: {
                        pageSize: searchOptions.pageSize,
                        current: searchOptions.pagination.current,
                        total: searchDataFinal.length,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                    }
                })
            }
            else {
                if(searchProductName.length>0){
                    setSearchClicked(true)
                    setSearchData(searchProductName)
                    setSearchOptions({
                        ...searchOptions,
                        pagination: {
                            pageSize: searchOptions.pagination.pageSize,
                            current: searchOptions.pagination.current,
                            total: searchProductName.count,
                            pageSizeOptions: ['10', '20', '50', '100'],
                            showSizeChanger: true,
                        }
                    })
                }
            }
        }
    }
    const handleResetButton = () => {
        setSearchClicked(false)
        setSearch("")
        setSearchData("")
        setSearchError("")
    }

    const handleFavourite = (record) => {
        fetchUpdatedQRCode(record.qRcodeId)
            .then(response => {
                toast.success(response.data.message,{
                    className:"toastSuccess",
                }, );
                getAllQRCodes();
                setChangeOnFav(true)
            })
            .catch((err) => console.log("error", err))
    }

    const getAllQRCodes = (options) => {
        setLoading(true)
        fetchAllQrCodes({})
            .then(response => {
                dispatch(getQrCodeData(response.data));
                setTable(response.data.data)
                setQrCodeId(response.data.data.qrCodeId)
                setParentId(response.data.data.parent_QRCodeID)
                setOptions({
                    ...options,
                    pagination: {
                        pageSize: options.pagination.pageSize,
                        current: options.pagination.current,
                        total: response.data.count,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                    }
                })             
            })
            .catch((err) => {
                console.log("Err: ", err);
            })
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    const verifyQRCodeData = (id) => {
        fetchAllParentChildQRId(id)
            .then((response) => {
              
                if (response.data.success) {
                    dispatch(getQrParentChildData(response.data.data));
                    setCheckScannedData(response.data.data.scanedQRDetails);                    
                }
            })

    }

    const getAllQRWithThumbnail = () => {
       
         setLoading(true)
        fetchAllQrCodesWithThumbnail()
            .then(response => {
                dispatch(getQrCodeThumbnail(response.data));
                setChangeOnFav(false)
                setLoading(false);
            })
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const handleChange = (pagination, sorter, current) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        setPageData(updatedOptions)
        getAllQRCodes({
            ...updatedOptions,

        });
    };
    const { confirm } = Modal;
    const handleUpdate = (childQRCodeId,parentQRID) => {
        selectedItem >= 0   && prevCountRef.current!=selectedItem ?
        confirm({
            className: "ant-modal",
            title: 'Are you sure you want to update this Record?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                updateParentByQR(childQRCodeId)
            },
            onCancel() {
                setSelectedItem("")
                prevCountRef.current= ""
            },
        })
        :
        confirm({
            className: "ant-modal",
            title: 'Kindly assign QR Code',
        })
    }

    const getFullDate = (date) => {    
       
        const dateAndTime = date.split('T');
        const time =  dateAndTime[1].split(':')
        const newtime = time[0] + ':' + time[1];
        const times = time[0] > 12 ?   "0" + time[0] % 12 : time[0]
        const newtime1= times + ':' + time[1]
        if (time[0] && time[0] >= 12) {
            return dateAndTime[0] == "0001-01-01" ? "" : dateAndTime[0].split('-').reverse().join('-') + ', ' + newtime1 + " PM ";
        }
        else {
            return dateAndTime[0] == "0001-01-01" ? "" : dateAndTime[0].split('-').reverse().join('-') + ', ' + newtime + " AM ";
        }
    };

    
    const updateParentByQR = (childQrCodeId) => {
        // get pid from state variable parentId
        const changedQrCodes = selectedQrCodePairs;
        let parentQRCodeId = changedQrCodes.find(item => item.child == childQrCodeId).parent;
        updateAllParentQRId(childQrCodeId, parentQRCodeId)
            .then(response => {
                dispatch(updateParentByQRId(response.data))
                toast.success(response.data.message,{
                    className:"toastSuccess",
                }, );
                setSelectedItem("")
                prevCountRef.current= ""
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    };
//states and function for dropdown search
const [searchState, setSearchState] = useState()
const handleInputForDropdown = (e) => {
    const QRProductPresent = allQrDataWithThumbnail.filter((item) => item.qRcodeId.toString().startsWith(e) || item.qRcodeId.toString().includes(e))
  QRProductPresent.length > 0 ? setSearchState("Number") : setSearchState("String")
}
const [selectedQrCodePairs, setSelectedQrCodePairs] = useState([]);
const parentDdChangeHndlr = (selectedParentValue, selectedForChild,defaultValue) => {
         prevCountRef.current = defaultValue;
        let alreadyChanged = false;
        let result = selectedQrCodePairs && selectedQrCodePairs.length > 0 && [...selectedQrCodePairs].map((item) => {
            if (item.child == selectedForChild) {
                item.parent = selectedParentValue
                alreadyChanged = true;
            }
            return item
        })
        if (alreadyChanged) // if already changed once and in the state array
            setSelectedQrCodePairs(result);
        else // add new object for new change
            setSelectedQrCodePairs([...selectedQrCodePairs, { child: selectedForChild, parent: selectedParentValue }]);
        setParentId(selectedParentValue);
        setSelectedItem(selectedParentValue)
    }

    const { Option } = Select;
    const columns = [

        {
            title: "QR Code",
            dataIndex: "qRcodeId",
            className: "qrcode_mapping",
        },
        {
            title : "Location",
            dataIndex: "qRcodeId",
            className: "Locations",
            key: 'index',
            render: (text, record, index) => (
                <>
                {record.location.name}
                </>
                )
        },

        {
            title: "Product Name",
            dataIndex: "product_Name",
            render: (product_Name) => <Tooltip placement='topLeft' className="media-tooltip prdouct-name" title={product_Name}>{product_Name ? product_Name : "- NA -"}</Tooltip>,
        },

        {
            title: "Mf. Date",
            dataIndex: "manufacturing_Date",
            className: "update_qrcode",
            key: 'manufacturing_Date',
            render: ((date) =>               
                getFullDate(date)            
            )        
        },

       
        {
            title: "Assigned Parent QR",
            dataIndex: "parent_QRCodeID",
            key: 'i',
            render: (parent_QRCodeID, row, i) => (
                                 
                    <div className='d-flex qrTable--select qr-img-select mapping wkmapping' >
                        <Select class="form-select mb-0 flex-grow-1 filter-select " aria-label=".form-select-lg example"
                        showSearch
                        style={{ width: 250 }}
                            placeholder="Add Parent"
                            onChange={(value) => {
                                var productFound = allQrDataWithThumbnail.filter((item) => item.product_Name==value)
                                if(productFound.length>0){
                                  parentDdChangeHndlr(productFound[0].qRcodeId, row.qRcodeId,parent_QRCodeID);
                                }
                                else{
                                  parentDdChangeHndlr(value, row.qRcodeId,parent_QRCodeID);
                                }
                            }}
                            id={i + 1}
                            key={row.qRcodeId}
                            defaultValue={parent_QRCodeID}
                            onSearch={(e) => {
                         handleInputForDropdown(e)
                                    }}
                            >

                            <Option key={0} value={0}>{'Assign QR Code'} </Option>

                            {
                                allQrDataWithThumbnail && allQrDataWithThumbnail.map((qrCodes, k) => (

                                    qrCodes.qRcodeId != row.qRcodeId ?
                                        <Option
                                            key={qrCodes.qRcodeId}
                                             value={searchState === "Number" || qrCodes.product_Name == null ? qrCodes.qRcodeId : qrCodes.product_Name}

                                        >
                                            <div className="d-flex gap20 align-items-center">
                                                <div className="avatar-img">
                                                {qrCodes.filename ?
                                                    <>
                                                       < img className="img-mapping" src={qrCodes.filename}></img>{qrCodes.favorites == true ? <img className="favorite_star" src={favoriteIcon}></img> : ""}
                                                    </>
                                                    :
                                                    <>
                                                        < img className="img-mapping" src={LoginImage}></img>{qrCodes.favorites == true ? <img className="favorite_star" src={favoriteIcon}></img> : ""}
                                                    </>
                                                    }
                                                    </div>
                                                <div className="qrDesc content d-flex ">
                                                    {qrCodes.product_Name ? <Tooltip title={qrCodes.product_Name}>
                                                        <h6>{qrCodes.product_Name}</h6>
                                                    </Tooltip>
                                                       
                                                     : <h6>- NA - </h6>}
                                                    <p> {qrCodes.qRcodeId}</p>
                                                   
                                                    <Tooltip title={qrCodes.qR_Description}>
                                                        <p>{qrCodes.qR_Description}</p>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </Option>
                                        : null
                                ))}
                        </Select>
                    </div>                                   
            )
        },

        {
            title: "Update",
            key: 'index',
            className: "update_mapping",
            render: (text, record, index) => (<>
                
                <button className="assign-btn btn " id={index + 1}>
                    <img src={updateIcon} onClick={(e) => handleUpdate(record.qRcodeId, record.parent_QRCodeID)} ></img>
                </button>
            </>)
        },

       

        {
            title: "Favourite",
            key: 'index',
            render: (text, record, index) => (
                <>
                    <Switch
                        onChange={() => handleFavourite(record)}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        checked={record.favorites}
                        onColor="#bb9977"
                        handleDiameter={20}
                        height={16}
                        width={35}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        onHandleColor="#b97a57"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    />
                </>
            )
        },
       

        {
            title: "User Section",
            dataIndex: "",
            render: (record) =>
           
            (           
                <a href={record.qRcodeId} target="_blank" className="preview_Link" onClick={(e) => verifyQRCodeData(record.qRcodeId)}>Preview
                    
            </a>
            )
        },
    ]
    return (
        <div>
           
                <Layout title="QR Mapping">
                    <Toaster />

                    <div className="position-relative reviewPage">
                    </div>

                    {!searchClicked ?
                        <>
                            <div className='ml-4 pt-3 mb-3' >Search QR/Product : <input type="text" className='form-control searchQR' value={search} placeholder='Enter QR/Product'
                                onChange={(e) => {
                                    handleDataSearch(e); setSearchError(false)
                                }}></input>
                            <button onClick={() => handleSearch()} className="searchButton ml-4 mt-2 mt-sm-0">Search</button>
                                {
                                    < small className="text-danger d-block mt-2 searchError">{searchError}</small>
                                }
                            </div>

                            <div className="Viewer-table position-relative mb-4 pagination-sticky">
                                {loading ? <Loader /> : ''}
                                <Table
                                    className='table-responsive antd--detail-table Viewer-table '
                                    columns={columns}
                                    dataSource={table}
                                    pagination={options.pagination.total > 10 ? options.pagination : false}
                                    onChange={handleChange}
                                    paginationTotalRows={total}
                                    scroll={{ x: "750px" }}
                                />
                            </div>
                        </> :
                        <>
                            <div className='ml-4 pt-3 mb-3'>Search QR/Product :  <input type="text" className='form-control searchQR' value={search} placeholder='Enter QR/Product'
                                onChange={(e) => {
                                    handleDataSearch(e)
                                }} ></input>
                                <button className='searchButton ml-4 mt-2 mt-sm-0' onClick={() => handleSearch()} >Search</button>
                            {search == "" ? "" : <button className='searchButton ml-4 mt-2 mt-sm-0' onClick={() => handleResetButton()} >Reset</button>}
                                {
                                    < small className="text-danger d-block mt-2 ms-5">{searchError}</small>
                                }
                            </div>
                            <div className="Viewer-table position-relative mb-4 pagination-sticky">

                                <Table
                                    className='table-responsive antd--detail-table Viewer-table '
                                    columns={columns}
                                    dataSource={searchData}
                                    pagination={searchOptions.pagination.total > 10 ? searchOptions.pagination : false}
                                    onChange={handleChange}
                                    paginationTotalRows={total}
                                    scroll={{ x: "750px" }}
                                />
                            </div>
                        </>
                    }

                </Layout>        </div>
        
    )
}

export default connect()(IndexQrAssigning);
