import { QRAssign } from "../Constants/QRCodesToAssign";

// initial state
const initialState = {
    qrAssign: []
}

export const QRAssignReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case QRAssign.GET_QRCODESNOTMAPPED:
            return { ...state, qrAssign: payload.data };

        default:
            return state;
    }
}
