import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';
import Footer from '../VerticalLayout/Footer';
import axios from "axios"
import React, { useEffect, useCallback, useMemo, useState } from "react";
import * as url from "../../api/uri_helper";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Skeleton } from 'antd';
import Log from "../Authentication/Login";
import Index from "../Dashboard/Index";

const Auth = () => {
  
    const history = useHistory();
    const [encryptedToken, setEncryptedToken] = useState('');
    const [isChecked, setisActive] = useState(true)
    const [isSuccess, setIsSuccess] = useState(false)

    useEffect(() => {       
        let url = location.pathname
        const parts = url.split('/Access/')[1].split('/');

        if (parts.length >= 3) {
            const encodedEmail = parts[0];           
            // Decode the base64-encoded strings
            const decodedEmail = atob(encodedEmail);                      
            RedirectToDashboard(decodedEmail);

        } else {
            console.error('Invalid URL format');
        }
    }, []);
   
    const RedirectToDashboard = async (emailID) => {
       
        let urlData = location.pathname.split('/');             
        setEncryptedToken(urlData[2])      
        if (urlData) {
            const response = await axios
                .post(url.GET_DECRYPTEDTOKEN,
                    {
                        token: emailID
                    })
                .then(response => {
                    
                    if (response.data.success) {
                        setIsSuccess(true)                       
                        localStorage.setItem('UserId', response.data.data.userId); // New changes
                         localStorage.setItem('isChecked', isChecked ? isChecked : '');
                        localStorage.setItem('userName', response.data.data.userName);
                        localStorage.setItem('tekaHelpToken', response.data.data.token);                           
                            history.push({
                                pathname: "/Dashboard",
                                state: { from: location.pathname },                               
                            });                                               
                    }
                })
                .catch((err) => {
                    console.log("Err: ", err);
                });
        }
    }

    return (
        <>
            {isSuccess ?
                <Index /> : <Skeleton active avatar />}
        </>
    )
   
};
export default connect()(Auth)
