import { QRParentChildData } from "../Constants/QRParentChild"
// initial state
const initialState = {
    qrDetails: []
}

export const QrParentChildReducers = (state = initialState, { type, payload }) => {
    switch (type) {
       
        case QRParentChildData.GET_QRParentChildData:
            return { ...state, qrDetails: payload };
            
        default:
            return state;
          
    }
};