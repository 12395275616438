import React, { useEffect, useCallback, useMemo, useState } from "react";
import axios from "axios"
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
//import Message from 'antd-message';
import toast, { Toaster } from 'react-hot-toast';
// import loading from "../../assets/images/loading.gif"
import Loader from "../loader";
////#Developer
import Layout from '../Layout';
import * as url from "../../api/uri_helper"
// Import static images
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import Add1 from "../../assets/images/Add.svg";
import { METHODS } from "http";
import { setLocationType, updateLocationTypeData } from "../../Redux/Actions/LocationActions";
import { fetchAllLocationComapnyId, fetchCompanyDetails, fetchLocationCompanyData, fetchLocationProducts, fetchUserData } from "../../Redux/Api/fetch.api";
import {  setUserType } from "../../Redux/Actions/UserActions";
import { Formik, Field, useFormik } from 'formik';
import { Input, Form, FormFeedback } from "reactstrap"
import * as Yup from 'yup';
import { SetCompanyDetails, setSelectedCompanydDetails } from "../../Redux/Actions/CompanyActions";
import { setRelationData, updateCompanyLocationData, updateRelationData, addCompanyLocationData } from "../../Redux/Actions/RelationActions";
import "../../../src/custom.css"

const Add = () => {
    const allLocationTypes = useSelector((state) => state.allLocationType.location)
    const allCompanyData = useSelector((state) => state.allCompanyDetails.company)
    const allCompanyLocation = useSelector((state)=>state.allCompanyLocationData.relation)

    const [currentLocationId, setCurrentLocationId] = useState(0);
    const [currentCompanyId, setCurrentCompanyId] = useState(0);
    const [id, setId] = useState();
    const [loading, setLoading] = useState(false);
  
    const [isActive, setisActive] = useState(true)
    const [currentId, setCurrentId] = useState(null);
    const [checked, setChecked] = useState(true);
    const [disable, setDisable] = useState(false);


    const location = useLocation();
    const dispatch = useDispatch();
    let history = useHistory();
    const handleChange = () => {
        setisActive(!isActive)
    }

    useEffect(() => {
       
        let urlData = location.pathname.split('/');
        
        setCurrentId(urlData[3])

        if (currentId) {

        setLoading(true);
            fetchAllLocationComapnyId(urlData[3]).then(
                response => {     
               
                    setCurrentCompanyId(response.data.data.companyId)
                    setCurrentLocationId(response.data.data.locationId)
                    setisActive(response.data.data.active)
                    setLoading(false)
                })

        }
        
    }, [currentId])

    useEffect(() => {
       
        getLocationData()
        getCompanyDetails()
        // getLocationCompanyData()
        
    }, [])
    const getLocationData = () => {
       
        fetchLocationProducts()
            .then(response => dispatch(setLocationType(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
           
    }

    const getCompanyDetails = () => {
       
        fetchCompanyDetails()
            .then(response => dispatch(SetCompanyDetails(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    // const getLocationCompanyData = () => {
       
    //     fetchLocationCompanyData()
    //         .then(response => dispatch(getLocationCompanyData(response.data)))
    //         .catch((err) => {
    //             console.log("Err: ", err);
    //         })
           
    // }
  

    const addRelationData = async () => {
       
            const response = await axios.post(url.POST_COMPANYLOCATIONDATA, {
                locationId: currentLocationId,
                companyId: currentCompanyId,
                Active: isActive
            }).then(response => {
                dispatch(addCompanyLocationData(response.data))
               
                if (response.data.success) {
                    toast.success(response.data.message,{
                        className:"toastSuccess",
                    }, );
                    history.push('/Relation');
                }
                else {
                    toast.error(response.data.message,{
                        className:"toastSuccess",
                    }, );

                }
              
            })
                .catch((err) => {
                    console.log("Err: ", err);
                    setDisable(false)

                    
                });
            setLoading(false);
    };

    const updateLocationType = async () => {
        
            const response = await axios.put(url.UPDATE_COMPANYLOCATIONDATA, {
                id: currentId,
                locationId: currentLocationId,
                companyId: currentCompanyId,
                Active: isActive
            }).then(response => {
                dispatch(updateCompanyLocationData(response.data))
                toast.success(response.data.message,{
                    className:"toastSuccess",
                }, );
                    history.push('/Relation');
                
            })
                .catch((err) => {
                    console.log("Err: ", err);
                    setDisable(false)
                });
            setLoading(false);
        
    };
    
    let pageButton = "Update"

    if(!currentId)
    {
       pageButton = "Save"
    }

    /*if (loading) return <Loader />;*/
    return (
        <Layout title="Location Mapping"><Toaster/>
            <div className="position-relative card--height">
                <div className="card-box-header d-inline-flex w-100 align-items-center">
                    <h4 className="m-0">
                        <Link className="backBTN" to="/Relation">
                            <img src={Arrow_leftbrown}></img>
                            Back
                        </Link>
                    </h4>
                </div>      
                <div className="content-area">
                {/* <Form className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}> */}
                        <div className="row">
                        <div class="col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label>Company</label>
                                    <select class="form-select mb-0  filter-select" aria-label=".form-select-lg example" 
                                     value={currentCompanyId}
                                     onChange={(e) => { setCurrentCompanyId(e.target.value) }}>
                                        <option value="0">Select Company</option>                                        
                                        {
                                        allCompanyData && allCompanyData.map(company =>
                                            company.active && < option key = { company.companyId } value = { company.companyId } >
                                                    {company.companyName} </option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div> 

                        <div class="col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label>Location</label>
                                    <select class="form-select mb-0  filter-select" aria-label=".form-select-lg example" 
                                     value={currentLocationId}
                                    onChange={(e) => { setCurrentLocationId(e.target.value) }}>
                                        <option value="0">Select Location</option>                                        
                                        {
                                            allLocationTypes && allLocationTypes.map(location =>
                                                location.active && <option key={location.locationId} value={location.locationId}>
                                                    {location.name} </option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div> 

                            <div className="form-checkbox mb-4">
                                    {loading && currentId == undefined || currentId == null || currentId == isNaN ?
                                        <label>
                                            <input type="checkbox" defaultChecked={checked} name="isActive" value={isActive} onChange={() => handleChange()} ></input> Active</label> :

                                        <label>  <input type="checkbox" name="isActive" checked={isActive} onChange={() => handleChange()} ></input>
                                            Active</label>}
                                </div>
                           

                            <div className="col-md-12">
                              {currentLocationId && currentCompanyId ?  <button className="btn btn-primary" 
                              onClick= {currentId == undefined || currentId == null || currentId == isNaN ? addRelationData : updateLocationType}>{pageButton}</button>
                              :  <button className="btn btn-primary" disabled 
                              onClick= {  currentId == undefined || currentId == null || currentId == isNaN ? addRelationData : updateLocationType}>{pageButton}</button>} 
                                
                                <Link className="btn btn-bordered ms-2" to="/Relation">Cancel</Link>

                            </div>
                        </div>
                    {/* </Form> */}
                </div>
            </div>
        </Layout>
    );
};
export default connect()(Add);

