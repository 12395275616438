import React, { useState } from "react";
import ImageUploader from "react-images-upload";

const SupportUploadImage = (props) => {

    const [isActive, setIsActive] = useState(false);
    const [data, setData] = useState()

    const onDrop = (pictureFiles, pictureDataURLs = []) => {
        setIsActive(!isActive);
        setData(pictureDataURLs);

        const newImagesUploaded = pictureDataURLs.slice(props.defaultImages.length);

        props.handleChange(newImagesUploaded);

    }

    return (
        <ImageUploader
            className={isActive ? 'active-image-upload' : ''}
            withIcon={false}
            withLabel={false}
            withPreview={false}
            buttonText={props.buttonText}
            singleImage={props.images}
            onChange={onDrop}
            imgExtension={['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.webp', '.heic']}
            defaultImages={props.defaultImages}
            accept={"accept=image/*"}
        />
    );

}
export default SupportUploadImage; 

