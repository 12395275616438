import React, { useState } from "react"
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

// Import static images 
import logo from "../../UserAssets/images/authenteklogo.png"
import TEkaHelp from "../../assets/images/TEkaHelp.svg";
import LeftArrow from "../../assets/images/leftArrow.svg";
import favIcon from "../../assets/images/fav.ico"
const Header = ({handleClose,handleOpen, isSideBaropen}) => {
    // const [isOpen, setIsOpen] = useState(false);
    // const handleTrigger = () => setIsOpen(!isOpen);

    return (
        <div className="dash-log">
            <NavbarBrand tag={Link} to="/Dashboard">
                <div className="d-flex align-items-center">
                  
                    <img className='logo-large' src={logo}></img>
                      <img className='logo-small' src={favIcon}></img>
                   
                </div>
            </NavbarBrand>

            <button className="hamburger" onClick={() => handleOpen()} >
                <img src={LeftArrow}></img>
            </button>

            <button className={["hamburger-rotate", isSideBaropen? "active":""].join(" ")} onClick={() => handleClose()}>
                <img src={LeftArrow}></img>
            </button>
        </div>
    )
}

export default Header
