import { GuideTypes } from "../Constants/Guide-Types"

// initial state
const initialState = {
    guideTypes: []
}

export const GuideReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case GuideTypes.SET_GUIDETYPES:
            return { ...state, guideTypes: payload.data };
        //Add GuideType
        case GuideTypes.ADD_GUIDETYPES:
            return { ...state, guideTypes: payload };

        //Delete GuideType
        case GuideTypes.DELETE_SELECTED_GUIDETYPES:
            return { ...state, guideTypes: payload };

        default:
            return state;
    }
};