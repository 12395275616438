import { QRTypes } from "../Constants/QR";

export const setLocationTypeData = (locationTypeData) => {
    return {
        type: QRTypes.SET_LOCATIONTYPEDATA,
        payload: locationTypeData
    };
};


//Get Qrcode Data

export const getQrCodeData = (qrData) => {
    return {
        type: QRTypes.GET_QRCODEDATA,
        payload: qrData
    };
};


export const getQrCodeThumbnail = (qrData) => {
    return {
        type: QRTypes.GET_QRCODETHUMBNAIL,
        payload: qrData
    };
};

//Add Qrcode SaveMedia

export const saveMedia= (qrData) => {
    return {
        type: QRTypes.SAVE_MEDIATYPES,
        payload: qrData
    };
};

//Update location by QRCode
export const updateLocationByQRCode= (qrData) => {
    return {
        type: QRTypes.PUT_LOCATIONIDBYQRCODE,
        payload: qrData
    };
};

export const getVirtualQRCode = (qrData) => {
    return {
        type: QRTypes.GET_VIRTUALQRCODE,
        payload: qrData
    };
};


