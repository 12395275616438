import { allQRByLocationId } from "../Constants/qrByLocationId";
// initial state
const initialState = {
    allQRByLocation: []
}

export const allQRByLocation = (state = initialState, { type, payload }) => {
    switch (type) {
       
            case allQRByLocationId.GET_AllQRByLocationId:
                return { ...state, allQRByLocation: payload.data };

        default:
            return state;
    }
};