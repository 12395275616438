const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (error) => reject(error));
        image.setAttribute("crossOrigin", "anonymous");
        image.src = url;
    });

async function getCroppedImg(imageSrc, pixelCrop) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Set canvas dimensions to match the crop area
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // Draw the image cropped onto the canvas
    ctx.drawImage(
        image,
        pixelCrop.x, pixelCrop.y, // Start cropping from (x, y)
        pixelCrop.width, pixelCrop.height, // Crop width and height
        0, 0, // Place the cropped result at (0, 0) on the canvas
        pixelCrop.width, pixelCrop.height // Draw the cropped image at the canvas size
    );

    // Extract the image name from the data URL
    const nameMatch = imageSrc.match(/name=([^;]+)/);
    const imageName = nameMatch ? nameMatch[1] : 'cropped-image.jpeg';

    const currentTime = new Date().toISOString().replace(/[:.]/g, "-");
    // Create the new data URL with the name included
    const croppedImageDataUrl = canvas.toDataURL("image/jpeg").replace(
        "data:image/jpeg;base64,",
        `data:image/jpeg;name=${imageName}_${currentTime};base64,`
    );

    return croppedImageDataUrl;
}

export const cropImage = async (image, croppedAreaPixels, onError) => {
    try {

        const croppedImage = await getCroppedImg(image, croppedAreaPixels);
        return croppedImage;
    } catch (err) {
        onError(err);
    }
}

