

import {ThumbnailDetails} from "../Constants/Thumbnail"
// initial state
const initialState = {
    thumbnail: []
}

export const ThumbnailReducers = (state = initialState, { type, payload }) => {
    switch (type) {
       

  
        case ThumbnailDetails.POST_ThumbnailDetails:
            return { ...state, thumbnail: payload };

       
        
        default:
            return state;
    }
};