import { virtualQRByLocationId } from "../Constants/VirtualQrByLocation";
// initial state
const initialState = {
    allVirtualQRByLocation: []
}

export const allVirtualQRByLocation = (state = initialState, { type, payload }) => {
    switch (type) {

        case virtualQRByLocationId.GET_VIRTUALQRByLocationId:
            return { ...state, allVirtualQRByLocation: payload.data };

        default:
            return state;
    }
};
