import { DashboardCount,GetAllThumbnail } from "../Constants/DashboardCount"


export const getDashboardCount = (dashboard) => {
    return {
        type: DashboardCount.GET_DASHBOARDCOUNT,
        payload: dashboard
    };
};
export const getThumbnail =(data)=>{
    return {
        type : GetAllThumbnail.GET_ALLTHUMBNAIL,
        payload: data  
    }
}