import { CopyQRCodeId } from "../Constants/CopyQRCode"
// initial state
const initialState = {
    copyQRData: []
}

export const CopyQRDataId = (state = initialState, { type, payload }) => {
    switch (type) {
  
        //Developer
        case CopyQRCodeId.SET_CopyQRCodeIdData:
            return { ...state, copyQRData: payload };

            default:
            return state;
    }
};