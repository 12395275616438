import React, { useState } from "react"
import Header from "../UserLayout/Header";
import Footer from "../UserLayout/Footer";
import comingSoon from "../../UserAssets/images/comingSoon.png"

const AboutUs = () => {
    return (
        <div>
            <Header
                isSupportIcon={false}
            ></Header>
            <div className="about-us inner-section">
                <div className="container">
                    <div className="card-box">
                <h3>ABOUT US</h3>
                <p>TEKaHelp is a global platform that connects users with instant help through the use of QR codes attached to all different types of media.</p>
                <p>Brought to you by AuthenTEK Solutions LLC.</p>

                    </div>
            </div>
            </div>
            <Footer></Footer>
        </div>
    )}
export default AboutUs