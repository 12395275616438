import { CompanyLocationMapping } from "../Constants/CompanyLocationMapping";
// initial state
const initialState = {
    CompanyLocationMappingData: []
}

export const CompanyLocationMappingReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case CompanyLocationMapping.SET_COMPANYLOCATIONMAPPING:
            return { ...state, CompanyLocationMappingData: payload.data };

        default:
            return state;
    }
};