import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { connect } from 'react-redux';
import * as url from "../../api/uri_helper"
import { fetchLocationProducts } from "../../Redux/Api/fetch.api";
import { Modal } from 'antd';
import 'antd/dist/antd.css';
import Loader from "../loader";
//import Message from 'antd-message';
import toast, { Toaster } from 'react-hot-toast';
// Import Layout
import Layout from '../Layout';

// Import static images
import Add from "../../assets/images/Add.svg";
import Trash_duotone_line from "../../assets/images/Trash_duotone_line.svg";
import Search_light from "../../assets/images/Search_light.svg";
import Union from "../../assets/images/Union.svg";
import double_arrow from "../../assets/images/double-arrow.svg";
import Edit_light from "../../assets/images/Edit_light.svg";
import Trash_light from "../../assets/images/Trash_light.svg";
import axios from "axios";
import { setLocationType, deleteLocationData } from "../../Redux/Actions/LocationActions";
import Table, { TableProps } from "antd/lib/table";
import { Tooltip } from 'antd';

const IndexLocationType = () => {
    const allLocationTypes = useSelector((state) => state.allLocationType.location);
    console.log("data", allLocationTypes)
    const dispatch = useDispatch();
    const history = useHistory()
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [totalIndex, setCurrentIndex] = useState()
    const [sort, setSort] = useState('');
    const [sortAdd, setSortAdd] = useState('');
    const [options, setOptions] = useState({
        pagination: { current: 1, pageSizeOptions: [5, 10, 20, 50] },

    });
    const [table, setTable] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        getProducts(options)
    }, [])

    const getProducts = (options) => {
        setLoading(true)
        fetchLocationProducts({
            PageIndex: options.pagination.current,
            PazeSize: 5,
        })
            .then(response => {
                dispatch(setLocationType(response.data))
                setTable(response.data.data)
                setOptions({
                    ...options,
                    pagination: {
                        current: options.pagination.current,
                        total: response.data.count,
                        pageSizeOptions: ['10', '20', '50'],
                        showSizeChanger: true,
                    }
                })
                // setLoading(false);
            })
            .catch((err) => {
                console.log("Err: ", err);
            })
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    const handleChange = (pagination, sorter, current) => {

        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        getProducts({
            ...updatedOptions,
        });

    };
    const deleteLocationTypeData = async (locationId) => {
        setLoading(true)
        await axios.delete(url.DELETE_LOCATIONTYPE + '/' + locationId, {
        }).then(response => {
            dispatch(deleteLocationData(response.data))           
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            toast.success(response.data.message,{
                className:"toastSuccess",
            }, );
            getProducts(options)

        }).catch((err) => {
            console.log("Err: ", err);
        });
        setLoading(true)
    }

    const { confirm } = Modal;
    const handleDelete = (locationId) => {
        confirm({
            title: 'Are you sure you want to delete this record?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteLocationTypeData(locationId)
            },
            onCancel() {
            },
        });
    }

    const handleEdit = (locationId) => {
        history.push('/Location/edit/' + locationId)
        console.log(locationId)
    }

    const uniqueIds = [];
    const filterDataAddress = data => formatter => data.filter(element => {
       
        if (element.address) {
            const isDublicate = uniqueIds.includes(element.address);
            if (!isDublicate) {
                uniqueIds.push(element.address);
                return true;
            }
            return false;
        }
    }).map(item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const filterData = data => formatter => data ? data.map(item => ({
        text: formatter(item),
        value: formatter(item)
    })): " ";

    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "",
            /* render:(text, record, index) => `${(options.pagination.current - 1) * 10 + index +1}`*/
            /* render: (text, record,index) =>`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index +1}`*/
            render: (text, record, index) => { setCurrentIndex(`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}`); return `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}` },
        },
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            filters: filterData(table)(i => i.name),
            onFilter: (value, record) => record.name === value,
            sortOrder: sort,
            onHeaderCell: () => ({
                onClick: () => setSort(sort === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            })

        },
        {
            title: "Address",
            dataIndex: "address",
            render: (address) => <Tooltip title={address}>{address}</Tooltip>,
            sorter: (a, b) => a.address.localeCompare(b.address),
            filters: filterDataAddress(table)(i => i.address),
            onFilter: (value, record) => record.address.indexOf(value) === 0,
            sortOrder: sortAdd,
            onHeaderCell: () => ({
                onClick: () => setSortAdd(sortAdd === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            })
        },
        {
            title: "Status",
            dataIndex: "active",
            render: (data) => (data ? "Active" : "Inactive"),
            filters: [
                { text: 'Active', value: true },
                { text: 'Inactive', value: false }
            ],
            onFilter: (value, record) => {
                return record.active === value
            }

        },
        {
            title: "Action ",
            dataIndex: "",
            render: (data, locationId) => (<><button className="table-btn" >
                <img src={Edit_light} onClick={() => handleEdit(data.locationId)}></img>
            </button>
                <button className="table-btn" >
                    <img src={Trash_light} onClick={() => handleDelete(data.locationId)}></img>
                </button></>)
        },
    ]

    return (
        <Layout title="Location"><Toaster/>
            <div className="position-relative">
                <div className="card-box-header d-inline-flex w-100 align-items-center">
                    <h4 className="m-0">Location</h4>
                    <div className="ms-auto">
                        <Link className="btn btn-primary d-flex align-items-center " to="/Location/Add" ><img src={Add}></img>Add Location</Link>
                    </div>
                </div>
            </div>
            <div className="Viewer-table table-new pagination-sticky device-type position-relative">
            {loading ? <Loader /> : ''}
                <Table
                    className='table-responsive antd--detail-table1 Viewer-table'
                    columns={columns}
                    dataSource={table}
                    pagination={options.pagination.total > 10 && totalIndex > 9 ? options.pagination : false}
                    onChange={handleChange}
                    scroll={{ x: "450px" }}
                />
            </div>
        </Layout>
    )
};
export default connect()(IndexLocationType);
