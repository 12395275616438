import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../loader";
import '../QR/scan.css';
import Layout from '../Layout';
import { Select } from 'antd';
import { Tooltip } from "antd";
import AddVirtual from "../../assets/images/Add.svg";
import { useHistory, useLocation } from "react-router-dom";
import { checkQrVerifyStatus, fetchAllLocationByQRId, fetchNotMappedLocationId, fetchCompanyLocationMappingData, fetchCustomerData, fetchDeviceProducts, fetchGuideProducts, fetchLocationByQRCodeId, fetchLocationDataQr, copyAllQRDataById, fetchLocationProducts, fetchMediaData, fetchProducts, fetchQrCodeData, VerifyQrStatus } from '../../Redux/Api/fetch.api';
import { setLocationType } from '../../Redux/Actions/LocationActions';
import { QrReader } from "react-qr-reader";
import { Link } from 'react-router-dom';
import errorIcon from "../../assets/images/error-icon.svg";
import qrcodeduotone from "../../assets/images/qrcode_duotone.svg"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactPlayer from "react-player";
import UploadImage from "../QR/QRUpload"
import { setDeviceTypes } from '../../Redux/Actions/DeviceActions';
import { setAreaTypes } from '../../Redux/Actions/AreaActions';
import { WebcamCapture } from '../QR/WebcamComponent'
import { updateLocationByQRCode } from '../../Redux/Actions/QR';
import { setMediaTypes } from '../../Redux/Actions/MediaActions';
import * as url from "../../api/uri_helper"
import { copyQRCodeIdData } from "../../Redux/Actions/CopyQRCode";
import favoriteIcon from "../../assets/images/favorite_star.png";
import LoginImage from "../../assets/images/login-image.png";
import ScanQR from "../../assets/images/scan-qr.svg";
import EnterQR from "../../assets/images/enter-qr.svg";
import axios from 'axios';
import { setGuideTypes } from '../../Redux/Actions/GuideActions';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { setCompanyLocationMapping } from '../../Redux/Actions/CompanyLocationMapping';
import { verifyNotMappedLocation } from '../../Redux/Actions/CompanyLocationMapping';
import { fetchAllQrCodesWithThumbnail, AddQRCodes } from "../../Redux/Api/fetch.api";
import { getQrCodeThumbnail } from '../../Redux/Actions/QR';
import '../QrReview/QRReview.css'
//import { Editor } from "react-draft-wysiwyg";
import CKEditor from '@ckeditor/ckeditor5-react';
import ImageCropper from "../QR/CropImage"
import ClassicEditor from 'ckeditor5-build-classic-base64-upload';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { addQrCodes } from "../../Redux/Actions/QrCodeId";
import { EditorState, ContentState } from 'draft-js';
// import {HtmlToDraft}  from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
const { Option } = Select;
//const TOAST_LIMIT = 1;

const AssignQR = () => {
    //const { toasts } = useToasterStore();
    //useEffect(() => {
    //    toasts
    //        .filter((t) => t.visible) // Only consider visible toasts
    //        .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
    //        .forEach((t) => toast.dismiss(t.id)); // Dismiss � Use toast.remove(t.id) removal without animation
    //}, [toasts]);

    const allLocationTypes = useSelector((state) => state.allLocationType.location)
    const allDeviceTypes = useSelector((state) => state.allDeviceType.deviceTypes)
    const allAreaTypes = useSelector((state) => state.allAreaType.areaTypes);
    const allMediaTypes = useSelector((state) => state.allMediaType.mediaTypes);
    const allGuideTypes = useSelector((state) => state.allGuideType.guideTypes)
    const allCompanyLocationMappingData = useSelector((state) => state.allCompanyLocationMapping.CompanyLocationMappingData)
    const [createModal, setCreateModal] = useState(false);
    const [disable, setDisable] = useState(false);
    const dispatch = useDispatch();
    const [copyQRCodeId, setCopyQRCodeId] = useState("Select QR");
    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [getRadioButton, setGetRadioButton] = useState({ show: "show", selected: "show", });
    const [handleLocation, setHandleLocation] = useState(false);
    const [showCamera, setShowCamera] = useState(false);
    const [showEnter, setShowEnter] = useState(false);
    const [currentLocationId, setCurrentLocationId] = useState();
    const [verifyLocationId, setVerifyLocationId] = useState();
    const [currentCompanyId, setCurrentCompanyId] = useState();
    const [currentLocationIdShow, setCurrentLocationIdShow] = useState();
    const [currentDeviceTypeId, setCurrentDeviceTypeId] = useState();
    const [currentAreaTypeId, setCurrentAreaTypeId] = useState();
    const [currentMediaTypeId, setCurrentMediaTypeId] = useState();
    const [currentMediaTypeKey, setCurrentMediaTypeKey] = useState();
    const [currentGuideTypeId, setCurrentGuideTypeId] = useState();
    const [companyName, setCompanyName] = useState([]);
    const [locationName, setLocationName] = useState([]);
    const [countDesc, setCountDesc] = useState(0);
    const [showLocation, setShowLocation] = useState([]);
    const [showLocationNameByQR, setShowLocationNameByQR] = useState([]);
    const [deviceName, setDeviceName] = useState([]);
    const [areaName, setAreaName] = useState([]);
    const [copyModal, setCopyModal] = useState(false);
    const [mediaName, setMediaName] = useState([]);
    const [guideName, setGuideName] = useState([]);
    const [data, setData] = useState('No result');
    const [qrError, setQRError] = useState('');
    const [textError, setTextError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [qrcodeError, setQrcodeError] = useState(false);
    const [validqrError, setValidQrError] = useState(false);
    const [videoMessage, setVideoMessage] = useState(false);
    const [currentCompanyLocationId, setcurrentCompanyLocationId] = useState();
    const [locationNameByQRId, setLocationNameByQRId] = useState();
    const [locationIdByQRId, setLocationIdByQRId] = useState(1);
    const [played, setPlayed] = useState(0);
    const [mediaTypeSelected, setMediaTypeSelected] = useState(false)
    const [id, setId] = useState();
    const [showQrDetail, setShowQrDetail] = useState(false);
    const [locationMapError, setLocationMapError] = useState(false);
    const [modal, setModal] = useState(false);
    const [qrModal, setQrModal] = useState(false);
    const [camera, setCamera] = useState(false);
    const [imgSrcs, setImgSrcs] = useState([]);
    const fileInputRef = useRef(null);
    const [show, setShow] = useState(true);
    const [images, setImages] = useState({
        upload: {
            pictures: [],
            maxFileSize: 5242880,
            imgExtension: [".jpg", ".png", ".jpeg"],
            defaultImages: [
            ]
        }

    })
    const [urlMedia, setUrlMedia] = useState("");
    const [desc, setDesc] = useState('');
    const InputRef = useRef();
    const [source, setSource] = useState();
    const [showVideo, setShowVideo] = useState();
    const [urlFormatError, setUrlFormatError] = useState(false);
    const [videoFormatError, setVideoFormatError] = useState(false);
    const [videoSizeError, setVideoSizeError] = useState(false);
    const [pdfMedia, setPdfMedia] = useState("");
    const [videoMedia, setVideoMedia] = useState("");
    const [showQrDropdown, setShowQrDropdown] = useState();
    const [pdfFileName, setPDFFileName] = useState("");
    const [searchState, setSearchState] = useState()
    const [qrcode, setqrcode] = useState("");
    //const [editorState, setEditorState] = useState(EditorState.createEmpty());
    //const [textMedia, setTextMedia] = useState("");
    const [textMedia, setTextMedia] = useState("");
    let mediaKey = '';
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [videoFileName, setVideoFileName] = useState("");
    const [error, setError] = useState({
        video: '',
        imagesErr: '',
        urlMediaErr: '',
        pdfMediaErr: '',
        // durationError: '',
        textMediaErr: '',

    })

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        getDeviceTypeData();
        getAreaTypeDetails();
        getMediaTypeDetails();
        getGuideTypeDetails();
        getCompanyLocationMapping();
        getLocationDetails();
        getAllQRWithThumbnail();
    }, [])
    const getAllQRWithThumbnail = () => {

        fetchAllQrCodesWithThumbnail()
            .then(response => {
                dispatch(getQrCodeThumbnail(response.data));

            })
            .catch((err) => {
                console.log("Err: ", err);
            })
    }
    //Data from Thumnbail action
    const allQrDataWithThumbnail = useSelector((state) => state.allQrDataWithThumbnail.qrCodeThumbnailData);
    const ProductName = allQrDataWithThumbnail.find(x => x.qRcodeId === parseInt(id))
    const [pdferror, setPdfError] = useState("");
    const [mediaFiles, setMediaFiles] = useState([]);
    const [pdfData, setPdfData] = useState()
    //pdf onchanges
    const onPdfSaveData = (e) => {
        

        setPdfData(e.target.value.replace(/ /g, "_"))
        setUrlMedia()
        setVideoMedia()
        setVideoFileName('')
        setMediaFiles([])
        setTextMedia("")
        const files = e.target.files[0];
        if (files && files.type == "application/pdf") {

            setPDFFileName(files.name.replace(/ /g, "_"));
            setPdfMedia(files)
            setMediaTypeSelected(true)
        }
        else {
            setPdfError("Only PDFs are valid.")
            return;
        }
        setError('')
        setPdfError('')
        //setPDFFileName("");

    };
    const errorShowClass = () => {

        setShow(!show);

    };

    

    const addQRCode = () => {

        if (qrcode) {
            setLoading(true)
            AddQRCodes(qrcode)
                .then(response => {

                    dispatch(addQrCodes(response.data))
                    if (!response.data.success) // False 
                    {
                        toast.error(response.data.message, {
                            className: "toastSuccess",
                        });

                    }
                    else {
                        toast.success(response.data.message, {
                            className: "toastSuccess",
                        })
                        setLocationIdByQRId(response.data.data)
                        setId(qrcode);
                        // setqrcode("")
                        setValidQrError(false)
                        setCreateModal(false);
                        setDisable(false)

                    }
                    setLoading(false)
                })
                .catch((err) => {
                    console.log("Err: ", err);
                });
            setLoading(false)
        }


    }

    const setEditorReference = (ref) => {
       

        if (ref != null) {
            if (ref.innerText != "") {
                setTextMedia(ref.innerHTML);
                setTextError(false)
            }
            else {
                setTextError(true);
            }
        }
        else {
            setTextError(true);
        }
    }

    const radioButtonValueChange = (e) => {
        if (e.target.value === "hide") {
            setGetRadioButton({
                show: "hide",
                selected: e.target.value,
            });
        } else {
            setGetRadioButton({
                show: "show",
                selected: e.target.value,
            });
        }
    };

    const handleInputForDropdown = (e) => {
        const QRProductPresent = allQrDataWithThumbnail.filter((item) => item.qRcodeId.toString().startsWith(e) || item.qRcodeId.toString().includes(e))
        QRProductPresent.length > 0 ? setSearchState("Number") : setSearchState("String")
    }


    const emptyData = () => {
        if (source) {
            setPDFFileName("");
            setPdfMedia("");
        }

    }
    //for video onchange
    const handleFileChange = (event) => {
       
        setPDFFileName()
        setMediaFiles([])
        setPdfMedia()
        setTextMedia()
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const size = file.size / 1024 / 1024;
            const url = URL.createObjectURL(file);
            setShowVideo(url);
            setSource(file.name);
            setVideoMedia(file);
            setVideoFileName(file.name);

            if (file.type == "audio/mpeg") {
                setVideoFormatError(true);
                setLoading(false)
            }
            else {
                setVideoFormatError(false)
                setError('');
                setMediaTypeSelected(true)
                // return;
            }

            if (size > 250) {
                setVideoSizeError(true);
                setLoading(false)
            }
            else {
                setVideoSizeError(false)
                setError('');
                setMediaTypeSelected(true)
                return;
            }
        }
        else {
            setSource();
            setShowVideo();
            setVideoMedia();
            setVideoFileName();
            setPDFFileName();
            setPdfMedia();
            setTextMedia();
            emptyData();


        }
    };

    const urlData = (e) => {
        setMediaFiles([]);
        setUrlMedia(e.target.value)
        var res = e.target.value.match('https?: \/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}');
        if (res == null) {
            setUrlFormatError(true)
        } else {
            setUrlFormatError(false)
            setMediaTypeSelected(true)
        }
        setError('');
    }
    //for video
    const handleErrors = (e) => {

        e.preventDefault();
        let err = { ...error }
        if (source == undefined)
            err.video = "Please select video"
        if (mediaFiles == null)
            err.imagesErr = "Please select image"
        if (urlMedia == '')
            err.urlMediaErr = "Please enter url"
        if (pdfMedia == "")
            err.pdfMediaErr = "Please select pdf"
       
        setError(err)
        if (err.imagesErr != undefined) {
            err.imagesErr = "Please select image"
            return false;
        }

       
    };
    //for image
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const handleChange = (files) => {

        const { pictures } = images.upload;

        setImages(
            {
                ...images,
                upload: {
                    ...images.upload,
                    pictures: [...pictures, ...files]
                },
            },
            () => {
                console.warn("It was added!");
            }
        );
        if (files.length > 0) {
            setMediaFiles(prevFiles => [...prevFiles, ...files]);

            // Check if we need to update the current image index
            const newIndex = Math.max(currentImageIndex, files.length - 1);
            setCurrentImageIndex(newIndex);
            setCurrentImage(files[newIndex]);
            setDialogOpen(true);
            setError('');
            setMediaTypeSelected(true);
        }
    };

    const handleCropComplete = (imagePromise) => {

        imagePromise.then((croppedImage) => {
            setImgSrcs(prev => {
                const newImgSrcs = [...prev, croppedImage];
                setMediaFiles([...newImgSrcs]);
                return newImgSrcs;
            });
            setDialogOpen(false);
        });
    };

    const confirmUpload = (e) => {
        let result = handleErrors(e);
        return result
    };

    const handleEnterManualQR = async () => {
        setDisable(true)
        if (qrcode) {

            fetchLocationByQRCodeId(qrcode)
                .then(response => {
                   
                    if (response.data.success) {
                       setLocationNameByQRId(response.data.data.name)
                        setLocationIdByQRId(response.data.data.locationId)
                       
                        setId(qrcode);
                    }
                    else {

                       
                        setValidQrError(true)
                    }
                  
                })

                .catch((err) => {
                    console.log("Err: ", err);
                    setErrorMessage("QR Code is not valid, please try again.");
                });
        }

        else {
            setQrcodeError(true)
        }

    }

    //upload the QR 
    const onResult = async (result, error) => {
        if (!!result) {
            setShow(true);
            setData(result.text);
            var uri = result.text.toString();
            if (uri.indexOf("/") > 0) { // Example : https://www.tekahelp.com/10000004
                var qrCode = uri.substring(uri.lastIndexOf('/') + 1);
                if (qrCode) {

                    fetchLocationByQRCodeId(qrCode)
                        .then(response => {
                            setLocationNameByQRId(response.data.data.name)
                            setLocationIdByQRId(response.data.data.locationId)
                            if (response.data.success) {
                                setId(qrCode);
                            }
                            else {
                                
                                setErrorMessage("Qr Code is not valid, please try again.");
                            }
                        })
                        .catch((err) => {
                            console.log("Err: ", err);
                            setErrorMessage("QR Code is not valid, please try again.");
                        });
                }
                else {
                    setErrorMessage("Qr Code is not valid, please try again.");
                }
            }
            else {
                setErrorMessage("Qr Code is not valid, please try again.");
            }
        }
        (<p>{data}</p>)

    }

    const RedirecttoQREntries = () => {
        setCreateModal(true)
    }

    const handleCreateModalClose = () => {
        setCreateModal(false);
    };

    const handleError = (err) => {
        setData({ result: "Device camera not found", qrError: err.toString() })
    }

   

    const updateLocationByQRId = async (e) => {
        setLocationIdByQRId(parseInt(currentLocationIdShow));
        fetchAllLocationByQRId(id, currentLocationIdShow)
            .then(response => {
                dispatch(updateLocationByQRCode(response.data))
                toast.success(response.data.message, {
                    className: "toastSuccess",
                },);
            })
            .catch((err) => {
                console.log("Err: ", err);

            });
    };

    const getDeviceTypeData = () => {
        setLoading(true)
        fetchDeviceProducts()
            .then(response => {
                dispatch(setDeviceTypes(response.data))
            })
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const handleCopyModalClose = () => {
        setCopyModal(false);
    };

    const handleAddLocation = () => {
        history.push("/Location");
        setHandleLocation(true);
    }

    const getAreaTypeDetails = () => {
        setLoading(true)
        fetchProducts()
            .then(response => dispatch(setAreaTypes(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
    }
    const getMediaTypeDetails = () => {
        setLoading(true)
        fetchMediaData()
            .then(response => dispatch(setMediaTypes(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const getLocationDetails = () => {
        setLoading(true)
        fetchLocationProducts()
            .then(response => dispatch(setLocationType(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const verifyNotMappedLocations = (location) => {
        fetchNotMappedLocationId(location)
            .then(response => {
                dispatch(verifyNotMappedLocation(response.data))
                if (response.data.success) {
                    toast.success(response.data.message, {
                        className: "toastSuccess",
                    });
                    setLocationMapError(false)
                }
                else {
                    toast.error(response.data.message, {
                        className: "toastSuccess",
                    });
                    setTimeout(() => {
                        history.push('/Relation');
                    }, 4000)

                    setLocationMapError(true)
                }
            })
            .catch((err) => console.log("error", err))

    };

    const getGuideTypeDetails = () => {
        setLoading(true)
        fetchGuideProducts()
            .then(response => dispatch(setGuideTypes(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const getCompanyLocationMapping = () => {
        setLoading(true)
        fetchCompanyLocationMappingData()
            .then(response => dispatch(setCompanyLocationMapping(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const getLocationData = (e) => {
        let locationDetails = allLocationTypes.find(x => x.locationId == e);
        if (locationDetails && locationDetails.name)
            setShowLocationNameByQR(locationDetails.name)
        if (locationDetails && locationDetails.locationId)
            setShowLocation(locationDetails.locationId)
        verifyNotMappedLocations(e);
    }

    const getDeviceDetails = (e) => {
        setCurrentDeviceTypeId(e.target.value)
        let deviceDetails = allDeviceTypes.find(x => x.deviceTypeId == e.target.value);
        { deviceDetails && deviceDetails.name ? setDeviceName(deviceDetails.name) : '' }

    }

    const getCompanyLocationTypeData = (e) => {
        setcurrentCompanyLocationId(e.target.value);
        if (e.target.value == "0")
            setCompanyName([])
        let companyLocationMappingData = allCompanyLocationMappingData.find(x => x.id == e.target.value);
        if (companyLocationMappingData)
            setCurrentLocationId(companyLocationMappingData.location.locationId); // get the selected location id
        if (e.target.value != "0") {
            setCompanyName(companyLocationMappingData.company.companyName)
            setLocationName(companyLocationMappingData.mappingName)
            setCurrentCompanyId(companyLocationMappingData.company.companyId)
        }
    }

    const getAreaDetails = (e) => {
        setCurrentAreaTypeId(e.target.value)
        let areaDetails = allAreaTypes.find(x => x.areaTypeId == e.target.value);
        { areaDetails && areaDetails.name ? setAreaName(areaDetails.name) : '' }
    }

    const getMediaDetails = (e) => {
        if (fileInputRef.current != null) { fileInputRef.current.value = ''; }
        if (InputRef.current != null) { InputRef.current.value = ''; }
        setCurrentMediaTypeId(e.target.value)
        let mediaDetails = allMediaTypes.find(x => x.mediaTypeId == e.target.value);
        if (mediaDetails && mediaDetails.name && mediaDetails.key) {
            setMediaName(mediaDetails.name)
            setCurrentMediaTypeKey(mediaDetails.key)
        }
        if (e.target.value === "Select Media") {
            setMediaName("Select Media")
            setCurrentMediaTypeKey("Select Media")
            setDesc("");
        }

        setUrlMedia("");
        setVideoMedia("");
        setVideoFileName("")
        setShowVideo("");
        setPDFFileName("");
        setSource();
        setPdfMedia("");
        setTextMedia("")
    }

    const getGuideDetails = (e) => {
        setCurrentGuideTypeId(e.target.value)
        let guideDetails = allGuideTypes.find(x => x.guideTypeId == e.target.value);
        { guideDetails && guideDetails.name ? setGuideName(guideDetails.name) : '' }
    }

    const copyMediaData = () => {
        
        const formData = new FormData();
        formData.append("secondaryQR", id);
        formData.append("primaryQR", copyQRCodeId);
        formData.append("QRCodeID", id);
        // formData.append("QRDescription", qrCodeDescription);
        // formData.append("Product_Name", productData);
        copyAllQRDataById(formData).then(response => {
            dispatch(copyQRCodeIdData(formData))
            if (response.data.success) {
                toast.success(response.data.message, {
                    className: "toastSuccess",
                });
                history.push('/QrReview');
                setModal(false);
                setCopyModal(false);
                setCopyQRCodeId("");
                setCompanyName("");
            }
            else {
                setCopyModal(false);
                toast.error(response.data.message, {
                    className: "toastSuccess",
                });
                // setLoading(false);
            }
        })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const handleClear = () => {
        setCompanyName([]);
        setcurrentCompanyLocationId('');
        setLocationName("Select Location");
        setcurrentCompanyLocationId("0")
    }

    const handleImages = (data) => {
        setImgSrcs(prev => [...prev, data]);
        setCamera(false);
        setModal(true);
    }

    const handleClose = () => {
        setShowQrDetail(false);
        setModal(false);
        setQrModal(false);
        setId(false);
        setCamera(false);
        setCompanyName([]);
        setcurrentCompanyLocationId("");
        setLocationName([]);
        setCurrentLocationId()
        setCurrentAreaTypeId("");
        setCurrentGuideTypeId("");
        setCurrentMediaTypeId("");
        setCurrentDeviceTypeId("");
        setShowVideo();
        setCurrentMediaTypeKey("");
        setDesc("");
        setCountDesc("");
        setUrlFormatError("");
        setUrlMedia("");
        setTextMedia("");
        setMediaFiles([])
        setImgSrcs([])
        setqrcode("")
    }

    const handleShow = () => {
        setQrModal(false);
        setModal(false);
        setCamera(false);
        setShowQrDetail(true);
        setcurrentCompanyLocationId("");
        setCurrentAreaTypeId("");
        setCurrentGuideTypeId("");
        setCurrentMediaTypeId("");
        setCurrentDeviceTypeId("");
        setShowVideo();
        setCurrentMediaTypeKey("");
        setDesc("");
        setUrlFormatError("");
        setUrlMedia("");
        setTextMedia("");
        setMediaFiles([])
        setImgSrcs([])
        setqrcode("")
    }

    const handleQRModalShow = () => {
        setErrorMessage("");
        setShowQrDetail(false);
        setQrModal(false);
        setCamera(false);
        setModal(true);
        //setSuccessMessage();
    }
    const handleCopyQRdata = () => {
        setCopyModal(true);
    };

    const handleRemoveImgClick = () => {
        setImgSrcs((prevImgs) => prevImgs.slice(1));
    }

    function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    const saveMediaType = async (e) => {
        setLoading(true);
       
        let errResult = confirmUpload(e);
        if (errResult == true || !urlFormatError && !videoFormatError && !textError) {
            // setLoading(false);
            let isMedia = false;
            // setLoading(true);
            const formData = new FormData();
            //setLoading(true);
            //image
            mediaFiles.forEach((mFile) => {

                try {
                    // Ensure mFile is a valid data URL
                    if (typeof mFile === 'string' && mFile.startsWith('data:')) {
                        // Split the data URL into the metadata and the Base64 string
                        const [header, base64Data] = mFile.split(',');

                        // Extract the content type from the header
                        const contentType = header.split(':')[1].split(';')[0];

                        // Extract the filename directly from the header
                        const imageUrlParts = header.split(';');
                        let fileName = imageUrlParts.find(part => part.startsWith('name=')).split('name=')[1];

                        // Convert Base64 string to a Blob
                        const blob = b64toBlob(base64Data, contentType);

                        // Create a File object and append it to formData
                        const _fileData = new File([blob], fileName, { type: contentType });
                        formData.append("MediaFiles", _fileData);
                        isMedia = true;
                    } else {
                        console.error("Invalid file format:", mFile);
                    }
                } catch (error) {
                    console.error("Error processing file:", error);
                }
            });
            // for source / vedios
            if (source) {
                setMediaFiles([])
                setImages()

                const vedioData = new File([
                    new Blob([videoMedia])
                ], videoFileName);
                formData.append("MediaFiles", vedioData);
                isMedia = true;
                setVideoMessage(true);
            }
            // for PDF 
            if (pdfMedia) {

                setMediaFiles([])
                setImages()
                const pdfData = new File([
                    new Blob([pdfMedia])
                ], pdfFileName);
                formData.append("MediaFiles", pdfData);
                isMedia = true;
            }

            // For TEXT

            if (textMedia) {
                setImages()
                isMedia = false;
            }
            formData.append("MediaData", urlMedia);
            formData.append("locationId", currentLocationId);
            formData.append("companyId", currentCompanyId);
            formData.append("qRCode", id);
            formData.append("deviceTypeId", currentDeviceTypeId);
            formData.append("areaTypeId", currentAreaTypeId);
            formData.append("guideTypeId", currentGuideTypeId);
            formData.append("mediaTypeId", currentMediaTypeId);
            formData.append("MediaTypeKey", currentMediaTypeKey);
            //formData.append("description", desc);
            formData.append("name", 'abc');
            formData.append("IsMedia", isMedia);

            if (mediaKey != "Text") {
                formData.append("description", desc);
            }
            else {
                formData.append("description", textMedia);
            }
            const response_ = await axios({
                method: 'post',
                url: url.SAVEMEDIA_QRDATA,
                data: formData,
            }).then(response => {
                
                toast.success(response.data.message, {
                    className: "toastSuccess",
                });
                setLoading(false);
                setVideoMessage(false);
                setUrlMedia("");
                setPdfMedia("");
                setPDFFileName();
                setVideoMedia("");
                setSource("");
                setMediaFiles([])
                setImgSrcs([]);
                setTextMedia("");
               // setEditorState("");
                setCurrentAreaTypeId("");
                setCurrentGuideTypeId("");
                setCurrentMediaTypeId("");
                setCurrentDeviceTypeId("");
                setCurrentMediaTypeKey("");
                setDesc("");
                setCountDesc("");
                setShowVideo("");
            })
                .catch((err) => {
                    console.log("Err: ", err);
                });

            setLoading(false);
            setImages({
                upload: {
                    pictures: [],
                    maxFileSize: 5242880,
                    imgExtension: [".jpg", ".png", ".jpeg"],
                    defaultImages: []
                }
            });
            setSource();
            setUrlMedia();
            setPdfMedia();
            setVideoMedia();
            setTextMedia("");
           // setEditorState("")
        }
    };

    const saveMediaData = async (e) => {
       
        setLoading(true);
        let errResult = confirmUpload(e);
        if (errResult == true || !urlFormatError && !videoFormatError || !textError) {
            let isMedia = false;
            const formData = new FormData();
            mediaFiles.forEach((mFile) => {

                try {
                    // Ensure mFile is a valid data URL
                    if (typeof mFile === 'string' && mFile.startsWith('data:')) {
                        // Split the data URL into the metadata and the Base64 string
                        const [header, base64Data] = mFile.split(',');

                        // Extract the content type from the header
                        const contentType = header.split(':')[1].split(';')[0];

                        // Extract the filename directly from the header
                        const imageUrlParts = header.split(';');
                        let fileName = imageUrlParts.find(part => part.startsWith('name=')).split('name=')[1];

                        // Convert Base64 string to a Blob
                        const blob = b64toBlob(base64Data, contentType);

                        // Create a File object and append it to formData
                        const _fileData = new File([blob], fileName, { type: contentType });
                        formData.append("MediaFiles", _fileData);
                        isMedia = true;
                    } else {
                        console.error("Invalid file format:", mFile);
                    }
                } catch (error) {
                    console.error("Error processing file:", error);
                }
            });
            // for source / vedios
            if (source) {
                const vedioData = new File([
                    new Blob([videoMedia])
                ], videoFileName);
                formData.append("MediaFiles", vedioData);
                isMedia = true;
                setVideoMessage(true);
            }
            // for PDF 
            if (pdfMedia) {
                const pdfData = new File([
                    new Blob([pdfMedia])
                ], pdfFileName);
                formData.append("MediaFiles", pdfData);
                isMedia = true;
            }

            if (textMedia) {
                isMedia = false;
            }
            formData.append("MediaData", urlMedia);
            formData.append("locationId", currentLocationId);
            formData.append("companyId", currentCompanyId);
            formData.append("qRCode", id);
            formData.append("deviceTypeId", currentDeviceTypeId);
            formData.append("areaTypeId", currentAreaTypeId);
            formData.append("guideTypeId", currentGuideTypeId);
            formData.append("mediaTypeId", currentMediaTypeId);
            formData.append("MediaTypeKey", currentMediaTypeKey);
            // formData.append("description", desc);
            formData.append("name", 'abc');
            formData.append("IsMedia", isMedia)
            if (mediaKey != "Text") {
                formData.append("description", desc);
            }
            else {
                formData.append("description", textMedia);
            }
            const response_ = await axios({
                method: 'post',
                url: url.SAVEMEDIA_QRDATA,
                data: formData,
            }).then(response => {
                toast.success(response.data.message, {
                    className: "toastSuccess",
                });
                setLoading(false);
                setVideoMessage(false);
                setUrlMedia("");
                setPdfMedia("");
                setVideoMedia("");
                setSource("");
                setTextMedia("");
                setMediaFiles([])
                setImgSrcs([]);
                setCurrentMediaTypeId("");
                setCurrentMediaTypeKey("");
                setDesc("")
                setCountDesc("");
                setShowVideo("");
            })
                .catch((err) => {
                    console.log("Err: ", err);
                });

            setLoading(false);
            setImages({
                upload: {
                    pictures: [],
                    maxFileSize: 5242880,
                    imgExtension: [".jpg", ".png", ".jpeg"],
                    defaultImages: []
                }
            });
            setSource();
            setUrlMedia();
            setPdfMedia();
            setVideoMedia();
            setTextMedia();
            //setEditorState("")
        }
    };

    const handleCancel = () => {
        setImgSrcs([...imgSrcs, currentImage]);
        setDialogOpen(false);
    };

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setTextMedia(data);
        setTextError(data.trim() === '');
    };

    const selectMedia = (params) => {
        mediaKey = params
        switch (params) {
            case "img":
                return <div className=" mt-3 qrBtn">
                    <div className="col-sm-12 text-center">
                        <UploadImage
                            {...images.upload}
                            handleChange={handleChange}
                            mediaTypeSelected={mediaTypeSelected}
                            setMediaTypeSelected={setMediaTypeSelected}
                        />
                        {currentImage && (
                            <ImageCropper
                                open={dialogOpen}
                                image={currentImage}
                                onComplete={handleCropComplete}
                                onCancel={handleCancel}
                                containerStyle={{
                                    position: "relative",
                                    width: "100%",
                                    height: 250,
                                    background: "#d3d3d3",
                                }}
                            />
                        )}
                    </div>
                    <div className='img-section m-0 flex-wrap d-flex align-items-center'>
                        {imgSrcs.map((imgSrc, index) => (
                            <div key={index} className='img--box position-relative'>
                                <span className="position-absolute" onClick={() => handleRemoveImgClick(index)}>X</span>
                                <img src={imgSrc} alt={`Cropped Image ${index}`} />
                            </div>
                        ))}
                    </div>
                    {error ? <small className="text-danger d-block mt-2">{error.imagesErr}</small> : ""}
                </div>;

            case "vdo":
                return <div className="VideoInput ">
                    <input
                        ref={InputRef}
                        className="VideoInput_input"
                        type="file"
                        onChange={(e) => handleFileChange(e)}
                        accept=".mov,.mp4,.wmv,.avi,.MOV"
                    />
                    {showVideo && showVideo.length > 0 ? <ReactPlayer
                        className="video_react"
                        url={showVideo}
                        width="450px"
                        height="300px"
                        controls={true}
                        playing={false}
                        onDuration={(duration) => {
                            setPlayed(duration);
                        }}
                        volume={0.5} 
                    /> : ""}
                    {error ? <small className="text-danger d-block mt-2">{error.video}</small> : ""}
                    {/* {error ? <small className="text-danger d-block mt-2">{error.durationError}</small> : ""}*/}
                    {
                        < small className="text-danger d-block mt-2">{!videoFormatError ? ' ' : 'Invalid Format'}</small>

                    }
                    {
                        < small className="text-danger d-block mt-2">{!videoSizeError ? ' ' : 'Size cannot exceed 250 MB'}</small>

                    }
                </div>;

            case "url":
                return <div>

                    <input name="urlMedia" className="form-control" placeholder="https://example.com"
                        type="text" maxLength={50} onChange={urlData} value={urlMedia}
                    />
                    {error ? <small className="text-danger d-block mt-2">{error.urlMediaErr}</small> : ""}
                    {
                        < small className="text-danger d-block mt-2">{!urlFormatError ? ' ' : 'Invalid URL'}</small>
                    }

                </div>

            case "pdf":
                return <div>
                    <input type="file" name="pdfMedia" className='form-control col-sm-6'
                        ref={fileInputRef} accept=".pdf" onChange={(e) => { onPdfSaveData(e) }} />
                    <small className="text-danger d-block mt-2">{pdferror}</small>
                    {error ? <small className="text-danger d-block mt-2">{error.pdfMediaErr}</small> : ""}
                </div>

            case "Text":
                return (
                    <div style={{ color: 'gray' }}>
                        <CKEditor
                            editor={ClassicEditor}
                            data={textMedia}
                            onChange={handleEditorChange}
                        />
                        {textError ? <small className="text-danger d-block mt-2">Please Enter Text</small> : ""}
                    </div>
                );
            default:
                return <div></div>
        }
    }

    return (
        <div>
        <Layout title="Upload Medias">
            <Toaster />

            {!id ?
                <div>
                    {/*<div className="position-relative">*/}
                    {/*    <div className="card-box-header">*/}
                    {/*        <h4 className="m-0">Upload Medias</h4>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="row up-row">
                        <div class="col-md-6">

                            <div class="scan-btns-wrap">

                                <button class="scan-btn-new d-flex align-items-center p-2" onClick={(e) => {
                                        setqrcode("")
                                        setShowEnter(false)
                                        setQrcodeError(false)
                                        setValidQrError(false)
                                }} type="button" >
                                    <div class="scan-code-icon me-3"><img className="nav-icon1" src={ScanQR}></img></div>
                                    <scan>Click to Scan QR Code</scan>
                                </button>
                                <button class="scan-btn-new d-flex align-items-center p-2" onClick={(e) => {
                                        setShowEnter(true),
                                            setqrcode("")
                                        setErrorMessage("")
                                        setQrcodeError(false)
                                        setValidQrError(false)
                                }} type="button" >
                                    <div class="scan-code-icon me-3"><img className="nav-icon1" src={EnterQR}></img></div>
                                    <scan>Click to Enter QR Code</scan>
                                </button>
                            </div>
                        </div>
                        <div class="col-md-6 d-flex align-items-center justify-content-center flex-column ">
                            <div class="scan-content w-100">
                                {!showEnter ?
                                    <div id="qr-id">
                                        <div className="card-box-header d-inline-flex w-100 align-items-center justify-content-center">
                                            <h4 className="m-0">Scan your QR code</h4>
                                        </div>
                                            <div className='QrReader qr-wrapper'>
                                            <QrReader
                                                className="qr--scanner mt-0"
                                                delay={300}
                                                onError={handleError}
                                                constraints={{ facingMode: 'environment' }}
                                                style={{ width: '100%' }}
                                                onResult={onResult}
                                            />
                                        </div>

                                        {
                                            errorMessage ?
                                                <div class="qr--error-msg_toast">
                                                    <div class={"toast align-items-center m-auto text-white bg-danger border-0 " + (show ? 'active' : 'hide')} role="alert" aria-live="assertive" aria-atomic="true">
                                                        <div class="d-flex">
                                                            <div class="toast-body">
                                                                {errorMessage}
                                                            </div>
                                                            <button type="button" class="btn-close btn-close-white me-2 m-auto"
                                                                onClick={errorShowClass} ></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''
                                        }

                                        < small className="qR-error-show text-danger d-block mt-2">{errorMessage}</small>
                                        <small className="qR-error-show text-danger d-block mt-2">{qrError}</small>
                                        {/*<div className="d-flex justify-content-center">*/}
                                        {/*    <button className="btn btn-bordered ms-2" onClick={(e) =>*/}
                                        {/*        setShowCamera(false)*/}
                                        {/*    }>Close</button>*/}
                                        {/*</div>*/}
                                    </div> : ""
                                }
                            </div>


                                <div class="scan-content px-5">
                                {showEnter && !showCamera ?
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Enter QR Code </label>
                                                <div className="input-group">
                                                    <input name="qrcode" className="form-control" placeholder="Enter QRCode" type="text" maxLength={10}
                                                            onChange={(e) => {
                                                                setDisable(false)
                                                                const newValue = e.target.value.replace(/[^0-9]/g, '');
                                                            setqrcode(e.target.value)
                                                            if (e.target.value == "Enter QRCode") {
                                                                setQrcodeError(true)
                                                            }
                                                            else {
                                                                setQrcodeError(false)
                                                                setValidQrError(false)
                                                            }

                                                        }} value={qrcode} />
                                                </div>
                                            </div>
                                                {qrcodeError ?
                                                    <p className="text-danger validation-msg d-block mb-2">{!qrcodeError ? ' ' : 'Please Enter QRCode'}
                                                        <span><img className="" src={errorIcon} ></img></span>
                                                    </p> : ""
                                                }

                                                {validqrError ?
                                                    < p className="text-danger validation-msg d-block mb-2">{validqrError ? 'QR Code Not found, Please create new one and then Try again' : ' '}
                                                        <span><img className="" src={errorIcon} ></img></span>
                                                    </p>
                                                    : ""
                                                }
                                        </div>
                                            {validqrError ?
                                                <div>
                                                    <button className="btn btn-bordered new-qr-btn mb-2 " onClick={() => RedirecttoQREntries()}>Create New QR</button>
                                                </div> : ""
                                            }

                                        <div className="d-flex justify-content-left col-md-12">
                                            <button className="btn btn-bordered " onClick={(e) => setShowEnter(false)}>Close</button>
                                                <button className="btn btn-primary ms-2" disabled={disable} onClick={(e) => handleEnterManualQR()}>Next</button>
                                        </div>
                                    </div> : ""}
                            </div>
                        </div>
                    </div>
                </div>
                : locationIdByQRId == 1 ?
                    <div>
                        <div className="card-box-header d-inline-flex w-100 align-items-center">
                            <h4 className="m-0">Assign Location</h4>
                            <div className="QRCode QrCode-new">
                                QR Code: <strong>{id}</strong>
                            </div>
                        </div>
                        <div className="content-area">
                            <form>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div class="form-group">
                                            <label>Select Location {" "} <span className="required-fields"> * </span></label>
                                            <div class="input-group QR_select">
                                                <Select class="form-select mb-0 flex-grow-1 filter-select"
                                                    aria-label=".form-select-lg example"
                                                    value={currentLocationIdShow ? currentLocationIdShow : "Select Location"}
                                                    style={{ width: 450 }}
                                                    //defaultValue="Select Location"
                                                    onChange={(e) => {
                                                       
                                                        if (e != 0) {

                                                            setCurrentLocationIdShow(e)
                                                            getLocationData(e)
                                                        }
                                                    }}>
                                                    <Option value="0"><div className="w-100 align-items-center d-flex justify-content-between">Select Location
                                                        {locationIdByQRId == 1 ? < button className="btn btn-primary virtual-btn"
                                                            onClick={() => handleAddLocation()}

                                                        ><img src={AddVirtual}></img>
                                                            Add Location</button> : ""}
                                                    </div>
                                                    </Option>
                                                    {
                                                        allLocationTypes && allLocationTypes.map(data =>
                                                            <Option key={data.locationId} value={data.locationId}>
                                                                {data.name}
                                                            </Option>)
                                                    }
                                                </Select>
                                            </div>
                                            <div class="mt-2">
                                                {!locationMapError ?
                                                    <button type="button" class="btn btn-primary" onClick={(e) => updateLocationByQRId(e)}>Submit</button>
                                                    :
                                                    <button type="button" class="btn btn-primary" disabled onClick={(e) => updateLocationByQRId(e)}>Submit</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> :
                    <div>
                        <div className="card-box-header d-inline-flex w-100 align-items-center">
                            <h4 className="m-0">Select Location</h4>
                            <div className="QRCode QrCode-new">
                                <div> <strong>{id}</strong></div>
                                <div> <strong>{ProductName != null ? ProductName.product_Name : ""}</strong></div>
                            </div>
                        </div>
                        <div className="content-area">
                            <form>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Location <span className="required-fields"> * </span></label>
                                            <div className="input-group">
                                                <select className="form-select mb-0  filter-select" aria-label=".form-select-lg example" value={currentCompanyLocationId}
                                                    onChange={(e) => getCompanyLocationTypeData(e)
                                                    }
                                                >
                                                    <option value="0" selected="selected">Select Location</option>
                                                    {
                                                        allCompanyLocationMappingData && allCompanyLocationMappingData.map((mapping) => {
                                                            return mapping.locationId == locationIdByQRId ?
                                                                (<option key={mapping.id} value={mapping.id}>
                                                                    {mapping.mappingName}
                                                                </option>) : ""
                                                        },
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group ">
                                            <label>Company Name</label>
                                            <div className="input-group bg-white">
                                                <label id="text" type="text" className="form-control" name="text" placeholder="Enter Company Name">{companyName}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        {locationName && companyName && currentCompanyId && currentLocationId && currentCompanyLocationId != 0 ? <Button variant="primary" onClick={handleShow} >Continue</Button> : <Button variant="primary" onClick={handleShow} disabled> Continue</Button>}
                                        <Button className="ms-2" onClick={handleClear}>Clear</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <Modal
                            show={showQrDetail}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Assign Media to QR</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        <ul className="QrDetail">
                                            <li><label>Location :</label> {locationName}</li>
                                            <li><label>Company Name :</label> {companyName}</li>
                                            <li><label>Product Name :</label> {ProductName != null ? ProductName.product_Name : ""}</li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-12  text-center">
                                        <div className="QRCode">
                                            QR Code: <strong>{id}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-12 text-center">
                                        {locationName && companyName && id ? <Button onClick={handleQRModalShow}>Add Media to QR</Button> : <Button onClick={handleQRModalShow} disabled>Add Media to QR</Button>}
                                        <button type="button" className="btn btn-outline-primary ms-2" onClick={handleClose}>Cancel</button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>

                        <Modal
                            show={qrModal}
                            onHide={handleShow}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Scan your QR code</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <QrReader
                                    className="qr--scanner mt-0"
                                    delay={300}
                                    onError={handleError}
                                    constraints={{ facingMode: 'environment' }}
                                    style={{ width: '100%', height: '50%' }}
                                    onResult={onResult}
                                />
                                <small className="qR-error-show text-danger d-block mt-2">{qrError}</small>
                            </Modal.Body>
                        </Modal>

                        <Modal
                            show={copyModal}
                            onHide={handleCopyModalClose}
                            backdrop="static"
                            keyboard={false}
                            dialogClassName="modal-dialog-copy"
                        >
                            <Modal.Body>
                                <div class="copy-model">
                                    <h5> Are you sure you want to link QR code?</h5>
                                    <div class="w-100 mt-3 btn-yesno">
                                        <button
                                            type="button"
                                            class="btn btn-primary"
                                            onClick={copyMediaData}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-primary ml-2"
                                            onClick={handleCopyModalClose}
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>

                        <Modal
                            show={camera}
                            onHide={handleQRModalShow}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Camera</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <WebcamCapture images={handleImages} />
                            </Modal.Body>
                        </Modal>
                        <Modal
                            show={modal}
                            onHide={handleShow}
                            backdrop="static"
                            keyboard={false}
                            dialogClassName="modal-dialog-media uploadMedia"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Read QR Detail</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {loading ? <Loader /> : ''}
                                <ul className="QrDetail mb-3">
                                    <li><label>Location :</label> {locationName}</li>
                                    <li><label>Company :</label> {companyName}</li>
                                    <li><label>QR Code Id :</label> {id}</li>
                                    {/* <li><label>Product Name :</label> {ProductName.product_Name != null ? ProductName.product_Name : ""}</li>*/}
                                </ul>
                                <div class="">
                                    <div class="row mb-2">
                                        <div class="col-md-12 d-flex">

                                            <div className="d-flex align-items-center ">
                                                {" "}
                                                <input
                                                    className="mr-2"
                                                    type="radio"
                                                    value="show"
                                                    name="content"
                                                    id="show"
                                                    onClick={radioButtonValueChange}
                                                    checked={getRadioButton.selected === "show"}
                                                />
                                                <label className="m-0" for="show">Upload Media</label>{" "}
                                            </div>

                                            <div class="d-flex align-items-center ml-4">
                                                {" "}
                                                <input

                                                    type="radio"
                                                    value="hide"
                                                    name="content"
                                                    id="hide"
                                                    className="mr-2"
                                                    onClick={radioButtonValueChange}
                                                    checked={getRadioButton.selected === "hide"}
                                                />
                                                <label className="m-0" for="show">Link QR Data</label>{" "}
                                            </div>



                                        </div>

                                    </div>

                                    {getRadioButton.show == "hide" ? (
                                        <div className="assign-media mt-4 row">
                                            <div className="col-sm-8">
                                                <div class="form-group m-0">
                                                    <label>Select QR </label>
                                                    <div class=" d-flex qr-img-select qrTable--select mapping wkmapping ">
                                                        <Select
                                                            class="form-select mb-0 flex-grow-1 filter-select"
                                                            aria-label=".form-select-lg example"
                                                            showSearch
                                                            value={copyQRCodeId}
                                                            style={{ width: 650 }}
                                                            onChange={(e) => {
                                                                var productFound = allQrDataWithThumbnail.filter((item) => item.product_Name == e)
                                                                if (productFound.length > 0) {
                                                                    setCopyQRCodeId(productFound[0].qRcodeId);
                                                                }
                                                                else {
                                                                    setCopyQRCodeId(e);
                                                                }
                                                            }}
                                                            onSearch={(e) => {
                                                                handleInputForDropdown(e)
                                                            }}
                                                        >
                                                            <Option value="0">Select QR </Option>
                                                            {allQrDataWithThumbnail &&
                                                                allQrDataWithThumbnail.map((QRCode, k) =>

                                                                    <Option
                                                                        key={QRCode.qRcodeId}
                                                                        value={searchState === "Number" || QRCode.product_Name == null ? QRCode.qRcodeId : QRCode.product_Name}
                                                                    >
                                                                        <div className="d-flex gap20 align-items-center">
                                                                            <div className="avatar-img">
                                                                                {QRCode.filename ?
                                                                                    <>
                                                                                        < img className="img-mapping" src={QRCode.filename}></img>{QRCode.favorites == true ? <img className="favorite_star" src={favoriteIcon}></img> : ""}
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        < img className="img-mapping" src={LoginImage}></img>{QRCode.favorites == true ? <img className="favorite_star" src={favoriteIcon}></img> : ""}
                                                                                    </>}
                                                                            </div>
                                                                            <div className="qrDesc content d-flex">
                                                                                {QRCode.product_Name ? <Tooltip title={QRCode.product_Name}>
                                                                                    <h6>{QRCode.product_Name}</h6>
                                                                                </Tooltip>
                                                                                    : <h6>- NA - </h6>}
                                                                                <p>{QRCode.qRcodeId}</p>
                                                                                <Tooltip title={QRCode.qR_Description}>
                                                                                    <p>{QRCode.qR_Description}</p>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                    </Option>

                                                                )}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 d-flex align-items-end">
                                                {id && copyQRCodeId && copyQRCodeId != "Select QR" ? <button

                                                    className="btn btn-primary mt20 w-100"
                                                    onClick={() => copyMediaData()}
                                                >
                                                    Link & Save
                                                </button> : <button

                                                    className="btn btn-primary mt20 w-100"
                                                    onClick={() => copyMediaData()} disabled
                                                >
                                                    Link & Save
                                                </button>}
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                {getRadioButton.show == "show" ?
                                    <div className="mt-4">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Select Device Type <span className="required-fields"> * </span></label>
                                                    <div className="input-group">

                                                        <select className="form-select mb-0  filter-select" aria-label=".form-select-lg example" value={currentDeviceTypeId}
                                                            onChange={(e) => getDeviceDetails(e)
                                                            }>
                                                            <option value="0">Select Device</option>
                                                            {
                                                                allDeviceTypes && allDeviceTypes.map(deviceTypes =>
                                                                    !deviceTypes.isDeleted && deviceTypes.active && (
                                                                        <option key={deviceTypes.deviceTypeId} value={deviceTypes.deviceTypeId}>
                                                                            {deviceTypes.name}
                                                                        </option>
                                                                    )
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Select Area Type <span className="required-fields"> * </span></label>
                                                    <div className="input-group">
                                                        <select className="form-select mb-0  filter-select" aria-label=".form-select-lg example" value={currentAreaTypeId}
                                                            onChange={(e) => getAreaDetails(e)
                                                            }>
                                                            <option selected="">Select Area</option>
                                                            {
                                                                allAreaTypes && allAreaTypes.map(areaTypes =>
                                                                    !areaTypes.isDeleted && areaTypes.active && (
                                                                        <option key={areaTypes.areaTypeId} value={areaTypes.areaTypeId}>
                                                                            {areaTypes.name}
                                                                        </option>
                                                                    )
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Select Guide Type <span className="required-fields"> * </span></label>
                                                    <div className="input-group">
                                                        <select className="form-select mb-0  filter-select" aria-label=".form-select-lg example" value={currentGuideTypeId}
                                                            onChange={(e) => getGuideDetails(e)
                                                            }>
                                                            <option selected="">Select Guide</option>
                                                            {
                                                                allGuideTypes && allGuideTypes.map(guideTypes =>
                                                                    !guideTypes.isDeleted && guideTypes.active && (
                                                                        <option key={guideTypes.guideTypeId} value={guideTypes.guideTypeId}>
                                                                            {guideTypes.name}
                                                                        </option>
                                                                    )
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Select Media Type <span className="required-fields"> * </span></label>
                                                    <div className="input-group">
                                                        <select className="form-select mb-0  filter-select" aria-label=".form-select-lg example" value={currentMediaTypeId}
                                                            onChange={(e) => {
                                                                
                                                                setCurrentMediaTypeId(e.target.value === "Select Media" ? 0 : e.target.value);
                                                                getMediaDetails(e);
                                                            }
                                                            }>
                                                            <option selected="">Select Media</option>
                                                            {
                                                                allMediaTypes && allMediaTypes.map(mediaTypes =>
                                                                    <option key={mediaTypes.mediaTypeId} value={mediaTypes.mediaTypeId}>

                                                                        {mediaTypes.name}
                                                                    </option>,
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            {currentMediaTypeKey != null && currentMediaTypeId != "Select Media" ? <div>{selectMedia(currentMediaTypeKey)}</div> : ''}
                                        </div>
                                        {videoMessage ? < small className="text-success d-block mt-2 alert-success video_message">Video is Uploading, Please Wait </small> : ""}
                                        {mediaKey != "Text" ?
                                            <div className="row mt-2">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Add Description</label>
                                                        <div className="input-group">
                                                            <textarea name="Description" id="" className="form-control" maxLength={1000} value={desc} onChange={(e) => { setDesc(e.target.value), setCountDesc(e.target.value.length) }} rows={6} placeholder="Enter description here"></textarea>
                                                            <span className="w-100 text-right fs12">{countDesc}/1000</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ""
                                        }
                                        <div className="modal-footer justify-content-center">
                                                {currentDeviceTypeId && currentDeviceTypeId != "0" && mediaTypeSelected && currentAreaTypeId && currentAreaTypeId != 'Select Area' && currentGuideTypeId != 'Select Guide' && currentGuideTypeId && currentMediaTypeId && currentMediaTypeId != 'Select Media' && !videoFormatError && !videoSizeError && !urlFormatError || textMedia != "" && !textError
                                                ? <button className="btn btn-primary" type="button" onClick={saveMediaType}>Save & New</button>
                                                : <button className="btn btn-primary" onClick={saveMediaType} disabled>Save & New</button>}

                                                {currentDeviceTypeId && currentDeviceTypeId != "0" && mediaTypeSelected && currentAreaTypeId && currentAreaTypeId != 'Select Area' && currentGuideTypeId != 'Select Guide' && currentAreaTypeId && currentGuideTypeId && currentMediaTypeId && currentMediaTypeId != 'Select Media' && !videoFormatError && !videoSizeError && !urlFormatError || textMedia != "" && !textError
                                                ? <button type="button" className="btn btn-primary ms-2" onClick={saveMediaData}>Save</button>
                                                : <button type="button" className="btn btn-primary" onClick={saveMediaData} disabled>Save</button>}

                                            <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal" onClick={handleShow}>Close</button>
                                        </div>

                                    </div> : ""
                                }
                            </Modal.Body>
                        </Modal>
                    </div>
            }
            </Layout>
            <Modal
                show={createModal}
                onHide={handleCreateModalClose}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog-copy"
            >
                <Modal.Body>
                    <div class="copy-model">
                        <h5> Are you sure you want to add QR code?</h5>
                        <div class="w-100 mt-3 btn-yesno">
                            <button
                                type="button"
                                class="btn btn-primary"
                                onClick={addQRCode}
                            >
                                Yes
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary ml-2"
                                onClick={handleCreateModalClose}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default AssignQR