import React, { useEffect, useCallback, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import * as url from "../../api/uri_helper";
import Loader from "../loader";
//import Message from 'antd-message';
import toast, { Toaster } from 'react-hot-toast';
import { Formik, Field, useFormik } from 'formik';
import { Input, Form, FormFeedback } from "reactstrap"
import * as Yup from 'yup';
import { useRef } from "react";
import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/bootstrap.css";


// Import Layout
import Layout from '../Layout';
// Import static images
import Arrow_left from "../../assets/images/Arrow_left.svg";
/*import Back_img from "../../assets/images/back_image.png";*/
import Add1 from "../../assets/images/Add.svg";
import { Link, useHistory } from 'react-router-dom';
import axios from "axios";
import '../../../src/custom.css'
import { updateCustomerTypeData, updateUserTypeData, addUserType } from "../../Redux/Actions/UserActions";
import { Checkbox, Select } from "antd";
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import "antd/dist/antd.css";
import { setUserRoles } from "../../Redux/Actions/userRoles";
import { fetchUserRoleData, fetchAllUserId } from "../../Redux/Api/fetch.api";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
const Option = Select.Option;

const Add = () => {
    const allUserTypes = useSelector((state) => state.allUserType.user)
    const allUserRoles = useSelector((state) => state.allUserRoleReducers.user)

    let history = useHistory();
    const dispatch = useDispatch();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [name, setName] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [password, setPassword] = useState("");
    const [phnumber, setPhnumber] = useState("");
    const [loading, setLoading] = useState(false);
    const [phnErr, setPhnErr] = useState();
    //const [error, setError] = useState({
    //    nameErr: '', lnameErr: '', emailErr: '', addressErr: '', passwordErr: '', phnumberErr: ''
    //})
    const [isActive, setisActive] = useState(true)
    const [ifUpdate, setifUpdate] = useState(false)
    const [currentId, setCurrentId] = useState(null);
    const [currentRoleId, setCurrentRoleId] = useState([]);
    const [selectRoles, setSelectRoles] = useState([]);
    const [checked, setChecked] = useState(true);
    const [roles, setRoles] = useState([]);
    const [count, setCount] = useState(0);
    const [disable, setDisable] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [rolesError, setRolesError] = useState(false);
    const [selectedCountryCode, setSlectedCountryCode] = useState()
    const [passwordType, setPasswordType] = useState("password");
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const handleChange = () => {
        setisActive(!isActive)
    }
    let pageButton = "Update"

    if (!currentId) {
        pageButton = "Save"
    }
    //useEffect(() => {
    //    let urlData = location.pathname.split('/');
    //    setCurrentId(urlData[3])
    //    if (currentId) {
    //        let matchingData = allUserTypes.find(item => item.userId == currentId)
    //        if (matchingData !== undefined) {
    //            setName(matchingData.firstName);
    //            setLname(matchingData.lastName)
    //            setEmail(matchingData.emailId)
    //            setCount(matchingData.address.length)
    //            let pswd = matchingData.passwordHash.substr(0, 20);
    //            /*setPassword(matchingData.password)*/
    //            setPassword(pswd)
    //            setPhnumber(matchingData.phoneNumber)
    //            { matchingData.roles ? setSelectRoles(getRole(matchingData.roles)) : "" }
    //            setAddress(matchingData.address)
    //            setisActive(matchingData.active)
    //            setifUpdate(true);
    //        }
    //    }
    //}, [currentId])

    useEffect(() => {
        let urlData = location.pathname.split('/');
        setCurrentId(urlData[3])
        if (currentId) {
            fetchAllUserId(urlData[3]).then(
                response => {
                    debugger;
          //  let matchingData = allUserTypes.find(item => item.userId == currentId)
          //  if (matchingData !== undefined) {
                setName(response.data.data.firstName);
                    setLname(response.data.data.lastName)
                    setEmail(response.data.data.emailId)
                    setCount(response.data.data.address.length)
                    let pswd = response.data.data.passwordHash.substr(0, 20);
                /*setPassword(matchingData.password)*/
                setPassword(pswd)
                    setPhnumber(response.data.data.phoneNumber)
                    { response.data.data.roles ? setSelectRoles(getRole(response.data.data.roles)) : "" }
                    setAddress(response.data.data.address)
                    setisActive(response.data.data.active)
                setifUpdate(true);
           })
        }
    }, [currentId])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        getProducts()
    }, [])

    const getRoleIds = (selectedRoles) => {
        let roles = [];
        selectedRoles.map(roleItem => {
            return roles.push(roleItem.roleId ? roleItem.roleId : roleItem.value)
            // return roles.push(roleItem.key)
        })
        return roles;
    }

    const getRole = (userRoles) => {
        let roles = [];
        userRoles.forEach(element => {
            let selectedRoles = {
                disabled: undefined, key: element.roleId, label: element.roleName,
                value: element.roleId
            };
            roles.push(selectedRoles);
        });
        return roles;
    }

    const getProducts = () => {
        setLoading(true)
        fetchUserRoleData()
            .then(response => dispatch(setUserRoles(response.data)))
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name ? name : '',
            lname: lname ? lname : '',
            address: address ? address : '',
            email: email ? email : '',
            password: password ? password : '',
        },
        // Alert messages 
        validationSchema: Yup.object({
            name: Yup.string().required('Please Enter Name ').matches(
                "^[A-Za-z][A-Za-z]*$",
                "Please enter valid Name"),
            lname: Yup.string().required('Please Enter Last Name ').matches(
                "^[A-Za-z][A-Za-z]*$",
                "Please enter valid Name"),
            address: Yup.string().required('Please Enter Address ').matches(
                "^(.|\s)*[a-zA-Z]+(.|\s)*$",
                "Please enter valid Address"),
            email: Yup.string().required('Please Enter Email').matches(
                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                "Please enter valid email "),
            password: Yup.string().when('currentId', {
                is: (currentId) => {
                    // return bool result and if its true then next THEN will be executed
                    return currentId === undefined && !ifUpdate
                },
                then: Yup.string().required("Please Enter Password").matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"), otherwise:
                    null,
            }),
        }),
        // on form submit / button click
        onSubmit: (values) => {
            if (!phoneError && !rolesError) {
                setDisable(true)
                { (currentId == undefined || currentId == null || currentId == isNaN) ? postUserData(values) : updateUserData(values) };
            }
        }
    });
    const postUserData = async (params) => {
        if (params.name && params.lname && params.email && params.address && params.password) {
            const response = await axios.post(url.POST_USER, {
                FirstName: params.name.trim(),
                LastName: params.lname.trim(),
                emailId: params.email,
                PasswordHash: params.password,
                PhoneNumber: phnumber,
                role: getRoleIds(roles),
                Address: params.address.trim(),
                Active: isActive,
            }).then(response => {
                dispatch(addUserType(response.data))
                if (!response.data.success) // False 
                {
                    toast.error(response.data.message, {
                        className: "toastSuccess",
                    },);
                    setLoading(false);
                    setDisable(false)
                }
                else {
                    toast.success(response.data.message, {
                        className: "toastSuccess",
                    },);
                    history.push('/User');
                    setLoading(false);
                    setDisable(true)
                }
            })
                .catch((err) => {
                    console.log("Err: ", err);
                    setDisable(false)
                })
        }
    };

    const updateUserData = async (params) => {

        const rolesIdsToUpdate = getRoleIds(roles && roles.length > 0 ? roles : selectRoles);
        if (params.name && phnumber) {
            const response = await axios.put(url.UPDATE_USER, {
                userId: currentId,
                FirstName: params.name.trim(),
                LastName: params.lname.trim(),
                emailId: params.email,
                PhoneNumber: phnumber,
                role: rolesIdsToUpdate,
                Address: params.address,
                Active: isActive,
            }).then(response => {
                dispatch(updateUserTypeData(response.data))
                if (!response.data.success) // False 
                {
                    toast.error(response.data.message, {
                        className: "toastSuccess",
                    },);
                    setLoading(false);
                    setDisable(false)
                }
                else {
                    toast.success(response.data.message, {
                        className: "toastSuccess",
                    });
                    history.push('/User');
                    setLoading(false);
                    setDisable(true)
                }
            })
                .catch((err) => {
                    console.log("Err: ", err);
                    setDisable(false)
                });
            setLoading(false);
        }
    };

    const handleChangeRoles = (selected) => {
        const arr1 = []
        selected.map((item, i) => {
            allUserRoles.filter(datas => datas.roleName === selected[i].label).map(filter_data => {
                arr1.push(filter_data)
            })
        })
        setRoles(arr1)
        setSelectRoles(selected)
        if (selected && selected.length > 0) {
            setRolesError(false);
        }
        else {
            setRolesError(true);
        }
    }
    //Function to be called in the main component
    const Update = () => {
        if (!ifUpdate) {
            return (
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Password
                            <span className="required-fields"> * </span>
                        </label>
                        <div className="input-group">
                            <Input name="password" className="form-control" placeholder="Enter Password"
                                type={passwordType} maxLength={50} onChange={validation.handleChange}
                                onBlur={validation.handleBlur} value={validation.values.password}
                                invalid={
                                    validation.touched.password && validation.errors.password ? true : false}
                            />
                            <div class="input-group-append">
                                <span class="input-group-text user_icon" id="basic-addon2">
                                    <div onClick={togglePassword}>
                                        {passwordType === "password" ? <VisibilityOffIcon style={{ color: 'gray' }} /> : <VisibilityIcon style={{ color: 'gray' }} />}
                                    </div>
                                </span>
                            </div>
                            {validation.touched.password && validation.errors.password ? (
                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                        </div>
                    </div>
                </div>
            )
        }
        else {
           
            <div className="col-md-4">
                <div className="form-group">
                    <label>Password
                       
                    </label>
                    <div className="input-group">
                        <Input name="password" className="form-control" placeholder="Enter Password"
                            type="password" maxLength={50} onChange={validation.handleChange}
                                 value={validation.values.password}
                            disabled={!ifUpdate ? '' : 'disabled'}
                           
                        />
                       
                       
                    </div>
                </div>
                </div>
             
        }
    }
   

    return (
        <Layout title="User">
            <Toaster />
            <div className="position-relative">
                {loading ? <Loader /> : ''}
                <div className="card-box-header d-inline-flex w-100 align-items-center">
                    <h4 className="m-0">
                        <Link className="backBTN" to="/User">
                            <img src={Arrow_leftbrown}></img>
                            Back
                        </Link>
                    </h4>
                </div>
                <div className="content-area">
                    <Form className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            if (!phnumber || !(phnumber && phnumber.length > 10)) {
                                setPhoneError(true);
                            }
                            if (!selectRoles || !(selectRoles && selectRoles.length > 0)) {
                                setRolesError(true);
                            }
                            return false;
                        }}>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>First Name <span className="required-fields"> * </span></label>
                                    <div className="input-group">
                                        <Input name="name" className="form-control" placeholder="Enter Name"
                                            type="text" maxLength={50} onChange={validation.handleChange}
                                            onBlur={validation.handleBlur} value={validation.values.name}
                                            invalid={validation.touched.name && validation.errors.name ? true : false}
                                        />
                                        {validation.touched.name && validation.errors.name ? (
                                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Last Name <span className="required-fields"> * </span></label>
                                    <div className="input-group">
                                        <Input name="lname" className="form-control" placeholder="Enter Last Name"
                                            type="text" maxLength={50} onChange={validation.handleChange}
                                            onBlur={validation.handleBlur} value={validation.values.lname}
                                            invalid={validation.touched.lname && validation.errors.lname ? true : false}
                                        />
                                        {validation.touched.lname && validation.errors.lname ? (
                                            <FormFeedback type="invalid">{validation.errors.lname}</FormFeedback>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Email <span className="required-fields"> * </span></label>
                                    <div className="input-group">
                                        <Input name="email" className="form-control" placeholder="Enter Email"
                                            type="text" maxLength={50} onChange={validation.handleChange}
                                            onBlur={validation.handleBlur} value={validation.values.email}
                                            invalid={
                                                validation.touched.email && validation.errors.email ? true : false}
                                        />
                                        {validation.touched.email && validation.errors.email ? (
                                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            {Update()}
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Phone Number <span className="required-fields"> * </span></label>
                                    <div className="input-group phnumber">
                                        <PhoneInput
                                            className="w-5"
                                            country={"us"}
                                            enableSearch={true}
                                            value={phnumber}
                                            onChange={(phone, country) => {
                                                setSlectedCountryCode((prevState) => {
                                                    if (prevState !== country.countryCode) {
                                                        setPhnumber(country.dialCode);
                                                    }
                                                    else {
                                                        setPhnumber(phone);
                                                    }
                                                    return country.countryCode;
                                                })
                                                if (phone && phone.length > 10 && !phone.replace(country.dialCode, '').startsWith('0')) {
                                                    setPhoneError(false);
                                                }
                                                else {
                                                    setPhoneError(true);
                                                }
                                            }}
                                        />
                                    </div>
                                    {< small className="text-danger d-block mt-2">{!phoneError ? ' ' : 'Please Enter Valid Phone Number'}</small>}
                                </div>
                            </div>
                            <div className="col-md-4 select-Role">
                                <label>Select Role <span className="required-fields"> * </span></label>
                                <Select
                                    placeholder="Select role"
                                    mode="multiple"
                                    labelInValue
                                    tokenSeparators={[" ", ","]}
                                    value={selectRoles}
                                    onChange={handleChangeRoles}
                                >
                                    {/* <option disabled value="0">Select Roles</option> */}
                                    {allUserRoles && allUserRoles.map(roles =>
                                        <Option key={roles.roleId} value={roles.roleName} >
                                            {roles.roleName}
                                        </Option>
                                    )}
                                </Select>
                                {
                                    < small className="text-danger d-block mt-2">{!rolesError ? ' ' : 'Please Select Roles'}</small>
                                }
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Address <span className="required-fields"> * </span></label>
                                    <div className="input-group">
                                        <Input name="address" className="form-control" placeholder="Enter  Address Here"
                                            type="textarea" maxLength="250" onChange={(e) => { validation.handleChange(e), setCount(e.target.value.length) }}
                                            onBlur={validation.handleBlur} value={validation.values.address}
                                            invalid={
                                                validation.touched.address && validation.errors.address ? true : false
                                            }
                                        /><span className="w-100 text-right fs12">{count}/250</span>
                                        {validation.touched.address && validation.errors.address ? (
                                            <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-4">
                                <div className="form-checkbox ">
                                    {loading && currentId == undefined || currentId == null || currentId == isNaN ?

                                        <label>

                                            <input type="checkbox" defaultChecked={checked} name="isActive" value={isActive} onChange={() => handleChange()} ></input> Active</label> :

                                        <label>  <input type="checkbox" name="isActive" checked={isActive} onChange={() => handleChange()} ></input>
                                            Active</label>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="btn btn-primary" disabled={disable}>{pageButton}</button>
                                <Link className="btn btn-bordered ms-2" to="/User">Cancel</Link>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

        </Layout>
    );
}
export default connect()(Add);
