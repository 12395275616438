import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loader from '../loader';
import Layout from '../Layout';
// Added api url
import * as url from "../../api/uri_helper"
import axios from 'axios';
import LockIcon from '@mui/icons-material/Lock';
// added Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Input, Form, FormFeedback } from "reactstrap"
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
//import Message from 'antd-message';
import toast, { Toaster } from 'react-hot-toast';
import logo from "../../UserAssets/images/authenteklogo.png"
import EyeClose from "../../assets/images/eyeClose.svg";
import PassLight from "../../assets/images/fi-rr-lock1.svg";

const changeOldPassword = (data) => {
    const [loading, setLoading] = useState(false);
    let history = useHistory();

    let { emailid } = useParams();

    const onChangePassword = async (params,username='') => {
        // call the api here
        debugger;
        /*setLoading(true);*/
        if ((params.emailid || username) && params.currentPassword && params.newPassword) {
            const response = await axios.post(url.POST_CHANGEPASSWORD, {
                // emailId: "saunak@gmail.com",

                EmailId: params.emailid,
                CurrentPassword: params.currentPassword,
                NewPassword: params.newPassword,
                Username : username

            }).then(response => {
                // debugger;
                setLoading(false);
                if (response.data.success) {
                    toast.success(response.data.message,{
                        className:"toastSuccess",
                    }, );
                    history.push('/Dashboard');
                }
                else {
                    toast.error(response.data.message,{
                        className:"toastSuccess",
                    }, );
                    setLoading(false);
                    return false;
                }

            })
                .catch((err) => {
                    console.log("Err: ", err);
                });
            setLoading(false);
        }
    }
    // On Validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        // initialize of the Values
        initialValues: {
            emailid: localStorage.getItem('emailid') ? localStorage.getItem('emailid') : '',
            currentPassword: '',
            newPassword: '',
            conPassword: ''
        },
        // Alert messages 
        validationSchema: Yup.object({
            currentPassword: Yup.string().required("Please Enter Current password"),


            newPassword: Yup.string().required("Please Enter New password").matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),

            conPassword: Yup.string().required("Please Enter Confirm password").oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        }),

        // on form submit / button click
        onSubmit: (values) => {
            // call the api here
            debugger;
            if (values.emailid) // get email id from local storage
            {
                onChangePassword(values)
            }
            else {
                // have to get email Id if not stored in local storage or can go for username
                const username = localStorage.getItem('userName') ? localStorage.getItem('userName') : '';
                onChangePassword(values,username)
            }
        }
    });

    //Call update password api
    

    if (loading) return <Loader />;

    return (
        <Layout title="Change Password">
            <Toaster/>
            <div id='passwordChangeSection' class="auth-section d-flex flex-wrap login-page">
                <div class="d-flex align-items-center flex-wrap flex w-100">
                    <div class="container">
                        <div class="row">

                            <div class="col-sm-6 right-section mx-auto py-4">
                                <div class="card auth-card">
                                    <h4>Change Password</h4>
                                    {/* <h6 class="m-0">Enter your new password below.</h6>*/}
                                    {/* <form class="form-signin"> */}
                                    <Form className="form-horizontal"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                        <div class="mt-4"></div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <label>Current Password</label>
                                                    <div class="input-group">
                                                        {/* <span className="input-group-addon"><img src={PassLight}></img></span> */}
                                                        <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon3">
                                                        <LockIcon style={{color:"gray"}}/>
                                                        </span>
                                                    </div>
                                                        <Input name="currentPassword" className="form-control" placeholder="Enter Current Password"
                                                            type="password" maxLength={50} onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur} value={validation.values.currentPassword}
                                                            invalid={
                                                                validation.touched.currentPassword && validation.errors.currentPassword ? true : false
                                                            }
                                                        />
                                                        {validation.touched.currentPassword && validation.errors.currentPassword ? (
                                                            <FormFeedback type="invalid">{validation.errors.currentPassword}</FormFeedback>
                                                        ) : null}

                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <label>New Password</label>
                                                    <div class="input-group">
                                                        {/* <span className="input-group-addon"><img src={PassLight}></img></span> */}
                                                        <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon3">
                                                        <LockIcon style={{color:"gray"}}/>
                                                        </span>
                                                    </div>
                                                        <Input name="newPassword" className="form-control" placeholder="Enter New Password"
                                                            type="password" maxLength={50} onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur} value={validation.values.newPassword}
                                                            invalid={
                                                                validation.touched.newPassword && validation.errors.newPassword ? true : false
                                                            }
                                                        />


                                                        {validation.touched.newPassword && validation.errors.newPassword ? (
                                                            <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                                                        ) : null}


                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <label>Confirm Password</label>
                                                    <div class="input-group">
                                                        {/* <span className="input-group-addon"><img src={PassLight}></img></span> */}
                                                        <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon3">
                                                        <LockIcon style={{color:"gray"}}/>
                                                        </span>
                                                    </div>
                                                        <Input name="conPassword" className="form-control" placeholder="Enter Confirm Password"
                                                            type="password" maxLength={50} onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur} value={validation.values.conPassword}
                                                            invalid={
                                                                validation.touched.conPassword && validation.errors.conPassword ? true : false
                                                            }
                                                        />

                                                        {validation.touched.conPassword && validation.errors.conPassword ? (
                                                            <FormFeedback type="invalid">{validation.errors.conPassword}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12 mt-4">
                                                <button className="btn btn-primary me-2">Save</button>
                                                <Link className="btn btn-bordered ms-2" to="/DashBoard">Cancel</Link>
                                            </div>
                                        </div>

                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

           
            </div>
            </Layout>
    )
}

export default connect()(changeOldPassword);




