import { AreaTypes } from "../Constants/Area-Types"

// initial state
const initialState = {
    areaTypes: []
}

export const AreaReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case AreaTypes.SET_AREATYPES:
            return { ...state, areaTypes: payload.data }; // assinging 
        //Developer
        case AreaTypes.ADD_AREATYPE:
            return { ...state, areaTypes: payload };

        //Delete AreaType
        case AreaTypes.DELETE_SELECTED_AREATYPE:
            return { ...state, areaTypes: payload };

           
        //
        default:
            return state;
    }
};