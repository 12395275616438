import React from "react"
import logo from "../../UserAssets/images/authenteklogo.png"

const Footer = () => {
  return (
      <div className="auth-footer w-100 position-fixed">
          <div className="container-fluid">
              <div className="row">
                  <div className="col-sm-6">© AuthenTEK ({new Date().getFullYear()} ) | TEKaHelp ™</div>
                 {/* <div class="col-sm-6 text-right wow fadeInUp">Powered by AuthenTEK</div>*/}
                  <div className="col-sm-6 center-text text-end">
                      <div className="footer--logo">Powered by <a href="https://authentek.io/" target="_blank"><img src={logo}></img> </a></div>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default Footer
