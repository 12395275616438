import React from "react"
import logoauthentek from "../../assets/images/logo-authentek-sm.png";
import logo from "../../UserAssets/images/authenteklogo.png"

const Footer = () => {
  return (
	  <div className="footer-section mb-3 mt-3 w-100">
		  <div className="inner-footer">
			  <div className="row m-0">
				  <div className="col-sm-6 center-text">© AuthenTEK ({new Date().getFullYear()}) | TEKaHelp ™</div>
				
				  <div className="col-sm-6 center-text text-end">
					  <div className="footer--logo">Powered by <a href="https://authentek.io/" target="_blank"><img src={logo}></img> </a></div>
				  </div>
			  </div>
		  </div>
	  </div>
  )
}

export default Footer
