import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from "react-redux";
import * as url from "../../api/uri_helper"
import Loader from "../loader";
import Table, { Icon, Button } from "antd/lib/table";
import { useHistory } from "react-router-dom";
import Layout from '../Layout';
import updateIcon from "../../assets/images/updateIcon.svg"
import "antd/dist/antd.css";
import '../../../src/custom.css'
import { Select } from 'antd';
import Header from "../UserLayout/Header";
import Footer from "../UserLayout/Footer";
import { Breadcrumb } from "antd";
import PDF from "../../UserAssets/images/pdf.png";
import { fetchAllVirtualQRData } from "../../Redux/Api/fetch.api";
import { getVirtualQrData } from "../../Redux/Actions/VirtualQR";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import "../../UserAssets/css/style.css";

const VirtualQRs = (param) => {
    const allVirtualDataUser = useSelector((state) => state.allVirtualQRData.virtualqrData);
    
    const dispatch = useDispatch();
    const [currentId, setCurrentId] = useState(null);
    const [productName, setProductName] = useState(null);
    const [guideName, setGuideName] = useState(null);
    const [loading, setLoading] = useState(true);
    const [virtualData, setVirtualData] = useState(true);
    
    const [modal, setModal] = useState(false);
    const [detailDesc, setDetailDesc] = useState();
    const virtualCodeData = param.param;
    const media = virtualCodeData.mediaDetails;
   
    const checkCode = virtualCodeData.virtualQRCode;
    console.log("code", checkCode)

   

   // const data = allVirtualDataUser[0].mediaDetails;
  
   

    const handleQRModalShow = (mediaDescription) => {
        debugger;
        setModal(true);
        if (mediaDescription)
            setDetailDesc(mediaDescription);
    };

    const handleClose = () => {
        setModal(false);
       
    };

    return (
        <div>
            {checkCode != 0 ?
                <section class="center-section inner-pages text-center p-0">
                    <div class="Qr-detail m-0">
                        <div class="container">
                            <div>
                                <Breadcrumb className="breadcrumb">
                                    <Breadcrumb.Item>
                                        <a href="/QrScan">
                                            <i class="fa-solid fa-house"></i>
                                        </a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <a href={`/$`}>

                                            {virtualCodeData.virtualQRCode}
                                        </a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>

                            </div>

                            <div className="sticky-parent-thumbnail mb-3">
                                <div className="wh_detail">
                                    <div class="list-text-wrap text-center">
                                        <strong>Virtual QRCode - {virtualCodeData.virtualQRCode}</strong>
                                        <strong>Product Name - {virtualCodeData.product_Name}</strong>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="head-list"> {virtualCodeData.guideType}</div>
                            </div>
                            <div className="main-box">
                                <div class="row">
                                    {media &&
                                        media.map((item) => (
                                            <div className="col-lg-4 col-md-4 col-12">
                                                {item.mediaType == "Image" ? (
                                                    <div
                                                        class="lists wow fadeInUp"
                                                        data-wow-delay="0.3s"
                                                    >
                                                        <div class="list-img">
                                                            <img
                                                                class="objectFit"
                                                                src={item.filename}
                                                            />

                                                        </div>
                                                        {item.description ? (
                                                            <div class="list-text">
                                                                <strong>VirtualQR - {virtualCodeData.virtualQRCode}</strong>
                                                                {item.description &&
                                                                    item.description.length >= 10 ? (
                                                                    <div>
                                                                        <p>{item.description.substr(0, 50)}{"... "}

                                                                            <Link
                                                                                onClick={() => {
                                                                                    handleQRModalShow(
                                                                                        item.description
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Show More
                                                                            </Link>
                                                                        </p>
                                                                    </div>) : (
                                                                    <p>{item.description}</p>
                                                                )}


                                                            </div>
                                                        ) : (
                                                            <div className="list-text no-data">
                                                                <p>No Description Available</p>
                                                            </div>
                                                        )}

                                                    </div>

                                                ) : item.mediaType == "video" ? (

                                                    <div
                                                        class="lists wow fadeInUp"
                                                        data-wow-delay="0.5s"
                                                    >
                                                        <div class="list-img">
                                                            <ReactPlayer
                                                                // url="https://vimeo.com/291715535"
                                                                url={item.filename}
                                                                width="100%"
                                                                height="calc(100vh - 100px)"
                                                                controls={true}
                                                                playing={false}


                                                            />
                                                        </div>
                                                        {item.description ? (
                                                            <div
                                                                class="list-text"

                                                            // onClick={() => next(details.mediaId)}
                                                            >
                                                                <strong>VirtualQR - {virtualCodeData.virtualQRCode}</strong>
                                                                {item.description &&
                                                                    item.description.length >= 15 ? (
                                                                    <div>
                                                                        <p>{item.description.substr(0, 50)}{"... "}
                                                                            <Link
                                                                                onClick={() => {

                                                                                    handleQRModalShow(
                                                                                        item.description
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Show More
                                                                            </Link>
                                                                        </p>
                                                                    </div>) : (
                                                                    <p>{item.description}</p>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div className="list-text no-data">
                                                                <p>No Description Available</p>
                                                            </div>
                                                        )}
                                                    </div>

                                                ) : item.mediaType == "pdf" ? (

                                                    <div
                                                        class="lists wow fadeInUp"
                                                        data-wow-delay="0.7s"
                                                    >
                                                        <div class="list-img">
                                                            <img src={PDF}></img>
                                                        </div>
                                                        {item.description ? (
                                                            <div class="list-text">
                                                                <strong>VirtualQR - {virtualCodeData.virtualQRCode}</strong>
                                                                <p>{item.description.substr(0, 50)}{"... "}
                                                                    <Link
                                                                        onClick={() => {
                                                                            handleQRModalShow(
                                                                                item.description
                                                                            );
                                                                        }}
                                                                    >
                                                                        Show More
                                                                    </Link>
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            <div className="list-text no-data">
                                                                <p>No Description Available</p>
                                                            </div>
                                                        )}
                                                    </div>

                                                ) : item.mediaType == "Video Url" ? (
                                                    <div
                                                        class="lists wow fadeInUp"
                                                        data-wow-delay="0.5s"
                                                    >
                                                        <div class="list-img">
                                                            <ReactPlayer
                                                                // url="https://vimeo.com/291715535"
                                                                url={item.filename}
                                                                width="100%"
                                                                height="calc(100vh - 100px)"
                                                                controls={true}
                                                                playing={false}
                                                            />
                                                        </div>
                                                        {item.description ? (
                                                            <div
                                                                class="list-text"
                                                            /* onClick={() => next(details.mediaId)}*/
                                                            >
                                                                <strong>VirtualQR - {virtualCodeData.virtualQRCode}</strong>
                                                                <p>{item.description.substr(0, 60)}{"... "}
                                                                    <Link
                                                                        onClick={() => {
                                                                            handleQRModalShow(
                                                                                item.description
                                                                            );
                                                                        }}
                                                                    >
                                                                        Show More
                                                                    </Link>
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            <div className="list-text no-data">
                                                                <p>No Description Available</p>
                                                            </div>
                                                        )}
                                                    </div>

                                                ) : ""}




                                            </div>

                                        ))}

                                </div>
                            </div>
                        </div>
                    </div>

                </section> :

                < div className="invalid-QR">
                   

                        <div class="container">
                            <a href="/QrScan" className="home-icon">
                                <i class="fa-solid fa-house"></i>
                            </a>
                            <div className="invalid">
                                <h4> QR Code has no data!.Thanks </h4>
                            </div>
                        </div>
                    
                </div>
            }
                <Modal
                    show={modal}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    dialogClassName="modal-dialog-centered"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Media Description</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            <div class="list-text">
                                <p>{detailDesc}</p>
                            </div>
                        }
                    </Modal.Body>
                </Modal>    
        </div>

     )

};
export default connect()(VirtualQRs);
