import React, { useState } from 'react';
import { connect } from 'react-redux';
import Footer from '../VerticalLayout/Footer';
import * as url from "../../api/uri_helper"
import Message from 'antd-message';
import toast, { Toaster } from 'react-hot-toast';

// Import static images 
import logo from "../../UserAssets/images/authenteklogo.png"

import EyeClose from "../../assets/images/eyeClose.svg";
import PassLight from "../../assets/images/fi-rr-lock1.svg";
import SeekPng from "../../assets/images/SeekPng.svg";
import axios from 'axios';

const EmailConfirmation = (data) => {
    debugger;
    const sendEmail = async (e) => {
       
            const response = await axios.get(url.GET_EMAILVERIFICATION + '\\' + data.location.state.data )
            .then(response => {
                toast.success(response.data.message,{
                    className:"toastSuccess",
                }, );
                console.log("res",response.data.data);  
            })
                .catch((err) => {
                    console.log("Err: ", err);
                });
        
       
    }
return (
    <section class="auth-section d-flex flex-wrap login-page min-vh-100 "><Toaster/>
        <div class="d-flex align-items-center flex-wrap flex w-100">
            <div class="container">
           <div class="row">
              <div class="col-sm-6 left-section d-flex justify-content-center flex-column">
                <a href="#"  className="logo mb-4 text-center"><img src={logo}/></a>
                <p class="m-0 text-center">Welcome to TEkaHelp</p>
              </div>  
              <div class="col-sm-6 right-section">
                <div class="card auth-card text-center emailConfirm">
                   {/* <img  class="emailImg" src='../../assets/images/SeekPng.svg'/>     */}
                   <img src={SeekPng} className='emailImg'></img>
                   
                   <h2>Email Confirmation</h2>
                   <p>We have sent email to <a href="#">{data.location.state.data}</a> to confirm
                    the validity of our email address. After receiving the email follow
                   the link provided to complete you registration.</p>

                   <div class="resend">
                    <p>If you not got any email <a className='text-link' onClick={(e)=>sendEmail()}> Resend confirmation mail </a></p>
                   </div>
                </div>

              </div>  
           </div>   
        </div>
    </div>
    {/* Robertjohnson541@gmail.com */}
        <div class="auth-footer w-100 position-fixed">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">© The Live TEkaHelp Group Inc. (2022) | TEkaHelp™</div>
                    <div class="col-sm-6 text-end">
                        <a href="#">Terms & Conditions | </a>
                        <a href="#">Privacy Policy</a>
                    </div>
                </div>
            </div>
        </div>
       
    </section>
);
};

export default connect()(EmailConfirmation);
