import { MediaTypes } from "../Constants/Media-Types"

// initial state
const initialState = {
    mediaTypes: []
}

export const MediaReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case MediaTypes.SET_MEDIATYPES:
            return { ...state, mediaTypes: payload.data };

        default:
            return state;
    }
};