import React, { useState } from 'react';
// Import static images 
import Menu from "../assets/images/Menu.svg";
import logo from "../assets/images/authenteklogo.png"
// Import js files
import Header from './HorizontalLayout/Header';
import Navbar from './HorizontalLayout/Navbar';
import Footer from './HorizontalLayout/Footer';
import Menubar from './HorizontalLayout/Menubar';


const Layout = ({ title, children }) => {
   
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () =>
    {setIsOpen(false)}
    const handleOpen = ()=>
    {setIsOpen(true)}
        return (
            <React.Fragment>
                <div className={["layoutSidenav",  isOpen ? "sidebar-small" : "" ].join(" ")}>
                    <div className="layoutSidenav_nav position-relative">
                       <Header isSideBaropen={isOpen} handleClose={handleClose} handleOpen={handleOpen} />
                        <Navbar /> </div>
                    <div className="layout-page">
                        <div className="header-section d-flex align-items-center">
                            <h4 className="mbnone">{title == "Dashboard" ? "Dashboard" : ""}</h4>
                            <button className="mb-hamburger" onClick={handleOpen}><img src={Menu}></img> </button>
                            <div className="mb-logo mobile-side-logo">
                                <a href="#"><img src={logo}></img></a>
                            </div>
                            <div className="ms-auto d-flex align-items-center"><Menubar /></div>
                        </div>
                        <div className="main-section mt-3">
                            <div className="container-fluid">
                                <div className={title == "Dashboard" ? "card-box-new" :"card-box"}> {children} </div>
                            </div>
                            <Footer/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
    export default Layout;
